import i18n from 'i18next';
import k from './../../../../../../i18n/keys';
import React, { useContext, useState } from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';

import Dialog from '../../../../../../Shared/Components/Editing/EditingDialog/Dialog';
import DialogContent from '../../../../../../Shared/Components/Editing/EditingDialog/DialogContent';
import DialogTitle from '../../../../../../Shared/Components/Editing/EditingDialog/DialogTitle';
import DialogActions from '../../../../../../Shared/Components/Editing/EditingDialog/DialogActions';
import WeddingContext from '../../../../../../Shared/Context/WeddingContext';

const EditingDialogCountdown = ({ showCountdown, stopEditing, saveChanges }) => {
  const { weddingConfig } = useContext(WeddingContext);
  const [newShowCountdown, setNewShowCountdown] = useState(showCountdown === undefined ? true : showCountdown);

  const handleSave = async e => {
    e.preventDefault();
    saveChanges({
      nameOfBride: weddingConfig.nameOfBride,
      nameOfGroom: weddingConfig.nameOfGroom,
      weddingDateIsExact: weddingConfig.weddingDateIsExact,
      weddingDate: weddingConfig.weddingDate,
      showCountdown: newShowCountdown,
      selectedLanguagesForWedding: weddingConfig.selectedLanguagesForWedding,
      useCustomLogo: weddingConfig.useCustomLogo,
      customLogoUrl: weddingConfig.customLogoUrl,
    });
  };

  return (
    <Dialog onSubmit={handleSave} open>
      <DialogTitle>{i18n.t(k.EDIT_COUNTDOWN)}</DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={
            <Switch
              onChange={e => setNewShowCountdown(e.target.checked)}
              checked={newShowCountdown}
              value={newShowCountdown}
            />
          }
          label={
            <span>
              {newShowCountdown && i18n.t(k.COUNTDOWN_VISIBLE)}
              {!newShowCountdown && i18n.t(k.COUNTDOWN_NOT_VISIBLE)}
            </span>
          }
        />
      </DialogContent>
      <DialogActions stopEditing={stopEditing}></DialogActions>
    </Dialog>
  );
};

export default EditingDialogCountdown;
