import React, { useContext } from 'react';
import styled from 'styled-components';

import WeddingContext from '../../../Shared/Context/WeddingContext';
import defaultStyles from '../../../Shared/WeddingStyles/DefaultStyles';

const Container = styled.span`
  cursor: pointer;
  margin: 6px;
  display: inline-flex;
  outline: 0;
  align-items: center;
  user-select: none;
  padding: 6px 16px;
  min-width: 64px;
  box-sizing: border-box;
  font-weight: 500;
  border-radius: 4px;
  text-transform: uppercase;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  color: ${({ color }) => `${color} !important`};
  background: ${({ backgroundColor }) => `${backgroundColor} !important`};
  :hover {
    color: ${({ colorHover }) => `${colorHover} !important`};
    background: ${({ backgroundColorHover }) => `${backgroundColorHover} !important`};
  }
`;

const SimulatedUploadButton = ({ children }) => {
  const weddingContext = useContext(WeddingContext);
  const weddingStyle = weddingContext ? weddingContext.weddingConfig.weddingStyle : 'eurehochzeitonline';
  return (
    <Container
      backgroundColor={defaultStyles[weddingStyle].colors.button.primaryOnWhite}
      color={defaultStyles[weddingStyle].colors.button.primaryOnWhite_T}
      backgroundColorHover={defaultStyles[weddingStyle].colors.button.primaryOnWhiteOnHover}
      colorHover={defaultStyles[weddingStyle].colors.button.primaryOnWhiteOnHover_T}
    >
      {children}
    </Container>
  );
};

export default SimulatedUploadButton;
