import styled from 'styled-components';
import { DialogContent } from '@material-ui/core';

import breakpoints from '../../../breakpoints';

export default styled(DialogContent)`
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.mobileKeyboardHeight}) {
    padding: 3px 12px;
    padding-bottom: 60px;
  }
`;
