import styled from 'styled-components';

import breakpoints from '../../../../../Shared/breakpoints';

export default styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: solid 3px;
  border-right: solid 3px;
  background-color: ${({ styleConfig }) => `${styleConfig.backgroundColor}cc`};
  width: calc(95% - 3px);
  border-top-right-radius: 15px;
  border-color: ${({ styleConfig }) => styleConfig.textColor};
  @media (orientation: landscape) and (max-width: ${breakpoints.sm}) {
    padding-left: env(safe-area-inset-left);
  }
  p,
  a {
    color: ${({ styleConfig }) => styleConfig.textColor};
  }
`;
