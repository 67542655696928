import React, { useEffect } from 'react';

const FacebookChat = () => {
  useEffect(() => {
    const checkAndShowDialog = () => {
      if (window.FB) {
        window.FB.CustomerChat.showDialog();
      } else {
        // If window.FB is not defined, check again in 5 seconds
        setTimeout(checkAndShowDialog, 5000);
      }
    };
    checkAndShowDialog();
  }, []);

  return (
    <div>
      <div className="fb-customerchat" attribution="setup_tool" page_id="138454887025084" theme_color="#0084FF" />
    </div>
  );
};

export default FacebookChat;
