const k = require('./keys');

export default {
  // BOF
  [k._DOT]: `.`,
  [k.HELLO]: `Hallo,`,
  [k.ENTER_NEW_PASSWORD]: `Gib hier Dein neues Passwort ein. Danach bist Du automatisch eingeloggt und wirst weitergeleitet.`,
  [k.MINIMUM_6_CHARACTERS]: `Bitte mindestens 6 Zeichen lang.`,
  [k.SAVE]: `Speichern`,
  [k.UNABLE_TO_RESET_PASSWORD]: `Wir konnten Dein Passwort nicht zurücksetzen.`,
  [k.PASSWORD_CHANGED_SUCCESSFULLY_TITLE]: `Passwort erfolgreich geändert`,
  [k.PASSWORD_CHANGED_SUCCESSFULLY]: `Wir haben Dein Passwort geändert! Du kannst Dich von jetzt an mit Deinem neuen Passwort anmelden. Wir leiten Dich jetzt weiter.`,
  [k.FUNCTIONS]: `Funktionen`,
  [k.DESIGNS]: `Designs`,
  [k.PRICES]: `Preise`,
  [k.FAQ]: `FAQ`,
  [k.BLOG]: `Blog`,
  [k.CREATE_NOW_FREE]: `Jetzt Kostenlos Erstellen`,
  [k.PERFECT_PAGE_FOR_YOU]: `Eure Seite perfekt auf Euch zugeschnitten!`,
  [k.PERFECT_DESIGN_IN_FEW_CLICKS]: `Mit unserem Design-Konfigurator in wenigen Klicks zum perfekten Design.`,
  [k.DESIGN]: `-Design`,
  [k.HOW_DESCRIBE_YOUR_DREAM_WEDDING]: `Wie würdet Ihr Eure Traumhochzeit am ehesten beschreiben?`,
  [k.CLICK_ON_ONE_OF_THE_IMAGES]: `Klickt auf eines der Bilder, um den Design-Konfigurator zu starten`,
  [k.CREATE_WEDDING_WEBSITE_NOW]: `Jetzt Hochzeits-Website erstellen`,
  [k.FREQUENTLY_ASKED_QUESTIONS]: `Häufig gestellte Fragen`,
  [k.ANY_MORE_QUESTIONS_OR_FEEDBACK]: `Ihr habt weitere Fragen oder Feedback`,
  [k.WE_LOOK_FORWARD_TO_IT]: `Wir freuen uns drauf!`,
  [k.CONTENT_AND_DESIGN]: `INHALTE & DESIGN`,
  [k.PHOTO_GALLERY]: `PHOTO_GALLERY`,
  [k.RSVP_CARD]: `RSVP / Antwortkarte`,
  [k.PLAYLIST]: `PLAYLIST`,
  [k.MULTILINGUAL_OWN_DOMAIN]: `MEHRSPRACHIGKEIT & EIGENE DOMAIN`,
  [k.DURATION_PASSWORD_PROTECTION]: `LAUFZEIT & PASSWORTSCHUTZ`,
  [k.COST_SUPPORT]: `KOSTEN & SUPPORT`,
  [k.WHAT_CONTENT_TYPICALLY]: `Welche Inhalte gehören typischerweise auf eine Hochzeitswebsite?`,
  [k.CAN_CREATE_WEBSITE_BEFORE_DETAILS]: `Kann ich die Hochzeitswebsite erstellen, bevor alle Details (z.B. unser Hochzeitsdatum) feststehen?`,
  [k.CAN_CUSTOMIZE_DESIGN]: `Kann ich das Design meiner Hochzeitswebsite anpassen?`,
  [k.CHANGES_VISIBLE_AFTER_SAVE]: `Besteht die Möglichkeit, Änderungen an der Homepage vorzunehmen, ohne dass sie sofort für alle sichtbar sind?`,
  [k.PHOTO_UPLOAD_LIMIT]: `Gibt es eine Begrenzung für die Anzahl der Fotos, die wir in die Galerie hochladen können?`,
  [k.ORIGINAL_SIZE_PHOTO_STORAGE]: `Werden die Fotos in ihrer ursprünglichen Größe gespeichert?`,
  [k.GUESTS_DOWNLOAD_UPLOADED_PHOTOS]: `Können Gäste die auf der Website hochgeladenen Fotos herunterladen?`,
  [k.USE_PHOTO_GALLERY_FOR_SLIDESHOW]: `Könnten wir die Fotogalerie auch für eine Live-Diashow während unserer Feier nutzen?`,
  [k.HOW_DOES_R_S_V_P_WORK]: `Wie funktioniert die RSVP-Funktion?`,
  [k.GUESTS_ADD_OWN_SONGS_TO_PLAYLIST]: `Können Gäste eigene Lieder zur Playlist hinzufügen?`,
  [k.CAN_DJ_ACCESS_PLAYLIST]: `Kann unser DJ auf die Playlist zugreifen?`,
  [k.POSSIBILITY_OF_MULTIPLE_LANGUAGES]: `Ist es möglich, unsere Homepage in mehreren Sprachen zu gestalten?`,
  [k.OWN_DOMAIN_POSSIBILITY]: `Besteht die Möglichkeit, eine eigene Domain für unsere Hochzeitshomepage zu erhalten?`,
  [k.HOW_LONG_ACCESS_WEDDING_WEBSITE]: `Wie lange können wir auf unsere Hochzeits-Website zugreifen?`,
  [k.PROTECT_SITE_WITH_PASSWORD]: `Können wir unsere Seite mit einem Passwort schützen?`,
  [k.IS_EUREHOCHZEITONLINE_FREE]: `Ist die Nutzung von EureHochzeitOnline.com kostenlos?`,
  [k.HOW_GET_SUPPORT_EUREHOCHZEITONLINE]: `Wie kann ich Unterstützung bei der Erstellung meiner Hochzeitswebsite auf EureHochzeitOnline.com erhalten?`,
  [k.WEDDING_CONTENT_INDIVIDUAL]: `Die Inhalte deiner Hochzeitswebsite sind so individuell wie deine Hochzeit selbst. Neben grundlegenden Informationen wie Datum und Ort kannst du Dresscodes, Anfahrtsbeschreibungen, Wünsche, Kontaktdaten der Trauzeugen oder den geplanten Ablauf hinzufügen. Für Gäste, die eine weite Anreise haben und Unterkünfte benötigen, kannst du Hotelvorschläge auflisten. Es ist auch eine schöne Idee, deine Liebesgeschichte für deine Gäste zu teilen oder persönliche Worte über deinen Partner zu schreiben. Deiner Kreativität sind keine Grenzen gesetzt.`,
  [k.YES_FEASIBLE_ADVANTAGES]: `Ja, das ist problemlos möglich und bietet sogar erhebliche Vorteile. Du kannst Informationen jederzeit hinzufügen und die neuesten Informationen bereitstellen, selbst wenn deine Hochzeitseinladungen bereits verschickt wurden.`,
  [k.YES_EUREHOCHZEITONLINE_OFFERS]: `Ja, EureHochzeitOnline.com bietet eine Vielzahl von Gestaltungsmöglichkeiten für deine Hochzeitswebsite. Du kannst Farben, Schriftarten und zusätzliche Elemente anpassen. Bei Fragen und Anmerkungen komme gerne auf uns zu.`,
  [k.INDEED_CHANGES_VISIBLE]: `Tatsächlich ist es so, dass alle Änderungen an deiner Homepage sofort und in Echtzeit für alle Besucher sichtbar werden. Wir bieten derzeit keine Funktion an, um Änderungen im Voraus zu planen oder zu verbergen.`,
  [k.IN_FREE_VERSION_LIMITED_PHOTOS]: `In der kostenfreien Version gibt es eine beschränkte Anzahl von Fotos, die ihr in eure Galerie hochladen könnt. Dies liegt daran, dass Speicher und Datenverkehr für uns auch Kosten verursachen. Wenn ihr jedoch das Premium- oder Domain-Paket bucht, habt ihr die Möglichkeit, eine unbegrenzte Anzahl von Fotos in eure Galerie hochzuladen. Tipps für die perfekten Hochzeitsfotos findet Ihr auf unserem Blog.`,
  [k.WE_GENERATE_DIFFERENT_SIZES]: `Wir erstellen während des Foto-Uploads verschiedene Größenvarianten für jedes Bild. Abhängig von der Bildschirmgröße des Besuchers wird automatisch die optimale Größe ausgewählt, um schnelle Ladezeiten bei bestmöglicher Bildqualität sicherzustellen. Wir speichern aber auch die Originaldateien der Fotos, so dass Ihr diese auch bekommen könnt.`,
  [k.YES_GUESTS_CAN_DOWNLOAD]: `Ja, Gäste können die hochgeladenen Fotos herunterladen.`,
  [k.YES_FEASIBLE_SCREEN_PROJECTOR]: `Absolut! Das ist sogar eine der beliebtesten Funktionen von EureHochzeitOnline. Ihr braucht lediglich eine stabile Internetverbindung und natürlich einen Bildschirm oder eine Leinwand mit Beamer.`,
  [k.R_S_V_P_FUNCTION_SIMPLIFIES]: `Die RSVP-Funktion ermöglicht eine einfache Weitergabe der Hochzeitswebsite-Zugangsdaten an eure Gäste. Diese werden beim Besuch Eurer Hochzeits-Website gebeten, ihre Rückmeldung zu hinterlassen (z.B. mit wie vielen Personen sie teilnehmen werden, ob es sich um Kinder oder Erwachsene handelt, sowie eventuelle besondere Essenswünsche, Allergien oder sonstige Anforderungen). Im Gästebereich behaltet ihr jederzeit den Überblick über die Anzahl der Zu- und Absagen. Eine Schritt-für Schritt-Anleitung findet Ihr in unserem Blog.`,
  [k.YES_YOUR_GUESTS_CAN_SUGGEST]: `Absolut! Deine Gäste haben die Möglichkeit, ihre Lieblingslieder vorzuschlagen und zur Playlist hinzuzufügen. Auf diese Weise könnt ihr nicht nur die Vielfalt eurer Gäste feiern, sondern auch die Musik genießen, die für dich und deine Liebsten wichtig ist.`,
  [k.NATURALLY_INVITE_DJ_TO_WEBSITE]: `Natürlich. Ladet Euren DJ einfach zu Eurer Webseite ein und schon stehen ihm alle Wünsche zur Verfügung – wenn Ihr möchtet, natürlich sogar live während Eurer Party.`,
  [k.YES_YOU_CAN_HAVE_MULTIPLE_LANGUAGES]: `Ja, ihr könnt eure Homepage in mehreren Sprachen gestalten. Diese Funktion steht ab dem Premium-Paket zur Verfügung, und ihr könnt alle Informationen in verschiedenen Sprachen übersetzen. Meldet Euch bitte bei uns, damit wir für Eure Hochzeit die Konfiguration vornehmen.`,
  [k.YES_YOU_CAN_HAVE_OWN_DOMAIN]: `Ja, ihr könnt eine eigene Domain (z.B. www.juliaundromeo.de) ganz einfach für eure Hochzeitshomepage mit unserem Premium-Paket buchen. Meldet Euch bitte bei uns, damit wir für Euch die Domain buchen und die Weiterleitung einrichten können.`,
  [k.YOUR_WEDDING_WEBSITE_AVAILABLE_TWO_YEARS]: `Deine Hochzeitswebsite bleibt für Dich zwei Jahre nach Erstellung verfügbar. Danach wird sie gelöscht, um Platz für neue Hochzeits-Websites zu machen. Wir erinnern Euch aber natürlich rechtzeitig vorab. Verlängerungen sind auf Anfrage möglich.`,
  [k.ALL_WEDDING_WEBSITES_PASSWORD_PROTECTED]: `Alle Hochzeits-Websites sind mit einem Passwort geschützt, das Euch nach Erstellung angezeigt wird.`,
  [k.YES_CREATE_WEDDING_WEBSITE_FREE]: `Ja, du kannst eine Hochzeitswebsite auf EureHochzeitOnline.com kostenlos erstellen und gestalten. Einige erweiterte Funktionen sind jedoch kostenpflichtig, insbesondere solche, die zusätzliche Server-Ressourcen erfordern. Es gibt keine versteckten Gebühren.`,
  [k.IF_YOU_NEED_SUPPORT_WE_CAN_HELP]: `Wenn du Unterstützung bei der Erstellung deiner Hochzeitswebsite benötigst, kannst du uns gerne eine Nachricht an beeni@eurehochzeitonline.com senden. Wir werden uns umgehend bei dir melden, um dir zu helfen.`,
  [k.KEY_FEATURES_OVERVIEW]: `Die wichtigsten Funktionen im Überblick!`,
  [k.YOUR_WEDDING_WEBSITE_OFFERS_EVERYTHING]: `Eure Hochzeits-Website bietet alles für eine Traumhochzeit.`,
  [k.NEW_NOW]: `Jetzt neu:`,
  [k.MULTILINGUAL_WEDDINGS]: `Mehrsprachige Hochzeiten!`,
  [k.ON_REQUEST_WE_PROVIDE_WEDDING_WEBSITES]: `Jetzt neu: Mehrsprachige Webseiten!`,
  [k.SCHEDULE]: `Ablauf`,
  [k.LOCATIONS]: `Locations`,
  [k.RSVP]: `RSVP`,
  [k.GUESTMANAGEMENT]: `Gästeverwaltung`,
  [k.HOTEL_RECOMMENDATION]: `Hotelempfehlungen`,
  [k.PRESENT_TABLE]: `Geschenketisch`,
  [k.PHOTO_GALLERY]: `Fotogalerien`,
  [k.PLAYLIST]: `Playlist`,
  [k.OWN_DOMAIN]: `Eigene Domain`,
  [k.DIVERSE_LAYOUTS]: `Unzählige Layouts und Designs einfach ausgewählt und angepasst.`,
  [k.YOUR_WEDDING_DAY]: `Euer Hochzeitstag übersichtlich für Eure Gäste.`,
  [k.CLEAR_DIRECTIONS]: `Verständliche Wegbeschreibungen zu allen Hochzeitslocations.`,
  [k.NO_GUESSWORK]: `Kein mühsames Nachfragen, wer zur Hochzeit kommt.`,
  [k.GUEST_ADDRESSES_ALLERGIES]: `Adressen, Allergien, etc. mit nur einem Klick gesammelt!`,
  [k.HOTEL_SELECTION_FOR_GUESTS]: `Hotelauswahl für Eure Gäste, um das Reisen zu erleichtern!`,
  [k.EASY_ONLINE_GIFT_REGISTRY]: `Online-Hochzeitstisch spielend leicht zusammengestellt`,
  [k.WEDDING_PICTURES_FOR_GUESTS]: `Hochzeitsbilder für Eure Gäste und ihre Aufnahmen.`,
  [k.CUSTOM_SPOTIFY_PLAYLIST_CREATION]: `Eigene Spotify-Playlist – erstellt durch Euch und Eure Gäste.`,
  [k.WEDDING_HOMEPAGE_WITH_OWN_DOMAIN]: `Hochzeitshomepage mit eigener Domain und Passwortschutz.`,
  [k.YOUR_WEDDING_WEBSITE]: `EURE HOCHZEITS-WEBSITE`,
  [k.UNIQUE_LIKE_YOU]: `- SO EINZIGARTIG WIE IHR!`,
  [k.EASY_SETUP_CUSTOMIZED]: `Spielend leicht eingerichtet und passgenau auf Euch abgestimmt!`,
  [k.ALL_DETAILS_OF_YOUR_CELEBRATION]: `Alle Details Eures Fests auf einen Blick – egal ob am Handy oder PC`,
  [k.GUEST_FEEDBACK_AND_WISHES]: `Die Rückmeldungen und Wünsche Eurer Gäste jederzeit im Blick`,
  [k.YOUR_CELEBRATION_FROM_ALL_VIEWS]: `Euer Fest aus allen Perspektiven - Live-Bildergalerie für Euch und Eure Gäste`,
  [k.MULTILINGUAL_SUPPORT]: `… natürlich auch für mehrsprachige Hochzeiten`,
  [k.FREE_CREATE]: `Kostenlos erstellen`,
  [k.DEMO_WEBSITE]: `Zur Demo-Website`,
  [k.OUR_PRICING]: `Unsere Preise`,
  [k.UNLOCK_ALL_FEATURES]: `Schaltet für einen einmaligen Betrag alle Funktionen frei, die Ihr wollt`,
  [k.ANY_QUESTIONS_OR_FEEDBACK]: `Ihr habt weitere Fragen oder Wünsche?`,
  [k.WE_ARE_HERE_TO_HELP]: `Wir freuen uns über Eure Nachricht und helfen weiter!`,
  [k.USER_TESTIMONIALS]: `Stimmen unserer Nutzer:innen`,
  [k.THOUSANDS_OF_PEOPLE]: `Bis heute haben Tausende Menschen mit EureHochzeitOnline gefeiert!`,
  [k.OUR_GIFT_TIP]: `Unser Geschenk-Tipp`,
  [k.FOR_ALL_WITNESSES]: `für alle Trauzeugen:`,
  [k.BUY_WEDDING_WEBSITE_AS_GIFT]: `Schenkt doch dem Brautpaar eine individuelle Hochzeitswebsite!`,
  [k.BRIDE]: `Braut`,
  [k.F_WITNESS]: `Trauzeugin`,
  [k.GROOM]: `Bräutigam`,
  [k.TESTIMONIAL_1]: `Die Erstellung unserer Homepage war ein Kinderspiel. Wir waren wirklich überrascht, wie einfach es war selbst komplizierte Informationen einzufügen. Wir konnten so wirklich UNSERE Website bauen. Unsere Gäste waren beeindruckt!`,
  [k.TESTIMONIAL_2]: `Toll war, dass alle Gäste die Website in ihrer eigenen Sprache nutzen konnten. Besonders cool fanden sie die Playlist-Funktion, mit der sie ihre eigenen Lieblingslieder vorschlagen konnten. So wurde die Vielfalt der Gäste auch durch die Partymusik gefeiert.`,
  [k.TESTIMONIAL_3]: `Unsere Webseite war während unserer gesamten Hochzeitsplanung eine unschätzbare Hilfe. Wir konnten jederzeit wichtige Informationen aktualisieren und sogar Fotos in einer wunder schönen Fotogalerie präsentieren. Unsere Gäste waren von der Live-Diashow begeistert und wir erst recht.`,
  [k.TESTIMONIAL_4]: `Wir hören nun über ein Jahr später immer noch gerne in die Spotify Playlist unserer Hochzeit rein. Mit ihren Funktionen hat uns unsere Hochzeits-Homepage dabei geholfen eine tolle Hochzeit mit wunderbaren Erinnerungen zu feiern.`,
  [k.THANK_YOU]: 'Vielen Dank!',
  [k.ACTIVATED_MAIL]: `Du hast deine Mail-Adresse aktiviert!`,
  [k.CLICK_HERE_TO_GO_TO_WED]: `Klicke hier, um auf die Hochzeits-Homepage von`,
  [k.AND]: `und`,
  [k.BE_REDIRECTED]: `weitergeleitet zu werden.`,
  [k.SET_A_PASSWORD]: `Bitte lege noch ein Passwort fest, mit dem Du Dich immer anmelden und Eure Hochzeit bearbeiten kannst!`,
  [k.SET_A_PASSWORD_SHORT]: `Bitte lege noch ein Passwort fest`,
  [k.ERROR_OCCURED]: `Leider ist ein Fehler aufgetreten. Bitte versuch es noch einmal. Falls der Fehler wiederholt auftritt, kannst Du uns eine E-Mail schreiben an:`,
  [k.PASSWORD_ALREADY_EXISTING_TITLE]: `Du hast schon ein Passwort angelegt`,
  [k.PASSWORD_ALREADY_EXISTING]: `Wir haben festgestellt, dass Du schon ein Passwort festgelegt hast. Du kannst`,
  [k.GO_TO_WEDDING]: `hier zu Eurer Hochzeits-Homepage gehen und Dich anmelden`,
  [k.I_AGREE]: `Ich akzeptiere die`,
  [k.REGISTER_NOW]: `Jetzt registrieren!`,
  [k.YOUR_FREE_WEDDING_WEBSITE]: `Eure kostenlose Hochzeits-Homepage!`,
  [k.ALREADY_CREATED_WEDDINGS]: `Du hast die folgenden Hochzeits-Homepages bereits erstellt. Wahrscheinlich möchtest Du lieber die vorhandene Hochzeits-Homepage bearbeiten als eine neue zu erstellen. Klick dafür einfach unten.`,
  [k.WEDDING]: `Hochzeit`,
  [k.ENTER_PASSWORD]: `Passwort eingeben`,
  [k.USER_ALREADY_EXISTING]: `Mit dieser E-Mail Adresse haben wir bereits einen registrierten Nutzer. Bitte gib Dein Passwort ein.`,
  [k.BACK]: `Zurück`,
  [k.LOGIN]: `Anmelden`,
  [k.FORGOT_PASSWORD]: `Passwort vergessen? Hier klicken.`,
  [k.MAIL_SENT]: `Wir haben Dir eine E-Mail geschickt. Bitte klicke auf den Link in der E-Mail, um ein neues Passwort zu vergeben.`,
  [k.OK]: `OK`,
  [k.PERFECT]: `Perfekt,`,
  [k.NAME_OF]: `Wie heißt`,
  [k.SPOUSE_M]: `Dein Auserwählter`,
  [k.SPOUSE_F]: `Deine Auserwählte`,
  [k.NEXT]: `Weiter`,
  [k.WHATS_YOUR_NAME]: `Super! Wie heißt Du?`,
  [k.PREPARING_1]: `Wir bereiten Eure Hochzeits-App vor...`,
  [k.PREPARING_2]: `Wir speichern Euer gewähltes Design...`,
  [k.PREPARING_3]: `Ein Paar letzte Optimierungen...`,
  [k.FINISHED_ENTER_MAIL]: `Fertig! Bitte gib uns Deine E-Mail-Adresse, damit wir Eure Hochzeits-App speichern und Euch die Zugangsdaten zusenden können.`,
  [k.ACCEPT_TERMS]: `Bitte Nutzungsbedingungen akzeptieren.`,
  [k.ENTER_VALID_MAIL]: `Bitte gültige E-Mail-Adresse eingeben.`,
  [k.QUESTION_BRIDE_OR_GROOM]: `Bist Du Braut oder Bräutigam?`,
  [k.QUESTION_WEDDING_DATE]: `Kennt Ihr schon Euer Hochzeitsdatum?`,
  [k.YES_WE_KNOW]: `Ja, kennen wir!`,
  [k.NO_WE_DONT_KNOW]: `Nein, noch nicht!`,
  [k.MAKE_SELECTION]: `Bitte eine Auswahl treffen.`,
  [k.QUESTION_WEDDING_LOCATION]: `Habt Ihr schon eine Location?`,
  [k.YES_WE_HAVE]: `Ja, haben wir!`,
  [k.ENTER_YOUR_LOCATION]: `Bitte gib Eure Hochzeits-Location ein.`,
  [k.LOCATION_CLASSIC]: `Klassisch`,
  [k.LOCATION_COUNTRY]: `Ländlich`,
  [k.LOCATION_OUTDOOR]: `Outdoor`,
  [k.LOCATION_PALACE_HOTEL]: `Schlosshotel`,
  [k.QUESTION_WEDDING_STYLE]: `Welcher Stil gefällt Dir am besten?`,
  [k.HOTEL]: `Hotel`,
  [k.INFOS]: `Infos`,
  [k.LOCATION]: `Location`,
  [k.PHOTOS]: `Fotos`,
  [k.NO_MUSIC_SAVED]: `Noch keine Lieder gespeichert...`,
  [k.SEARCH_FOR_SONGS]: `Suche oben nach Deinem Lieblingslied!`,
  [k.LISTEN_TO_FULL_LIST]: `Gesamte Hochzeits-Playlist anhören`,
  [k.OPEN_IN_SPOTIFY]: `In Spotify öffnen`,
  [k.LISTEN_TO_SINGLE_SONGS]: `Einzelne Lieder der Playlist anhören`,
  [k.NO_RESULT_FOUND]: `Kein Ergebnis gefunden`,
  [k.CANCEL]: `Abbrechen`,
  [k.ADD_SONG]: `Lied hinzufügen`,
  [k.NO_PHOTOS_UPLOADED_YET]: `Noch keine Fotos hochgeladen...`,
  [k.ADD_SOME_PHOTOS]: `Klicke oben und mach ein Paar Fotos!`,
  [k.DROP_PHOTOS_HERE_OR]: `Fotos hier ablegen oder`,
  [k.SHOOT_PHOTO]: `Foto schießen`,
  [k.OVERVIEW_OF_ALL_PRESENTS]: `Übersicht aller Geschenke und Eintragungen`,
  [k.LOADING_BACKINGS]: `Eintragungen werden geladen...`,
  [k.NO_PRESENTS_YET]: `Ihr habt noch keine Geschenke für Euren Gabentisch erfasst.`,
  [k.PRESENT]: `Geschenk`,
  [k.E_MAIL_ADDRESS]: `E-Mail-Adresse`,
  [k.DATE]: `Datum`,
  [k.NO_BACKINGS_YET]: `Noch keine Eintragung`,
  [k.CLOSE]: `Schließen`,
  [k.ONLY_VISIBLE_FOR_WEDDING_COUPLE]: `(Nur für das Brautpaar sichtbar)`,
  [k.SHOW_ALL_ENTRIES]: `Alle Eintragungen anzeigen`,
  [k.EUR]: `EUR`,
  [k.ADD_BACKING]: `Eintragen`,
  [k.BUY]: `Kaufen`,
  [k.YOUR_BACKING_SAVED]: `Du bist eingetragen!`,
  [k.A_GUEST_ALREADY_BACKED]: `Bereits eingetragen.`,
  [k.DELETE_MY_BACKING]: `Meine Eintragung wieder löschen.`,
  [k.OVERVIEW_OF_ALL_RSVPS]: `Übersicht aller Anmeldungen`,
  [k.LOADING_RSVPS]: `Anmeldungen werden geladen...`,
  [k.NO_RSVPS_YET]: `Noch keine Antwortkarten erhalten.`,
  [k.RESPONSE]: `Antwort`,
  [k.GUESTS_AND_MENU_WISH]: `Gäste & Menüwunsch`,
  [k.QUESTIONS_AND_REMARKS]: `Frage/Anmerkung`,
  [k.WILL_ATTEND]: `Zusage`,
  [k.WILL_NOT_ATTEND]: `Absage`,
  [k.RSVP]: `Antwortkarte`,
  [k.SHOW_ALL_RSVPS]: `Alle Anmeldungen anzeigen`,
  [k.YOU_ALREADY_SUBMITTED_ON]: `Du hast die Antwort bereits am`,
  [k.CANNOT_CHANGE_ANY_MOR]: `verschickt. Die Angaben können nicht mehr geändert werden.`,
  [k.MENU_WISH]: `Menüwunsch`,
  [k.RSVP_LOADING]: `Antwortkarte wird geladen...`,
  [k.LIST_EVERYONE_WE_MAY_EXPECT]: `Bitte macht unten eine Aufstellung von allen, die wir erwarten dürfen`,
  [k.LIST_EVERYONE_WE_MAY_EXPECT_WITH_MENU]: `Bitte macht unten eine Aufstellung von allen, die wir erwarten dürfen und nennt uns auch Eure Menüwünsche`,
  [k.GUEST]: `Gast`,
  [k.ADD_GUEST]: `Weiteren Gast hinzufügen`,
  [k.SUBMIT_RSVP]: `Antwortkarte senden`,
  [k.SCHEDULE]: `Ablauf`,
  [k.WEBSITE]: `Website`,
  [k.OUR_PACKAGES]: `Unsere Pakete`,
  [k.PACKAGE_BASIC]: `Basis`,
  [k.PACKAGE_COMPLETE]: `Komplett`,
  [k.PACKAGE_PREMIUM]: `Premium`,
  [k.PACKAGE_VIP]: `VIP`,
  [k.BEST_SELLER]: `Best Seller`,
  [k.SELECTED]: `Ausgewählt`,
  [k.SELECT]: `Auswählen`,
  [k.CAN_UPGRADE_LATER]: `Keine Sorge, Du kannst Dein Paket auch jederzeit später upgraden, indem Du einfach die Differenz zahlst.`,
  [k.LOOK_IN_FAQ]: `Weitere Fragen? Schau gerne in unsere FAQ!`,
  [k.PACKAGE_FEATURES_DESIGN]: `Designs, Farben, Schriftarten`,
  [k.PACKAGE_FEATURES_SUBDOMAIN]: `Eure Subdomain mit Kennwort`,
  [k.PACKAGE_FEATURES_MOBILE]: `Desktop & Mobile optimiert`,
  [k.PACKAGE_FEATURES_SCHEDULE]: `Tagesablauf mit Zeitplan`,
  [k.PACKAGE_FEATURES_LOCATION]: `Locationplaner und -infos`,
  [k.PACKAGE_FEATURES_INFOS]: `Infoseiten (FAQ, Geschenke, etc.)`,
  [k.PACKAGE_FEATURES_RSVP]: `Gästeanmeldung & Verwaltung`,
  [k.PACKAGE_FEATURES_MUSIC]: `Musikwünsche durch Gäste`,
  [k.PACKAGE_FEATURES_PHOTOS]: `Fotoupload durch Gäste`,
  [k.PACKAGE_FEATURES_SUPPORT]: `Kundenservice via E-Mail`,
  [k.PACKAGE_FEATURES_MULTILANGUAGE]: `Mehrsprachige Webseite`,
  [k.PACKAGE_FEATURES_OWN_DOMAIN]: `Eure Eigene Domain (.de)`,
  [k.PACKAGE_FEATURES_CUSTOM_DESIGN]: `Individuelles Design vom Profi`,
  [k.MAX_FIVE]: `max. 5`,
  [k.CHECKING_PAYMENT_STATUS]: `Prüfe Status der Bezahlung`,
  [k.PAYMENT_SUCCESSFULL]: `Vielen Dank! Die Bezahlung war erfolgreich, Ihr könnt Eure Hochzeits-App nun in folgendem Paket nutzen: `,
  [k.PAYMENT_FAILED]: `Leider hat die Bezahlung nicht funktioniert. Ihr könnt es noch einmal versuchen oder uns eine E-Mail schreiben an: `,
  [k.ACTIVATE_PACKAGE_NOW]: `Jetzt freischalten: `,
  [k.PAY]: `Bezahlen`,
  [k.EDITING_MODE]: `Bearbeitungsmodus`,
  [k.ACTIVE]: `ist aktiv`,
  [k.NOT_ACTIVE]: `ist nicht aktiv`,
  [k.ERROR_OCCURED_WITH_MESSAGE]: `Leider ist ein Fehler aufgetreten. Technische Rückmeldung: `,
  [k.TRY_AGAIN_PLEASE]: `Bitte versuch es noch einmal. Falls der Fehler wiederholt auftritt, kannst Du uns eine E-Mail schreiben an: `,
  [k.PACKAGE_UPGRADE]: `Paket Upgrade`,
  [k.PACKAGE_ALREADY_BOUGHT]: `Ihr habt bereits ein Paket für Eure Hochzeit gebucht.`,
  [k.UPGRADE_TO_HIGHER_PACKAGE]: `Natürlich könnt Ihr Eure Website auch auf ein höheres Paket upgraden, indem Ihr einfach die Differenz bezahlt. Bitte schreibt uns dazu eine kurze E-Mail und wir machen das schnell für Euch.`,
  [k.REGISTRATION_NOT_COMPLETE]: `Registrierung noch nicht abgeschlossen. Bitte klicke auf den Link, den wir Dir zugeschickt haben.`,
  [k.YOU_HAVE_AN_EMAIL]: `Du hast eine E-Mail von uns.`,
  [k.YOU_MUST_REGISTER_FIRST]: `Du musst Deine Registrierung abschließen, um Eure Hochzeits-Homepage bearbeiten zu können.`,
  [k.CREATOR_MUST_REGISTER]: `Das ist alles kostenlos und geht ganz schnell: Du musst einfach auf den Link in der E-Mail klicken, die wir Dir geschickt haben. Du findest die E-Mail nicht mehr? Kein Problem klick einfach: `,
  [k.HERE]: `Hier`,
  [k.E_MAIL_RESENT]: `E-Mail erneut verschickt.`,
  [k.CONFIRMATION_RESENT_TO]: `Wir haben die E-Mail mit dem Bestätigungslink nochmal geschickt an: `,
  [k.CAN_TAKE_SOME_MINUTES]: `Es kann ein Paar Minuten dauern, bis die E-Mail bei Dir ankommt. Falls Du weiterhin Probleme hast, kannst Du uns gerne einfach eine E-Mail schreiben und wir helfen Dir weiter: `,
  [k.RESENDING_MAIL]: `E-Mail wird erneut gesendet.`,
  [k.DEMO_WEDDING_HINT]: `Demo-Seite - jetzt kostenlos Eure eigene erstellen und ausprobieren!`,
  [k.CREATE_OWN_WEBSITE]: `Erstellt Eure eigene Website!`,
  [k.SOME_FEATURES_DEACTIVATED_IN_DEMO]: `Auf der Demo-Seite sind einige Funktionen deaktiviert.`,
  [k.CAN_CREATE_OWN_WEDDING_WEBSITE]: `Ihr könnt Euch anmelden, Eure eigene Hochzeits-Website erstellen und alle Funktionen dort ausprobieren - kostenlos!`,
  [k.NO_PACKAGE_BOUGHT_HINT]: `Kostenlose Basisversion - jetzt upgraden für unbegrenzt Fotos, Gäste und weitere Features.`,
  [k.LETS_UPGRADE]: `Lass uns updgraden.`,
  [k.MAX_NUMBER_FOR_BASIC_PACKAGE]: `Ihr habt die maximale Anzahl für das kostenlose Basis-Paket erreicht.`,
  [k.UPGRADE_FOR_UNLIMITED_PHOTOS]: `Ihr könnt auf eines unserer Premium-Pakete upgraden, um unbeschränkt viele Fotos hochzuladen.`,
  [k.UPGRADE_FOR_UNLIMITED_MUSIC]: `Ihr könnt auf eines unserer Premium-Pakete upgraden, um unbeschränkt viele Lieder hinzuzufügen.`,
  [k.CONFIRM_DELETE_TITLE]: `Löschen bestätigen`,
  [k.CONFIRM_DELETE_TEXT]: `Sicher, dass Du dieses Element löschen möchtest?`,
  [k.DELETE]: `Löschen`,
  [k.ADD_ELEMENT]: `Element hinzufügen`,
  [k.WHICH_ELEMENT_TO_ADD]: `Was für ein Element soll hinzugefügt werden?`,
  [k.SELECT_ICON_FOR_LOCATION]: `Suche ein Icon für die Location aus`,
  [k.IMAGE_POSITION]: `Bildposition`,
  [k.SELECT_IMAGE]: `Bild aussuchen`,
  [k.UPLOAD]: `Hochladen`,
  [k.SELECTED_IMAGE]: `Ausgewähltes Bild`,
  [k.NO_IMAGE]: `Ohne Bild`,
  [k.OTHER_IMAGE]: `Anderes Bild`,
  [k.EDIT_WEDDING_BASE_DATA]: `Eckdaten der Hochzeit bearbeiten`,
  [k.ENTER_NAME_OF_BRIDE]: `Bitte Name der Braut eingeben.`,
  [k.ENTER_NAME_OF_GROOM]: `Bitte Name des Bräutigams eingeben.`,
  [k.SELECT_DATE]: `Bitte ein Datum wählen.`,
  [k.HINT_CHANGE_URL]: `Hinweis: Wenn Ihr die Adresse, unter der Eure Hochzeits-Homepage erreichbar ist, ändern wollt, schreibt uns gerne eine E-Mail an: `,
  [k.EDIT_COUNTDOWN]: `Hochzeits-Countdown bearbeiten`,
  [k.EDIT_INFO_BOX]: `Info-Box bearbeiten`,
  [k.EDIT_BOX_WITH_LARGE_PHOTO]: `Box mit großem Foto bearbeiten`,
  [k.EDIT_LOCATION]: `Location bearbeiten`,
  [k.EDIT_PLACE]: `Ort bearbeiten`,
  [k.ADD_LOCATION]: `Location hinzufügen`,
  [k.ADD_PLACE]: `Ort hinzufügen`,
  [k.SELECT_SEARCH_RESULT]: `Bitte ein Suchergebnis auswählen.`,
  [k.EDIT_PAGE_TITLE]: `Seitentitel bearbeiten`,
  [k.EDIT_PRESENTS]: `Gabentisch bearbeiten`,
  [k.PRESENT_INTRO_TEXT]: `Legt hier Euren ganz persönlichen Gabentisch an, der Euren Gästen Impulse geben kann.`,
  [k.ADDITIONAL_ENTRY]: `Weiterer Eintrag`,
  [k.EDIT_RSVP]: `RSVP bearbeiten`,
  [k.MENU_WISHES_ACTIVATED]: `Sollen Eure Gäste Menüwünsche bei der Anmeldung angeben?`,
  [k.EDIT_SCHEDULE_ITEM]: `Programmpunkt bearbeiten`,
  [k.NAVIGATION]: `Menü`,
  [k.COUPLE]: `Brautpaar`,
  [k.PRESENT_TABLE]: `Gabentisch`,
  [k.MUSIC]: `Musik`,
  [k.YOU_ARE_LOGGED_IN_AS_GROOM]: `Du bist als Bräutigam angemeldet.`,
  [k.YOU_ARE_LOGGED_IN_AS_BRIDE]: `Du bist als Braut angemeldet.`,
  [k.ADDRESS_OF_YOUR_PAGE]: `Adresse Eurer Hochzeits-Homepage:`,
  [k.WEDDING_CODE_FOR_GUESTS]: `Hochzeitscode für Eure Gäste: `,
  [k.CURRENT_PACKAGE]: `Euer aktuelles Paket:`,
  [k.UPGRADE]: `Upgrade`,
  [k.LOGOUT]: `Abmelden`,
  [k.FOR_GUESTS]: `Für Gäste`,
  [k.INVISIBLE]: `unsichtbar`,
  [k.VISIBLE]: `sichtbar`,
  [k.WEDDING_NOT_FOUND]: `Hochzeits-Homepage nicht gefunden.`,
  [k.WEDDING_NOT_FOUND_TEXT]: `Leider konnten wir unter der von Dir eingegebenen Adresse keine Hochzeits-Homepage finden. Kann es sein, dass Du Dich vertippt hast? Bitte versuch es noch einmal.`,
  [k.IF_ERROR_REPEATS]: `Falls der Fehler wiederholt auftritt, kannst Du uns eine E-Mail schreiben an:`,
  [k.ERROR_GENERAL]: `Es ist ein Fehler aufgetreten`,
  [k.SORRY_WRITE_MAIL]: `Das tut uns leid. Du kannst es gerne noch einmal probieren. Falls der Fehler wiederholt auftritt, kannst Du uns gerne einfach eine E-Mail schreiben und wir helfen Dir weiter.`,
  [k.MOVE_UP]: `Nach oben verschieben`,
  [k.MOVE_DOWN]: `Nach unten verschieben`,
  [k.OH_SORRY]: `Oh je... Da ist jetzt ein Fehler passiert...`,
  [k.ERROR_REFRESH_PAGE]: `Das tut uns sehr leid. Du kannst gerne die Seite neu laden und es noch einmal probieren. Falls der Fehler wiederholt auftritt, kannst Du uns eine E-Mail schreiben an:`,
  [k.IF_YOU_WANT_TO_HELP]: `Wenn Du uns dabei helfen willst, den Fehler besonders schnell zu finden und zu beheben, kannst Du gerne den folgenden Text in Deine E-Mail reinkopieren:`,
  [k.ENTER_DISPLAY_NAME]: `Super! Gib bitte noch Deinen Namen ein - Spitzname geht auch. Er erscheint z.B. unter Fotos, die Du hochlädst.`,
  [k.ENTER_DISPLAY_NAME_SHORT]: `Bitte einen Namen eingeben.`,
  [k.WE_SENT_EMAIL]: `Wir haben Dir eine E-Mail geschickt. Bitte klicke auf den Link in der E-Mail, um ein neues Passwort zu vergeben.`,
  [k.WELCOME_BACK]: `Willkommen zurück,`,
  [k.ENTER_PASSWORD]: `Bitte gib Dein Passwort ein.`,
  [k.ALMOST_THERE]: `Fast geschafft,`,
  [k.PLEASE_SET_PASSWORD]: `Bitte lege jetzt noch ein Passwort fest.`,
  [k.PLEASE_SET_PASSWORD_SHORT]: `Lege ein Passwort fest`,
  [k.ENTER_PASSWORD]: `Bitte gib Dein Passwort ein`,
  [k.WELCOME_TO_WEDDING_OF]: `Herzlich Willkommen zur Hochzeits-Homepage von`,
  [k.PLEASE_ENTER_MAIL]: `! Bitte gib Deine E-Mail-Adresse ein.`,
  [k.WELCOME_TO_EHO]: `Herzlich Willkommen zur Hochzeits-Plattform EureHochzeitOnline.com! Bitte gib Deine E-Mail-Adresse ein.`,
  [k.ENTER_VALID_MAIL]: `Bitte gültige E-Mail eingeben.`,
  [k.TO_WEDDING]: `Zur Hochzeit`,
  [k.ENTER_WEDDING_CODE]: `! Bitte gib den Hochzeits-Code ein.`,
  [k.ENTER_WEDDING_CODE_SHORT]: `Bitte Hochzeits-Code eingeben.`,
  [k.RESTART]: `Neu starten`,
  [k.UPLOAD_PREPARING]: `Upload wird vorbereitet...`,
  [k.UPLOAD_STARTED]: `Upload läuft...`,
  [k.UPLOAD_FINISHING]: `Upload wird abgeschlossen...`,
  [k.UPLOAD_CANCELED]: `Upload wurde abgebrochen...`,
  [k.INVALID_FILE_TYPE]: `Dateityp ist nicht gültig...`,
  [k.MAX_FILE_NUMBER]: `Maximale Anzahl Dateien überschritten...`,
  [k.FILE_TOO_LARGE]: `Datei ist zu groß...`,
  [k.DAYS_TO_GO]: `Tage noch!`,
  [k.DAY_TO_GO]: `Tag noch!`,
  [k.TODAY]: `Heute`,
  [k.INVALID_DATE]: `Datum ist nicht gültig`,
  [k.DATE_MUST_BE_IN_FUTURE]: `Datum muss in der Zukunft liegen`,
  [k.ADDRESS_OR_NAME_OF_LOCATION]: `Adresse oder Name der Location`,
  [k.ENTER_SONG_WISH]: `Gib hier Dein Wunschlied ein... `,
  [k.NAME_OF_GUEST]: `Name des Gasts`,
  [k.QUESTION_REMARK_MAX_1000]: `Fragen/Anmerkungen (max. 1000 Zeichen)`,
  [k.LEFT]: `Links`,
  [k.TOP]: `Oben`,
  [k.RIGHT]: `Rechts`,
  [k.BOTTOM]: `Unten`,
  [k.NAME_OF_BRIDE]: `Name der Braut`,
  [k.NAME_OF_GROOM]: `Name des Bräutigams`,
  [k.TITLE]: `Titel`,
  [k.DESCRIPTION]: `Beschreibung`,
  [k.NAME_OF_PLACE]: `Name des Ortes`,
  [k.STREET]: `Straße`,
  [k.HOUSENUMBER]: `Nr.`,
  [k.ZIP]: `PLZ`,
  [k.CITY]: `Ort/Stadt`,
  [k.COUNTRY]: `Land`,
  [k.HINTS_FOR_GUESTS]: `Hinweise für Gäste`,
  [k.TITLE_FOR_THIS_PAGE]: `Titel für diese Seite`,
  [k.SUBTITLE_FOR_THIS_PAGE]: `Untertitel für diese Seite`,
  [k.LABEL]: `Bezeichnung`,
  [k.AMAZON_URL]: `Amazon-URL (optional)`,
  [k.COSTS]: `Kosten`,
  [k.NEW_FOOD_OPTION]: `Neue Menüoption`,
  [k.TIME]: `Uhrzeit`,
  [k.SCHEDULE_ITEM]: `Programmpunkt`,
  [k.YOUR_NAME_OR_NICKNAME]: `Deine Name oder Spitzname`,
  [k.YOUR_EMAIL_ADDRESS]: `Deine E-Mail-Adresse`,
  [k.YOUR_WEDDING_CODE]: `Dein Hochzeits-Code`,
  [k.COULD_NOT_SAVE_WEDDING]: `Wir konnten die Hochzeit leider nicht speichern.`,
  [k.ALREADY_HAVE_WEDDING]: `Du hast bereits Hochzeits-Homepages`,
  [k.CREATE_NEW]: `Neu erstellen`,
  [k.COULD_NOT_LOAD_YOUR_DATA]: `Wir konnten Deine Daten nicht abrufen.`,
  [k.COULD_NOT_SEND_MAIL]: `Leider konnten wir die E-Mail nicht verschicken.`,
  [k.DELETE_IMAGE]: `Bild löschen`,
  [k.DELETE_IMAGE_ARE_YOU_SURE]: `Sicher, dass Du das Bild löschen möchtest?`,
  [k.COULD_NOT_LOGIN]: `Wir konnten keine Anmeldung vornehmen.`,
  [k.WRONG_PASSWORD_TITLE]: `Falsches Passwort`,
  [k.WRONG_PASSWORD_TEXT]: `Das eingegebene Passwort ist nicht korrekt.`,
  [k.WRONG_CODE_TITLE]: `Falscher Hochzeits-Code`,
  [k.WRONG_CODE_TEXT]: `Der eingegebene Hochzeits-Code ist nicht korrekt.`,
  [k.LOADING]: `Laden...`,
  [k.GUEST_NAME_MISSING]: `Bitte bei folgenden Gästen den Namen ergänzen: `,
  [k.GUEST_FOOD_MISSING]: `Bitte bei folgenden Gästen den Menüwunsch ergänzen: `,
  [k.LIMIT_EXCEEDED_PASSWORD_RESET]: `Maximale Versuche überschritten. Du kannst es nachher wieder versuchen.`,
  [k.EXPIRED_CODE_PASSWORD_RESET]: `Du hast diesen Link zum Zurücksetzen Deines Passworts bereits genutzt.`,
  [k.PLEASE_ENTER_NAME]: `Bitte gib einen Namen ein`,
  [k.COUNTDOWN_VISIBLE]: `Countdown sichtbar.`,
  [k.COUNTDOWN_NOT_VISIBLE]: `Countdown nicht sichtbar.`,
  [k.ASK_FOR_MENU_WISHES]: `Ja, nach Menüwünschen fragen`,
  [k.ASK_NOT_FOR_MENU_WISHES]: `Nein, nicht nach Menüwünschen fragen`,
  [k.SPRING]: `Frühling`,
  [k.SUMMER]: `Sommer`,
  [k.AUTUMN]: `Herbst`,
  [k.WINTER]: `Winter`,
  [k.COULD_NOT_SAVE_PASSWORD]: `Wir konnten Dein Passwort nicht speichern.`,
  [k.YES_WE_WILL_ATTEND]: `Ja, wir werden an Eurer Hochzeit teilnehmen`,
  [k.NO_WE_WILL_NOT_ATTEND]: `Nein, wir werden an Eurer Hochzeit leider nicht teilnehmen`,
  [k.LOADING_BACKINGS]: `Eintragungen werden geladen...`,
  [k.LOADING_RSVPS]: `Anmeldungen werden geladen...`,
  [k.CONFIRM_DIALOG_TITLE]: `Bist Du sicher?`,
  [k.ALERT_DIALOG_TITLE]: `Hinweis`,

  [k.DEFAULT_IMAGES_TYPE_CAKE]: `Hochzeitstorte und Kuchen`,
  [k.DEFAULT_IMAGES_TYPE_CEREMONY]: `Hochzeitszeremonie`,
  [k.DEFAULT_IMAGES_TYPE_CHAMPAGNERECEPTION]: `Sektempfang und Champagner`,
  [k.DEFAULT_IMAGES_TYPE_DANCE]: `Tanz und Party`,
  [k.DEFAULT_IMAGES_TYPE_FOOD]: `Hochzeitsessen und Buffet`,
  [k.DEFAULT_IMAGES_TYPE_CAR]: `Hochzeitsauto und Korso`,
  [k.DEFAULT_IMAGES_TYPE_GAME]: `Spiele und Einlagen`,
  [k.DEFAULT_IMAGES_TYPE_DRESSCODE]: `Dresscode`,
  [k.DEFAULT_IMAGES_TYPE_HOTEL]: `Hotel und Unterkunft`,
  [k.DEFAULT_IMAGES_TYPE_PHOTO]: `Foto-Shooting`,
  [k.DEFAULT_IMAGES_TYPE_PRESENT]: `Geschenke`,
  [k.DEFAULT_IMAGES_TYPE_LOVE]: `Allgemein und Liebe`,

  [k.DEFAULT_ELEMENT_TEXT_PAGE_TITLE_LABEL]: `Seitentitel`,
  [k.DEFAULT_ELEMENT_TEXT_PAGE_TITLE_TITLE]: `Seitentitel`,
  [k.DEFAULT_ELEMENT_TEXT_PAGE_TITLE_SUBTITLE]: `Herzlich Willkommen auf diese Seite`,
  [k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_LABEL]: `Programmpunkt`,
  [k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_TITLE]: `Programmpunkt`,
  [k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_TIME]: `Ab 15:00 Uhr`,
  [k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_DESCRIPTION]: `Gemeinsames Foto-Shooting`,
  [k.DEFAULT_ELEMENT_TEXT_INFO_ITEM_LABEL]: `Info-Box`,
  [k.DEFAULT_ELEMENT_TEXT_INFO_ITEM_TITLE]: `🍰 Kuchen`,
  [k.DEFAULT_ELEMENT_TEXT_INFO_ITEM_DESCRIPTION]: `Wenn ihr gerne einen Kuchen backt, gibt uns gerne Bescheid. Wir freuen uns, wenn wir eine bunte Auswahl zusammen bekommen.`,
  [k.DEFAULT_ELEMENT_TEXT_LARGE_PHOTO_ITEM_LABEL]: `Box mit großem Foto`,
  [k.DEFAULT_ELEMENT_TEXT_LARGE_PHOTO_ITEM_TITLE]: `Eure Vorstellung`,
  [k.DEFAULT_ELEMENT_TEXT_LARGE_PHOTO_ITEM_DESCRIPTION]: `Hier könnt Ihr Euch Euren Gästen vorstellen. Erzählt davon, wie Ihr Euch kennengelernt habt...`,
  [k.DEFAULT_ELEMENT_TEXT_LOCATION_MAP_LABEL]: `Karte für Locations`,
  [k.DEFAULT_ELEMENT_TEXT_LOCATION_ITEM_LABEL]: `Hochzeitslocation`,

  [k.DEFAULT_STYLES_MODERN_TITLE]: `Modern`,
  [k.DEFAULT_STYLES_MODERN_DESCRIPTION]: `Eure Hochzeit im modernen Stil! Klare Linien, innovative Designs und schicke Eleganz erwarten Euch. Von minimalistischer Raffinesse bis hin zu futuristischem Glamour – der moderne Hochzeitsstil spiegelt zeitgemäße Ästhetik wider. Lasst Eure Liebe in einem Ambiente erstrahlen, das den Puls der Zeit trifft.`,
  [k.DEFAULT_STYLES_KLASSISCH_TITLE]: `Klassisch`,
  [k.DEFAULT_STYLES_KLASSISCH_DESCRIPTION]: `Die Eleganz der Ewigkeit erwartet Euch im klassischen Stil. Zeitlose Schönheit, traditionelle Akzente und eine raffinierte Atmosphäre definieren diesen Stil. Klare Schnitte, neutrale Farben und ein Hauch von Opulenz schaffen ein Ambiente, das die Geschichte Eurer Liebe würdigt. Eure Hochzeit wird zu einem zeitlosen Meisterwerk, das Generationen überdauert.`,
  [k.DEFAULT_STYLES_VINTAGE_TITLE]: `Vintage`,
  [k.DEFAULT_STYLES_VINTAGE_DESCRIPTION]: `Tretet ein in die Zeit des zeitlosen Charmes. Der Vintage-Stil bringt das Flair vergangener Epochen zurück, mit einem Hauch von Nostalgie und romantischer Eleganz. Sanfte Farben, zarte Spitze und antike Details schaffen eine Atmosphäre der Eleganz und Erinnerung. Eure Hochzeit wird zu einem lebendigen Märchen, das die Schönheit vergangener Tage feiert.`,
  [k.DEFAULT_STYLES_FLOWER_TITLE]: `Flower`,
  [k.DEFAULT_STYLES_FLOWER_DESCRIPTION]: `Willkommen in der blühenden Pracht des Flower-Power! Dieser Hochzeitsstil zelebriert die natürliche Schönheit von Blumen in ihrer vollen Pracht. Ein Festival der Farben und Düfte erwartet Euch, während florale Elemente Eure Liebe umrahmen. Von üppigen Blumenarrangements bis hin zu botanisch inspirierter Dekoration – Eure Hochzeit wird zu einem blühenden Garten der Liebe.`,

  [k.COOKIES_01]: `Ein Paar Hochzeits-Cookies für Dich?`,
  [k.COOKIES_02]: `Mit deinem Klick auf „Alle bestätigen“ akzeptierst du alle Cookies. Alternativ hast du die Möglichkeit, deine individuelle Auswahl zu treffen. Deine Auswahl kannst du nachträglich jederzeit über die „Cookie-Einstellungen“ widerrufen.`,
  [k.COOKIES_03]: `Notwendige Cookies`,
  [k.COOKIES_04]: `Diese Cookies sind für die Funktionalität der Webseite notwendig, sodass du sie nicht deaktivieren kannst.`,
  [k.COOKIES_05]: `Mehr Infos`,
  [k.COOKIES_06]: `Marketing- und Personalisierungs-Cookies`,
  [k.COOKIES_07]: `Diese Cookies ermöglichen es dir, auf dein Nutzerverhalten abgestimmte Webseiten-Inhalte zu erhalten und bieten uns die Möglichkeit, unsere Webseite auf Basis von Nutzungsstatistiken optimal auf die Interessen unserer Kunden anzupassen.`,
  [k.COOKIES_08]: `Auswahl akzeptieren`,
  [k.COOKIES_09]: `Alles akzeptieren`,

  [k.TERMS_AND_CONDITIONS_00]: `Nutzungs­bedingungen`,
  [k.TERMS_AND_CONDITIONS_01]: `Die fidira Softare UG (haftungsbeschränkt), nachfolgend auch „Anbieter“, "wir", oder "EureHochzeitOnline.com" genannt, bietet eine Plattform für die Erstellung von individuellen Hochzeits-Apps. Mit diesen Apps können Nutzerinnen und Nutzer (nachfolgend einheitlich "Nutzer") als Gastgeber eine App für ihre Hochzeit gestalten und ihren Gästen durch diese App Informationen zur Hochzeit und Interaktionsmöglichkeiten anbieten. Für die Nutzung der Plattform gelten die folgenden Bedingungen.`,
  [k.TERMS_AND_CONDITIONS_02]: `§ 1 Nutzung`,
  [k.TERMS_AND_CONDITIONS_03]: `Die Nutzung der Plattform ist grundsätzlich in einer Testversion kostenlos. Für die Nutzung im Rahmen einer Hochzeit ab definerter Gästezahl ist eine einmalige Nutzungsgebühr zu zahlen. Sowohl die Testversion als auch die Vollversion setzen eine vorherige Registrierung mit Erstellung eines Nutzerkontos voraus. Der Nutzer darf seinen Zugang nicht Dritten zur Nutzung überlassen. Der Nutzer ist verpflichtet, seine Zugangsdaten geheim zu halten und vor dem Zugriff Dritter zu schützen.`,
  [k.TERMS_AND_CONDITIONS_04]: `Die Hochzeits-App wird nach der Hochzeit noch sechs Monate erreichbar sein, danach werden wir die Hochzeits-App deaktivieren, um die Wunschadresse anderen Paaren anbieten zu können. Wenn Ihr Eure Hochzeits-App länger online benötigt, können wir gerne eine Sonderregelung finden.`,
  [k.TERMS_AND_CONDITIONS_05]: `Eine eingerichtete Hochzeits-App darf auch nur für eine Hochzeit genutzt werden. Falls wir beispielsweise feststellen, dass ein Nutzer eine einmalig freigeschaltete Hochzeits-App an andere Hochzeitspaare weitergibt oder verkauft, behalten wir uns das Recht vor, diese Nutzung zu verhindern und die Nutzungsgebühren für die weiteren Hochzeiten einzufordern.`,
  [k.TERMS_AND_CONDITIONS_06]: `§ 2 Datenschutz`,
  [k.TERMS_AND_CONDITIONS_07]: `Mit Nutzung der Software erklären sich die Nutzer mit den`,
  [k.TERMS_AND_CONDITIONS_08]: `Datenschutzbestimmungen`,
  [k.TERMS_AND_CONDITIONS_09]: `einverstanden.`,
  [k.TERMS_AND_CONDITIONS_10]: `§ 3 Funktionen und Leistungen von EureHochzeitOnline.com`,
  [k.TERMS_AND_CONDITIONS_11]: `Die individuellen Hochzeits-Apps, die über EureHochzeitOnline.com erstellt werden können, bieten folgende Funktionen an:`,
  [k.TERMS_AND_CONDITIONS_12]: `Individuelles Design:`,
  [k.TERMS_AND_CONDITIONS_13]: `Der Gastgeber kann bestimmte Aspekte der Hochzeits-App selbst konfigurieren und gestalten, um beispielsweise die Optik an die grafische Gestaltung der Hochzeitseinladungen anzupassen. Er kann Hintergründe, Bilder, Farben und Schriften einstellen.`,
  [k.TERMS_AND_CONDITIONS_14]: `Bildergalerie:`,
  [k.TERMS_AND_CONDITIONS_15]: `Die Gäste können vor oder während der Hochzeit Fotos machen und hochladen. Diese Fotos können in einer automatisch aktualisierten Live-Bildergalerie gezeigt werden.`,
  [k.TERMS_AND_CONDITIONS_16]: `Musikwünsche:`,
  [k.TERMS_AND_CONDITIONS_17]: `Über eine Suchfunktion können Gäste sich Lieder für die Hochzeit wünschen und die Wünsche bewerten. Diese Funktion kann der Gastgeber für die Liedauswahl nutzen.`,
  [k.TERMS_AND_CONDITIONS_18]: `Hochzeitsinformationen:`,
  [k.TERMS_AND_CONDITIONS_19]: `Der Gastgeber kann in seiner Hochzeits-App den Veranstaltungsort mit interaktiven Karten, den Ablaufplan und weitere Informationen für seine Gäste Veröffentlichen.`,
  [k.TERMS_AND_CONDITIONS_20]: `EureHochzeitOnline.com ist jederzeit berechtigt Änderungen an dem Umfang und der Gestaltung der Funktionen in der Software vorzunehmen.`,
  [k.TERMS_AND_CONDITIONS_21]: `Für die Nutzung der Funktionen ist ein modernes Smartphone, Tablet oder PC mit einem modernen Internetbrowser erforderlich. Insbesondere Live-Funktionen wie Bilder-Upload, Hochzeits-Quiz oder Musikwünsche erfordern eine stabile und schnelle Internetverbindung. EureHochzeitOnline.com übernimmt keine Gewähr für die Richtigkeit, Vollständigkeit, Verlässlichkeit, Aktualität und Brauchbarkeit der bereit gestellten Inhalte.`,
  [k.TERMS_AND_CONDITIONS_22]: `Während Wartungsarbeiten, die durch EureHochzeitOnline.com den Gastgebern angekündigt werden, kann die Plattform kurzzeitig unerreichbar sein. Wir sind selbstverständlich bemüht, unseren Dienst verfügbar zu halten und können stolz sagen, dass wir bisher keinen Nutzer enttäuscht haben. Dennoch können wir keine darüber hinausgehenden Leistungspflichten übernehmen, insbesondere besteht kein Anspruch des Nutzers auf eine ständige Verfügbarkeit des Dienstes.`,
  [k.TERMS_AND_CONDITIONS_23]: `§ 4 Pflichten des Nutzers`,
  [k.TERMS_AND_CONDITIONS_24]: `Der Nutzer kann sowohl als Gast als auch als Gastgeber eigene Inhalte und Dateien auf der Plattform EureHochzeitOnline.com speichern und verögffentlichen. Für diese Inhalte ist der Nutzer selbst verantwortlich. Der Nutzer verpflichtet sich, keine Beiträge, Dateien, Hochzeitsinformationen oder sonstigen Inhalte zu veröffentlichen, die gegen die guten Sitten oder geltendes Recht verstoßen. Der Nutzer verpflichtet sich insbesondere dazu, keine Beiträge, Dateien, Hochzeitsinformationen oder sonstigen Inhalte zu veröffentlichen,`,
  [k.TERMS_AND_CONDITIONS_25]: `deren Veröffentlichung einen Straftatbestand erfüllt oder eine Ordnungswidrigkeit darstellt,`,
  [k.TERMS_AND_CONDITIONS_26]: `die gegen das Urheberrecht, Markenrecht oder Wettbewerbsrecht verstoßen,`,
  [k.TERMS_AND_CONDITIONS_27]: `die gegen das Rechtsdienstleistungsgesetz verstoßen,`,
  [k.TERMS_AND_CONDITIONS_28]: `die beleidigenden, rassistischen, diskriminierenden oder pornographischen Inhalt haben,`,
  [k.TERMS_AND_CONDITIONS_29]: `die Werbung enthalten.`,
  [k.TERMS_AND_CONDITIONS_30]: `Bei einem Verstoß gegen diese Pflichten sowie bei dem Verdacht eines Verstoßes gegen diese Pflichten ist EureHochzeitOnline.com berechtigt, die entsprechenden Inhalte zu ändern oder zu löschen. EureHochzeitOnline.com ist auch berechtigt, den Zugang und die eingerichtete Hochzeits-App des Nutzers zu sperren, wenn er gegen Nutzungsvereinbarungen verstößt. Der durch die Pflichtverletzung entstandene Schaden ist dem Anbieter durch den Nutzer zu erstatten. Der Anbieter hat gegen den Nutzer einen Anspruch auf Freistellung von Ansprüchen Dritter, die diese wegen der Verletzung eines Rechts durch den Nutzer geltend machen. Der Nutzer verpflichtet sich, den Anbieter bei der Abwehr derartiger Ansprüche zu unterstützen. Der Nutzer ist außerdem verpflichtet, die Kosten einer angemessenen Rechtsverteidigung des Anbieters zu tragen.`,
  [k.TERMS_AND_CONDITIONS_31]: `§ 5 Geistiges Eigentum`,
  [k.TERMS_AND_CONDITIONS_32]: `Ihr geistiges Eigentum`,
  [k.TERMS_AND_CONDITIONS_33]: `Sie besitzen die Eigentumsrechte an den durch Sie eingestellten Inhalte, Texte, Bilder und sonstiger Materialien. EureHochzeitOnline.com beansprucht keine geistigen Eigentumsrechte für diese Inhalte. Sie gestatten EureHochzeitOnline.com aber zu, auf diese Inhalte zuzugreifen, sie zu kopieren und zu verteilen, um die Inhalte an die Gäste der Hochzeit ausliefern zu können. Sie erlauben uns auch alle weiteren Tätigkeiten, die erforderlich sind, um unsere Dienste bereitzustellen, insbesondere das Erstellen von Sicherungskopien sowie den Zugriff durch Support-Mitarbeiter.`,
  [k.TERMS_AND_CONDITIONS_34]: `Unser geistiges Eigentum`,
  [k.TERMS_AND_CONDITIONS_35]: `Der Anbieter räumt keine Lizenzrechte oder sonstige Rechte für die Nutzung der geistigen Eigentumsrechte an der EureHochzeitOnline.com-Website oder Software Plattform außerhalb einer regulär erstellten und gemöß der Nutzungsbedingungen bereitgestellten Hochzeits-App ein. Die Vervielfältigung, Verbreitung, Wiedergabe, Weitergabe, anderweitige Inanspruchnahme der geistigen Eigentumsrechte einschließlich deren Bereitstellung gegenüber Dritten ist ohne vorherige schriftliche Zustimmung des Anbieters nicht gestattet.`,
  [k.TERMS_AND_CONDITIONS_36]: `§ 6 Widerrufsrecht`,
  [k.TERMS_AND_CONDITIONS_37]: `Sie haben das Recht, binnen vierzehn Tagen ohne Angaben von Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsschlusses. Sie können Ihr Widerrufsrecht ausüben, indem Sie uns eine E-Mail an`,
  [k.TERMS_AND_CONDITIONS_38]: `mit einer eindeutigen Erklärung über Ihre Entscheidung den Vertrag zu widerrufen senden. Gerne können Sie in Ihrer E-Mail auch das folgende Musterformular verwenden:`,
  [k.TERMS_AND_CONDITIONS_39]: `Muster-Widerrufsformular`,
  [k.TERMS_AND_CONDITIONS_40]: `— An die fidira Software UG (haftungsbeschränkt), E-Mail: beeni@eurehochzeitonline.com:`,
  [k.TERMS_AND_CONDITIONS_41]: `— Hiermit widerrufe(n) ich/wir ( * ) den von mir/uns ( * ) abgeschlossenen Vertrag über die Freischaltung unserer Hochzeits-App ( * )`,
  [k.TERMS_AND_CONDITIONS_42]: `— Bestellt am ( * ) / erhalten am ( * )`,
  [k.TERMS_AND_CONDITIONS_43]: `— Name des/der Verbraucher(s)`,
  [k.TERMS_AND_CONDITIONS_44]: `— Anschrift des/der Verbraucher(s)`,
  [k.TERMS_AND_CONDITIONS_45]: `— Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)`,
  [k.TERMS_AND_CONDITIONS_46]: `— Datum`,
  [k.TERMS_AND_CONDITIONS_47]: `(*) Unzutreffendes streichen`,
  [k.TERMS_AND_CONDITIONS_48]: `Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.`,
  [k.TERMS_AND_CONDITIONS_49]: `Folgen des Widerrufs:`,
  [k.TERMS_AND_CONDITIONS_50]: `Falls Sie Ihren Vertrag widerrufen, werden wir Ihre Hochzeits-App löschen und, falls wir von Ihnen bereits Zahlungen erhalten haben, diese Zahlungen über dasselbe Zahlungsverfahren Ihnen zurückerstatten. Durch diese Zurückerstattung entstehen für Sie keine Kosten.`,
  [k.TERMS_AND_CONDITIONS_51]: `Bei einem Vertrag über die Lieferung von nicht auf einem körperlichen Datenträger befindlichen digitalen Inhalten wie einer Hochzeits-App, erlischt das Widerrufsrecht nach § 356 Abs. 5 BGB auch dann, wenn der Anbieter mit der Ausführung des Vertrags begonnen hat, nachdem der Nutzer 1. ausdrücklich zugestimmt hat, dass der Anbieter mit der Ausführung des Vertrags vor Ablauf der Widerrufsfrist beginnt, und 2. seine Kenntnis davon bestätigt hat, dass er durch seine Zustimmung mit Beginn der Ausführung des Vertrags sein Widerrufsrecht verliert.`,
  [k.TERMS_AND_CONDITIONS_52]: `§ 7 Garantie und Haftungsausschluss`,
  [k.TERMS_AND_CONDITIONS_53]: `Der Anbieter bietet seine Dienste in der vorliegenden Form, „mit allen Fehlern“ und „wie verfügbar“ an, und übernimmt keinerlei ausdrückliche oder stillschweigende Gewährleistung für die Marktgängigkeit, Eignung für einen bestimmten Zweck, fachgerechte Ausführung, Nichtverletzung der Rechte Dritter oder sonstige Gewährleistungen – im gesetzlich zulässigen Rahmen. Es wird auch keine Garantie dafür übernommen, dass die Dienste des Anbieters (oder Teile, Funktionen oder Inhalte davon) vollständig, von einer bestimmten Qualität, zuverlässig, sicher, korrekt, mit Ihren beabsichtigten Aktivitäten, genutzten Geräten, Betriebssystemen, Browsern oder Programmen (der Gäste oder der Gastgeber) kompatibel sind (oder das sie dies auch zukünftig sein werden), oder dass sie mit allen gesetzlichen Bestimmungen, die für Sie gelten, konform sind, oder dass die Dienste frei von Viren, Programmfehlern oder anderen schädlichen Komponenten oder Programmbeschränkungen sind. Darüber hinaus übenrimmt EureHochzeitOnline.com auch keine Haftung für Unternehmen, Produkte oder Dienste (einschließlich der Drittanbieter-Dienste), die in der EureHochzeitOnline.com Plattform erwähnt oder angeboten werden. Bei Verlust von Daten und Informationen wird keine Haftung übernommen und der Anbieter gewährleistet keine Rekonstruktion dieser Daten und Informationen. Der Nutzer wird seine Daten und Informationen auf eigene Verantwortung sichern. EureHochzeitOnline.com übernimmt auch keine Gewährleistung für die durch Nutzer eingestellten Inhalte oder die richtige Identität der Nutzer.`,
  [k.TERMS_AND_CONDITIONS_54]: `§ 8 Salvatorische Klausel`,
  [k.TERMS_AND_CONDITIONS_55]: `Sofern eine Bestimmung dieser Vereinbarung aus irgendeinem Grund und Umfang als ungültig oder undurchsetzbar ist, beeinflusst diese Unwirksamkeit oder Undurchsetzbarkeit die übrigen Bestimmungen der Vereinbarungen nicht oder lässt diese unwirksam oder undurchsetzbar werden und die Anwendung dieser Bestimmung wird im gesetzlich zulässigen Rahmen durchgesetzt. Wenn EureHochzeitOnline.com bezüglich eines Verstoßes gegen eine oder mehrere der Bedingungen auf die Durchsetzung ihrer Rechte verzichtet, bedeutet dies nicht, dass EureHochzeitOnline.com auch auf die Durchsetzung der anderen Bedingungen verzichtet.`,
  [k.TERMS_AND_CONDITIONS_56]: `§ 9 Schlussbestimmungen`,
  [k.TERMS_AND_CONDITIONS_57]: `Sofern nicht von der Gesetzgebung vorgeschrieben, unterliegen die Vereinbarungen den Gesetzen der Bundesrepublik Deutschland. Gerichtsstand für alle Streitigkeiten/Forderungen aus oder in Verbindung mit ihnen ist ausschließlich München. Wir behalten uns vor, diese Nutzungsbedingungen in Zukunft anzupassen und die neue Version am selben Ort zu veröffentlichen. Nach einer Änderung der Nutzungsbedingungen ist die neue Version durch den Nutzer zu bestätigen.`,
  [k.TERMS_AND_CONDITIONS_58]: `Stand: 01.04.2018`,

  [k.IMPRINT_00]: `Impressum`,
  [k.IMPRINT_01]: `fidira Software UG (haftungsbeschränkt)`,
  [k.IMPRINT_02]: `EureHochzeitOnline.com ist eine Marke der fidira Software UG`,
  [k.IMPRINT_03]: `Enzianstr. 31`,
  [k.IMPRINT_04]: `86343 Königsbrunn`,
  [k.IMPRINT_05]: `E-Mail:`,
  [k.IMPRINT_06]: `Web:`,
  [k.IMPRINT_07]: `https://www.eurehochzeitonline.com`,
  [k.IMPRINT_08]: `Sitz der Gesellschaft: München, Handelsregister beim Amtsgericht München HRB`,
  [k.IMPRINT_09]: `Vertretungsberechtigte:`,

  [k.PRIVACY_00]: `Datenschutz­bestimmungen`,
  [k.PRIVACY_01]: `Einführung und Allgemeines`,
  [k.PRIVACY_02]: `Der Schutz Ihrer Daten und der Daten Ihrer Hochzeitsgäste hat für EureHochzeitOnline.com die höchste Priorität. `,
  [k.PRIVACY_03]: `Wir sorgen zu jedem Zeitpunkt im Einklang mit den Anforderungen des Bundesdatenschutzgesetzes (BDSG) und des Telemediengesetzes (TMG) dafür, dass Ihre Daten nur für die Erstellung und Nutzung Ihrer persönlichen Hochzeits-App sowie die Optimierung des Nutzererlebnisses Ihrer Hochzeits-App verwendet werden. Wir nutzen sowohl bei der Erstellung als auch bei der Nutzung von Hochzeits-Apps die TLS/SSL-Technologie (Transport Layer Security / Secure Sockets Layer), so dass die Daten während der Übertragung stets verschlüsselt sind. Alle unsere Anwendungs-Server stehen in der Europäischen Union und erfüllen die EU-Datenschutzbestimmungen. Wir werden Ihre Daten nicht über die in diesen Datenschutzbestimmungen aufgeführten Zwecke hinaus verarbeiten.`,
  [k.PRIVACY_04]: `Während der Registrierung werden Sie um Ihre Einverständnis für die in den Datenschutzbestimmungen beschriebenen Verarbeitungsvorgänge gebeten, die Sie durch Anklicken der Checkbox erteilen. Durch Ihre Einverständnis bestätigen Sie uns, dass wir im nachfolgend beschriebenen Umfang Ihre persönlichen Daten erheben, verarbeiten und nutzen dürfen. Sie können Ihre Einverständnis auch jederzeit widerrufen. Schreiben Sie hierzu einfach eine Mail an unseren Kundenservice unter`,
  [k.PRIVACY_05]: `. Auf Anfrage kann Ihnen unser Kundenservice auch eine Auskunft zu allen zu Ihrer Person gespeicherten Daten liefern (gemäß § 13 Abs. 7 Telemediengesetz) und diese Daten auf Ihren Wunsch hin auch löschen.`,
  [k.PRIVACY_06]: `§ 1 Verantwortliche Stelle und Geltungsbereich`,
  [k.PRIVACY_07]: `Verantwortliche Stelle ist die fidira Softare UG (haftungsbeschränkt), nachfolgend auch „Anbieter“, "wir", oder "EureHochzeitOnline.com" genannt. Details zur ladungsfähigen Anschrift und Vertretungsberechtigungen finden Sie im`,
  [k.PRIVACY_08]: `Diese Datenschutzerklärung umfasst die Erhebung und Verwendung von personenbezogenen Daten beim Besuch und bei der Nutzung aller Plattformen des Anbieters über Internet-Browser. Durch diese Angebote ermöglicht es der Anbieter, eine individuelle Hochzeits-App zu erstellen, mit der die Gäste sich über die Hochzeit informieren und während der Hochzeit miteinander interagieren (z.B. Fotos teilen oder Fragen beantworten) können. Die personenbezogenen Daten werden durch den Anbieter zudem für statistische und marktanalytische Zwecke verwendet. Dabei erstellen wir anonymisierte Statistiken z.B. zu Hochzeiten, Locations, Hochzeitsgrößen oder App-Nutzung, um unser Angebot weiter optimieren zu können.`,
  [k.PRIVACY_09]: `§ 2 Links zu anderen Webseiten`,
  [k.PRIVACY_10]: `Unsere Website kann Links zu Websites von anderen Anbietern enthalten. Wir haben keinen Einfluss auf den Inhalt, auf den Sie nach Klick auf diese Links weitergeleitet werden und auch nicht auf die Verarbeitung Ihrer Daten, die dadurch an den Dritten potenziell übertragen werden (z.B. Ihre IP-Adresse). Daher können wir für die Inhalte Dritter und die Verarbeitung dieser Daten durch Dritte keine Verantwortung übernehmen.`,
  [k.PRIVACY_11]: `§ 3 Datennutzung ohne Registrierung`,
  [k.PRIVACY_12]: `Wenn Sie die Webseite oder App von EureHochzeitOnline.com besuchen, speichern wir automatisch einige grundlegende Daten über Ihren Besuch: insbesondere die IP-Adresse, die besuchten Seiten und die Besuchsdauer. Diese Daten nutzen wir, um anonyme Statistiken über Nutzerverhalten und viel genutzte Funktionen zu erstellen, um unser Angebot stetig zu verbessern. Ihre IP-Adresse wird nur für einen begrenzten Zeitraum in technischen "Log-Files" gespeichert, um unsere Systeme und die erstellten Hochzeits-Apps vor Missbrauch und Betrug zu schützen.`,
  [k.PRIVACY_13]: `§ 4 Datennutzung mit Registrierung`,
  [k.PRIVACY_14]: `Um als Gast Zugriff auf eine Hochzeits-App zu erhalten bzw. als Gastgeber eine Hochzeits-App erstellen zu können, muss der Nutzer eine Registrierung vornehmen. Bei dieser Registrierung werden die E-Mail-Adresse, ein Anzeigename (frei wählbar, kann auch ein erfundenes Pseudonym sein), ggf. ein Hochzeits-Code und ein Passwort (frei definierbar) gespeichert. Diese Daten werden genutzt, um die Hochzeits-Apps unserer Gastgeber von unberechtigten Zugriffen zu schützen. Bei der Erstellung einer Hochzeits-App können die Gastgeber Informationen zu Ihrer Hochzeit (z.B. Ort, Zeit, Ablaufplan) eingeben, die nur den Gästen dieser Hochzeit angezeigt werden. Dies wird dadurch sichergestellt, dass durch den Gastgeber Hochzeits-Codes erzeugt werden, die von den Gästen eingegeben werden müssen, um Zugang zur Hochzeits-App zu erhalten.`,
  [k.PRIVACY_15]: `Wir nutzen Ihre-E-Mail-Adresse auch für Kommunikation: beispielsweise können an den Gastgeber E-Mail-Benachrichtigungen bei neuen Anmeldungen versendet, andere Hochzeitsneuigkeiten kommuniziert sowie Service-Mails von EureHochzeitOnline.com an die E-Mail-Adresse gesendet werden (z.B. Zufriedenheitsbefragungen). Zudem zeigen wir die E-Mail-Adressen der Hochzeitsgäste dem Gastgeber an, damit er sie für die weitere Hochzeitsorganisation nutzen kann.`,
  [k.PRIVACY_16]: `Eine Weitergabe Ihrer E-Mail-Adresse an Dritte oder andere Unternehmen erfolgt niemals.`,
  [k.PRIVACY_17]: `§ 5 Cookies`,
  [k.PRIVACY_18]: `Um Ihnen die beste Nutzerfahrung anbieten zu können, setzen wir "Cookies" und den sogenannten "Local Storage" Ihres Browsers ein. Dies gibt uns die Möglichkeit, kleine Datenmengen zu Ihrer Hochzeits-App in Ihrem Browser zu speichern, damit zum Beispiel Ihre Änderungen nicht verlorengehen, wenn Sie Ihren Browser schließen, in den Vollbildmodus des App-Konfigurators wechseln oder damit Sie sich nicht jedesmal neu einloggen müssen. Falls Sie den Einsatz von Cookies nicht wünschen oder bestehende Cookies löschen wollen, können Sie diese über Ihren Browser deaktivieren oder entfernen. Informationen hierzu finden Sie in der Nutzungsanleitung Ihres Browsers - gerne können Sie auch uns fragen, wir unterstützen Sie gerne.`,
  [k.PRIVACY_19]: `Wir nutzen auch Cookies für die Leistungen der im Folgenden unter "§ 7 Apps und Webseiten" aufgelisteten Drittanbieter, um Ihnen eine bessere Nutzererfahrung anbieten zu können.`,
  [k.PRIVACY_20]: `§ 6 Apps und Webseiten`,
  [k.PRIVACY_21]: `Google Maps`,
  [k.PRIVACY_22]: `Zur Darstellung interaktiver Karten nutzen wir Google Maps der Firma Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. Dadurch werden Ihre IP-Adresse und gegebenenfalls Ihr Standort sowie Informationen über die Nutzung unserer Dienste an einen Server von Google in den USA übertragen und dort gespeichert. Die Nutzungsbedingungen für Google Maps finden Sie unter`,
  [k.PRIVACY_23]: `https://www.google.com/intl/de_de/help/terms_maps.html`,
  [k.PRIVACY_24]: `HotJar`,
  [k.PRIVACY_25]: `Wir verwenden auch HotJar, ein Webanalyse-Tool der Hotjar Ltd., um Daten für Marketing- und Optimierungszwecke zu sammeln und zu speichern. Aus den Daten können pseudonymisierte Nutzungsprofile erstellt werden. Es werden zufällig ausgewählte einzelne Besuche (ohne zusätzliche persönliche Informationen, sogenannte "User Attributes") aufgezeichnet. Dabei entsteht ein Protokoll der Mausbewegungen und Klicks mit der Absicht einzelne Website-Besuche stichprobenartig abzuspielen und potentielle Verbesserungen für die Website daraus abzuleiten. Die erhobenen Daten werden ohne die gesondert erteilte Zustimmung des Betroffenen nicht dazu benutzt, den Besucher dieser Website persönlich zu identifizieren und nicht mit personenbezogenen Daten über den Träger des Pseudonyms zusammengeführt. Wenn Sie eine Aufzeichnung nicht wünschen, können Sie eine Aufzeichnung auf allen Websites, die HotJar einsetzen, global für Ihren gerade verwendeten Browser unter folgendem Link deaktivieren:`,
  [k.PRIVACY_26]: `https://www.hotjar.com/privacy/do-not-track/`,
  [k.PRIVACY_27]: `Mailchimp`,
  [k.PRIVACY_28]: `Wir verwenden für den Versand unseres Newsletters Mailchimp von The Rocket Science Group, LLC, 675 Ponce de Leon Ave NE Suite 5000, Atlanta, GA 30308 USA (Mailchimp). Dies ermöglicht uns, mit Abonnenten direkt in Kontakt zu treten. Zusätzlich analysieren wir Ihr Nutzungsverhalten, um unser Angebot zu optimieren.`,
  [k.PRIVACY_29]: `Dafür geben wir folgende personenbezogene Daten an Mailchimp weiter:`,
  [k.PRIVACY_30]: `E-Mail-Adresse [Vorname] [Hochzeitsdatum] [Hochzeitsinformationen] [Login-Daten]`,
  [k.PRIVACY_31]: `Unsere E-Mail-Aussendungen beinhalten einen Link, mit dem Sie Ihre personenbezogenen Daten aktualisieren können. Mailchimp ist Empfänger Ihrer personenbezogenen Daten und als Auftragsverarbeiter für uns tätig, soweit es um den Verand unseres Newsletters geht. Die Verarbeitung der unter diesem Abschnitt angegebenen Daten ist weder gesetzlich noch vertraglich vorgeschrieben. Ohne Ihre Einwilligung und die Übermittlung Ihrer personenbezogenen Daten, können wir keinen Newsletter an Sie aussenden. Zusätzlich werden von Mailchimp folgende personenbezogene Daten mit Hilfe von Cookies und anderen Tracking-Methoden gesammelt: Informationen über Ihr Endgerät (IP-Adresse, Geräteinformationen, Betriebssystem, Browser-ID, Informationen über die Anwendung mit der Sie Ihre E-Mails lesen und weitere Informationen über Hardware und Internetverbindung. Darüber hinaus werden Nutzungsdaten gesammelt wie Datum und Uhrzeit, wann Sie die E-Mail/ Kampagne geöffnet haben und Browseraktivitäten (z.B. welche E-Mails / Webseiten geöffnet wurden). Mailchimp benötigt diese Daten, um die Sicherheit und Zuverlässigkeit der Systeme, die Einhaltung der Nutzungsbedingungen und die Vermeidung von Missbrauch zu gewährleisten. Dies entspricht dem berechtigten Interesse Mailchimp (gem. Art. 6 Abs. 1 lit. f DSGVO) und dient der Vertragsdurchführung (gem. Art. 6 Abs. 1 lit. b DSGVO). Weiter werden von Mailchimp Leistungsdaten ausgewertet, wie die Auslieferungsstatistik von E-Mails und weitere Kommunikationsdaten. Diese Informationen werden verwendet, um Nutzungs- und Leistungsstatistiken der Dienste zu erstellen. Mailchimp sammelt zusätzlich Informationen aus anderen Quellen über Sie. In einem nicht näher bestimmten Zeitraum und Umfang, werden personenbezogene Daten über soziale Medien und weiteren Drittanbietern von Daten gesammelt. Wir haben auf diesen Prozess keinen Einfluss.`,
  [k.PRIVACY_32]: `Weitere Informationen zu Widerspruchs- und Beseitigungsmöglichkeiten gegenüber Mailchimp finden Sie unter:`,
  [k.PRIVACY_33]: `https://mailchimp.com/legal/privacy/#3._Privacy_for_Contacts`,
  [k.PRIVACY_34]: `Rechtsgrundlage für diese Verarbeitungen ist Ihre Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO. Die Einwilligung zur Verarbeitung Ihrer personenbezogenen Daten können Sie jederzeit widerrufen. In allen Aussendungen findet sich ein entsprechender Link. Außerdem kann der Widerruf über die angegebenen Kontaktmöglichkeiten erfolgen. Durch Erklärung des Widerrufs, wird die Rechtmäßigkeit, der bisher erfolgten Verarbeitung nicht berührt.`,
  [k.PRIVACY_35]: `Ihre Daten werden solange verarbeitet, wie eine entsprechende Einwilligung vorliegt. Abgesehen davon, werden diese nach Beendigung des Vertrages zwischen uns und Mailchimp gelöscht, es sei denn, dass gesetzliche Vorgaben eine weitere Speicherung erforderlich machen.`,
  [k.PRIVACY_36]: `Mailchimp hat Compliance-Maßnahmen für internationale Datenübermittlungen umgesetzt. Diese gelten für alle weltweiten Aktivitäten, bei denen Mailchimp personenbezogene Daten von natürlichen Personen in der EU verarbeitet. Diese Maßnahmen basieren auf den EU-Standardvertragsklauseln (SCCs). Weitere Informationen finden Sie unter:`,
  [k.PRIVACY_37]: `https://mailchimp.com/legal/data-processing-addendum/`,
  [k.PRIVACY_38]: `Spotify`,
  [k.PRIVACY_39]: `Wir verwenden auch das Spotify Music Player Widget, ein Musik-Player der Spotify AB, um euch die Möglichkeit zu geben die Hochzeitsplaylist anzuhören. Der Dienstanbieter des Spotify Music Player Widget ist Spotify AB, Regeringsgatan 19, 111 53 Stockholm, Sweden. Mehr Informationen über die Verarbeitung von Daten von Spotify AB findest Du unter:`,
  [k.PRIVACY_40]: `https://www.spotify.com/de/legal/privacy-policy/`,
  [k.PRIVACY_41]: `Facebook Pixel`,
  [k.PRIVACY_42]: `Um EureHochzeitOnline.com zu verbessern und einer größeren Nutzergruppe bekannt zu machen, nutzen wir den Facebook Pixel der Firma Facebook Inc., 1601 South California Avenue, Palo Alto, CA 94304, USA („Facebook“). Hierzu wird durch Facebook ein Cookie in Ihrem Browser gespeichert. Die in diesem Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden an einen Server von Facebook in den USA übertragen und dort gespeichert. Facebook kann über diese Datenübermittlung feststellen, dass eine bestimmte Seite von ihrem Browser aus aufgerufen wurde und ob dies durch Klick einer Werbeanzeige erfolgt ist. Dabei reichen wir die übertragenen Daten aber nicht um eingegebene Informationen von unserer Seite an. Facebook nutzt diese Informationen, um uns beim Erstellen von weiteren Werbeanzeigen und dem Bekanntmachen von EureHochzeitOnline.com zu unterstützen. Die gewonnen Daten des Facebook Pixels werten wir im Facebook-Analyse-Tool "Facebook Analytics" aus um EureHochzeitOnline.com weiter zu verbessern. Facebook wird diese Informationen gegebenenfalls an Dritte übertragen, falls dies gesetzlich vorgeschrieben ist oder soweit Dritte diese Daten im Auftrag von Facebook verarbeiten. Durch die Nutzung von EureHochzeitOnline.com erklären Sie sich mit der Bearbeitung der über Sie erhobenen Daten durch Facebook in der beschriebenen Art und Weise und zur Verbesserung von EureHochzeitOnline.com einverstanden. Wir übermitteln an Facebook keine Informationen über Ihre persönlichen, Kontakt- oder Hochzeitsinformationen. Mehr zu den Sicherheits- und Datenschutzgrundsätzen vom Facebook Pixel erfahren sie unter`,
  [k.PRIVACY_43]: `https://www.facebook.com/policy.php`,
  [k.PRIVACY_44]: `Sie haben auch die Möglichkeit die Datenerhebung durch Facebook Pixel zu verhindern, indem Sie hier klicken:`,
  [k.PRIVACY_45]: `Pixel deaktivieren`,
  [k.PRIVACY_46]: `§ 7 Auskunftsrecht und sonstige Rechte nach dem BDSG`,
  [k.PRIVACY_47]: `Selbstverständlich geben wir Ihnen gerne jederzeit Auskunft über die bei uns über Sie gespeicherten personenbezogenen Daten. Auf Ihren Wunsch können wir Ihre Daten auch berichtigen, sperren oder löschen. Sie können sich mit diesen Anliegen oder mit Widerrufserklärungen jederzeit an`,
  [k.PRIVACY_48]: `wenden und wir nehmen die Änderungen schnellstmöglich vor.`,
  [k.PRIVACY_49]: `§ 8 Änderungen der Datenschutzerklärung`,
  [k.PRIVACY_50]: `Das Internet und technische Möglichkeiten entwickeln sich stetig weiter. Selbstverständlich werden wir diese neuen Möglichkeiten nutzen, um die Hochzeiten unserer Nutzer noch unvergesslicher zu machen. Das kann auch dazu führen, dass wir unsere Datenschutzbestimmungen aktualisieren und ergänzen. Dies werden wir an dieser Stelle veröffentlichen.`,
  [k.PRIVACY_51]: `Stand: 28.11.2022`,
  [k.IF_YOU_NEED_MORE_LANGUAGES]: `Falls Ihr weitere Sprachen braucht, schreibt uns eine kurze Mail!`,
  [k.DECIDE_LANGUAGES]: `Entscheidet welche Sprachen Eure Hochzeits-Website unterstützen soll. Zu allen ausgewählten Sprachen könnt Ihr Eure Texte eingeben.`,
  [k.EDIT_LANGUAGES]: `Sprachen bearbeiten`,
  [k.ACTIVE_VOUCHER]: `Aktiver Gutschein:`,
  [k.ENTER_VOUCHER_CODE]: `Gutschein Code eingeben`,
  [k.VOUCHER_CODE]: `Rabatt-Code`,
  [k.INVALID_CODE]: `Leider kein gültiger Code!`,
  [k.WANT_CUSTOM_LOGO]: `Wollt Ihr ein eigenes Hochzeits-Logo nutzen?`,
  [k.INDIVIDUAL_LOGO]: `Individuelles Logo`,
  [k.STANDARD_LOGO]: `Standard-Logo`,
  [k.PRIVATE_PURCHASE]: `Der Kauf erfolgt privat`,
  [k.COMMERCIAL_PURCHASE]: `Der Kauf erfolgt gewerblich`,
  [k.MAX_GUESTS]: 'Gratisversion: Maximale Gästezahl',
  [k.MAX_GUESTS_REACHED]:
    'Diese Hochzeit nutzt immer noch die kostenlose Basisversion von EureHochzeitOnline.com, aber hat inzwischen mehr als fünf angemeldete Gäste. Gemäß unserer Nutzungsbedingungen ist dafür ein Upgrade auf ein Komplett- oder Premiumpaket nötig. Daher führt bitte ein Upgrade Eure Pakets durch, um Eure Hochzeits-Weibsite wie gewohnt nutzen zu können.',
  [k.ACTIVATE_PACKAGE]: 'Paket freischalten',
  [k.IF_MESSAGE_WRONGFUL]:
    'Falls Ihr der Meinung seid, dass diese Meldung fälschlicherweise angezeigt wird, dann schreibt uns bitte eine Mail an:',

  // EOF
};
