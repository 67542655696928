import styled from 'styled-components';

import breakpoints from '../../../../breakpoints';
import boxShadows from '../../../../boxShadows';

export default styled.div`
  position: fixed;
  top: ${({ belowTopBar }) => (belowTopBar ? '60px' : 0)};
  right: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  z-index: 10;
  border-radius: 0;
  border-bottom-left-radius: 12px;
  height: 34px;
  padding: 6px 12px 6px 21px;
  margin-right: -12px;
  background-color: ${({ weddingStyle }) => `${weddingStyle.colors.button.primaryColor}`};
  @media (min-width: ${breakpoints.xs}) {
    height: 41px;
  }
  box-shadow: ${boxShadows.z2};
  span {
    color: ${({ weddingStyle }) => `${weddingStyle.colors.button.primaryColor_T}!important`};
  }
  .MuiFormControlLabel-label {
    max-width: 125px;
    text-align: left;
    line-height: 1.1;
    @media (min-width: ${breakpoints.xs}) {
      max-width: 180px;
    }
  }
`;
