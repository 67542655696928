import i18n from 'i18next';
import k from './../../../../i18n/keys';
import React, { useContext, useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import styled from 'styled-components';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import defaultStyles from '../../../../Shared/WeddingStyles/DefaultStyles';
import GenericStyleContainer from '../../../../Shared/WeddingStyles/GenericStyleContainer';
import WeddingContext from '../../../../Shared/Context/WeddingContext';
import { ReactComponent as CloseIcon } from '../../../../_assets/svg/close-simple.svg';

import CheckoutForm from './Components/CheckoutForm';
import PackageSelection from '../PackageSelection/PackageSelection';
import PACKAGES from '../../../../Shared/Constants/PACKAGES';
import breakpoints from '../../../../Shared/breakpoints';
import isProduction from '../../../../Shared/FunctionUtils/isProduction';

const StyledDialog = styled(Dialog)`
  @media (max-width: ${breakpoints.sm}) {
    .MuiDialog-paper {
      margin: 0;
      max-height: 100%;
      width: 100%;
      height: 100%;
      border-radius: 0;
    }
  }
`;

const StyledDialogContent = styled(DialogContent)`
  width: 1000px;
  max-width: 85vw;
  @media (max-width: ${breakpoints.sm}) {
    max-width: calc(100vw - 18px);
    padding: 9px;
    margin-top: -21px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  h3,
  p {
    margin: 0px;
    margin-bottom: 12px;
  }
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  #payment-element {
    margin-top: 18px !important;
    margin-bottom: 18px !important;
    margin-right: 0 !important;
  }
  #submit {
    width: 150px;
    margin: auto;
    > #button-text {
      border-color: #677b9a;
      background-color: #677b9a;
    }
  }
`;

const CloseButton = styled(IconButton)`
  display: block;
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 10;
  svg {
    height: 15px;
    width: 15px;
  }
`;

const PaymentDialog = () => {
  const history = useHistory();
  const { weddingConfig, weddingId } = useContext(WeddingContext);
  const [clientSecret, setClientSecret] = useState('');
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [loadingPaymentIntent, setLoadingPaymentIntent] = useState(false);
  const [appliedVoucher, setAppliedVoucher] = useState(null);
  let stripePromise;
  if (isProduction()) {
    stripePromise = loadStripe('pk_live_oaJZIdkpUNKaVb0a0pMeOQ76');
  } else {
    stripePromise = loadStripe('pk_test_RU4re21Yl94ODCBWxHYaSdA2');
  }

  useEffect(() => {
    const selectedPackageFromUrl = new URLSearchParams(window.location.search).get('package');
    const clientSecretFromUrl = new URLSearchParams(window.location.search).get('payment_intent_client_secret');
    if (clientSecretFromUrl) {
      setClientSecret(clientSecretFromUrl);
    }
    if (selectedPackageFromUrl) {
      setSelectedPackage(selectedPackageFromUrl);
    }
    if (!selectedPackage || clientSecret || clientSecretFromUrl) {
      return;
    }
    const createPaymentIntent = async () => {
      setLoadingPaymentIntent(true);
      ReactPixel.trackCustom('PaymentIntentCreated');
      const { data: receivedClientSecret } = await axios.post(`${weddingId}/payment-service/create-payment-intent`, {
        weddingId: weddingId,
        selectedPackage: selectedPackage,
        appliedVoucher: appliedVoucher,
        price: appliedVoucher?.discountInEuro
          ? PACKAGES[selectedPackage].price - appliedVoucher?.discountInEuro * 100
          : PACKAGES[selectedPackage].price,
      });
      setClientSecret(receivedClientSecret);
      setLoadingPaymentIntent(false);
    };
    createPaymentIntent();
  }, [weddingId, clientSecret, selectedPackage, appliedVoucher]);

  const handleClose = (e, reason) => {
    if (reason === 'backdropClick') return;
    history.push(`/${weddingId}/`);
  };

  return (
    <StyledDialog
      open
      maxWidth="xl"
      onClose={handleClose}
      aria-labelledby="{messageTitle}"
      aria-describedby="{messageDescriptionText}"
      className="color-background-white"
    >
      <GenericStyleContainer weddingStyleData={defaultStyles[weddingConfig.weddingStyle]}>
        <DialogTitle>
          <CloseButton onClick={() => handleClose()}>
            <CloseIcon></CloseIcon>
          </CloseButton>
        </DialogTitle>
        <StyledDialogContent className="color-background-white">
          {selectedPackage && clientSecret ? (
            <Elements
              options={{
                fonts: [
                  {
                    cssSrc: `https://fonts.googleapis.com/css?family=${
                      defaultStyles[weddingConfig.weddingStyle].bodyFont
                    }`,
                  },
                ],
                clientSecret,
                appearance: {
                  theme: 'flat',
                  variables: {
                    colorText: defaultStyles[weddingConfig.weddingStyle].colors.primary.onWhite,
                    fontFamily: defaultStyles[weddingConfig.weddingStyle].bodyFont,
                  },
                },
              }}
              stripe={stripePromise}
            >
              <CheckoutForm
                weddingId={weddingId}
                weddingConfig={weddingConfig}
                closeDialog={handleClose}
                selectedPackage={selectedPackage}
                appliedVoucher={appliedVoucher}
              />
            </Elements>
          ) : weddingConfig.selectedPackage && weddingConfig.selectedPackage !== 'basic' ? (
            <div>
              <h2>{i18n.t(k.PACKAGE_UPGRADE)}</h2>
              {i18n.t(k.PACKAGE_ALREADY_BOUGHT)}
              <br></br>
              <br></br>
              {i18n.t(k.UPGRADE_TO_HIGHER_PACKAGE)}

              <br></br>
              <br></br>
              <a href="mailto:beeni@eurehochzeitonline.com">beeni@eurehochzeitonline.com</a>
              <br></br>
              <br></br>
              <br></br>
            </div>
          ) : (
            <PackageSelection
              weddingId={weddingId}
              weddingStyle={weddingConfig.weddingStyle}
              handleClose={handleClose}
              setSelectedPackage={setSelectedPackage}
              loadingPaymentIntent={loadingPaymentIntent}
              appliedVoucher={appliedVoucher}
              setAppliedVoucher={setAppliedVoucher}
            ></PackageSelection>
          )}
        </StyledDialogContent>
      </GenericStyleContainer>
    </StyledDialog>
  );
};

export default PaymentDialog;
