const classification = {
  XL: 'XL',
  L: 'L',
  M: 'M',
  S: 'S',
  XS: 'XS',
};

const fontAdjustmentMap = {
  'Great Vibes': classification.S,
  Poppins: classification.L,
  'Amatic SC': classification.XS,
  Quicksand: classification.M,
};

export default (font, originalSizeInPixels) => {
  const currentFontClassification = fontAdjustmentMap[font];
  let adjustmentFactor = 1;
  switch (currentFontClassification) {
    case classification.XL:
      adjustmentFactor = 0.6;
      break;
    case classification.L:
      adjustmentFactor = 0.9;
      break;
    case classification.S:
      adjustmentFactor = 1.3;
      break;
    case classification.XS:
      adjustmentFactor = 1.6;
      break;
    default:
      adjustmentFactor = 1;
  }
  return `${Math.ceil(parseInt(originalSizeInPixels.split('px')[0], 10) * adjustmentFactor)}px`;
};
