import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { hotjar } from 'react-hotjar';
import { Element } from 'react-scroll';
import defaultStyles from '../../Shared/WeddingStyles/DefaultStyles';
import GenericStyleContainer from '../../Shared/WeddingStyles/GenericStyleContainer';
import LegalFooter from '../../Shared/Components/LegalFooter/LegalFooter';
import HeroSection from './Sections/HeroSection';
import TopBar from './Components/TopBar';
import FAQSection from './Sections/FAQSection';
import DesignSection from './Sections/DesignSection';
import FeaturesSection from './Sections/FeaturesSection';
import TestimonialsSection from './Sections/TestimonialsSection';
import PricingSection from './Sections/PricingSection';
import FacebookChat from './Components/FacebookChat';
import isProduction from '../../Shared/FunctionUtils/isProduction';

const LandingPage = ({ cookiePreference }) => {
  const { search } = useLocation();
  useEffect(() => {
    if (cookiePreference === 'ALL' && isProduction()) {
      hotjar.initialize(1808603, 6);
    }
  }, [cookiePreference]);

  return (
    <GenericStyleContainer weddingStyleData={defaultStyles['eurehochzeitonline']}>
      <TopBar></TopBar>
      <HeroSection search={search}></HeroSection>
      <Element name="features">
        <FeaturesSection search={search}></FeaturesSection>
      </Element>
      <Element name="design">
        <DesignSection search={search}></DesignSection>
        <TestimonialsSection search={search}></TestimonialsSection>
      </Element>
      <Element name="pricing">
        <PricingSection search={search}></PricingSection>
      </Element>
      <Element name="faq">
        <FAQSection search={search}></FAQSection>
      </Element>
      <FacebookChat></FacebookChat>
      <LegalFooter weddingStyle={'eurehochzeitonline'}></LegalFooter>
    </GenericStyleContainer>
  );
};

export default LandingPage;
