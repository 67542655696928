import React, { useContext } from 'react';
import styled from 'styled-components';
import { Dialog } from '@material-ui/core';

import defaultStyles from '../../../WeddingStyles/DefaultStyles';
import GenericStyleContainer from '../../../WeddingStyles/GenericStyleContainer';
import WeddingContext from '../../../Context/WeddingContext';

const InnerContainerForm = styled.form`
  width: 600px;
  max-width: 100%;
`;
const InnerContainerDiv = styled.div`
  width: 600px;
  max-width: 100%;
`;

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    transform: translateY(-20px);
    position: relative;
    overflow-y: unset;
    max-width: 97%;
    > div {
      overflow-y: auto;
    }
  }
`;
const EditingDialog = ({ onSubmit, open, isForm = true, children }) => {
  const weddingContext = useContext(WeddingContext);
  const weddingStyle = weddingContext ? weddingContext.weddingConfig.weddingStyle : 'eurehochzeitonline';

  return (
    <StyledDialog open={open}>
      <GenericStyleContainer weddingStyleData={defaultStyles[weddingStyle]}>
        {isForm ? (
          <InnerContainerForm onSubmit={onSubmit} className="color-background-white">
            {children}
          </InnerContainerForm>
        ) : (
          <InnerContainerDiv className="color-background-white">{children}</InnerContainerDiv>
        )}
      </GenericStyleContainer>
    </StyledDialog>
  );
};

export default EditingDialog;
