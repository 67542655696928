export default {
  modern: {
    type: 'modern',
    navigationWithShadow: true,
    headerFont: 'Great Vibes',
    navigationFont: 'Poppins',
    bodyFont: 'Poppins',
    background: {
      type: 'gradient',
      colors: ['#ffeaca', '#fcfdf8'],
    },
    colors: {
      primary: {
        light: '#d6b584',
        base: '#c69e61',
        dark: '#af8648',
        onWhite: '#af8648',
        onDefaultBackground: '#c69e61',
      },
      secondary: {
        light: '#7d9a72',
        base: '#5d7952',
        dark: '#49633f',
        onDefaultBackground: '#5d7952',
        onPrimaryColor: '#ffffff',
        onWhite: '#49633f',
      },
      button: {
        primaryColor: '#a4762f',
        primaryColorOnHover: '#805819',
        primaryColor_T: '#ffffff',
        primaryColorOnHover_T: '#ffffff',
        secondaryColor: '#baa17a',
        secondaryColorOnHover: '#a18a67',
        secondaryColor_T: '#ffffff',
        secondaryColorOnHover_T: '#ffffff',
        primaryOnWhite: '#a4762f',
        primaryOnWhiteOnHover: '#805819',
        primaryOnWhite_T: '#ffffff',
        primaryOnWhiteOnHover_T: '#ffffff',
        secondaryOnWhite: '#baa17a',
        secondaryOnWhiteOnHover: '#a18a67',
        secondaryOnWhite_T: '#ffffff',
        secondaryOnWhiteOnHover_T: '#ffffff',
      },
      navigation: {
        textColor: '#49633f',
        inverseColor: '#ffffff',
      },
      mapMarker: {
        icon: '#ffffff',
        background: '#a4762f',
      },
    },
    assets: {
      mood: '/assets/wedding-styles/modern/mood_modern.jpg',
      element: '/assets/wedding-styles/modern/modern_element.png',
    },
  },
  vintage: {
    type: 'vintage',
    navigationWithShadow: false,
    headerFont: 'Amatic SC',
    navigationFont: 'Amatic SC',
    bodyFont: 'Quicksand',
    background: {
      type: 'image',
      image: '/assets/wedding-styles/vintage/vintage_kraftpapier.jpg',
      overlay: ['#99570066'],
    },
    colors: {
      primary: {
        light: '#ffffff',
        base: '#ffffff',
        dark: '#f5f5f5',
        onWhite: '#725328',
        onDefaultBackground: '#ffffff',
      },
      secondary: {
        light: '#9a7644',
        base: '#725328',
        dark: '#5c4019',
        onDefaultBackground: '#ffffff',
        onPrimaryColor: '#725328',
        onWhite: '#5c4019',
      },
      button: {
        primaryColor: '#ffffff',
        primaryColorOnHover: '#ecdfcc',
        primaryColor_T: '#805819',
        primaryColorOnHover_T: '#805819',
        secondaryColor: '#ecdfcc',
        secondaryColorOnHover: '#bfae96',
        secondaryColor_T: '#805819',
        secondaryColorOnHover_T: '#805819',
        primaryOnWhite: '#805819',
        primaryOnWhiteOnHover: '#5c4019',
        primaryOnWhite_T: '#ffffff',
        primaryOnWhiteOnHover_T: '#ffffff',
        secondaryOnWhite: '#ecdfcc',
        secondaryOnWhiteOnHover: '#bfae96',
        secondaryOnWhite_T: '#805819',
        secondaryOnWhiteOnHover_T: '#805819',
      },
      navigation: {
        textColor: '#ffffff',
        inverseColor: '#725328',
      },
      mapMarker: {
        icon: '#ffffff',
        background: '#725328',
      },
    },
    assets: {
      mood: '/assets/wedding-styles/vintage/mood_vintage.jpg',
      border: '/assets/wedding-styles/vintage/vintage_trennstrich.png',
      horizontalBorder: '/assets/wedding-styles/vintage/vintage_horizontale_linie.png',
      heart: '/assets/wedding-styles/vintage/vintage_herz.png',
      threeHearts: '/assets/wedding-styles/vintage/vintage_drei_herzen.png',
    },
  },
  flower: {
    type: 'flower',
    navigationWithShadow: true,
    headerFont: 'Sofia',
    navigationFont: 'Poppins',
    bodyFont: 'Poppins',
    background: {
      type: 'gradient',
      colors: ['#fcf3fb', '#fce9eb'],
    },
    colors: {
      primary: {
        light: '#8498b7',
        base: '#677b9a',
        dark: '#536a8c',
        onWhite: '#677b9a',
        onDefaultBackground: '#677b9a',
      },
      secondary: {
        light: '#ddc8ea',
        base: '#ac96ba',
        dark: '#8b64a2',
        onDefaultBackground: '#916ea8',
        onPrimaryColor: '#ffffff',
        onWhite: '#916ea8',
      },
      button: {
        primaryColor: '#677b9a',
        primaryColorOnHover: '#3f5475',
        primaryColor_T: '#ffffff',
        primaryColorOnHover_T: '#ffffff',
        secondaryColor: '#937bab',
        secondaryColorOnHover: '#8f70ad',
        secondaryColor_T: '#ffffff',
        secondaryColorOnHover_T: '#ffffff',
        primaryOnWhite: '#677b9a',
        primaryOnWhiteOnHover: '#3f5475',
        primaryOnWhite_T: '#ffffff',
        primaryOnWhiteOnHover_T: '#ffffff',
        secondaryOnWhite: '#937bab',
        secondaryOnWhiteOnHover: '#8f70ad',
        secondaryOnWhite_T: '#ffffff',
        secondaryOnWhiteOnHover_T: '#ffffff',
      },
      navigation: {
        textColor: '#8b64a2',
        inverseColor: '#ffffff',
      },
      mapMarker: {
        icon: '#ffffff',
        background: '#8b64a2',
      },
    },
    assets: {
      mood: '/assets/wedding-styles/flower/mood_flower.jpg',
      element1: '/assets/wedding-styles/flower/flower_element_1.png',
      element2: '/assets/wedding-styles/flower/flower_element_2.png',
      frame: '/assets/wedding-styles/flower/flower_frame.png',
    },
  },
  klassisch: {
    type: 'klassisch',
    navigationWithShadow: false,
    headerFont: 'Quicksand',
    navigationFont: 'Quicksand',
    bodyFont: 'Quicksand',
    background: {
      type: 'gradient',
      colors: ['#f47386', '#f47386'],
    },
    colors: {
      primary: {
        light: '#ffffff',
        base: '#ffffff',
        dark: '#f5f5f5',
        onWhite: '#9a3241',
        onDefaultBackground: '#ffffff',
      },
      secondary: {
        light: '#e06e7e',
        base: '#b04756',
        dark: '#9a3241',
        onDefaultBackground: '#ffffff',
        onPrimaryColor: '#b04756',
        onWhite: '#b04756',
      },
      button: {
        primaryColor: '#ffffff',
        primaryColorOnHover: '#ece2e2',
        primaryColor_T: '#b04756',
        primaryColorOnHover_T: '#b04756',
        secondaryColor: '#ffffff',
        secondaryColorOnHover: '#ece2e2',
        secondaryColor_T: '#b04756',
        secondaryColorOnHover_T: '#b04756',
        primaryOnWhite: '#b04756',
        primaryOnWhiteOnHover: '#9a3241',
        primaryOnWhite_T: '#ffffff',
        primaryOnWhiteOnHover_T: '#ffffff',
        secondaryOnWhite: '#f47386',
        secondaryOnWhiteOnHover: '#e06e7e',
        secondaryOnWhite_T: '#ffffff',
        secondaryOnWhiteOnHover_T: '#ffffff',
      },
      navigation: {
        textColor: '#ffffff',
        inverseColor: '#9a3241',
      },
      mapMarker: {
        icon: '#ffffff',
        background: '#9a3241',
      },
    },
    assets: {
      mood: '/assets/wedding-styles/klassisch/mood_klassisch.jpg',
      heart: '/assets/wedding-styles/klassisch/klassisch_herz.png',
    },
  },
  eurehochzeitonline: {
    type: 'eurehochzeitonline',
    navigationWithShadow: false,
    headerFont: 'Quicksand',
    navigationFont: 'Quicksand',
    bodyFont: 'Quicksand',
    background: {
      type: 'gradient',
      colors: ['#f47386', '#f47386'],
    },
    colors: {
      primary: {
        light: '#e87076',
        base: '#e06e7e',
        dark: '#9a3241',
        onWhite: '#9a3241',
        onDefaultBackground: '#9a3241',
      },
      secondary: {
        light: '#999999',
        base: '#3f3f3f',
        dark: '#333333',
        onDefaultBackground: '#3f3f3f',
        onPrimaryColor: '#ffffff',
        onWhite: '#3f3f3f',
      },
      button: {
        primaryColor: '#e87076',
        primaryColorOnHover: '#9a3241',
        primaryColor_T: '#ffffff',
        primaryColorOnHover_T: '#ffffff',
        secondaryColor: '#999999',
        secondaryColorOnHover: '#888888',
        secondaryColor_T: '#ffffff',
        secondaryColorOnHover_T: '#ffffff',
        primaryOnWhite: '#e87076',
        primaryOnWhiteOnHover: '#9a3241',
        primaryOnWhite_T: '#ffffff',
        primaryOnWhiteOnHover_T: '#ffffff',
        secondaryOnWhite: '#999999',
        secondaryOnWhiteOnHover: '#888888',
        secondaryOnWhite_T: '#ffffff',
        secondaryOnWhiteOnHover_T: '#ffffff',
      },
      navigation: {
        textColor: '#ffffff',
        inverseColor: '#9a3241',
      },
      mapMarker: {
        icon: '#ffffff',
        background: '#9a3241',
      },
    },
    assets: {
      mood: '/assets/wedding-styles/klassisch/mood_klassisch.jpg',
      heart: '/assets/wedding-styles/klassisch/klassisch_herz.png',
    },
  },
};
