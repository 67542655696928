import styled from 'styled-components';

import defaultStyles from '../../DefaultStyles';
import breakpoints from '../../../breakpoints';

export default styled.p`
  font-size: 30px !important;
  text-transform: uppercase;
  letter-spacing: 6px;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${defaultStyles.vintage.bodyFont} !important;
  color: ${defaultStyles.vintage.colors.primary.base} !important;
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
    margin-top: 6px;
    font-size: 18px;
  }
`;
