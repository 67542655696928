import i18n from 'i18next';
import k from './../../../../../../i18n/keys';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import axios from 'axios';
import WeddingContext from '../../../../../../Shared/Context/WeddingContext';
import LoadingState from '../../../../../../Shared/Components/LoadingState/LoadingState';
import breakpoints from '../../../../../../Shared/breakpoints';
import ContentCardContainer from '../../../../Components/ContentCardContainer';
import LanguageContext from '../../../../../../Shared/Context/LanguageContext';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    min-width: 90%;
    max-width: 90%;
    min-height: 90%;
    max-height: 90%;
    .MuiDialogTitle-root {
      text-align: center;
    }
    .MuiTableRow-root {
      vertical-align: text-top;
      span,
      td,
      th {
        font-size: 14px !important;
      }
      .border-bottom-required {
        border-bottom: solid #00000025 1px;
        padding-bottom: 12px;
      }
      th {
        font-weight: bold;
      }
    }
  }

  @media (max-width: ${breakpoints.md}) {
    .MuiTableContainer-root {
      display: none;
    }
  }
`;

const GuestLine = styled.p`
  display: flex;
  flex-direction: row;
`;
const GuestCell = styled.span`
  width: 200px;
`;

const MobileViewContainer = styled.div`
  display: none;
  > div {
    max-width: 95%;
    width: 500px;
    padding: 9px;
  }
  @media (max-width: ${breakpoints.md}) {
    display: block;
  }
`;

const CardLine = styled.div`
  display: flex;
  text-align: left;
`;
const CardSectionHeader = styled.div`
  margin: 12px 0 0 0;
  font-weight: bold;
`;
const CardKey = styled.div`
  width: 150px;
`;
const CardValue = styled.div`
  margin-left: auto;
  font-weight: bold;
`;
const NoRsvpsReceived = styled.p`
  text-align: center;
`;

const RsvpOverviewDialog = ({ pageData, showRsvpOverviewDialog, setShowRsvpOverviewDialog }) => {
  const { weddingId } = useContext(WeddingContext);
  const { currentLanguage } = useContext(LanguageContext);
  const [loadingRsvpList, setLoadingRsvpList] = useState(true);
  const [allRsvps, setAllRsvps] = useState([]);
  const foodOptions = pageData.elements.find(e => e.elementType === 'rsvpElement').foodOptions;

  const loadAllRsvps = async () => {
    setLoadingRsvpList(true);
    const { data: result } = await axios.get(`/${weddingId}/wedding-rsvp-read/?read-all=true`);
    setAllRsvps(result);
    setLoadingRsvpList(false);
  };

  useEffect(() => {
    loadAllRsvps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledDialog open={showRsvpOverviewDialog} onClose={() => setShowRsvpOverviewDialog(false)}>
      <DialogTitle>{i18n.t(k.OVERVIEW_OF_ALL_RSVPS)}</DialogTitle>
      <DialogContent>
        {loadingRsvpList ? (
          <LoadingState message={i18n.t(k.LOADING_RSVPS)}></LoadingState>
        ) : (
          <>
            {allRsvps.length === 0 ? (
              <NoRsvpsReceived>{i18n.t(k.NO_RSVPS_YET)}</NoRsvpsReceived>
            ) : (
              <>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="right">#</TableCell>
                        <TableCell align="left">{i18n.t(k.E_MAIL_ADDRESS)}</TableCell>
                        <TableCell align="left">{i18n.t(k.DATE)}</TableCell>
                        <TableCell align="left">{i18n.t(k.RESPONSE)}</TableCell>
                        <TableCell align="left">{i18n.t(k.GUESTS_AND_MENU_WISH)}</TableCell>
                        <TableCell align="left">{i18n.t(k.QUESTIONS_AND_REMARKS)}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {allRsvps.map((rsvp, index) => (
                        <TableRow hover tabIndex={-1} key={index}>
                          <TableCell align="right">{index + 1}</TableCell>
                          <TableCell align="left">{rsvp.username}</TableCell>
                          <TableCell align="left">{new Date(rsvp.submissionDate).toLocaleDateString()}</TableCell>
                          <TableCell align="left">
                            {rsvp.willAttend ? i18n.t(k.WILL_ATTEND) : i18n.t(k.WILL_NOT_ATTEND)}
                          </TableCell>
                          <TableCell align="left">
                            {rsvp.guests.map((guest, index) => {
                              const foodOption = foodOptions.find(o => o.id === guest.selectedFood);
                              const optionName =
                                foodOption?.i18n.find(l => l.lng === currentLanguage)?.option ||
                                foodOptions.find(o => o.id === guest.selectedFood)?.option ||
                                guest.selectedFood;
                              return (
                                <GuestLine
                                  key={index}
                                  className={index < rsvp.guests.length - 1 && 'border-bottom-required'}
                                >
                                  <GuestCell>{guest.displayName}</GuestCell>
                                  <GuestCell>{optionName}</GuestCell>
                                </GuestLine>
                              );
                            })}
                          </TableCell>
                          <TableCell align="left">{rsvp.remark}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <MobileViewContainer>
                  {allRsvps.map((rsvp, index) => (
                    <ContentCardContainer key={index}>
                      <h3>
                        {i18n.t(k.RSVP)} #{index + 1}
                      </h3>
                      <CardLine>
                        <CardKey>{i18n.t(k.E_MAIL_ADDRESS)}</CardKey>
                        <CardValue>{rsvp.username}</CardValue>
                      </CardLine>
                      <CardLine>
                        <CardKey>{i18n.t(k.DATE)}</CardKey>
                        <CardValue>{new Date(rsvp.submissionDate).toLocaleDateString()}</CardValue>
                      </CardLine>
                      <CardLine>
                        <CardKey>{i18n.t(k.RESPONSE)}</CardKey>
                        <CardValue>{rsvp.willAttend ? i18n.t(k.WILL_ATTEND) : i18n.t(k.WILL_NOT_ATTEND)}</CardValue>
                      </CardLine>
                      {rsvp.guests.length > 0 && (
                        <CardSectionHeader>
                          <strong>{i18n.t(k.GUESTS_AND_MENU_WISH)}</strong>
                        </CardSectionHeader>
                      )}

                      {rsvp.guests.map((guest, index) => {
                        const foodOption = foodOptions.find(o => o.id === guest.selectedFood);
                        const optionName =
                          foodOption?.i18n.find(l => l.lng === currentLanguage)?.option ||
                          foodOptions.find(o => o.id === guest.selectedFood)?.option ||
                          guest.selectedFood;
                        return (
                          <CardLine key={index} className={index < rsvp.guests.length - 1 && 'border-bottom-required'}>
                            <CardKey>{guest.displayName}</CardKey>
                            <CardValue>{optionName}</CardValue>
                          </CardLine>
                        );
                      })}
                      {rsvp.remark && (
                        <CardSectionHeader>
                          <strong>{i18n.t(k.QUESTIONS_AND_REMARKS)}</strong>
                        </CardSectionHeader>
                      )}

                      <CardLine>{rsvp.remark}</CardLine>
                    </ContentCardContainer>
                  ))}
                </MobileViewContainer>
              </>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={() => setShowRsvpOverviewDialog(false)}>
          {i18n.t(k.CLOSE)}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default RsvpOverviewDialog;
