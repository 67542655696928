import i18n from 'i18next';
import k from './../../../../../../../i18n/keys';
import React, { useContext } from 'react';
import styled from 'styled-components';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import axios from 'axios';

import breakpoints from '../../../../../../../Shared/breakpoints';
import { ReactComponent as UploadSvg } from '../../../../../../../_assets/svg/upload-simple.svg';
import UploadPreview from '../../../../../../../Shared/Components/UploadComponent/UploadPreview';
import WeddingContext from '../../../../../../../Shared/Context/WeddingContext';
import DefaultStyles from '../../../../../../../Shared/WeddingStyles/DefaultStyles';

const Container = styled.div`
  display: flex;
  width: 400px;
  max-width: 100%;
  margin: 12px auto;
`;

const Image = styled.div`
  width: 100%;
  height: 300px;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  background-size: cover;
  background-position: center;
  position: relative;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #e8e8e8;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  border-color: rgba(0, 0, 0, 0.42) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${breakpoints.xs}) {
    height: 350px;
  }
`;

const ButtonContainer = styled.div`
  background-color: #ffffffa8;
  position: absolute;
  max-width: 100%;
  bottom: 0;
  right: 0;
  padding: 3px 0 0 10px;
  border-top-left-radius: 4px;
  .dzu-dropzone {
    overflow: hidden;
    min-height: 40px;
    min-width: 150px;
    background-color: #ffffff;
    border-radius: 0;
    border-top-left-radius: 4px;
  }
  .dzu-inputLabel {
    background-color: ${({ weddingStyle }) => DefaultStyles[weddingStyle].colors.button.primaryColor};
    span {
      color: ${({ weddingStyle }) => DefaultStyles[weddingStyle].colors.button.primaryColor_T};
    }
  }
  .dzu-inputLabelWithFiles {
    display: none;
  }
`;

const UploadButton = styled.span`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 30px;
    width: 30px;
    margin: 6px;
    fill: ${({ weddingStyle }) => DefaultStyles[weddingStyle].colors.button.primaryColor_T} !important;
  }
`;

const ImageUploader = ({ imageUrl, setImageUrl, hideButtonText = false }) => {
  const { weddingId, weddingConfig } = useContext(WeddingContext);

  const getUploadParams = async file => {
    const { meta } = file;
    try {
      const response = await axios.post('photo-presign-url/', {
        weddingId: weddingId,
        name: meta.name,
        contentType: meta.type,
        height: meta.height,
        width: meta.width,
        contentImage: true,
      });
      const { fields, url } = response.data;
      const fileUrl = url + '/' + fields.key;
      return { fields, meta: { fileUrl }, url: url };
    } catch (error) {
      return {};
    }
  };
  const handleChangeStatus = (file, status) => {
    if (status === 'done') {
      const { meta } = file;
      const fileUrl = meta.fileUrl;
      setImageUrl(fileUrl);
      file.remove();
    }
  };

  return (
    <Container className="image-uploader-container">
      <Image className="image" imageUrl={imageUrl}>
        <ButtonContainer className="color-background-white" weddingStyle={weddingConfig.weddingStyle}>
          <Dropzone
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            accept="image/*"
            PreviewComponent={UploadPreview}
            styles={{
              dropzoneActive: { borderColor: '#333333', borderStyle: 'dashed', backgroundColor: '#fafafaaa' },
            }}
            inputContent={() => (
              <UploadButton key={0} weddingStyle={weddingConfig.weddingStyle}>
                <UploadSvg></UploadSvg>
                {!hideButtonText && <span>{i18n.t(k.UPLOAD)}</span>}
              </UploadButton>
            )}
          />
        </ButtonContainer>
      </Image>
    </Container>
  );
};

export default ImageUploader;
