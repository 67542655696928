import i18n from 'i18next';
import k from './../../../i18n/keys';
import React, { useState, useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';

import IconBox from '../.../../../../Shared/Components/SelectionComponent/IconBox';
import SelectionContainer from '../.../../../../Shared/Components/SelectionComponent/SelectionContainer';

import QuestionHeader from '../Components/QuestionHeader';
import OuterContainer from '../Components/OuterContainer';
import NextButton from '../Components/NextButton';
import ButtonContainer from '../Components/ButtonContainer';

const WeddingStyle = ({ onSubmit, requestPreview, initialSelection }) => {
  const [style, setStyle] = useState(initialSelection);

  useEffect(() => {
    setStyle(initialSelection);
  }, [initialSelection]);

  const onSelect = async selection => {
    requestPreview(selection.value);
    setStyle(selection.value);
  };
  const submit = () => {
    ReactPixel.trackCustom('StyleFunnel', { style: style });
    onSubmit({
      value: style,
      question: 'weddingStyle',
    });
  };

  const { default: defaultStyles } = require('../../../Shared/WeddingStyles/DefaultStyles');

  return (
    <OuterContainer>
      <QuestionHeader>{i18n.t(k.QUESTION_WEDDING_STYLE)}</QuestionHeader>
      <SelectionContainer>
        {Object.keys(defaultStyles)
          .filter(key => key !== 'eurehochzeitonline')
          .map(key => {
            const title = i18n.t(k[`DEFAULT_STYLES_${defaultStyles[key].type.toUpperCase()}_TITLE`]);
            return (
              <IconBox
                active={style === key}
                image={defaultStyles[key].assets.mood}
                text={title}
                question="weddingStyle"
                selectValue={key}
                onSelect={onSelect}
                key={key}
                weddingStyle={style}
              />
            );
          })}
      </SelectionContainer>
      <ButtonContainer>
        <NextButton onClick={() => submit()} variant="contained">
          {i18n.t(k.NEXT)}
        </NextButton>
      </ButtonContainer>
    </OuterContainer>
  );
};

export default WeddingStyle;
