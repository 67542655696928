import i18n from 'i18next';
import k from './../../../i18n/keys';
import React from 'react';
import styled from 'styled-components';
import SectionContainer from '../Components/SectionContainer';
import SectionTextContainer from '../Components/SectionTextContainer';
import SectionTitle from '../Components/SectionTitle';
import SectionSubTitle from '../Components/SectionSubTitle';
import boxShadows from '../../../Shared/boxShadows';
import { ReactComponent as EditSvg } from '../../../_assets/svg/edit.svg';
import { ReactComponent as ScheduleSvg } from '../../../_assets/svg/schedule.svg';
import { ReactComponent as LocationSvg } from '../../../_assets/svg/meeting-point.svg';
import { ReactComponent as RsvpSvg } from '../../../_assets/svg/love-letter-menu.svg';
import { ReactComponent as UserSvg } from '../../../_assets/svg/guest_guestlist.svg';
import { ReactComponent as HotelSvg } from '../../../_assets/svg/house-heart.svg';
import { ReactComponent as PresentsSvg } from '../../../_assets/svg/wedding-arch.svg';
import { ReactComponent as CameraSvg } from '../../../_assets/svg/camera.svg';
import { ReactComponent as MusicSvg } from '../../../_assets/svg/music.svg';
import { ReactComponent as DomainSvg } from '../../../_assets/svg/app.svg';
import Button from '../../../Shared/Components/LoginFlow/Components/Button';
import breakpoints from '../../../Shared/breakpoints';
import { NavLink } from 'react-router-dom';

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  min-height: 900px;
  background-color: #fbeff1;
  padding-bottom: 50px;
  box-shadow: ${boxShadows.z2};
  margin-bottom: 12px;
  ul {
    width: 60%;
    min-width: 890px;
    padding-inline-start: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 800px;
    @media (max-width: ${breakpoints.md}) {
      flex-wrap: nowrap;
      height: auto;
      width: 50%;
      min-width: 660px;
    }

    @media (max-width: ${breakpoints.sm}) {
      margin-top: 200px;
      z-index: 1;
    }
  }
`;

const DesignContainer = styled.li`
  display: flex;
  margin: 6px 0;
  background-color: #ffffffbf;
  box-shadow: ${boxShadows.z1};
  padding: 6px 12px 6px 6px;
  border-radius: 12px 0 0 12px;
  width: calc(50% - 50px);
  margin-left: auto;
  max-height: 130px;
  min-height: 130px;
  overflow: hidden;
  > div:first-child {
    margin-right: 21px;
    border-radius: 0 12px 12px 0;
    margin: auto;
    width: 50px;
    svg {
      fill: #9a3241;
      width: 40px;
    }
  }
  > div:last-child {
    width: 85%;
    @media (max-width: ${breakpoints.md}) {
      width: 90%;
    }
  }
  h3 {
    font-weight: bold;
    margin: 12px 6px;
    @media (max-width: ${breakpoints.md}) {
      margin: 6px 3px;
      font-size: 15px;
    }
  }
  p {
    margin: 3px;
    @media (max-width: ${breakpoints.md}) {
      margin: 3px;
      font-size: 15px;
    }
  }
  @media (max-width: ${breakpoints.md}) {
    width: 80%;
  }
  @media (max-width: ${breakpoints.sm}) {
    max-width: 300px;
  }
`;

const SideImage = styled.div`
  position: relative;
  background-image: url('/assets/landing-page/hochzeits-website-funktionen.jpg');
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 35%;
  margin: 25px auto 25px 0;
  box-shadow: ${boxShadows.z1};
  border-radius: 0 12px 12px 0;
  @media (max-width: ${breakpoints.md}) {
    width: 45%;
  }
  @media (max-width: ${breakpoints.sm}) {
    position: absolute;
    top: 200px;
    left: 0;
    right: 0;
    bottom: -100px;
    width: 100%;
  }
`;

const ImageText = styled.div`
  background-color: #ba5a5e66;
  width: 70%;
  max-width: 350px;
  margin-right: auto;
  border-radius: 0 12px 12px 0;
  box-shadow: ${boxShadows.z2};
  h3 {
    padding: 6px 12px;
    font-weight: bold;
    margin-bottom: -6px;
    color: #ffa8ac;
  }
  p {
    color: white;
    padding: 6px 12px;
    font-weight: bold;
    margin-top: -6px;
  }
`;

const SectionInnerContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const FeaturesSection = ({ search }) => {
  const features = [
    {
      key: 'designs',
      label: i18n.t(k.DESIGNS),
      description: i18n.t(k.DIVERSE_LAYOUTS),
      icon: <EditSvg></EditSvg>,
    },
    {
      key: 'agenda',
      label: i18n.t(k.SCHEDULE),
      description: i18n.t(k.YOUR_WEDDING_DAY),
      icon: <ScheduleSvg></ScheduleSvg>,
    },
    {
      key: 'locations',
      label: i18n.t(k.LOCATIONS),
      description: i18n.t(k.CLEAR_DIRECTIONS),
      icon: <LocationSvg></LocationSvg>,
    },
    {
      key: 'rsvp',
      label: i18n.t(k.RSVP),
      description: i18n.t(k.NO_GUESSWORK),
      icon: <RsvpSvg></RsvpSvg>,
    },
    {
      key: 'guests',
      label: i18n.t(k.GUESTMANAGEMENT),
      description: i18n.t(k.GUEST_ADDRESSES_ALLERGIES),
      icon: <UserSvg></UserSvg>,
    },
    {
      key: 'hotels',
      label: i18n.t(k.HOTEL_RECOMMENDATION),
      description: i18n.t(k.HOTEL_SELECTION_FOR_GUESTS),
      icon: <HotelSvg></HotelSvg>,
    },
    {
      key: 'presents',
      label: i18n.t(k.PRESENT_TABLE),
      description: i18n.t(k.EASY_ONLINE_GIFT_REGISTRY),
      icon: <PresentsSvg></PresentsSvg>,
    },
    {
      key: 'photos',
      label: i18n.t(k.PHOTO_GALLERY),
      description: i18n.t(k.WEDDING_PICTURES_FOR_GUESTS),
      icon: <CameraSvg></CameraSvg>,
    },
    {
      key: 'music',
      label: i18n.t(k.PLAYLIST),
      description: i18n.t(k.CUSTOM_SPOTIFY_PLAYLIST_CREATION),
      icon: <MusicSvg></MusicSvg>,
    },
    {
      key: 'domain',
      label: i18n.t(k.OWN_DOMAIN),
      description: i18n.t(k.WEDDING_HOMEPAGE_WITH_OWN_DOMAIN),
      icon: <DomainSvg></DomainSvg>,
    },
  ];
  return (
    <Container>
      <SectionContainer alignment="left">
        <SectionTextContainer alignment="left">
          <SectionTitle>{i18n.t(k.KEY_FEATURES_OVERVIEW)}</SectionTitle>
          <SectionSubTitle>{i18n.t(k.YOUR_WEDDING_WEBSITE_OFFERS_EVERYTHING)}</SectionSubTitle>
          <div>
            <div></div>
            <SectionInnerContainer>
              <ul>
                {features.map(feature => {
                  return (
                    <DesignContainer key={feature.key}>
                      <div children={feature.icon}></div>
                      <div>
                        <h3>{feature.label}</h3>
                        <p>{feature.description}</p>
                      </div>
                    </DesignContainer>
                  );
                })}
              </ul>
              <SideImage>
                <ImageText>
                  <h3>
                    {i18n.t(k.NEW_NOW)} <br></br>
                    {i18n.t(k.MULTILINGUAL_WEDDINGS)}
                  </h3>
                  <p>{i18n.t(k.ON_REQUEST_WE_PROVIDE_WEDDING_WEBSITES)}</p>
                </ImageText>
              </SideImage>
            </SectionInnerContainer>
          </div>
          <NavLink exact to={{ pathname: `/hochzeit-erstellen/1${search}` }}>
            <Button color="primary" variant="contained">
              {i18n.t(k.CREATE_WEDDING_WEBSITE_NOW)}
            </Button>
          </NavLink>
        </SectionTextContainer>
      </SectionContainer>
    </Container>
  );
};

export default FeaturesSection;
