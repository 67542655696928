import styled from 'styled-components';

import breakpoints from '../../../breakpoints';
import Element from './Element';

export default styled(Element)`
  height: 700px;
  width: 600px;
  transform: rotate(15deg);
  bottom: -200px;
  right: -200px;
  transform: ${({ visible }) => (visible ? 'none' : 'translate3d(300px, 300px, 0)')};
  z-index: 1;
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
    height: 500px;
    width: 300px;
    bottom: -215px;
    right: -160px;
  }
`;
