import i18n from 'i18next';
import k from '../../../../i18n/keys';
import React from 'react';
import styled from 'styled-components';
import { Button, CircularProgress } from '@material-ui/core';

import getContentPage from '../../Editing/Functions/getContentPage';

import { ReactComponent as VisibleSvg } from '../../../../_assets/svg/visible.svg';
import { ReactComponent as InvisibleSvg } from '../../../../_assets/svg/invisible.svg';
import { ReactComponent as ArrowUpSvg } from '../../../../_assets/svg/arrow-up.svg';
import { ReactComponent as ArrowDownSvg } from '../../../../_assets/svg/arrow-down.svg';
import boxShadows from '../../../../Shared/boxShadows';

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 9px;
  padding: 3px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 80px;
  background-color: ${({ styles }) => styles.textColor};
  border-radius: 4px;
  box-shadow: ${boxShadows.z2};
`;

const StyledVisibilityButton = styled(Button)`
  color: ${({ styles }) => styles.backgroundColor};
  background-color: ${({ styles }) => styles.textColor} !important;
  width: 100%;
  height: 100%;
  .MuiButton-label {
    display: flex;
    flex-direction: column;
    line-height: 1;
    svg {
      fill: ${({ styles }) => styles.backgroundColor};
      color: ${({ styles }) => styles.backgroundColor};
      &.visibility {
        height: 25px;
        width: 25px;
      }
    }
    .MuiCircularProgress-root {
      margin-bottom: 3px;
    }
    span {
      font-size: 12px;
      text-transform: none;
    }
  }
`;

const OrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: absolute;
  right: 100px;
  top: 0;
  bottom: 0;
`;

const MoveButton = styled(Button)`
  min-width: 30px;
  box-shadow: ${boxShadows.z2};
  background-color: ${({ styles }) => styles.backgroundColor} !important;
  svg {
    height: 15px;
    width: 15px;
    fill: ${({ styles }) => styles.textColor} !important;
  }
  &.Mui-disabled {
    opacity: 0.5;
    svg {
      opacity: 0.5;
    }
  }
`;

const VisibilityAndOrderContainer = ({
  styles,
  toggleVisibility,
  page,
  index,
  numberOfPages,
  pageVisibilitiesBeingToggled,
  weddingConfig,
  moveUp,
  moveDown,
  pageOrdersBeingToggled,
}) => {
  const pageIsVisible = getContentPage(weddingConfig, { contentPage: page })?.visible;
  const pageVisibilityIsLoading = pageVisibilitiesBeingToggled.includes(page);
  const pageOrderIsLoading = pageOrdersBeingToggled.includes(page);

  return (
    <>
      <OrderContainer>
        <MoveButton disabled={pageOrderIsLoading || index === 0} onClick={() => moveUp(page)} styles={styles}>
          {pageOrderIsLoading ? <CircularProgress size={15}></CircularProgress> : <ArrowUpSvg></ArrowUpSvg>}
        </MoveButton>
        <MoveButton
          disabled={pageOrderIsLoading || index === numberOfPages - 1}
          onClick={() => moveDown(page)}
          styles={styles}
        >
          {pageOrderIsLoading ? <CircularProgress size={15}></CircularProgress> : <ArrowDownSvg></ArrowDownSvg>}
        </MoveButton>
      </OrderContainer>
      <Container styles={styles}>
        <StyledVisibilityButton onClick={() => toggleVisibility(page)} styles={styles}>
          {!pageVisibilityIsLoading && !pageIsVisible && (
            <>
              <InvisibleSvg className="visibility"></InvisibleSvg>
              <span>
                {i18n.t(k.FOR_GUESTS)} <br></br> {i18n.t(k.INVISIBLE)}
              </span>
            </>
          )}

          {!pageVisibilityIsLoading && pageIsVisible && (
            <>
              <VisibleSvg className="visibility"></VisibleSvg>
              <span>
                {i18n.t(k.FOR_GUESTS)} <br></br> {i18n.t(k.VISIBLE)}
              </span>
            </>
          )}

          {pageVisibilityIsLoading && <CircularProgress size={25}></CircularProgress>}
        </StyledVisibilityButton>
      </Container>
    </>
  );
};

export default VisibilityAndOrderContainer;
