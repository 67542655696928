module.exports = {
  _DOT: '_DOT',
  HELLO: 'HELLO',
  ENTER_NEW_PASSWORD: 'ENTER_NEW_PASSWORD',
  MINIMUM_6_CHARACTERS: 'MINIMUM_6_CHARACTERS',
  SAVE: 'SAVE',
  UNABLE_TO_RESET_PASSWORD: 'UNABLE_TO_RESET_PASSWORD',
  PASSWORD_CHANGED_SUCCESSFULLY_TITLE: 'PASSWORD_CHANGED_SUCCESSFULLY_TITLE',
  PASSWORD_CHANGED_SUCCESSFULLY: 'PASSWORD_CHANGED_SUCCESSFULLY',
  FUNCTIONS: 'FUNCTIONS',
  DESIGNS: 'DESIGNS',
  PRICES: 'PRICES',
  FAQ: 'FAQ',
  BLOG: 'BLOG',
  CREATE_NOW_FREE: 'CREATE_NOW_FREE',
  PERFECT_PAGE_FOR_YOU: 'PERFECT_PAGE_FOR_YOU',
  PERFECT_DESIGN_IN_FEW_CLICKS: 'PERFECT_DESIGN_IN_FEW_CLICKS',
  DESIGN: 'DESIGN',
  HOW_DESCRIBE_YOUR_DREAM_WEDDING: 'HOW_DESCRIBE_YOUR_DREAM_WEDDING',
  CLICK_ON_ONE_OF_THE_IMAGES: 'CLICK_ON_ONE_OF_THE_IMAGES',
  CREATE_WEDDING_WEBSITE_NOW: 'CREATE_WEDDING_WEBSITE_NOW',
  FREQUENTLY_ASKED_QUESTIONS: 'FREQUENTLY_ASKED_QUESTIONS',
  ANY_MORE_QUESTIONS_OR_FEEDBACK: 'ANY_MORE_QUESTIONS_OR_FEEDBACK',
  WE_LOOK_FORWARD_TO_IT: 'WE_LOOK_FORWARD_TO_IT',
  CONTENT_AND_DESIGN: 'CONTENT_AND_DESIGN',
  PHOTO_GALLERY: 'PHOTO_GALLERY',
  RSVP_CARD: 'RSVP_CARD',
  PLAYLIST: 'PLAYLIST',
  MULTILINGUAL_OWN_DOMAIN: 'MULTILINGUAL_OWN_DOMAIN',
  DURATION_PASSWORD_PROTECTION: 'DURATION_PASSWORD_PROTECTION',
  COST_SUPPORT: 'COST_SUPPORT',
  WHAT_CONTENT_TYPICALLY: 'WHAT_CONTENT_TYPICALLY',
  CAN_CREATE_WEBSITE_BEFORE_DETAILS: 'CAN_CREATE_WEBSITE_BEFORE_DETAILS',
  CAN_CUSTOMIZE_DESIGN: 'CAN_CUSTOMIZE_DESIGN',
  CHANGES_VISIBLE_AFTER_SAVE: 'CHANGES_VISIBLE_AFTER_SAVE',
  PHOTO_UPLOAD_LIMIT: 'PHOTO_UPLOAD_LIMIT',
  ORIGINAL_SIZE_PHOTO_STORAGE: 'ORIGINAL_SIZE_PHOTO_STORAGE',
  GUESTS_DOWNLOAD_UPLOADED_PHOTOS: 'GUESTS_DOWNLOAD_UPLOADED_PHOTOS',
  USE_PHOTO_GALLERY_FOR_SLIDESHOW: 'USE_PHOTO_GALLERY_FOR_SLIDESHOW',
  HOW_DOES_R_S_V_P_WORK: 'HOW_DOES_R_S_V_P_WORK',
  GUESTS_ADD_OWN_SONGS_TO_PLAYLIST: 'GUESTS_ADD_OWN_SONGS_TO_PLAYLIST',
  CAN_DJ_ACCESS_PLAYLIST: 'CAN_DJ_ACCESS_PLAYLIST',
  POSSIBILITY_OF_MULTIPLE_LANGUAGES: 'POSSIBILITY_OF_MULTIPLE_LANGUAGES',
  OWN_DOMAIN_POSSIBILITY: 'OWN_DOMAIN_POSSIBILITY',
  HOW_LONG_ACCESS_WEDDING_WEBSITE: 'HOW_LONG_ACCESS_WEDDING_WEBSITE',
  PROTECT_SITE_WITH_PASSWORD: 'PROTECT_SITE_WITH_PASSWORD',
  IS_EUREHOCHZEITONLINE_FREE: 'IS_EUREHOCHZEITONLINE_FREE',
  HOW_GET_SUPPORT_EUREHOCHZEITONLINE: 'HOW_GET_SUPPORT_EUREHOCHZEITONLINE',
  WEDDING_CONTENT_INDIVIDUAL: 'WEDDING_CONTENT_INDIVIDUAL',
  YES_FEASIBLE_ADVANTAGES: 'YES_FEASIBLE_ADVANTAGES',
  YES_EUREHOCHZEITONLINE_OFFERS: 'YES_EUREHOCHZEITONLINE_OFFERS',
  INDEED_CHANGES_VISIBLE: 'INDEED_CHANGES_VISIBLE',
  IN_FREE_VERSION_LIMITED_PHOTOS: 'IN_FREE_VERSION_LIMITED_PHOTOS',
  WE_GENERATE_DIFFERENT_SIZES: 'WE_GENERATE_DIFFERENT_SIZES',
  YES_GUESTS_CAN_DOWNLOAD: 'YES_GUESTS_CAN_DOWNLOAD',
  YES_FEASIBLE_SCREEN_PROJECTOR: 'YES_FEASIBLE_SCREEN_PROJECTOR',
  R_S_V_P_FUNCTION_SIMPLIFIES: 'R_S_V_P_FUNCTION_SIMPLIFIES',
  YES_YOUR_GUESTS_CAN_SUGGEST: 'YES_YOUR_GUESTS_CAN_SUGGEST',
  NATURALLY_INVITE_DJ_TO_WEBSITE: 'NATURALLY_INVITE_DJ_TO_WEBSITE',
  YES_YOU_CAN_HAVE_MULTIPLE_LANGUAGES: 'YES_YOU_CAN_HAVE_MULTIPLE_LANGUAGES',
  YES_YOU_CAN_HAVE_OWN_DOMAIN: 'YES_YOU_CAN_HAVE_OWN_DOMAIN',
  YOUR_WEDDING_WEBSITE_AVAILABLE_TWO_YEARS: 'YOUR_WEDDING_WEBSITE_AVAILABLE_TWO_YEARS',
  ALL_WEDDING_WEBSITES_PASSWORD_PROTECTED: 'ALL_WEDDING_WEBSITES_PASSWORD_PROTECTED',
  YES_CREATE_WEDDING_WEBSITE_FREE: 'YES_CREATE_WEDDING_WEBSITE_FREE',
  IF_YOU_NEED_SUPPORT_WE_CAN_HELP: 'IF_YOU_NEED_SUPPORT_WE_CAN_HELP',
  KEY_FEATURES_OVERVIEW: 'KEY_FEATURES_OVERVIEW',
  YOUR_WEDDING_WEBSITE_OFFERS_EVERYTHING: 'YOUR_WEDDING_WEBSITE_OFFERS_EVERYTHING',
  NEW_NOW: 'NEW_NOW',
  MULTILINGUAL_WEDDINGS: 'MULTILINGUAL_WEDDINGS',
  ON_REQUEST_WE_PROVIDE_WEDDING_WEBSITES: 'ON_REQUEST_WE_PROVIDE_WEDDING_WEBSITES',
  SCHEDULE: 'SCHEDULE',
  LOCATIONS: 'LOCATIONS',
  RSVP: 'RSVP',
  GUESTMANAGEMENT: 'GUESTMANAGEMENT',
  HOTEL_RECOMMENDATION: 'HOTEL_RECOMMENDATION',
  PRESENT_TABLE: 'PRESENT_TABLE',
  OWN_DOMAIN: 'OWN_DOMAIN',
  DIVERSE_LAYOUTS: 'DIVERSE_LAYOUTS',
  YOUR_WEDDING_DAY: 'YOUR_WEDDING_DAY',
  CLEAR_DIRECTIONS: 'CLEAR_DIRECTIONS',
  NO_GUESSWORK: 'NO_GUESSWORK',
  GUEST_ADDRESSES_ALLERGIES: 'GUEST_ADDRESSES_ALLERGIES',
  HOTEL_SELECTION_FOR_GUESTS: 'HOTEL_SELECTION_FOR_GUESTS',
  EASY_ONLINE_GIFT_REGISTRY: 'EASY_ONLINE_GIFT_REGISTRY',
  WEDDING_PICTURES_FOR_GUESTS: 'WEDDING_PICTURES_FOR_GUESTS',
  CUSTOM_SPOTIFY_PLAYLIST_CREATION: 'CUSTOM_SPOTIFY_PLAYLIST_CREATION',
  WEDDING_HOMEPAGE_WITH_OWN_DOMAIN: 'WEDDING_HOMEPAGE_WITH_OWN_DOMAIN',
  YOUR_WEDDING_WEBSITE: 'YOUR_WEDDING_WEBSITE',
  UNIQUE_LIKE_YOU: 'UNIQUE_LIKE_YOU',
  EASY_SETUP_CUSTOMIZED: 'EASY_SETUP_CUSTOMIZED',
  ALL_DETAILS_OF_YOUR_CELEBRATION: 'ALL_DETAILS_OF_YOUR_CELEBRATION',
  GUEST_FEEDBACK_AND_WISHES: 'GUEST_FEEDBACK_AND_WISHES',
  YOUR_CELEBRATION_FROM_ALL_VIEWS: 'YOUR_CELEBRATION_FROM_ALL_VIEWS',
  MULTILINGUAL_SUPPORT: 'MULTILINGUAL_SUPPORT',
  FREE_CREATE: 'FREE_CREATE',
  DEMO_WEBSITE: 'DEMO_WEBSITE',
  OUR_PRICING: 'OUR_PRICING',
  UNLOCK_ALL_FEATURES: 'UNLOCK_ALL_FEATURES',
  ANY_QUESTIONS_OR_FEEDBACK: 'ANY_QUESTIONS_OR_FEEDBACK',
  WE_ARE_HERE_TO_HELP: 'WE_ARE_HERE_TO_HELP',
  USER_TESTIMONIALS: 'USER_TESTIMONIALS',
  THOUSANDS_OF_PEOPLE: 'THOUSANDS_OF_PEOPLE',
  OUR_GIFT_TIP: 'OUR_GIFT_TIP',
  FOR_ALL_WITNESSES: 'FOR_ALL_WITNESSES',
  BUY_WEDDING_WEBSITE_AS_GIFT: 'BUY_WEDDING_WEBSITE_AS_GIFT',
  BRIDE: 'BRIDE',
  F_WITNESS: 'F_WITNESS',
  GROOM: 'GROOM',
  TESTIMONIAL_1: 'TESTIMONIAL_1',
  TESTIMONIAL_2: 'TESTIMONIAL_2',
  TESTIMONIAL_3: 'TESTIMONIAL_3',
  TESTIMONIAL_4: 'TESTIMONIAL_4',
  THANK_YOU: 'THANK_YOU',
  ACTIVATED_MAIL: 'ACTIVATED_MAIL',
  CLICK_HERE_TO_GO_TO_WED: 'CLICK_HERE_TO_GO_TO_WED',
  AND: 'AND',
  BE_REDIRECTED: 'BE_REDIRECTED',
  SET_A_PASSWORD: 'SET_A_PASSWORD',
  SET_A_PASSWORD_SHORT: 'SET_A_PASSWORD_SHORT',
  ERROR_OCCURED: 'ERROR_OCCURED',
  PASSWORD_ALREADY_EXISTING_TITLE: 'PASSWORD_ALREADY_EXISTING_TITLE',
  PASSWORD_ALREADY_EXISTING: 'PASSWORD_ALREADY_EXISTING',
  GO_TO_WEDDING: 'GO_TO_WEDDING',
  I_AGREE: 'I_AGREE',
  REGISTER_NOW: 'REGISTER_NOW',
  YOUR_FREE_WEDDING_WEBSITE: 'YOUR_FREE_WEDDING_WEBSITE',
  ALREADY_CREATED_WEDDINGS: 'ALREADY_CREATED_WEDDINGS',
  WEDDING: 'WEDDING',
  ENTER_PASSWORD: 'ENTER_PASSWORD',
  USER_ALREADY_EXISTING: 'USER_ALREADY_EXISTING',
  BACK: 'BACK',
  LOGIN: 'LOGIN',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  MAIL_SENT: 'MAIL_SENT',
  OK: 'OK',
  PERFECT: 'PERFECT',
  NAME_OF: 'NAME_OF',
  SPOUSE_M: 'SPOUSE_M',
  SPOUSE_F: 'SPOUSE_F',
  NEXT: 'NEXT',
  WHATS_YOUR_NAME: 'WHATS_YOUR_NAME',
  PREPARING_1: 'PREPARING_1',
  PREPARING_2: 'PREPARING_2',
  PREPARING_3: 'PREPARING_3',
  FINISHED_ENTER_MAIL: 'FINISHED_ENTER_MAIL',
  ACCEPT_TERMS: 'ACCEPT_TERMS',
  ENTER_VALID_MAIL: 'ENTER_VALID_MAIL',
  QUESTION_BRIDE_OR_GROOM: 'QUESTION_BRIDE_OR_GROOM',
  QUESTION_WEDDING_DATE: 'QUESTION_WEDDING_DATE',
  YES_WE_KNOW: 'YES_WE_KNOW',
  NO_WE_DONT_KNOW: 'NO_WE_DONT_KNOW',
  MAKE_SELECTION: 'MAKE_SELECTION',
  QUESTION_WEDDING_LOCATION: 'QUESTION_WEDDING_LOCATION',
  YES_WE_HAVE: 'YES_WE_HAVE',
  ENTER_YOUR_LOCATION: 'ENTER_YOUR_LOCATION',
  LOCATION_CLASSIC: 'LOCATION_CLASSIC',
  LOCATION_COUNTRY: 'LOCATION_COUNTRY',
  LOCATION_OUTDOOR: 'LOCATION_OUTDOOR',
  LOCATION_PALACE_HOTEL: 'LOCATION_PALACE_HOTEL',
  QUESTION_WEDDING_STYLE: 'QUESTION_WEDDING_STYLE',
  HOTEL: 'HOTEL',
  INFOS: 'INFOS',
  LOCATION: 'LOCATION',
  PHOTOS: 'PHOTOS',
  NO_MUSIC_SAVED: 'NO_MUSIC_SAVED',
  SEARCH_FOR_SONGS: 'SEARCH_FOR_SONGS',
  LISTEN_TO_FULL_LIST: 'LISTEN_TO_FULL_LIST',
  OPEN_IN_SPOTIFY: 'OPEN_IN_SPOTIFY',
  LISTEN_TO_SINGLE_SONGS: 'LISTEN_TO_SINGLE_SONGS',
  NO_RESULT_FOUND: 'NO_RESULT_FOUND',
  CANCEL: 'CANCEL',
  ADD_SONG: 'ADD_SONG',
  NO_PHOTOS_UPLOADED_YET: 'NO_PHOTOS_UPLOADED_YET',
  ADD_SOME_PHOTOS: 'ADD_SOME_PHOTOS',
  DROP_PHOTOS_HERE_OR: 'DROP_PHOTOS_HERE_OR',
  SHOOT_PHOTO: 'SHOOT_PHOTO',
  OVERVIEW_OF_ALL_PRESENTS: 'OVERVIEW_OF_ALL_PRESENTS',
  LOADING_BACKINGS: 'LOADING_BACKINGS',
  NO_PRESENTS_YET: 'NO_PRESENTS_YET',
  PRESENT: 'PRESENT',
  E_MAIL_ADDRESS: 'E_MAIL_ADDRESS',
  DATE: 'DATE',
  NO_BACKINGS_YET: 'NO_BACKINGS_YET',
  CLOSE: 'CLOSE',
  ONLY_VISIBLE_FOR_WEDDING_COUPLE: 'ONLY_VISIBLE_FOR_WEDDING_COUPLE',
  SHOW_ALL_ENTRIES: 'SHOW_ALL_ENTRIES',
  EUR: 'EUR',
  ADD_BACKING: 'ADD_BACKING',
  BUY: 'BUY',
  YOUR_BACKING_SAVED: 'YOUR_BACKING_SAVED',
  A_GUEST_ALREADY_BACKED: 'A_GUEST_ALREADY_BACKED',
  DELETE_MY_BACKING: 'DELETE_MY_BACKING',
  OVERVIEW_OF_ALL_RSVPS: 'OVERVIEW_OF_ALL_RSVPS',
  LOADING_RSVPS: 'LOADING_RSVPS',
  NO_RSVPS_YET: 'NO_RSVPS_YET',
  RESPONSE: 'RESPONSE',
  GUESTS_AND_MENU_WISH: 'GUESTS_AND_MENU_WISH',
  QUESTIONS_AND_REMARKS: 'QUESTIONS_AND_REMARKS',
  WILL_ATTEND: 'WILL_ATTEND',
  WILL_NOT_ATTEND: 'WILL_NOT_ATTEND',
  SHOW_ALL_RSVPS: 'SHOW_ALL_RSVPS',
  YOU_ALREADY_SUBMITTED_ON: 'YOU_ALREADY_SUBMITTED_ON',
  CANNOT_CHANGE_ANY_MOR: 'CANNOT_CHANGE_ANY_MOR',
  MENU_WISH: 'MENU_WISH',
  RSVP_LOADING: 'RSVP_LOADING',
  LIST_EVERYONE_WE_MAY_EXPECT: 'LIST_EVERYONE_WE_MAY_EXPECT',
  LIST_EVERYONE_WE_MAY_EXPECT_WITH_MENU: 'LIST_EVERYONE_WE_MAY_EXPECT_WITH_MENU',
  GUEST: 'GUEST',
  ADD_GUEST: 'ADD_GUEST',
  SUBMIT_RSVP: 'SUBMIT_RSVP',
  WEBSITE: 'WEBSITE',
  OUR_PACKAGES: 'OUR_PACKAGES',
  PACKAGE_BASIC: 'PACKAGE_BASIC',
  PACKAGE_COMPLETE: 'PACKAGE_COMPLETE',
  PACKAGE_PREMIUM: 'PACKAGE_PREMIUM',
  PACKAGE_VIP: 'PACKAGE_VIP',
  BEST_SELLER: 'BEST_SELLER',
  SELECTED: 'SELECTED',
  SELECT: 'SELECT',
  CAN_UPGRADE_LATER: 'CAN_UPGRADE_LATER',
  LOOK_IN_FAQ: 'LOOK_IN_FAQ',
  PACKAGE_FEATURES_DESIGN: 'PACKAGE_FEATURES_DESIGN',
  PACKAGE_FEATURES_SUBDOMAIN: 'PACKAGE_FEATURES_SUBDOMAIN',
  PACKAGE_FEATURES_MOBILE: 'PACKAGE_FEATURES_MOBILE',
  PACKAGE_FEATURES_SCHEDULE: 'PACKAGE_FEATURES_SCHEDULE',
  PACKAGE_FEATURES_LOCATION: 'PACKAGE_FEATURES_LOCATION',
  PACKAGE_FEATURES_INFOS: 'PACKAGE_FEATURES_INFOS',
  PACKAGE_FEATURES_RSVP: 'PACKAGE_FEATURES_RSVP',
  PACKAGE_FEATURES_MUSIC: 'PACKAGE_FEATURES_MUSIC',
  PACKAGE_FEATURES_PHOTOS: 'PACKAGE_FEATURES_PHOTOS',
  PACKAGE_FEATURES_SUPPORT: 'PACKAGE_FEATURES_SUPPORT',
  PACKAGE_FEATURES_MULTILANGUAGE: 'PACKAGE_FEATURES_MULTILANGUAGE',
  PACKAGE_FEATURES_OWN_DOMAIN: 'PACKAGE_FEATURES_OWN_DOMAIN',
  PACKAGE_FEATURES_CUSTOM_DESIGN: 'PACKAGE_FEATURES_CUSTOM_DESIGN',
  MAX_FIVE: 'MAX_FIVE',
  CHECKING_PAYMENT_STATUS: 'CHECKING_PAYMENT_STATUS',
  PAYMENT_SUCCESSFULL: 'PAYMENT_SUCCESSFULL',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  ACTIVATE_PACKAGE_NOW: 'ACTIVATE_PACKAGE_NOW',
  PAY: 'PAY',
  EDITING_MODE: 'EDITING_MODE',
  ACTIVE: 'ACTIVE',
  NOT_ACTIVE: 'NOT_ACTIVE',
  ERROR_OCCURED_WITH_MESSAGE: 'ERROR_OCCURED_WITH_MESSAGE',
  TRY_AGAIN_PLEASE: 'TRY_AGAIN_PLEASE',
  PACKAGE_UPGRADE: 'PACKAGE_UPGRADE',
  PACKAGE_ALREADY_BOUGHT: 'PACKAGE_ALREADY_BOUGHT',
  UPGRADE_TO_HIGHER_PACKAGE: 'UPGRADE_TO_HIGHER_PACKAGE',
  REGISTRATION_NOT_COMPLETE: 'REGISTRATION_NOT_COMPLETE',
  YOU_HAVE_AN_EMAIL: 'YOU_HAVE_AN_EMAIL',
  YOU_MUST_REGISTER_FIRST: 'YOU_MUST_REGISTER_FIRST',
  CREATOR_MUST_REGISTER: 'CREATOR_MUST_REGISTER',
  HERE: 'HERE',
  E_MAIL_RESENT: 'E_MAIL_RESENT',
  CONFIRMATION_RESENT_TO: 'CONFIRMATION_RESENT_TO',
  CAN_TAKE_SOME_MINUTES: 'CAN_TAKE_SOME_MINUTES',
  RESENDING_MAIL: 'RESENDING_MAIL',
  DEMO_WEDDING_HINT: 'DEMO_WEDDING_HINT',
  CREATE_OWN_WEBSITE: 'CREATE_OWN_WEBSITE',
  SOME_FEATURES_DEACTIVATED_IN_DEMO: 'SOME_FEATURES_DEACTIVATED_IN_DEMO',
  CAN_CREATE_OWN_WEDDING_WEBSITE: 'CAN_CREATE_OWN_WEDDING_WEBSITE',
  NO_PACKAGE_BOUGHT_HINT: 'NO_PACKAGE_BOUGHT_HINT',
  LETS_UPGRADE: 'LETS_UPGRADE',
  MAX_NUMBER_FOR_BASIC_PACKAGE: 'MAX_NUMBER_FOR_BASIC_PACKAGE',
  UPGRADE_FOR_UNLIMITED_PHOTOS: 'UPGRADE_FOR_UNLIMITED_PHOTOS',
  UPGRADE_FOR_UNLIMITED_MUSIC: 'UPGRADE_FOR_UNLIMITED_MUSIC',
  CONFIRM_DELETE_TITLE: 'CONFIRM_DELETE_TITLE',
  CONFIRM_DELETE_TEXT: 'CONFIRM_DELETE_TEXT',
  DELETE: 'DELETE',
  ADD_ELEMENT: 'ADD_ELEMENT',
  WHICH_ELEMENT_TO_ADD: 'WHICH_ELEMENT_TO_ADD',
  SELECT_ICON_FOR_LOCATION: 'SELECT_ICON_FOR_LOCATION',
  IMAGE_POSITION: 'IMAGE_POSITION',
  SELECT_IMAGE: 'SELECT_IMAGE',
  UPLOAD: 'UPLOAD',
  SELECTED_IMAGE: 'SELECTED_IMAGE',
  NO_IMAGE: 'NO_IMAGE',
  OTHER_IMAGE: 'OTHER_IMAGE',
  EDIT_WEDDING_BASE_DATA: 'EDIT_WEDDING_BASE_DATA',
  ENTER_NAME_OF_BRIDE: 'ENTER_NAME_OF_BRIDE',
  ENTER_NAME_OF_GROOM: 'ENTER_NAME_OF_GROOM',
  SELECT_DATE: 'SELECT_DATE',
  HINT_CHANGE_URL: 'HINT_CHANGE_URL',
  EDIT_COUNTDOWN: 'EDIT_COUNTDOWN',
  EDIT_INFO_BOX: 'EDIT_INFO_BOX',
  EDIT_BOX_WITH_LARGE_PHOTO: 'EDIT_BOX_WITH_LARGE_PHOTO',
  EDIT_LOCATION: 'EDIT_LOCATION',
  EDIT_PLACE: 'EDIT_PLACE',
  ADD_LOCATION: 'ADD_LOCATION',
  ADD_PLACE: 'ADD_PLACE',
  SELECT_SEARCH_RESULT: 'SELECT_SEARCH_RESULT',
  EDIT_PAGE_TITLE: 'EDIT_PAGE_TITLE',
  EDIT_PRESENTS: 'EDIT_PRESENTS',
  PRESENT_INTRO_TEXT: 'PRESENT_INTRO_TEXT',
  ADDITIONAL_ENTRY: 'ADDITIONAL_ENTRY',
  EDIT_RSVP: 'EDIT_RSVP',
  MENU_WISHES_ACTIVATED: 'MENU_WISHES_ACTIVATED',
  EDIT_SCHEDULE_ITEM: 'EDIT_SCHEDULE_ITEM',
  NAVIGATION: 'NAVIGATION',
  COUPLE: 'COUPLE',
  MUSIC: 'MUSIC',
  YOU_ARE_LOGGED_IN_AS_GROOM: 'YOU_ARE_LOGGED_IN_AS_GROOM',
  YOU_ARE_LOGGED_IN_AS_BRIDE: 'YOU_ARE_LOGGED_IN_AS_BRIDE',
  ADDRESS_OF_YOUR_PAGE: 'ADDRESS_OF_YOUR_PAGE',
  WEDDING_CODE_FOR_GUESTS: 'WEDDING_CODE_FOR_GUESTS',
  CURRENT_PACKAGE: 'CURRENT_PACKAGE',
  UPGRADE: 'UPGRADE',
  LOGOUT: 'LOGOUT',
  FOR_GUESTS: 'FOR_GUESTS',
  INVISIBLE: 'INVISIBLE',
  VISIBLE: 'VISIBLE',
  WEDDING_NOT_FOUND: 'WEDDING_NOT_FOUND',
  WEDDING_NOT_FOUND_TEXT: 'WEDDING_NOT_FOUND_TEXT',
  IF_ERROR_REPEATS: 'IF_ERROR_REPEATS',
  ERROR_GENERAL: 'ERROR_GENERAL',
  SORRY_WRITE_MAIL: 'SORRY_WRITE_MAIL',
  MOVE_UP: 'MOVE_UP',
  MOVE_DOWN: 'MOVE_DOWN',
  OH_SORRY: 'OH_SORRY',
  ERROR_REFRESH_PAGE: 'ERROR_REFRESH_PAGE',
  IF_YOU_WANT_TO_HELP: 'IF_YOU_WANT_TO_HELP',
  ENTER_DISPLAY_NAME: 'ENTER_DISPLAY_NAME',
  ENTER_DISPLAY_NAME_SHORT: 'ENTER_DISPLAY_NAME_SHORT',
  WE_SENT_EMAIL: 'WE_SENT_EMAIL',
  WELCOME_BACK: 'WELCOME_BACK',
  ALMOST_THERE: 'ALMOST_THERE',
  PLEASE_SET_PASSWORD: 'PLEASE_SET_PASSWORD',
  PLEASE_SET_PASSWORD_SHORT: 'PLEASE_SET_PASSWORD_SHORT',
  WELCOME_TO_WEDDING_OF: 'WELCOME_TO_WEDDING_OF',
  PLEASE_ENTER_MAIL: 'PLEASE_ENTER_MAIL',
  WELCOME_TO_EHO: 'WELCOME_TO_EHO',
  TO_WEDDING: 'TO_WEDDING',
  ENTER_WEDDING_CODE: 'ENTER_WEDDING_CODE',
  ENTER_WEDDING_CODE_SHORT: 'ENTER_WEDDING_CODE_SHORT',
  RESTART: 'RESTART',
  UPLOAD_PREPARING: 'UPLOAD_PREPARING',
  UPLOAD_STARTED: 'UPLOAD_STARTED',
  UPLOAD_FINISHING: 'UPLOAD_FINISHING',
  UPLOAD_CANCELED: 'UPLOAD_CANCELED',
  INVALID_FILE_TYPE: 'INVALID_FILE_TYPE',
  MAX_FILE_NUMBER: 'MAX_FILE_NUMBER',
  FILE_TOO_LARGE: 'FILE_TOO_LARGE',
  DAYS_TO_GO: 'DAYS_TO_GO',
  DAY_TO_GO: 'DAY_TO_GO',
  TODAY: 'TODAY',
  INVALID_DATE: 'INVALID_DATE',
  DATE_MUST_BE_IN_FUTURE: 'DATE_MUST_BE_IN_FUTURE',
  ADDRESS_OR_NAME_OF_LOCATION: 'ADDRESS_OR_NAME_OF_LOCATION',
  ENTER_SONG_WISH: 'ENTER_SONG_WISH',
  NAME_OF_GUEST: 'NAME_OF_GUEST',
  QUESTION_REMARK_MAX_1000: 'QUESTION_REMARK_MAX_1000',
  LEFT: 'LEFT',
  TOP: 'TOP',
  RIGHT: 'RIGHT',
  BOTTOM: 'BOTTOM',
  NAME_OF_BRIDE: 'NAME_OF_BRIDE',
  NAME_OF_GROOM: 'NAME_OF_GROOM',
  TITLE: 'TITLE',
  DESCRIPTION: 'DESCRIPTION',
  NAME_OF_PLACE: 'NAME_OF_PLACE',
  STREET: 'STREET',
  HOUSENUMBER: 'HOUSENUMBER',
  ZIP: 'ZIP',
  CITY: 'CITY',
  COUNTRY: 'COUNTRY',
  HINTS_FOR_GUESTS: 'HINTS_FOR_GUESTS',
  TITLE_FOR_THIS_PAGE: 'TITLE_FOR_THIS_PAGE',
  SUBTITLE_FOR_THIS_PAGE: 'SUBTITLE_FOR_THIS_PAGE',
  LABEL: 'LABEL',
  AMAZON_URL: 'AMAZON_URL',
  COSTS: 'COSTS',
  NEW_FOOD_OPTION: 'NEW_FOOD_OPTION',
  TIME: 'TIME',
  SCHEDULE_ITEM: 'SCHEDULE_ITEM',
  YOUR_NAME_OR_NICKNAME: 'YOUR_NAME_OR_NICKNAME',
  YOUR_EMAIL_ADDRESS: 'YOUR_EMAIL_ADDRESS',
  YOUR_WEDDING_CODE: 'YOUR_WEDDING_CODE',
  COULD_NOT_SAVE_WEDDING: 'COULD_NOT_SAVE_WEDDING',
  ALREADY_HAVE_WEDDING: 'ALREADY_HAVE_WEDDING',
  CREATE_NEW: 'CREATE_NEW',
  COULD_NOT_LOAD_YOUR_DATA: 'COULD_NOT_LOAD_YOUR_DATA',
  COULD_NOT_SEND_MAIL: 'COULD_NOT_SEND_MAIL',
  DELETE_IMAGE: 'DELETE_IMAGE',
  DELETE_IMAGE_ARE_YOU_SURE: 'DELETE_IMAGE_ARE_YOU_SURE',
  COULD_NOT_LOGIN: 'COULD_NOT_LOGIN',
  WRONG_PASSWORD_TITLE: 'WRONG_PASSWORD_TITLE',
  WRONG_PASSWORD_TEXT: 'WRONG_PASSWORD_TEXT',
  WRONG_CODE_TITLE: 'WRONG_CODE_TITLE',
  WRONG_CODE_TEXT: 'WRONG_CODE_TEXT',
  LOADING: 'LOADING',
  GUEST_NAME_MISSING: 'GUEST_NAME_MISSING',
  GUEST_FOOD_MISSING: 'GUEST_FOOD_MISSING',
  LIMIT_EXCEEDED_PASSWORD_RESET: 'LIMIT_EXCEEDED_PASSWORD_RESET',
  EXPIRED_CODE_PASSWORD_RESET: 'EXPIRED_CODE_PASSWORD_RESET',
  PLEASE_ENTER_NAME: 'PLEASE_ENTER_NAME',
  COUNTDOWN_VISIBLE: 'COUNTDOWN_VISIBLE',
  COUNTDOWN_NOT_VISIBLE: 'COUNTDOWN_NOT_VISIBLE',
  ASK_FOR_MENU_WISHES: 'ASK_FOR_MENU_WISHES',
  ASK_NOT_FOR_MENU_WISHES: 'ASK_NOT_FOR_MENU_WISHES',
  SPRING: 'SPRING',
  SUMMER: 'SUMMER',
  AUTUMN: 'AUTUMN',
  WINTER: 'WINTER',
  COULD_NOT_SAVE_PASSWORD: 'COULD_NOT_SAVE_PASSWORD',
  YES_WE_WILL_ATTEND: 'YES_WE_WILL_ATTEND',
  NO_WE_WILL_NOT_ATTEND: 'NO_WE_WILL_NOT_ATTEND',
  CONFIRM_DIALOG_TITLE: 'CONFIRM_DIALOG_TITLE',
  ALERT_DIALOG_TITLE: 'ALERT_DIALOG_TITLE',
  DEFAULT_IMAGES_TYPE_CAKE: 'DEFAULT_IMAGES_TYPE_CAKE',
  DEFAULT_IMAGES_TYPE_CEREMONY: 'DEFAULT_IMAGES_TYPE_CEREMONY',
  DEFAULT_IMAGES_TYPE_CHAMPAGNERECEPTION: 'DEFAULT_IMAGES_TYPE_CHAMPAGNERECEPTION',
  DEFAULT_IMAGES_TYPE_DANCE: 'DEFAULT_IMAGES_TYPE_DANCE',
  DEFAULT_IMAGES_TYPE_FOOD: 'DEFAULT_IMAGES_TYPE_FOOD',
  DEFAULT_IMAGES_TYPE_CAR: 'DEFAULT_IMAGES_TYPE_CAR',
  DEFAULT_IMAGES_TYPE_GAME: 'DEFAULT_IMAGES_TYPE_GAME',
  DEFAULT_IMAGES_TYPE_DRESSCODE: 'DEFAULT_IMAGES_TYPE_DRESSCODE',
  DEFAULT_IMAGES_TYPE_HOTEL: 'DEFAULT_IMAGES_TYPE_HOTEL',
  DEFAULT_IMAGES_TYPE_PHOTO: 'DEFAULT_IMAGES_TYPE_PHOTO',
  DEFAULT_IMAGES_TYPE_PRESENT: 'DEFAULT_IMAGES_TYPE_PRESENT',
  DEFAULT_IMAGES_TYPE_LOVE: 'DEFAULT_IMAGES_TYPE_LOVE',
  DEFAULT_ELEMENT_TEXT_PAGE_TITLE_LABEL: 'DEFAULT_ELEMENT_TEXT_PAGE_TITLE_LABEL',
  DEFAULT_ELEMENT_TEXT_PAGE_TITLE_TITLE: 'DEFAULT_ELEMENT_TEXT_PAGE_TITLE_TITLE',
  DEFAULT_ELEMENT_TEXT_PAGE_TITLE_SUBTITLE: 'DEFAULT_ELEMENT_TEXT_PAGE_TITLE_SUBTITLE',
  DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_LABEL: 'DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_LABEL',
  DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_TITLE: 'DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_TITLE',
  DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_TIME: 'DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_TIME',
  DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_DESCRIPTION: 'DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_DESCRIPTION',
  DEFAULT_ELEMENT_TEXT_INFO_ITEM_LABEL: 'DEFAULT_ELEMENT_TEXT_INFO_ITEM_LABEL',
  DEFAULT_ELEMENT_TEXT_INFO_ITEM_TITLE: 'DEFAULT_ELEMENT_TEXT_INFO_ITEM_TITLE',
  DEFAULT_ELEMENT_TEXT_INFO_ITEM_DESCRIPTION: 'DEFAULT_ELEMENT_TEXT_INFO_ITEM_DESCRIPTION',
  DEFAULT_ELEMENT_TEXT_LARGE_PHOTO_ITEM_LABEL: 'DEFAULT_ELEMENT_TEXT_LARGE_PHOTO_ITEM_LABEL',
  DEFAULT_ELEMENT_TEXT_LARGE_PHOTO_ITEM_TITLE: 'DEFAULT_ELEMENT_TEXT_LARGE_PHOTO_ITEM_TITLE',
  DEFAULT_ELEMENT_TEXT_LARGE_PHOTO_ITEM_DESCRIPTION: 'DEFAULT_ELEMENT_TEXT_LARGE_PHOTO_ITEM_DESCRIPTION',
  DEFAULT_ELEMENT_TEXT_LOCATION_MAP_LABEL: 'DEFAULT_ELEMENT_TEXT_LOCATION_MAP_LABEL',
  DEFAULT_ELEMENT_TEXT_LOCATION_ITEM_LABEL: 'DEFAULT_ELEMENT_TEXT_LOCATION_ITEM_LABEL',
  DEFAULT_STYLES_MODERN_TITLE: 'DEFAULT_STYLES_MODERN_TITLE',
  DEFAULT_STYLES_MODERN_DESCRIPTION: 'DEFAULT_STYLES_MODERN_DESCRIPTION',
  DEFAULT_STYLES_KLASSISCH_TITLE: 'DEFAULT_STYLES_KLASSISCH_TITLE',
  DEFAULT_STYLES_KLASSISCH_DESCRIPTION: 'DEFAULT_STYLES_KLASSISCH_DESCRIPTION',
  DEFAULT_STYLES_VINTAGE_TITLE: 'DEFAULT_STYLES_VINTAGE_TITLE',
  DEFAULT_STYLES_VINTAGE_DESCRIPTION: 'DEFAULT_STYLES_VINTAGE_DESCRIPTION',
  DEFAULT_STYLES_FLOWER_TITLE: 'DEFAULT_STYLES_FLOWER_TITLE',
  DEFAULT_STYLES_FLOWER_DESCRIPTION: 'DEFAULT_STYLES_FLOWER_DESCRIPTION',
  COOKIES_01: 'COOKIES_01',
  COOKIES_02: 'COOKIES_02',
  COOKIES_03: 'COOKIES_03',
  COOKIES_04: 'COOKIES_04',
  COOKIES_05: 'COOKIES_05',
  COOKIES_06: 'COOKIES_06',
  COOKIES_07: 'COOKIES_07',
  COOKIES_08: 'COOKIES_08',
  COOKIES_09: 'COOKIES_09',
  TERMS_AND_CONDITIONS_00: 'TERMS_AND_CONDITIONS_00',
  TERMS_AND_CONDITIONS_01: 'TERMS_AND_CONDITIONS_01',
  TERMS_AND_CONDITIONS_02: 'TERMS_AND_CONDITIONS_02',
  TERMS_AND_CONDITIONS_03: 'TERMS_AND_CONDITIONS_03',
  TERMS_AND_CONDITIONS_04: 'TERMS_AND_CONDITIONS_04',
  TERMS_AND_CONDITIONS_05: 'TERMS_AND_CONDITIONS_05',
  TERMS_AND_CONDITIONS_06: 'TERMS_AND_CONDITIONS_06',
  TERMS_AND_CONDITIONS_07: 'TERMS_AND_CONDITIONS_07',
  TERMS_AND_CONDITIONS_08: 'TERMS_AND_CONDITIONS_08',
  TERMS_AND_CONDITIONS_09: 'TERMS_AND_CONDITIONS_09',
  TERMS_AND_CONDITIONS_10: 'TERMS_AND_CONDITIONS_10',
  TERMS_AND_CONDITIONS_11: 'TERMS_AND_CONDITIONS_11',
  TERMS_AND_CONDITIONS_12: 'TERMS_AND_CONDITIONS_12',
  TERMS_AND_CONDITIONS_13: 'TERMS_AND_CONDITIONS_13',
  TERMS_AND_CONDITIONS_14: 'TERMS_AND_CONDITIONS_14',
  TERMS_AND_CONDITIONS_15: 'TERMS_AND_CONDITIONS_15',
  TERMS_AND_CONDITIONS_16: 'TERMS_AND_CONDITIONS_16',
  TERMS_AND_CONDITIONS_17: 'TERMS_AND_CONDITIONS_17',
  TERMS_AND_CONDITIONS_18: 'TERMS_AND_CONDITIONS_18',
  TERMS_AND_CONDITIONS_19: 'TERMS_AND_CONDITIONS_19',
  TERMS_AND_CONDITIONS_20: 'TERMS_AND_CONDITIONS_20',
  TERMS_AND_CONDITIONS_21: 'TERMS_AND_CONDITIONS_21',
  TERMS_AND_CONDITIONS_22: 'TERMS_AND_CONDITIONS_22',
  TERMS_AND_CONDITIONS_23: 'TERMS_AND_CONDITIONS_23',
  TERMS_AND_CONDITIONS_24: 'TERMS_AND_CONDITIONS_24',
  TERMS_AND_CONDITIONS_25: 'TERMS_AND_CONDITIONS_25',
  TERMS_AND_CONDITIONS_26: 'TERMS_AND_CONDITIONS_26',
  TERMS_AND_CONDITIONS_27: 'TERMS_AND_CONDITIONS_27',
  TERMS_AND_CONDITIONS_28: 'TERMS_AND_CONDITIONS_28',
  TERMS_AND_CONDITIONS_29: 'TERMS_AND_CONDITIONS_29',
  TERMS_AND_CONDITIONS_30: 'TERMS_AND_CONDITIONS_30',
  TERMS_AND_CONDITIONS_31: 'TERMS_AND_CONDITIONS_31',
  TERMS_AND_CONDITIONS_32: 'TERMS_AND_CONDITIONS_32',
  TERMS_AND_CONDITIONS_33: 'TERMS_AND_CONDITIONS_33',
  TERMS_AND_CONDITIONS_34: 'TERMS_AND_CONDITIONS_34',
  TERMS_AND_CONDITIONS_35: 'TERMS_AND_CONDITIONS_35',
  TERMS_AND_CONDITIONS_36: 'TERMS_AND_CONDITIONS_36',
  TERMS_AND_CONDITIONS_37: 'TERMS_AND_CONDITIONS_37',
  TERMS_AND_CONDITIONS_38: 'TERMS_AND_CONDITIONS_38',
  TERMS_AND_CONDITIONS_39: 'TERMS_AND_CONDITIONS_39',
  TERMS_AND_CONDITIONS_40: 'TERMS_AND_CONDITIONS_40',
  TERMS_AND_CONDITIONS_41: 'TERMS_AND_CONDITIONS_41',
  TERMS_AND_CONDITIONS_42: 'TERMS_AND_CONDITIONS_42',
  TERMS_AND_CONDITIONS_43: 'TERMS_AND_CONDITIONS_43',
  TERMS_AND_CONDITIONS_44: 'TERMS_AND_CONDITIONS_44',
  TERMS_AND_CONDITIONS_45: 'TERMS_AND_CONDITIONS_45',
  TERMS_AND_CONDITIONS_46: 'TERMS_AND_CONDITIONS_46',
  TERMS_AND_CONDITIONS_47: 'TERMS_AND_CONDITIONS_47',
  TERMS_AND_CONDITIONS_48: 'TERMS_AND_CONDITIONS_48',
  TERMS_AND_CONDITIONS_49: 'TERMS_AND_CONDITIONS_49',
  TERMS_AND_CONDITIONS_50: 'TERMS_AND_CONDITIONS_50',
  TERMS_AND_CONDITIONS_51: 'TERMS_AND_CONDITIONS_51',
  TERMS_AND_CONDITIONS_52: 'TERMS_AND_CONDITIONS_52',
  TERMS_AND_CONDITIONS_53: 'TERMS_AND_CONDITIONS_53',
  TERMS_AND_CONDITIONS_54: 'TERMS_AND_CONDITIONS_54',
  TERMS_AND_CONDITIONS_55: 'TERMS_AND_CONDITIONS_55',
  TERMS_AND_CONDITIONS_56: 'TERMS_AND_CONDITIONS_56',
  TERMS_AND_CONDITIONS_57: 'TERMS_AND_CONDITIONS_57',
  TERMS_AND_CONDITIONS_58: 'TERMS_AND_CONDITIONS_58',
  IMPRINT_00: 'IMPRINT_00',
  IMPRINT_01: 'IMPRINT_01',
  IMPRINT_02: 'IMPRINT_02',
  IMPRINT_03: 'IMPRINT_03',
  IMPRINT_04: 'IMPRINT_04',
  IMPRINT_05: 'IMPRINT_05',
  IMPRINT_06: 'IMPRINT_06',
  IMPRINT_07: 'IMPRINT_07',
  IMPRINT_08: 'IMPRINT_08',
  IMPRINT_09: 'IMPRINT_09',
  PRIVACY_00: 'PRIVACY_00',
  PRIVACY_01: 'PRIVACY_01',
  PRIVACY_02: 'PRIVACY_02',
  PRIVACY_03: 'PRIVACY_03',
  PRIVACY_04: 'PRIVACY_04',
  PRIVACY_05: 'PRIVACY_05',
  PRIVACY_06: 'PRIVACY_06',
  PRIVACY_07: 'PRIVACY_07',
  PRIVACY_08: 'PRIVACY_08',
  PRIVACY_09: 'PRIVACY_09',
  PRIVACY_10: 'PRIVACY_10',
  PRIVACY_11: 'PRIVACY_11',
  PRIVACY_12: 'PRIVACY_12',
  PRIVACY_13: 'PRIVACY_13',
  PRIVACY_14: 'PRIVACY_14',
  PRIVACY_15: 'PRIVACY_15',
  PRIVACY_16: 'PRIVACY_16',
  PRIVACY_17: 'PRIVACY_17',
  PRIVACY_18: 'PRIVACY_18',
  PRIVACY_19: 'PRIVACY_19',
  PRIVACY_20: 'PRIVACY_20',
  PRIVACY_21: 'PRIVACY_21',
  PRIVACY_22: 'PRIVACY_22',
  PRIVACY_23: 'PRIVACY_23',
  PRIVACY_24: 'PRIVACY_24',
  PRIVACY_25: 'PRIVACY_25',
  PRIVACY_26: 'PRIVACY_26',
  PRIVACY_27: 'PRIVACY_27',
  PRIVACY_28: 'PRIVACY_28',
  PRIVACY_29: 'PRIVACY_29',
  PRIVACY_30: 'PRIVACY_30',
  PRIVACY_31: 'PRIVACY_31',
  PRIVACY_32: 'PRIVACY_32',
  PRIVACY_33: 'PRIVACY_33',
  PRIVACY_34: 'PRIVACY_34',
  PRIVACY_35: 'PRIVACY_35',
  PRIVACY_36: 'PRIVACY_36',
  PRIVACY_37: 'PRIVACY_37',
  PRIVACY_38: 'PRIVACY_38',
  PRIVACY_39: 'PRIVACY_39',
  PRIVACY_40: 'PRIVACY_40',
  PRIVACY_41: 'PRIVACY_41',
  PRIVACY_42: 'PRIVACY_42',
  PRIVACY_43: 'PRIVACY_43',
  PRIVACY_44: 'PRIVACY_44',
  PRIVACY_45: 'PRIVACY_45',
  PRIVACY_46: 'PRIVACY_46',
  PRIVACY_47: 'PRIVACY_47',
  PRIVACY_48: 'PRIVACY_48',
  PRIVACY_49: 'PRIVACY_49',
  PRIVACY_50: 'PRIVACY_50',
  PRIVACY_51: 'PRIVACY_51',
  IF_YOU_NEED_MORE_LANGUAGES: 'IF_YOU_NEED_MORE_LANGUAGES',
  DECIDE_LANGUAGES: 'DECIDE_LANGUAGES',
  EDIT_LANGUAGES: 'EDIT_LANGUAGES',
  ACTIVE_VOUCHER: 'ACTIVE_VOUCHER',
  ENTER_VOUCHER_CODE: 'ENTER_VOUCHER_CODE',
  VOUCHER_CODE: 'VOUCHER_CODE',
  INVALID_CODE: 'INVALID_CODE',
  WANT_CUSTOM_LOGO: 'WANT_CUSTOM_LOGO',
  INDIVIDUAL_LOGO: 'INDIVIDUAL_LOGO',
  STANDARD_LOGO: 'STANDARD_LOGO',
  PRIVATE_PURCHASE: 'PRIVATE_PURCHASE',
  COMMERCIAL_PURCHASE: 'COMMERCIAL_PURCHASE',
  MAX_GUESTS: 'MAX_GUESTS',
  MAX_GUESTS_REACHED: 'MAX_GUESTS_REACHED',
  ACTIVATE_PACKAGE: 'ACTIVATE_PACKAGE',
  IF_MESSAGE_WRONGFUL: 'IF_MESSAGE_WRONGFUL',
};
