import styled from 'styled-components';

export default styled.div`
  position: absolute;
  top: -2px;
  left: -15px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: solid 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  svg {
    height: 65%;
    fill: ${({ iconColor }) => iconColor};
  }
`;
