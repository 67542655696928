import styled from 'styled-components';

import defaultStyles from '../../DefaultStyles';
import breakpoints from '../../../breakpoints';

export default styled.h1`
  margin-top: 200px;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  font-size: 80px !important;
  transform: rotate(-10deg);
  font-family: ${defaultStyles.modern.headerFont} !important;
  color: ${defaultStyles.modern.colors.primary.base} !important;
  @media (max-height: 980px) {
    font-size: 70px !important;
  }
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
    margin-top: 90px;
    max-width: 90vw;
    line-height: 1.05;
    font-size: 40px !important;
  }
`;
