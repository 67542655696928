import React from 'react';
import styled from 'styled-components';

import defaultStyles from '../DefaultStyles';

import EditingContainerForBaseData from '../../../Pages/WeddingPage/Editing/Components/EditingContainer/EditingContainerForBaseData';
import BackgroundElementContainer from '../../Components/BackgroundElementContainer/BackgroundElementContainer';

import TopCenter from './Components/TopCenter';
import BottomLeft from './Components/BottomLeft';
import BottomRight from './Components/BottomRight';
import WeddingTitle from './Components/WeddingTitle';
import Countdown from '../Components/Countdown';

const Container = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  overflow: hidden;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
  background-image: ${`radial-gradient(circle at 50% 80%, ${defaultStyles.flower.background.colors[1]}, ${defaultStyles.flower.background.colors[0]})`};
`;

const WeddingTitleInnerContainer = styled.div`
  position: relative;
  width: 600px;
  max-width: 90vw;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  z-index: 2;
`;

const CustomLogo = styled.div`
  height: 350px;
  max-height: 50vh;
  width: 600px;
  max-width: 70vw;
  margin: auto;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const FlowerAppBackground = ({ showStartPage, weddingConfig }) => {
  const { nameOfBride, nameOfGroom, weddingDate } = weddingConfig;
  return (
    <Container visible>
      <BackgroundElementContainer>
        <TopCenter visible />
        {nameOfBride && nameOfGroom && showStartPage && (
          <>
            <Countdown weddingConfig={weddingConfig} weddingStyle={weddingConfig.weddingStyle}></Countdown>
            {weddingConfig.useCustomLogo ? (
              <WeddingTitleInnerContainer>
                <CustomLogo imageUrl={weddingConfig.customLogoUrl}></CustomLogo>
                <EditingContainerForBaseData></EditingContainerForBaseData>
              </WeddingTitleInnerContainer>
            ) : (
              <WeddingTitleInnerContainer>
                <EditingContainerForBaseData>
                  <WeddingTitle
                    nameOfBride={nameOfBride}
                    nameOfGroom={nameOfGroom}
                    weddingDate={weddingDate}
                    visible
                  ></WeddingTitle>
                </EditingContainerForBaseData>
              </WeddingTitleInnerContainer>
            )}
          </>
        )}
        <BottomLeft visible />
        <BottomRight visible />
      </BackgroundElementContainer>
    </Container>
  );
};

export default FlowerAppBackground;
