import i18n from 'i18next';
import k from './../../../../i18n/keys';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  Checkbox,
  CircularProgress,
  Hidden,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { ReactComponent as TrueIcon } from '../../../../_assets/svg/true.svg';
import { ReactComponent as FalseIcon } from '../../../../_assets/svg/close-simple.svg';
import { ReactComponent as Heart } from '../../../../_assets/svg/heart-balloon.svg';
import { ReactComponent as Champagne } from '../../../../_assets/svg/champagne-bottle.svg';
import { ReactComponent as Cake } from '../../../../_assets/svg/cake.svg';
import { ReactComponent as Offer } from '../../../../_assets/svg/offer.svg';
import ReactPixel from 'react-facebook-pixel';
import breakpoints from '../../../../Shared/breakpoints';
import defaultStyles from '../../../../Shared/WeddingStyles/DefaultStyles';
import { Helmet } from 'react-helmet';
import VoucherDialog from './Components/VoucherDialog';
import PACKAGES from '../../../../Shared/Constants/PACKAGES';

const StyledVoucherButton = styled.a`
  align-self: center;
  cursor: pointer;
  margin: 6px;
`;

const Container = styled.div`
  > h2 {
    margin: 12px 0 0 0;
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  > button {
    width: 300px;
    align-self: self-end;
  }
  .MuiTable-root {
    margin: 0 0 12px 0;
  }
  .MuiTableCell-sizeSmall {
    padding: 4px 12px 4px 12px;
    font-size: 16px;
    @media (max-width: ${breakpoints.sm}) {
      padding: 2px 6px 2px 6px;
      font-size: 12px;
      strong {
        display: flex;
        justify-content: center;
      }
    }
    @media (max-width: ${breakpoints.xxs}) {
      max-width: 45px;
      padding: 2px 3px;
      strong {
        font-size: 11px !important;
      }
    }
  }
  .MuiTableCell-head {
    position: relative;
  }
  .MuiTableCell-body.selected,
  .MuiTableCell-body.deselected {
    min-width: 145px;
    width: 145px;
    max-width: 145px;
    @media (max-width: ${breakpoints.sm}) {
      min-width: 21px;
      width: 21px;
      max-width: 21px;
    }
  }
  .MuiTableCell-body.deselected {
    cursor: pointer;
  }
  .MuiTableCell-body.selected {
    background-color: ${({ weddingStyle }) => defaultStyles[weddingStyle].colors.button.secondaryColorOnHover};
    color: ${({ weddingStyle }) => defaultStyles[weddingStyle].colors.button.secondaryColorOnHover_T};
    svg {
      fill: ${({ weddingStyle }) => defaultStyles[weddingStyle].colors.button.secondaryColorOnHover_T};
    }
  }
  svg {
    width: 24px;
    height: 21px;
    &.false {
      width: 14px;
      fill: #979797;
    }
  }
`;

const StyledButton = styled(Button)`
  margin: 4px;
  svg {
    height: 32px;
    width: 32px;
    margin-right: 6px;
  }
`;

const IconContainer = styled.div`
  margin: 9px;
  svg {
    height: 48px;
    width: 48px;
    fill: ${({ weddingStyle }) => defaultStyles[weddingStyle].colors.primary.onWhite};
    @media (max-width: ${breakpoints.sm}) {
      height: 28px;
      width: 28px;
    }
  }
`;

const Label = styled.div`
  position: absolute;
  top: 0;
  right: 3px;
  font-size: 13px !important;
  transform: rotate(30deg);
  background-color: ${({ weddingStyle }) => defaultStyles[weddingStyle].colors.primary.onWhite};
  color: white !important;
  padding: 0px 3px;
  border-radius: 15px;
  @media (max-width: ${breakpoints.sm}) {
    font-size: 11px !important;
    transform: none;
    top: 38px;
    right: 3px;
    padding: 0px 4px;
    height: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: ${breakpoints.xs}) {
    font-size: 9px !important;
  }
  @media (max-width: ${breakpoints.xxs}) {
    font-size: 7px !important;
  }
`;

const PackageSelection = ({
  weddingId,
  weddingStyle = 'eurehochzeitonline',
  setSelectedPackage,
  handleClose,
  loadingPaymentIntent,
  enableSelection = true,
  appliedVoucher,
  setAppliedVoucher,
}) => {
  const [currentlySelected, setCurrentlySelected] = useState('premium');
  const [showVoucherDialog, setShowVoucherDialog] = useState(false);

  const packageFeatures = [
    {
      label: i18n.t(k.PACKAGE_FEATURES_DESIGN),
      basic: true,
      complete: true,
      premium: true,
      vip: true,
    },
    {
      label: i18n.t(k.PACKAGE_FEATURES_SUBDOMAIN),
      basic: true,
      complete: true,
      premium: true,
      vip: true,
    },
    {
      label: i18n.t(k.PACKAGE_FEATURES_MOBILE),
      basic: true,
      complete: true,
      premium: true,
      vip: true,
    },
    {
      label: i18n.t(k.PACKAGE_FEATURES_SCHEDULE),
      basic: true,
      complete: true,
      premium: true,
      vip: true,
    },
    {
      label: i18n.t(k.PACKAGE_FEATURES_LOCATION),
      basic: true,
      complete: true,
      premium: true,
      vip: true,
    },
    {
      label: i18n.t(k.PACKAGE_FEATURES_INFOS),
      basic: true,
      complete: true,
      premium: true,
      vip: true,
    },
    {
      label: i18n.t(k.PACKAGE_FEATURES_RSVP),
      basic: i18n.t(k.MAX_FIVE),
      complete: true,
      premium: true,
      vip: true,
    },
    {
      label: i18n.t(k.PACKAGE_FEATURES_MUSIC),
      basic: i18n.t(k.MAX_FIVE),
      complete: true,
      premium: true,
      vip: true,
    },
    {
      label: i18n.t(k.PACKAGE_FEATURES_PHOTOS),
      basic: i18n.t(k.MAX_FIVE),
      complete: true,
      premium: true,
      vip: true,
    },
    {
      label: i18n.t(k.PACKAGE_FEATURES_SUPPORT),
      basic: false,
      complete: true,
      premium: true,
      vip: true,
    },
    {
      label: i18n.t(k.PACKAGE_FEATURES_MULTILANGUAGE),
      basic: false,
      complete: false,
      premium: true,
      vip: true,
    },
    {
      label: i18n.t(k.PACKAGE_FEATURES_OWN_DOMAIN),
      basic: false,
      complete: false,
      premium: true,
      vip: true,
    },
    {
      label: i18n.t(k.PACKAGE_FEATURES_CUSTOM_DESIGN),
      basic: false,
      complete: false,
      premium: false,
      vip: true,
    },
  ];

  useEffect(() => {
    ReactPixel.trackCustom('PackageSelectionViewed');
  }, []);

  return (
    <Container weddingStyle={weddingStyle}>
      {weddingId && (
        <Helmet>
          <title>{`EureHochzeitOnline: ${weddingId} | Package Selection`}</title>
        </Helmet>
      )}

      <h2>{i18n.t(k.OUR_PACKAGES)}</h2>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="center">
              <IconContainer weddingStyle={weddingStyle}>
                <Offer></Offer>
              </IconContainer>
              <div>
                <strong>{i18n.t(k.PACKAGE_BASIC)}</strong>
              </div>
              <div>0.- €</div>
            </TableCell>
            <TableCell align="center">
              <IconContainer weddingStyle={weddingStyle}>
                <Heart></Heart>
              </IconContainer>
              <div>
                <strong>{i18n.t(k.PACKAGE_COMPLETE)}</strong>
              </div>
              <div>
                {appliedVoucher?.discountInEuro
                  ? PACKAGES.complete.price / 100 - appliedVoucher.discountInEuro
                  : PACKAGES.complete.price / 100}
                .- €
              </div>
            </TableCell>
            <TableCell align="center">
              <IconContainer weddingStyle={weddingStyle}>
                <Champagne></Champagne>
              </IconContainer>
              <div>
                <strong>{i18n.t(k.PACKAGE_PREMIUM)}</strong>
              </div>
              <Label weddingStyle={weddingStyle}>{i18n.t(k.BEST_SELLER)}</Label>
              <div>
                {appliedVoucher?.discountInEuro
                  ? PACKAGES.premium.price / 100 - appliedVoucher.discountInEuro
                  : PACKAGES.premium.price / 100}
                .- €
              </div>
            </TableCell>
            <TableCell align="center">
              <IconContainer weddingStyle={weddingStyle}>
                <Cake></Cake>
              </IconContainer>
              <div>
                <strong>{i18n.t(k.PACKAGE_VIP)}</strong>
              </div>
              <div>
                {appliedVoucher?.discountInEuro
                  ? PACKAGES.vip.price / 100 - appliedVoucher.discountInEuro
                  : PACKAGES.vip.price / 100}
                .- €
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {packageFeatures.map(feature => (
            <TableRow key={feature.label}>
              <TableCell>{feature.label}</TableCell>
              <TableCell
                align="center"
                style={{ cursor: 'default' }}
                className={enableSelection && currentlySelected === 'basic' ? 'selected' : 'deselected'}
              >
                {feature.basic === true ? (
                  <TrueIcon className="true"></TrueIcon>
                ) : feature.basic === false ? (
                  <FalseIcon className="false"></FalseIcon>
                ) : (
                  feature.basic
                )}
              </TableCell>
              <TableCell
                align="center"
                className={enableSelection && currentlySelected === 'complete' ? 'selected' : 'deselected'}
                onClick={() => {
                  setCurrentlySelected('complete');
                }}
              >
                {feature.complete === true ? (
                  <TrueIcon className="true"></TrueIcon>
                ) : feature.complete === false ? (
                  <FalseIcon className="false"></FalseIcon>
                ) : (
                  feature.complete
                )}
              </TableCell>
              <TableCell
                align="center"
                className={enableSelection && currentlySelected === 'premium' ? 'selected' : 'deselected'}
                onClick={() => {
                  setCurrentlySelected('premium');
                }}
              >
                {feature.premium === true ? (
                  <TrueIcon className="true"></TrueIcon>
                ) : feature.premium === false ? (
                  <FalseIcon className="false"></FalseIcon>
                ) : (
                  feature.premium
                )}
              </TableCell>
              <TableCell
                align="center"
                className={enableSelection && currentlySelected === 'vip' ? 'selected' : 'deselected'}
                onClick={() => {
                  setCurrentlySelected('vip');
                }}
              >
                {feature.vip === true ? (
                  <TrueIcon className="true"></TrueIcon>
                ) : feature.vip === false ? (
                  <FalseIcon className="false"></FalseIcon>
                ) : (
                  feature.vip
                )}
              </TableCell>
            </TableRow>
          ))}
          {enableSelection && (
            <TableRow>
              <TableCell></TableCell>
              <Hidden only={['md', 'lg', 'xl']}>
                <TableCell align="center">
                  {currentlySelected === 'basic' ? (
                    <Checkbox checked={currentlySelected === 'basic'} disabled></Checkbox>
                  ) : (
                    <></>
                  )}
                </TableCell>
                <TableCell align="center">
                  <Checkbox
                    checked={currentlySelected === 'complete'}
                    disabled={currentlySelected === 'complete'}
                    onChange={e => {
                      setCurrentlySelected('complete');
                    }}
                  ></Checkbox>
                </TableCell>
                <TableCell align="center">
                  <Checkbox
                    checked={currentlySelected === 'premium'}
                    disabled={currentlySelected === 'premium'}
                    onChange={e => {
                      setCurrentlySelected('premium');
                    }}
                  ></Checkbox>
                </TableCell>
                <TableCell align="center">
                  <Checkbox
                    checked={currentlySelected === 'vip'}
                    disabled={currentlySelected === 'vip'}
                    onChange={e => {
                      setCurrentlySelected('vip');
                    }}
                  ></Checkbox>
                </TableCell>
              </Hidden>
              <Hidden only={['xs', 'sm']}>
                <TableCell align="center" className={currentlySelected === 'basic' ? 'deselected' : 'deselected'}>
                  <></>
                </TableCell>
                <TableCell align="center" className={currentlySelected === 'complete' ? 'deselected' : 'deselected'}>
                  {currentlySelected === 'complete' ? (
                    <Button color="primary" variant="contained" disabled size="small" onClick={() => {}}>
                      {i18n.t(k.SELECTED)}
                    </Button>
                  ) : (
                    <Button
                      color="secondary"
                      variant="contained"
                      size="small"
                      onClick={() => {
                        setCurrentlySelected('complete');
                      }}
                    >
                      {i18n.t(k.SELECT)}
                    </Button>
                  )}
                </TableCell>
                <TableCell align="center" className={currentlySelected === 'premium' ? 'deselected' : 'deselected'}>
                  {currentlySelected === 'premium' ? (
                    <Button color="primary" variant="contained" disabled size="small" onClick={() => {}}>
                      {i18n.t(k.SELECTED)}
                    </Button>
                  ) : (
                    <Button
                      color="secondary"
                      variant="contained"
                      size="small"
                      onClick={() => {
                        setCurrentlySelected('premium');
                      }}
                    >
                      {i18n.t(k.SELECT)}
                    </Button>
                  )}
                </TableCell>
                <TableCell align="center" className={currentlySelected === 'vip' ? 'deselected' : 'deselected'}>
                  {currentlySelected === 'vip' ? (
                    <Button color="primary" variant="contained" disabled size="small" onClick={() => {}}>
                      {i18n.t(k.SELECTED)}
                    </Button>
                  ) : (
                    <Button
                      color="secondary"
                      variant="contained"
                      size="small"
                      onClick={() => {
                        setCurrentlySelected('vip');
                      }}
                    >
                      {i18n.t(k.SELECT)}
                    </Button>
                  )}
                </TableCell>
              </Hidden>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <div>
        {i18n.t(k.CAN_UPGRADE_LATER)}
        <br></br> {i18n.t(k.LOOK_IN_FAQ)}
      </div>
      {enableSelection && (
        <>
          <StyledVoucherButton color="primary" varia nt="text" onClick={() => setShowVoucherDialog(true)}>
            {appliedVoucher?.code
              ? `${i18n.t(k.ACTIVE_VOUCHER)} ${appliedVoucher?.code} - ${appliedVoucher?.discountInEuro} €`
              : i18n.t(k.ENTER_VOUCHER_CODE)}
          </StyledVoucherButton>
          <VoucherDialog
            setAppliedVoucher={setAppliedVoucher}
            setShowVoucherDialog={setShowVoucherDialog}
            showVoucherDialog={showVoucherDialog}
          ></VoucherDialog>
        </>
      )}
      {enableSelection && (
        <StyledButton
          color="primary"
          variant="contained"
          disabled={loadingPaymentIntent}
          onClick={() => {
            if (currentlySelected !== 'basic') {
              setSelectedPackage(currentlySelected);
            } else {
              handleClose();
            }
          }}
        >
          {loadingPaymentIntent ? <CircularProgress size={32}></CircularProgress> : <>{i18n.t(k.NEXT)}</>}
        </StyledButton>
      )}
    </Container>
  );
};

export default PackageSelection;
