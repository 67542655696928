import React from 'react';

import WeddingContext from '../../../Shared/Context/WeddingContext';
import ModernMenuContainer from '../../../Shared/WeddingStyles/Modern/ModernMenuContainer';
import VintageMenuContainer from '../../../Shared/WeddingStyles/Vintage/VintageMenuContainer';
import FlowerMenuContainer from '../../../Shared/WeddingStyles/Flower/FlowerMenuContainer';
import KlassischMenuContainer from '../../../Shared/WeddingStyles/Klassisch/KlassischMenuContainer';
import defaultStyles from '../../../Shared/WeddingStyles/DefaultStyles';

import UserContainer from './UserContainer/UserContainer';
import InnerContentMenu from './InnerContentMenu';

const getStyleConfigForMenu = weddingStyle => {
  return {
    textColor: defaultStyles[weddingStyle].colors.navigation.textColor,
    backgroundColor: defaultStyles[weddingStyle].colors.navigation.inverseColor,
  };
};

const OuterStyledMenu = ({ closeDrawer }) => {
  return (
    <WeddingContext.Consumer>
      {({ weddingId, weddingConfig, selectedPackage }) => {
        return (
          <>
            {weddingConfig.weddingStyle === 'modern' && (
              <ModernMenuContainer weddingId={weddingId} weddingConfig={weddingConfig} closeDrawer={closeDrawer}>
                <InnerContentMenu
                  styleConfig={getStyleConfigForMenu(weddingConfig.weddingStyle)}
                  closeDrawer={closeDrawer}
                />
              </ModernMenuContainer>
            )}
            {weddingConfig.weddingStyle === 'vintage' && (
              <VintageMenuContainer weddingId={weddingId} weddingConfig={weddingConfig} closeDrawer={closeDrawer}>
                <InnerContentMenu
                  styleConfig={getStyleConfigForMenu(weddingConfig.weddingStyle)}
                  closeDrawer={closeDrawer}
                />
              </VintageMenuContainer>
            )}
            {weddingConfig.weddingStyle === 'flower' && (
              <FlowerMenuContainer weddingId={weddingId} weddingConfig={weddingConfig} closeDrawer={closeDrawer}>
                <InnerContentMenu
                  styleConfig={getStyleConfigForMenu(weddingConfig.weddingStyle)}
                  closeDrawer={closeDrawer}
                />
              </FlowerMenuContainer>
            )}
            {weddingConfig.weddingStyle === 'klassisch' && (
              <KlassischMenuContainer weddingId={weddingId} weddingConfig={weddingConfig} closeDrawer={closeDrawer}>
                <InnerContentMenu
                  styleConfig={getStyleConfigForMenu(weddingConfig.weddingStyle)}
                  closeDrawer={closeDrawer}
                />
              </KlassischMenuContainer>
            )}
            <UserContainer
              styleConfig={getStyleConfigForMenu(weddingConfig.weddingStyle)}
              weddingId={weddingId}
              weddingConfig={weddingConfig}
              selectedPackage={selectedPackage}
            ></UserContainer>
          </>
        );
      }}
    </WeddingContext.Consumer>
  );
};

export default OuterStyledMenu;
