const k = require('./keys');
export default {
  [k._DOT]: '.',
  [k.HELLO]: 'Ahoj,',
  [k.ENTER_NEW_PASSWORD]: 'zde prosím zadejte své nové heslo. Budete automaticky přihlášeni a přesměrováni.',
  [k.MINIMUM_6_CHARACTERS]: 'Použijte prosím minimálně 6 znaků.',
  [k.SAVE]: 'Uložit',
  [k.UNABLE_TO_RESET_PASSWORD]: 'Vaše heslo se nám nepodařilo obnovit.',
  [k.PASSWORD_CHANGED_SUCCESSFULLY_TITLE]: 'Heslo bylo úspěšně změněno',
  [k.PASSWORD_CHANGED_SUCCESSFULLY]:
    'Vaše heslo jsme změnili! Nyní se můžete přihlásit pomocí nového hesla. Nyní vás přeposíláme.',
  [k.FUNCTIONS]: 'Funkce',
  [k.DESIGNS]: 'Témata',
  [k.PRICES]: 'Ceník',
  [k.FAQ]: 'NEJČASTĚJŠÍ DOTAZY',
  [k.BLOG]: 'Blog',
  [k.CREATE_NOW_FREE]: 'Vytvořte nyní zdarma',
  [k.PERFECT_PAGE_FOR_YOU]: 'Vaše webové stránky jsou šité na míru!',
  [k.PERFECT_DESIGN_IN_FEW_CLICKS]:
    'Pomocí našeho konfigurátoru designu dosáhnete dokonalého designu na několik kliknutí.',
  [k.DESIGN]: '-Design',
  [k.HOW_DESCRIBE_YOUR_DREAM_WEDDING]: 'Jak byste popsali svatbu svých snů?',
  [k.CLICK_ON_ONE_OF_THE_IMAGES]: 'Kliknutím na jeden z obrázků spustíte konfigurátor designu',
  [k.CREATE_WEDDING_WEBSITE_NOW]: 'Vytvořte si nyní zdarma',
  [k.FREQUENTLY_ASKED_QUESTIONS]: 'Často kladené otázky',
  [k.ANY_MORE_QUESTIONS_OR_FEEDBACK]: 'Máte nějaké dotazy nebo zpětnou vazbu?',
  [k.WE_LOOK_FORWARD_TO_IT]: 'Těšíme se na vaše dotazy!',
  [k.CONTENT_AND_DESIGN]: 'OBSAH A DESIGN',
  [k.PHOTO_GALLERY]: 'Fotogalerie',
  [k.RSVP_CARD]: 'RSVP/odpovědní karta',
  [k.PLAYLIST]: 'Playlist',
  [k.MULTILINGUAL_OWN_DOMAIN]: 'VÍCEJAZYČNOST A VLASTNÍ DOMÉNA',
  [k.DURATION_PASSWORD_PROTECTION]: 'OCHRANA SPUŠTĚNÍM A HESLEM',
  [k.COST_SUPPORT]: 'NÁKLADY A PODPORA',
  [k.WHAT_CONTENT_TYPICALLY]: 'Jaký obsah je typický pro svatební stránky?',
  [k.CAN_CREATE_WEBSITE_BEFORE_DETAILS]:
    'Mohu vytvořit svatební webovou stránku ještě předtím, než jsou dokončeny všechny detaily (například datum naší svatby)?',
  [k.CAN_CUSTOMIZE_DESIGN]: 'Mohu si přizpůsobit design svatební webové stránky?',
  [k.CHANGES_VISIBLE_AFTER_SAVE]:
    'Je možné provádět změny na domovské stránce, aniž by byly okamžitě viditelné pro všechny?',
  [k.PHOTO_UPLOAD_LIMIT]: 'Je počet fotografií, které můžeme nahrát do galerie, omezen?',
  [k.ORIGINAL_SIZE_PHOTO_STORAGE]: 'Budou fotografie uloženy v původní velikosti?',
  [k.GUESTS_DOWNLOAD_UPLOADED_PHOTOS]: 'Mohou si hosté stáhnout fotografie nahrané na web?',
  [k.USE_PHOTO_GALLERY_FOR_SLIDESHOW]: 'Můžeme fotogalerii použít pro živou prezentaci během naší oslavy?',
  [k.HOW_DOES_R_S_V_P_WORK]: 'Jak funguje funkce RSVP?',
  [k.GUESTS_ADD_OWN_SONGS_TO_PLAYLIST]: 'Mohou hosté do playlistu přidávat vlastní skladby?',
  [k.CAN_DJ_ACCESS_PLAYLIST]: 'Má náš DJ přístup k playlistu?',
  [k.POSSIBILITY_OF_MULTIPLE_LANGUAGES]: 'Je možné mít naši domovskou stránku v různých jazycích?',
  [k.OWN_DOMAIN_POSSIBILITY]: 'Můžeme pro naše svatební webové stránky získat vlastní doménu?',
  [k.HOW_LONG_ACCESS_WEDDING_WEBSITE]: 'Jak dlouho budou naše svatební webové stránky k dispozici?',
  [k.PROTECT_SITE_WITH_PASSWORD]: 'Můžeme naše stránky chránit heslem?',
  [k.IS_EUREHOCHZEITONLINE_FREE]: 'Je používání stránek EureHochzeitOnline.com zdarma?',
  [k.HOW_GET_SUPPORT_EUREHOCHZEITONLINE]:
    'Jak mohu získat pomoc při vytváření své svatební webové stránky na EureHochzeitOnline.com?',
  [k.WEDDING_CONTENT_INDIVIDUAL]:
    'Obsah vaší svatební webové stránky je stejně individuální jako vaše svatba samotná. Kromě základních informací, jako je datum a místo, můžete přidat kódy oblečení, pokyny, zvláštní přání, kontaktní informace na oddávajícího nebo plánovaný časový harmonogram. Pro hosty, kteří cestují z daleka a potřebují ubytování, můžete uvést návrhy hotelů. Skvělý nápad je také podělit se s hosty o váš milostný příběh nebo napsat osobní slova o vašem partnerovi. Vaší kreativitě se meze nekladou.',
  [k.YES_FEASIBLE_ADVANTAGES]:
    'Ano, je to snadné a dokonce to nabízí značné výhody. Můžete kdykoli přidávat informace a poskytovat aktualizace, a to i po odeslání svatebních oznámení.',
  [k.YES_EUREHOCHZEITONLINE_OFFERS]:
    'Ano, EureHochzeitOnline.com nabízí různé možnosti designu vašich svatebních webových stránek. Můžete si přizpůsobit barvy, písma a další prvky. V případě jakýchkoli dotazů nebo připomínek nás prosím kontaktujte.',
  [k.INDEED_CHANGES_VISIBLE]:
    'Ve skutečnosti budou všechny změny na vašich webových stránkách okamžitě viditelné všem návštěvníkům v reálném čase. V současné době nenabízíme funkci, která by umožňovala plánovat nebo skrývat změny předem.',
  [k.IN_FREE_VERSION_LIMITED_PHOTOS]:
    'V bezplatné verzi je omezen počet fotografií, které můžete do galerie nahrát. Je to proto, že máme náklady spojené s úložištěm a provozem. Pokud si však zakoupíte balíčky Premium nebo Domain, budete moci do galerie nahrát neomezený počet fotografií. Podívejte se na náš blog, kde najdete tipy, jak získat dokonalé svatební fotografie.',
  [k.WE_GENERATE_DIFFERENT_SIZES]:
    'Při nahrávání fotografií vytvoříme pro každý snímek jinou velikost. V závislosti na velikosti obrazovky návštěvníka je automaticky vybrána optimální velikost, aby bylo zajištěno rychlé načítání s nejlepší možnou kvalitou obrázků. Ukládáme však také původní soubory fotografií, abyste je mohli obdržet i vy.',
  [k.YES_GUESTS_CAN_DOWNLOAD]: 'Ano, hosté si mohou nahrané fotografie stáhnout.',
  [k.YES_FEASIBLE_SCREEN_PROJECTOR]:
    'Rozhodně! Ve skutečnosti je to jedna z nejoblíbenějších funkcí EureHochzeitOnline. Vše, co potřebujete, je stabilní připojení k internetu a samozřejmě plátno nebo projektor.',
  [k.R_S_V_P_FUNCTION_SIMPLIFIES]:
    'Funkce RSVP umožňuje snadno sdílet přihlašovací údaje na svatební webové stránky s hosty. Když navštíví vaše svatební stránky, budou vyzváni, aby zanechali své hodnocení (např. kolik osob se zúčastní, zda se jedná o děti nebo dospělé a jakékoliv speciální požadavky na jídlo, alergie nebo jiné požadavky). V oblasti pro hosty můžete kdykoli sledovat počet přijatých a zrušených hostů. Pokyny krok za krokem najdete na našem blogu.',
  [k.YES_YOUR_GUESTS_CAN_SUGGEST]:
    'Rozhodně! Vaši hosté mohou navrhnout své oblíbené skladby a přidat je do seznamu skladeb. Můžete tak nejen oslavit rozmanitost svých hostů, ale také si užít hudbu, která je důležitá pro vás a vaše blízké.',
  [k.NATURALLY_INVITE_DJ_TO_WEBSITE]:
    'Samozřejmě. Stačí pozvat DJ na své webové stránky a všechna vaše přání mu budou k dispozici - dokonce i živě během vaší party, pokud budete chtít.',
  [k.YES_YOU_CAN_HAVE_MULTIPLE_LANGUAGES]:
    'Ano, své webové stránky můžete mít ve více jazycích. Tato funkce je k dispozici v balíčku Premium a všechny informace můžete přeložit do různých jazyků. Kontaktujte nás, abychom mohli nastavit konfiguraci pro vaši svatbu.',
  [k.YES_YOU_CAN_HAVE_OWN_DOMAIN]:
    'Ano, s naším balíčkem Premium si můžete pro své svatební webové stránky snadno rezervovat vlastní doménu (např. www.juliaundromeo.de). Kontaktujte nás prosím, abychom pro vás mohli doménu rezervovat a nastavit přesměrování.',
  [k.YOUR_WEDDING_WEBSITE_AVAILABLE_TWO_YEARS]:
    'Vaše svatební webové stránky budou k dispozici po dobu dvou let od jejich vytvoření. Poté bude smazána, aby se uvolnilo místo pro nové svatební webové stránky. V předstihu vám to připomeneme. Prodloužení je možné na vyžádání.',
  [k.ALL_WEDDING_WEBSITES_PASSWORD_PROTECTED]:
    'Všechny svatební webové stránky jsou chráněny heslem, které vám bude po vytvoření sděleno.',
  [k.YES_CREATE_WEDDING_WEBSITE_FREE]:
    'Ano, na stránkách EureHochzeitOnline.com si můžete vytvořit a navrhnout svatební webové stránky zdarma. Některé pokročilé funkce však zdarma nejsou, zejména ty, které vyžadují další serverové zdroje. Neexistují žádné skryté poplatky.',
  [k.IF_YOU_NEED_SUPPORT_WE_CAN_HELP]:
    'Pokud potřebujete s tvorbou svatebních webových stránek pomoci, neváhejte nám poslat zprávu na adresu beeni@eurehochzeitonline.com. Co nejdříve se vám ozveme a pomůžeme vám.',
  [k.KEY_FEATURES_OVERVIEW]: 'Přehled nejlepších funkcí!',
  [k.YOUR_WEDDING_WEBSITE_OFFERS_EVERYTHING]:
    'Zde je váš svatební web se vším, co potřebujete k vytvoření svatby svých snů.',
  [k.NEW_NOW]: 'Novinka:',
  [k.MULTILINGUAL_WEDDINGS]: 'Vícejazyčné svatby!',
  [k.ON_REQUEST_WE_PROVIDE_WEDDING_WEBSITES]: 'Nyní k dispozici: Vícejazyčné webové stránky!',
  [k.SCHEDULE]: 'Svatební časová osa',
  [k.LOCATIONS]: 'Svatební místa',
  [k.RSVP]: 'Odpovědní karta',
  [k.GUESTMANAGEMENT]: 'Správa hostů',
  [k.HOTEL_RECOMMENDATION]: 'Doporučení hotelu',
  [k.PRESENT_TABLE]: 'Dárkový stůl',
  [k.OWN_DOMAIN]: 'Vlastní doména',
  [k.DIVERSE_LAYOUTS]: 'Nespočet rozvržení a designů, ze kterých si můžete vybrat a přizpůsobit je.',
  [k.YOUR_WEDDING_DAY]: 'Váš svatební den přehledně uspořádaný pro vaše hosty.',
  [k.CLEAR_DIRECTIONS]: 'Přehledné pokyny ke všem svatebním místům.',
  [k.NO_GUESSWORK]: 'Už žádné zdlouhavé dotazy, kdo přijde na svatbu.',
  [k.GUEST_ADDRESSES_ALLERGIES]: 'Sbírejte adresy, alergie atd. jedním kliknutím!',
  [k.HOTEL_SELECTION_FOR_GUESTS]: 'Výběr hotelu pro vaše hosty, abyste jim usnadnili cestování!',
  [k.EASY_ONLINE_GIFT_REGISTRY]: 'Snadná registrace vaší svatby.',
  [k.WEDDING_PICTURES_FOR_GUESTS]: 'Vaše svatební fotografie a fotografie vašich hostů na jednom místě.',
  [k.CUSTOM_SPOTIFY_PLAYLIST_CREATION]: 'Váš vlastní playlist Spotify - vytvořený vámi a vašimi hosty.',
  [k.WEDDING_HOMEPAGE_WITH_OWN_DOMAIN]: 'Svatební webové stránky s vlastní doménou a ochranou heslem.',
  [k.YOUR_WEDDING_WEBSITE]: 'VAŠE SVATEBNÍ WEBOVÉ STRÁNKY',
  [k.UNIQUE_LIKE_YOU]: '- STEJNĚ JEDINEČNÉ JAKO VY!',
  [k.EASY_SETUP_CUSTOMIZED]: 'Snadné nastavení a přizpůsobení!',
  [k.ALL_DETAILS_OF_YOUR_CELEBRATION]:
    'Všechny podrobnosti o vaší události na první pohled - ať už na telefonu nebo na počítači.',
  [k.GUEST_FEEDBACK_AND_WISHES]: 'Potvrzení a žádosti hostů na dosah ruky',
  [k.YOUR_CELEBRATION_FROM_ALL_VIEWS]: 'Vaše oslava ze všech úhlů - živá fotogalerie pro vás i vaše hosty',
  [k.MULTILINGUAL_SUPPORT]: '... samozřejmě také pro vícejazyčné svatby',
  [k.FREE_CREATE]: 'Vytvořte si nyní zdarma',
  [k.DEMO_WEBSITE]: 'Ukázkové webové stránky',
  [k.OUR_PRICING]: 'Naše ceny',
  [k.UNLOCK_ALL_FEATURES]: 'Odemkněte všechny požadované funkce za jednorázový poplatek',
  [k.ANY_QUESTIONS_OR_FEEDBACK]: 'Máte nějaké dotazy nebo požadavky?',
  [k.WE_ARE_HERE_TO_HELP]: 'Těšíme se na vaše dotazy!',
  [k.USER_TESTIMONIALS]: 'Hlasy našich uživatelů',
  [k.THOUSANDS_OF_PEOPLE]: 'Svou svatbu s EureHochzeitOnline oslavily již tisíce lidí!',
  [k.OUR_GIFT_TIP]: 'Náš nápad na dárek:',
  [k.FOR_ALL_WITNESSES]: '',
  [k.BUY_WEDDING_WEBSITE_AS_GIFT]: 'Dopřejte nevěstě a ženichovi osobní svatební webové stránky!',
  [k.BRIDE]: 'Nevěsta',
  [k.F_WITNESS]: 'Družička',
  [k.GROOM]: 'Ženich',
  [k.TESTIMONIAL_1]:
    'Vytvoření našich webových stránek byla hračka. Byli jsme opravdu překvapeni, jak snadné bylo přidávat i složité informace. Opravdu jsme si mohli vytvořit NAŠE webové stránky. Naši hosté byli ohromeni!',
  [k.TESTIMONIAL_2]:
    'Bylo fantastické, že všichni naši hosté mohli stránky používat ve svém jazyce. Líbila se jim funkce playlistu, která jim umožnila navrhnout vlastní oblíbené skladby. Tímto způsobem byla prostřednictvím hudby na večírku oslavena i různorodost hostů.',
  [k.TESTIMONIAL_3]:
    'Webové stránky nám byly neocenitelným pomocníkem po celou dobu plánování naší svatby. Mohli jsme kdykoli aktualizovat důležité informace a dokonce prezentovat fotografie v krásné fotogalerii. Našim hostům se živá slideshow líbila a nám se líbila ještě víc.',
  [k.TESTIMONIAL_4]:
    'Po více než roce si stále rádi poslechneme playlist ze Spotify z naší svatby. Naše svatební webové stránky nám pomohly prožít skvělou svatbu s nádhernými vzpomínkami.',
  [k.THANK_YOU]: 'Děkuji moc!',
  [k.ACTIVATED_MAIL]: 'Aktivovali jste si svou e-mailovou adresu!',
  [k.CLICK_HERE_TO_GO_TO_WED]: 'Kliknutím sem přejdete na svatební webové stránky',
  [k.AND]: 'a',
  [k.BE_REDIRECTED]: 'pro přesměrování.',
  [k.SET_A_PASSWORD]: 'Nastavte si prosím heslo, abyste se mohli kdykoli přihlásit a upravovat svou svatbu!',
  [k.SET_A_PASSWORD_SHORT]: 'Zvolte si prosím heslo',
  [k.ERROR_OCCURED]:
    'Došlo k chybě. Zkuste to prosím znovu. Pokud se chyba objevuje opakovaně, můžete nám poslat e-mail na adresu:',
  [k.PASSWORD_ALREADY_EXISTING_TITLE]: 'Heslo již máte',
  [k.PASSWORD_ALREADY_EXISTING]: 'Zaznamenali jsme, že jste si již vytvořili heslo. Můžete',
  [k.GO_TO_WEDDING]: 'přejít na svou svatební domovskou stránku zde a zaregistrovat se',
  [k.I_AGREE]: 'Přijímám',
  [k.REGISTER_NOW]: 'Zaregistrujte se nyní!',
  [k.YOUR_FREE_WEDDING_WEBSITE]: 'Vaše svatební webové stránky zdarma!',
  [k.ALREADY_CREATED_WEDDINGS]:
    'Již jste vytvořili následující svatební domovské stránky. Pravděpodobně byste raději upravovali stávající svatební domovské stránky, než vytvářeli nové. Stačí kliknout níže.',
  [k.WEDDING]: 'Svatební webové stránky',
  [k.ENTER_PASSWORD]: 'Zadejte prosím své heslo',
  [k.USER_ALREADY_EXISTING]: 'S touto e-mailovou adresou již máme registrovaného uživatele. Zadejte prosím své heslo.',
  [k.BACK]: 'Zpět',
  [k.LOGIN]: 'Přihlásit se',
  [k.FORGOT_PASSWORD]: 'Zapomněli jste své heslo? Klikněte prosím zde.',
  [k.MAIL_SENT]: 'Zaslali jsme vám e-mail. Klikněte na odkaz v e-mailu a obnovte své heslo.',
  [k.OK]: 'OK',
  [k.PERFECT]: 'Perfektní,',
  [k.NAME_OF]: 'Jak se jmenuje',
  [k.SPOUSE_M]: 'Váš vybraný',
  [k.SPOUSE_F]: 'Váš vybraný',
  [k.NEXT]: 'Další',
  [k.WHATS_YOUR_NAME]: 'Úžasné! Jak se jmenuješ?',
  [k.PREPARING_1]: 'Připravujeme vaši svatební aplikaci...',
  [k.PREPARING_2]: 'Ukládáme váš vybraný návrh...',
  [k.PREPARING_3]: 'Několik posledních úprav...',
  [k.FINISHED_ENTER_MAIL]:
    'Hotovo! Zadejte prosím svou e-mailovou adresu, abychom mohli vaši svatební aplikaci uložit a zaslat vám přihlašovací údaje.',
  [k.ACCEPT_TERMS]: 'Prosím, přijměte podmínky poskytování služeb.',
  [k.ENTER_VALID_MAIL]: 'Zadejte prosím platnou e-mailovou adresu.',
  [k.QUESTION_BRIDE_OR_GROOM]: 'Jste nevěsta nebo ženich?',
  [k.QUESTION_WEDDING_DATE]: 'Znáte datum své svatby?',
  [k.YES_WE_KNOW]: 'Ano, známe!',
  [k.NO_WE_DONT_KNOW]: 'Ne, ještě ne!',
  [k.MAKE_SELECTION]: 'Prosím, vyberte si.',
  [k.QUESTION_WEDDING_LOCATION]: 'Máte vybrané místo?',
  [k.YES_WE_HAVE]: 'Ano, máme!',
  [k.ENTER_YOUR_LOCATION]: 'Zadejte prosím místo konání svatby.',
  [k.LOCATION_CLASSIC]: 'Classic',
  [k.LOCATION_COUNTRY]: 'Country',
  [k.LOCATION_OUTDOOR]: 'Venkovní',
  [k.LOCATION_PALACE_HOTEL]: 'Zámek',
  [k.QUESTION_WEDDING_STYLE]: 'Jaký styl preferujete?',
  [k.HOTEL]: 'Hotely',
  [k.INFOS]: 'Info',
  [k.LOCATION]: 'Lokality',
  [k.PHOTOS]: 'Fotografie',
  [k.NO_MUSIC_SAVED]: 'Zatím nejsou uloženy žádné skladby...',
  [k.SEARCH_FOR_SONGS]: 'Vyhledejte svou oblíbenou píseň výše!',
  [k.LISTEN_TO_FULL_LIST]: 'Poslechněte si celý svatební playlist',
  [k.OPEN_IN_SPOTIFY]: 'Otevřete ve Spotify',
  [k.LISTEN_TO_SINGLE_SONGS]: 'Poslechněte si jednotlivé skladby v playlistu',
  [k.NO_RESULT_FOUND]: 'Nebyly nalezeny žádné výsledky',
  [k.CANCEL]: 'Zrušit',
  [k.ADD_SONG]: 'Přidat píseň',
  [k.NO_PHOTOS_UPLOADED_YET]: 'Zatím nebyly nahrány žádné fotografie...',
  [k.ADD_SOME_PHOTOS]: 'Klikněte výše a pořiďte fotografie!',
  [k.DROP_PHOTOS_HERE_OR]: 'Uložte fotografie zde nebo',
  [k.SHOOT_PHOTO]: 'Pořiďte si fotografii',
  [k.OVERVIEW_OF_ALL_PRESENTS]: 'Zobrazit všechny dárky a záznamy',
  [k.LOADING_BACKINGS]: 'Příspěvky byly načteny...',
  [k.NO_PRESENTS_YET]: 'Zatím jste do svého dárkového registru nepřidali žádné dárky.',
  [k.PRESENT]: 'Přidejte dárek',
  [k.E_MAIL_ADDRESS]: 'Vaše e-mailová adresa',
  [k.DATE]: 'Datum dárku',
  [k.NO_BACKINGS_YET]: 'Zatím žádné záznamy',
  [k.CLOSE]: 'zavřít',
  [k.ONLY_VISIBLE_FOR_WEDDING_COUPLE]: '(Viditelné pouze pro nevěstu a ženicha)',
  [k.SHOW_ALL_ENTRIES]: 'Zobrazit všechny příspěvky',
  [k.EUR]: 'SUMA EUR',
  [k.ADD_BACKING]: 'Připojte se k',
  [k.BUY]: 'Koupit nyní',
  [k.YOUR_BACKING_SAVED]: 'Jste přihlášeni!',
  [k.A_GUEST_ALREADY_BACKED]: 'Host se již zaregistroval.',
  [k.DELETE_MY_BACKING]: 'Smazat můj záznam.',
  [k.OVERVIEW_OF_ALL_RSVPS]: 'Přehled všech registrací',
  [k.LOADING_RSVPS]: 'Registrace se načítají...',
  [k.NO_RSVPS_YET]: 'Zatím nebyly přijaty žádné karty s odpovědí.',
  [k.RESPONSE]: 'Odpovědi na',
  [k.GUESTS_AND_MENU_WISH]: 'Žádost o hosty a menu',
  [k.QUESTIONS_AND_REMARKS]: 'Otázka/komentář',
  [k.WILL_ATTEND]: 'Závazky',
  [k.WILL_NOT_ATTEND]: 'Zrušení rezervace',
  [k.SHOW_ALL_RSVPS]: 'Zobrazit všechny požadavky',
  [k.YOU_ALREADY_SUBMITTED_ON]: 'Odpověď jste již odeslali',
  [k.CANNOT_CHANGE_ANY_MOR]: 'již byla odeslána. Údaje nelze změnit.',
  [k.MENU_WISH]: 'Nabídka požadavků',
  [k.RSVP_LOADING]: 'Karta s odpovědí se načítá...',
  [k.LIST_EVERYONE_WE_MAY_EXPECT]: 'Níže prosím uveďte seznam všech, které můžeme očekávat',
  [k.LIST_EVERYONE_WE_MAY_EXPECT_WITH_MENU]:
    'Níže prosím uveďte seznam všech, které můžeme očekávat, a také nám sdělte své preference ohledně menu',
  [k.GUEST]: 'Hosté',
  [k.ADD_GUEST]: 'Přidejte dalšího hosta',
  [k.SUBMIT_RSVP]: 'Odeslat odpovědní kartu',
  [k.WEBSITE]: 'Webové stránky',
  [k.OUR_PACKAGES]: 'Naše balíčky',
  [k.PACKAGE_BASIC]: 'Základní',
  [k.PACKAGE_COMPLETE]: 'Kompletní',
  [k.PACKAGE_PREMIUM]: 'Premium',
  [k.PACKAGE_VIP]: 'VIP',
  [k.BEST_SELLER]: 'Nejprodávanější',
  [k.SELECTED]: 'Vybrané',
  [k.SELECT]: 'Vybrat',
  [k.CAN_UPGRADE_LATER]: 'Nebojte se, vždy můžete později upgradovat, stačí doplatit rozdíl.',
  [k.LOOK_IN_FAQ]: 'Máte ještě otázky? Podívejte se na naše nejčastější dotazy!',
  [k.PACKAGE_FEATURES_DESIGN]: 'Vzory, barvy, písma',
  [k.PACKAGE_FEATURES_SUBDOMAIN]: 'Vaše subdoména s heslem',
  [k.PACKAGE_FEATURES_MOBILE]: 'Optimalizováno pro stolní počítače a mobilní zařízení',
  [k.PACKAGE_FEATURES_SCHEDULE]: 'Denní plán s rozvrhem',
  [k.PACKAGE_FEATURES_LOCATION]: 'Plánovač a informace o lokalitě',
  [k.PACKAGE_FEATURES_INFOS]: 'Informační stránky (nejčastější dotazy, dárky atd.)',
  [k.PACKAGE_FEATURES_RSVP]: 'Registrace a správa hostů',
  [k.PACKAGE_FEATURES_MUSIC]: 'Požadavky hostů na hudbu',
  [k.PACKAGE_FEATURES_PHOTOS]: 'Nahrávání fotografií hostů',
  [k.PACKAGE_FEATURES_SUPPORT]: 'Obsluha zákazníků prostřednictvím e-mailu',
  [k.PACKAGE_FEATURES_MULTILANGUAGE]: 'Vícejazyčné webové stránky',
  [k.PACKAGE_FEATURES_OWN_DOMAIN]: 'Vlastní doména (.de)',
  [k.PACKAGE_FEATURES_CUSTOM_DESIGN]: 'Vlastní design od profesionála',
  [k.MAX_FIVE]: 'max. 5',
  [k.CHECKING_PAYMENT_STATUS]: 'Kontrola stavu platby',
  [k.PAYMENT_SUCCESSFULL]:
    'Děkujeme! Platba proběhla úspěšně, nyní můžete používat svatební aplikaci v následujícím balíčku:',
  [k.PAYMENT_FAILED]: 'Platba se bohužel nezdařila. Můžete to zkusit znovu nebo nám poslat e-mail na adresu',
  [k.ACTIVATE_PACKAGE_NOW]: 'Odemknout nyní: V případě, že se vám to nelíbí, můžete se obrátit na nás:',
  [k.PAY]: 'Zaplaťte na',
  [k.EDITING_MODE]: 'Režim úprav',
  [k.ACTIVE]: 'je aktivní',
  [k.NOT_ACTIVE]: 'není aktivní',
  [k.ERROR_OCCURED_WITH_MESSAGE]: 'Došlo k chybě. Technická zpětná vazba:',
  [k.TRY_AGAIN_PLEASE]: 'Zkuste to prosím znovu. Pokud se chyba objevuje opakovaně, můžete nám poslat e-mail na adresu',
  [k.PACKAGE_UPGRADE]: 'Aktualizační balíček',
  [k.PACKAGE_ALREADY_BOUGHT]: 'Pro svou svatbu jste si již zakoupili balíček.',
  [k.UPGRADE_TO_HIGHER_PACKAGE]:
    'Samozřejmě můžete své webové stránky upgradovat na vyšší balíček, stačí doplatit rozdíl. Pošlete nám prosím krátký e-mail a my se o vše postaráme.',
  [k.REGISTRATION_NOT_COMPLETE]: 'Registrace ještě není dokončena. Klikněte prosím na odkaz, který jsme vám zaslali.',
  [k.YOU_HAVE_AN_EMAIL]: 'Obdrželi jste od nás e-mail.',
  [k.YOU_MUST_REGISTER_FIRST]: 'Abyste mohli upravovat své svatební webové stránky, musíte dokončit registraci.',
  [k.CREATOR_MUST_REGISTER]:
    'Vše je zdarma a velmi rychlé: stačí kliknout na odkaz v e-mailu, který jsme vám zaslali. Nemůžete najít e-mail? Žádný problém, stačí kliknout sem:',
  [k.HERE]: 'Zde:',
  [k.E_MAIL_RESENT]: 'Znovu zaslaný e-mail.',
  [k.CONFIRMATION_RESENT_TO]: 'Znovu jsme vám zaslali potvrzovací odkaz:',
  [k.CAN_TAKE_SOME_MINUTES]:
    'Může trvat několik minut, než se k vám e-mail dostane. Pokud budete mít i nadále problémy, napište nám a my vám pomůžeme:',
  [k.RESENDING_MAIL]: 'E-mail bude odeslán znovu.',
  [k.DEMO_WEDDING_HINT]: 'Ukázkový web - vytvořte si vlastní web zdarma a vyzkoušejte ho!',
  [k.CREATE_OWN_WEBSITE]: 'Vytvořte si vlastní webové stránky!',
  [k.SOME_FEATURES_DEACTIVATED_IN_DEMO]: 'Některé funkce jsou na ukázkovém webu vypnuté.',
  [k.CAN_CREATE_OWN_WEDDING_WEBSITE]:
    'Můžete se zaregistrovat, vytvořit si vlastní svatební web a vyzkoušet všechny funkce zdarma!',
  [k.NO_PACKAGE_BOUGHT_HINT]:
    'Základní verze zdarma - přejděte nyní na vyšší verzi a získejte neomezený počet fotografií, hostů a dalších funkcí.',
  [k.LETS_UPGRADE]: 'Pojďme provést upgrade.',
  [k.MAX_NUMBER_FOR_BASIC_PACKAGE]: 'Dosáhli jste maximálního počtu fotografií pro bezplatný základní balíček.',
  [k.UPGRADE_FOR_UNLIMITED_PHOTOS]:
    'Můžete přejít na některý z našich prémiových plánů a nahrát neomezený počet fotografií.',
  [k.UPGRADE_FOR_UNLIMITED_MUSIC]:
    'Můžete upgradovat na jeden z našich prémiových plánů a přidat neomezený počet písní.',
  [k.CONFIRM_DELETE_TITLE]: 'Potvrďte smazání',
  [k.CONFIRM_DELETE_TEXT]: 'Opravdu chcete tuto položku smazat?',
  [k.DELETE]: 'Odstranit položku',
  [k.ADD_ELEMENT]: 'Přidat novou položku',
  [k.WHICH_ELEMENT_TO_ADD]: 'Jaký typ položky chcete přidat?',
  [k.SELECT_ICON_FOR_LOCATION]: 'Vyberte ikonu pro umístění',
  [k.IMAGE_POSITION]: 'Umístění obrázku',
  [k.SELECT_IMAGE]: 'Vyberte obrázek',
  [k.UPLOAD]: 'Nahrát obrázek',
  [k.SELECTED_IMAGE]: 'Vybraný obrázek',
  [k.NO_IMAGE]: 'Žádný obrázek',
  [k.OTHER_IMAGE]: 'Jiný obrázek',
  [k.EDIT_WEDDING_BASE_DATA]: 'Upravit podrobnosti o svatbě',
  [k.ENTER_NAME_OF_BRIDE]: 'Zadejte prosím jméno nevěsty.',
  [k.ENTER_NAME_OF_GROOM]: 'Zadejte prosím jméno ženicha.',
  [k.SELECT_DATE]: 'Vyberte prosím datum.',
  [k.HINT_CHANGE_URL]:
    'Poznámka: Pokud chcete změnit adresu, na které bude svatební stránka k dispozici, pošlete nám prosím e-mail:',
  [k.EDIT_COUNTDOWN]: 'Upravit svatební odpočet',
  [k.EDIT_INFO_BOX]: 'Upravit informační okno',
  [k.EDIT_BOX_WITH_LARGE_PHOTO]: 'Upravit rámeček s velkou fotografií',
  [k.EDIT_LOCATION]: 'Upravit místo',
  [k.EDIT_PLACE]: 'Upravit místo',
  [k.ADD_LOCATION]: 'Přidat místo',
  [k.ADD_PLACE]: 'Přidat umístění',
  [k.SELECT_SEARCH_RESULT]: 'Vyberte výsledek hledání.',
  [k.EDIT_PAGE_TITLE]: 'Upravit název stránky',
  [k.EDIT_PRESENTS]: 'Upravit registr dárků',
  [k.PRESENT_INTRO_TEXT]: 'Vytvořte si vlastní personalizovaný dárkový registr a inspirujte své hosty.',
  [k.ADDITIONAL_ENTRY]: 'Více záznamů',
  [k.EDIT_RSVP]: 'Upravit RSVP',
  [k.MENU_WISHES_ACTIVATED]: 'Chcete, aby vaši hosté při RSVP uvedli své preference ohledně menu?',
  [k.EDIT_SCHEDULE_ITEM]: 'Upravit položku menu',
  [k.NAVIGATION]: 'Nastavit menu',
  [k.COUPLE]: 'Nevěsta a ženich',
  [k.MUSIC]: 'Hudební menu',
  [k.YOU_ARE_LOGGED_IN_AS_GROOM]: 'Jste registrován jako ženich.',
  [k.YOU_ARE_LOGGED_IN_AS_BRIDE]: 'Jste zaregistrováni jako nevěsta.',
  [k.ADDRESS_OF_YOUR_PAGE]: 'Adresa vaší svatební webové stránky:',
  [k.WEDDING_CODE_FOR_GUESTS]: 'Svatební kód pro vaše hosty:',
  [k.CURRENT_PACKAGE]: 'Váš aktuální balíček:',
  [k.UPGRADE]: 'Upgrade:',
  [k.LOGOUT]: 'Odhlásit se',
  [k.FOR_GUESTS]: 'Pro hosty',
  [k.INVISIBLE]: 'neviditelný',
  [k.VISIBLE]: 'Viditelné',
  [k.WEDDING_NOT_FOUND]: 'Svatební stránka nebyla nalezena.',
  [k.WEDDING_NOT_FOUND_TEXT]:
    'Omlouváme se, ale na zadané adrese se nám nepodařilo najít svatební stránku. Je možné, že jste se spletli v zadávání? Zkuste to prosím znovu.',
  [k.IF_ERROR_REPEATS]: 'Pokud se chyba opakuje, můžete nám napsat na adresu',
  [k.ERROR_GENERAL]: 'Došlo k chybě',
  [k.SORRY_WRITE_MAIL]:
    'Omlouváme se. Zkuste to prosím znovu. Pokud se chyba vyskytne opakovaně, můžete nám jednoduše poslat e-mail a my vám pomůžeme dále.',
  [k.MOVE_UP]: 'Posunout se nahoru',
  [k.MOVE_DOWN]: 'Posunout dolů',
  [k.OH_SORRY]: 'Ach bože... Udělal jsem chybu...',
  [k.ERROR_REFRESH_PAGE]:
    'Velmi se omlouváme. Neváhejte prosím znovu načíst stránku a zkuste to znovu. Pokud se chyba opakuje, můžete nám napsat na adresu',
  [k.IF_YOU_WANT_TO_HELP]:
    'Pokud nám chcete pomoci chybu najít a opravit extra rychle, můžete do e-mailu zkopírovat následující text:',
  [k.ENTER_DISPLAY_NAME]:
    'Skvělé! Zadejte prosím své jméno - funguje i přezdívka. Zobrazí se například pod nahranými fotografiemi.',
  [k.ENTER_DISPLAY_NAME_SHORT]: 'Zadejte prosím jméno.',
  [k.WE_SENT_EMAIL]: 'Odeslali jsme vám e-mail. Klikněte prosím na odkaz v e-mailu a obnovte své heslo.',
  [k.WELCOME_BACK]: 'Vítejte zpět na stránkách,',
  [k.ALMOST_THERE]: 'Už to skoro je,',
  [k.PLEASE_SET_PASSWORD]: 'Nyní si prosím zvolte heslo.',
  [k.PLEASE_SET_PASSWORD_SHORT]: 'Zvolte si heslo',
  [k.WELCOME_TO_WEDDING_OF]: 'Vítejte na svatebních stránkách',
  [k.PLEASE_ENTER_MAIL]: '! Zadejte prosím svou e-mailovou adresu.',
  [k.WELCOME_TO_EHO]: 'Vítejte na svatební platformě EureHochzeitOnline.com! Zadejte prosím svou e-mailovou adresu.',
  [k.TO_WEDDING]: 'Pro svatbu',
  [k.ENTER_WEDDING_CODE]: '! Zadejte prosím svatební kód.',
  [k.ENTER_WEDDING_CODE_SHORT]: 'Zadejte prosím svatební kód.',
  [k.RESTART]: 'Prosím, restartujte',
  [k.UPLOAD_PREPARING]: 'Nahrávání v přípravě...',
  [k.UPLOAD_STARTED]: 'Probíhá nahrávání...',
  [k.UPLOAD_FINISHING]: 'Nahrávání dokončeno...',
  [k.UPLOAD_CANCELED]: 'Nahrávání bylo zrušeno...',
  [k.INVALID_FILE_TYPE]: 'Typ souboru není platný...',
  [k.MAX_FILE_NUMBER]: 'Byl překročen maximální počet souborů...',
  [k.FILE_TOO_LARGE]: 'Soubor je příliš velký...',
  [k.DAYS_TO_GO]: 'Zbývají dny!',
  [k.DAY_TO_GO]: 'Ještě jeden den!',
  [k.TODAY]: 'Dnes',
  [k.INVALID_DATE]: 'Datum není platné',
  [k.DATE_MUST_BE_IN_FUTURE]: 'Datum musí být v budoucnosti',
  [k.ADDRESS_OR_NAME_OF_LOCATION]: 'Adresa nebo název místa',
  [k.ENTER_SONG_WISH]: 'Zde zadejte svou oblíbenou píseň...',
  [k.NAME_OF_GUEST]: 'Jméno hosta',
  [k.QUESTION_REMARK_MAX_1000]: 'Otázky/komentáře (max. 1000 znaků)',
  [k.LEFT]: 'Odkazy',
  [k.TOP]: 'Top',
  [k.RIGHT]: 'Právo',
  [k.BOTTOM]: 'Dole',
  [k.NAME_OF_BRIDE]: 'Jméno nevěsty',
  [k.NAME_OF_GROOM]: 'Jméno ženicha',
  [k.TITLE]: 'Název',
  [k.DESCRIPTION]: 'Popis události',
  [k.NAME_OF_PLACE]: 'Název místa',
  [k.STREET]: 'Ulice',
  [k.HOUSENUMBER]: 'Č.',
  [k.ZIP]: 'PSČ',
  [k.CITY]: 'Město/obec',
  [k.COUNTRY]: 'Země/stát',
  [k.HINTS_FOR_GUESTS]: 'Poznámky pro hosty',
  [k.TITLE_FOR_THIS_PAGE]: 'Název této stránky',
  [k.SUBTITLE_FOR_THIS_PAGE]: 'Podtitul této stránky',
  [k.LABEL]: 'Označení',
  [k.AMAZON_URL]: 'Adresa URL Amazonu (nepovinné)',
  [k.COSTS]: 'Náklady',
  [k.NEW_FOOD_OPTION]: 'Nová možnost nabídky',
  [k.TIME]: 'Čas',
  [k.SCHEDULE_ITEM]: 'Položka programu',
  [k.YOUR_NAME_OR_NICKNAME]: 'Vaše jméno nebo přezdívka',
  [k.YOUR_EMAIL_ADDRESS]: 'Vaše e-mailová adresa',
  [k.YOUR_WEDDING_CODE]: 'Váš svatební kód',
  [k.COULD_NOT_SAVE_WEDDING]: 'Je nám líto, ale svatbu se nám nepodařilo uložit.',
  [k.ALREADY_HAVE_WEDDING]: 'Svatební domovské stránky již máte',
  [k.CREATE_NEW]: 'Vytvořte si novou',
  [k.COULD_NOT_LOAD_YOUR_DATA]: 'Vaše údaje se nám nepodařilo načíst.',
  [k.COULD_NOT_SEND_MAIL]: 'Nepodařilo se nám odeslat e-mail.',
  [k.DELETE_IMAGE]: 'Smazat obrázek',
  [k.DELETE_IMAGE_ARE_YOU_SURE]: 'Opravdu chcete tento obrázek smazat?',
  [k.COULD_NOT_LOGIN]: 'Nepodařilo se nám přihlásit.',
  [k.WRONG_PASSWORD_TITLE]: 'Špatné heslo',
  [k.WRONG_PASSWORD_TEXT]: 'Zadané heslo je nesprávné.',
  [k.WRONG_CODE_TITLE]: 'Nesprávný svatební kód',
  [k.WRONG_CODE_TEXT]: 'Zadaný svatební kód je nesprávný.',
  [k.LOADING]: 'Načítá se...',
  [k.GUEST_NAME_MISSING]: 'Zadejte prosím jména následujících hostů:',
  [k.GUEST_FOOD_MISSING]: 'Vyplňte prosím požadavek na menu pro následující hosty:',
  [k.LIMIT_EXCEEDED_PASSWORD_RESET]: 'Překročili jste maximální počet pokusů. Můžete to zkusit později.',
  [k.EXPIRED_CODE_PASSWORD_RESET]: 'Tento odkaz jste již použili k obnovení hesla.',
  [k.PLEASE_ENTER_NAME]: 'Zadejte prosím jméno',
  [k.COUNTDOWN_VISIBLE]: 'Odpočet je viditelný.',
  [k.COUNTDOWN_NOT_VISIBLE]: 'Odpočet není viditelný.',
  [k.ASK_FOR_MENU_WISHES]: 'Ano, požádejte o zaslání požadavků na menu',
  [k.ASK_NOT_FOR_MENU_WISHES]: 'Ne, nežádat o požadavky na jídelní lístek',
  [k.SPRING]: 'Spring',
  [k.SUMMER]: 'Léto',
  [k.AUTUMN]: 'Podzim',
  [k.WINTER]: 'Zima',
  [k.COULD_NOT_SAVE_PASSWORD]: 'Vaše heslo se nám nepodařilo uložit.',
  [k.YES_WE_WILL_ATTEND]: 'Ano, zúčastníme se vaší svatby',
  [k.NO_WE_WILL_NOT_ATTEND]: 'Ne, vaší svatby se nezúčastníme',
  [k.CONFIRM_DIALOG_TITLE]: 'Jste si jisti?',
  [k.ALERT_DIALOG_TITLE]: 'Poznámka',
  [k.DEFAULT_IMAGES_TYPE_CAKE]: 'Svatební dort',
  [k.DEFAULT_IMAGES_TYPE_CEREMONY]: 'Svatební obřad',
  [k.DEFAULT_IMAGES_TYPE_CHAMPAGNERECEPTION]: 'Recepce a šampaňské',
  [k.DEFAULT_IMAGES_TYPE_DANCE]: 'Tanec a večírek',
  [k.DEFAULT_IMAGES_TYPE_FOOD]: 'Svatební večeře a bufet',
  [k.DEFAULT_IMAGES_TYPE_CAR]: 'Svatební vůz a průvod',
  [k.DEFAULT_IMAGES_TYPE_GAME]: 'Hry a zábava',
  [k.DEFAULT_IMAGES_TYPE_DRESSCODE]: 'Dress Code',
  [k.DEFAULT_IMAGES_TYPE_HOTEL]: 'Hotel a ubytování',
  [k.DEFAULT_IMAGES_TYPE_PHOTO]: 'Focení',
  [k.DEFAULT_IMAGES_TYPE_PRESENT]: 'Dary a dárky',
  [k.DEFAULT_IMAGES_TYPE_LOVE]: 'Obecné a láska',
  [k.DEFAULT_ELEMENT_TEXT_PAGE_TITLE_LABEL]: 'Název stránky',
  [k.DEFAULT_ELEMENT_TEXT_PAGE_TITLE_TITLE]: 'Page Title',
  [k.DEFAULT_ELEMENT_TEXT_PAGE_TITLE_SUBTITLE]: 'Vítejte na této stránce',
  [k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_LABEL]: 'Položka programu',
  [k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_TITLE]: 'Položka programu',
  [k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_TIME]: 'Od 15:00',
  [k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_DESCRIPTION]: 'Skupinové foto',
  [k.DEFAULT_ELEMENT_TEXT_INFO_ITEM_LABEL]: 'Informační box',
  [k.DEFAULT_ELEMENT_TEXT_INFO_ITEM_TITLE]: '🍰 Dort',
  [k.DEFAULT_ELEMENT_TEXT_INFO_ITEM_DESCRIPTION]:
    ' Pokud byste chtěli upéct dort, dejte nám prosím vědět. Těšíme se, že pro vás sestavíme pestrý výběr.',
  [k.DEFAULT_ELEMENT_TEXT_LARGE_PHOTO_ITEM_LABEL]: 'Box s velkou fotografií',
  [k.DEFAULT_ELEMENT_TEXT_LARGE_PHOTO_ITEM_TITLE]: 'Vaše představení',
  [k.DEFAULT_ELEMENT_TEXT_LARGE_PHOTO_ITEM_DESCRIPTION]:
    'Zde se můžete svým hostům představit. Řekněte jim, jak jste se seznámili...',
  [k.DEFAULT_ELEMENT_TEXT_LOCATION_MAP_LABEL]: 'Mapa pro umístění',
  [k.DEFAULT_ELEMENT_TEXT_LOCATION_ITEM_LABEL]: 'Místo svatby',
  [k.DEFAULT_STYLES_MODERN_TITLE]: 'Moderní',
  [k.DEFAULT_STYLES_MODERN_DESCRIPTION]:
    'Vaše svatba v moderním stylu! Čisté linie, inovativní design a šik elegance. Moderní svatební styly odrážejí současnou estetiku - od minimalistické sofistikovanosti až po futuristický půvab. Nechte svou lásku zazářit v prostředí, které zachycuje tep doby.',
  [k.DEFAULT_STYLES_KLASSISCH_TITLE]: 'Klasika',
  [k.DEFAULT_STYLES_KLASSISCH_DESCRIPTION]:
    'Elegance věčnosti na vás čeká ve stylu Classic. Nadčasová krása, tradiční prvky a sofistikovaná atmosféra definují tento styl. Čisté střihy, neutrální barvy a nádech bohatství vytvářejí atmosféru, která ctí příběh vaší lásky. Vaše svatba bude nadčasovým mistrovským dílem, které přetrvá generace.',
  [k.DEFAULT_STYLES_VINTAGE_TITLE]: 'Vintage',
  [k.DEFAULT_STYLES_VINTAGE_DESCRIPTION]:
    'Vraťte se v čase do éry nadčasového šarmu. Vintage styly přinášejí atmosféru minulých dob s nádechem nostalgie a romantické elegance. Měkké barvy, jemné krajky a starožitné detaily vytvářejí atmosféru elegance a vzpomínek. Vaše svatba bude živoucí pohádkou, která oslavuje krásu zašlých časů.',
  [k.DEFAULT_STYLES_FLOWER_TITLE]: 'Květiny',
  [k.DEFAULT_STYLES_FLOWER_DESCRIPTION]:
    'Vítejte v kvetoucí nádheře Flower Power! Tento svatební styl oslavuje přirozenou krásu květin v celé jejich kráse. Čeká vás oslava barev a vůní, protože květinové prvky rámují vaši lásku. Vaše svatba bude rozkvetlou zahradou lásky - od svěžích květinových vazeb až po botanicky inspirovanou výzdobu.',
  [k.COOKIES_01]: 'Nějaké svatební koláčky pro vás?',
  [k.COOKIES_02]:
    "Kliknutím na tlačítko 'Potvrdit vše' přijmete všechny soubory cookie. Případně můžete provést vlastní výběr. Svůj výběr můžete kdykoli odvolat prostřednictvím 'Nastavení souborů cookie'.",
  [k.COOKIES_03]: 'Nezbytné soubory cookie',
  [k.COOKIES_04]: 'Tyto soubory cookie jsou nezbytné pro funkčnost stránek, takže je nemůžete zakázat.',
  [k.COOKIES_05]: 'Zjistěte více',
  [k.COOKIES_06]: 'Marketingové a personalizační soubory cookie',
  [k.COOKIES_07]:
    'Tyto soubory cookie umožňují přijímat obsah webových stránek přizpůsobený vašemu uživatelskému chování a umožňují nám přizpůsobit naše webové stránky zájmům našich zákazníků na základě statistik používání.',
  [k.COOKIES_08]: 'Přijmout výběr',
  [k.COOKIES_09]: 'Přijmout vše',
  [k.TERMS_AND_CONDITIONS_00]: 'Podmínky používání',
  [k.TERMS_AND_CONDITIONS_01]:
    "Společnost fidira Software UG (haftungsbeschränkt), dále také 'poskytovatel', 'my' nebo 'EureHochzeitOnline.com', poskytuje platformu pro vytváření individuálních svatebních aplikací. Pomocí těchto aplikací mohou uživatelé (dále společně jen 'uživatelé') jako hostitelé vytvořit aplikaci pro svou svatbu a prostřednictvím této aplikace nabídnout svým hostům informace o svatbě a možnosti interakce. Na používání platformy se vztahují následující podmínky.",
  [k.TERMS_AND_CONDITIONS_02]: '§ 1 Používání platformy',
  [k.TERMS_AND_CONDITIONS_03]:
    'Používání platformy je zpravidla bezplatné ve zkušební verzi. Pokud je platforma využívána pro svatbu s definovaným počtem hostů nebo vyšším, je třeba zaplatit jednorázový poplatek. Zkušební verze i plná verze vyžadují předchozí registraci a vytvoření uživatelského účtu. Uživatel nesmí povolit třetím stranám používat svůj účet. Uživatel je povinen udržovat své přístupové údaje v tajnosti a chránit je před přístupem třetích osob.',
  [k.TERMS_AND_CONDITIONS_04]:
    'Svatební aplikace bude k dispozici šest měsíců po svatbě. Poté Svatební aplikaci deaktivujeme, abychom mohli požadovanou adresu nabídnout dalším párům. Pokud potřebujete, aby svatební aplikace zůstala online po delší dobu, můžeme se domluvit na zvláštní dohodě.',
  [k.TERMS_AND_CONDITIONS_05]:
    'Po vytvoření svatební aplikace ji lze použít pouze pro jednu svatbu. Pokud například zjistíme, že uživatel svatební aplikaci poskytl nebo prodal dalším párům, vyhrazujeme si právo takovému použití zabránit a účtovat poplatek za další svatby.',
  [k.TERMS_AND_CONDITIONS_06]: '§ 2 Ochrana osobních údajů',
  [k.TERMS_AND_CONDITIONS_07]: 'Používáním softwaru uživatelé souhlasí s tím, že',
  [k.TERMS_AND_CONDITIONS_08]: 'ochranu osobních údajů',
  [k.TERMS_AND_CONDITIONS_09]: 'předpisy',
  [k.TERMS_AND_CONDITIONS_10]: '§ 3 Funkce a služby EureHochzeitOnline.com',
  [k.TERMS_AND_CONDITIONS_11]:
    'Jednotlivé svatební aplikace, které lze vytvořit prostřednictvím EureHochzeitOnline.com, nabízejí následující funkce',
  [k.TERMS_AND_CONDITIONS_12]: 'Přizpůsobení:',
  [k.TERMS_AND_CONDITIONS_13]:
    'Hostitel může konfigurovat a navrhovat určité aspekty svatební aplikace, například aby odpovídala grafickému designu svatebních oznámení, může si vybrat pozadí, obrázky, barvy a písma.',
  [k.TERMS_AND_CONDITIONS_14]: 'Fotogalerie:',
  [k.TERMS_AND_CONDITIONS_15]:
    'Hosté mohou pořizovat a nahrávat fotografie před svatbou nebo během ní. Tyto fotografie mohou být zobrazeny v automaticky aktualizované živé galerii obrázků.',
  [k.TERMS_AND_CONDITIONS_16]: 'Požadavky na hudbu:',
  [k.TERMS_AND_CONDITIONS_17]:
    'Hosté mohou pomocí funkce vyhledávání požádat o písně na svatbu a ohodnotit žádosti. Hostitel může tuto funkci použít k výběru skladeb.',
  [k.TERMS_AND_CONDITIONS_18]: 'Informace o svatbě: Svatební hosté se mohou informovat o tom, jaká bude jejich svatba:',
  [k.TERMS_AND_CONDITIONS_19]:
    'Hostitel může ve své svatební aplikaci zveřejnit místo konání svatby s interaktivními mapami, časový rozvrh a další informace pro své hosty.',
  [k.TERMS_AND_CONDITIONS_20]:
    'EureHochzeitOnline.com si vyhrazuje právo kdykoli provést změny v rozsahu a designu funkcí v softwaru.',
  [k.TERMS_AND_CONDITIONS_21]:
    'K používání funkcí je zapotřebí moderní chytrý telefon, tablet nebo počítač s moderním internetovým prohlížečem. Zejména živé funkce, jako je nahrávání obrázků, svatební kvízy nebo žádosti o hudbu, vyžadují stabilní a rychlé internetové připojení. EureHochzeitOnline.com nenese žádnou odpovědnost za přesnost, úplnost, spolehlivost, aktuálnost a použitelnost poskytovaného obsahu.',
  [k.TERMS_AND_CONDITIONS_22]:
    'Během údržbových prací, které EureHochzeitOnline.com oznámí hostitelům, může být platforma dočasně nedostupná. Vynakládáme veškeré úsilí, aby naše služba byla dostupná, a jsme hrdí na to, že jsme naše uživatele nikdy nezklamali. Nemůžeme však převzít žádné další závazky, zejména uživatel nemá právo na stálou dostupnost služby.',
  [k.TERMS_AND_CONDITIONS_23]: '§ 4 Povinnosti uživatele',
  [k.TERMS_AND_CONDITIONS_24]:
    'Uživatel může na platformě EureHochzeitOnline.com ukládat a zveřejňovat vlastní obsah a soubory jako host nebo jako hostitel. Za tento obsah odpovídá uživatel. Uživatel se zavazuje, že nebude zveřejňovat žádné články, soubory, svatební informace ani jiný obsah, který by porušoval obecné mravy nebo platné právní předpisy. Uživatel se zejména zavazuje, že nebude zveřejňovat žádné příspěvky, soubory, svatební informace ani jiný obsah',
  [k.TERMS_AND_CONDITIONS_25]: 'jejichž zveřejnění představuje trestný čin nebo správní delikt,',
  [k.TERMS_AND_CONDITIONS_26]: 'porušují autorská práva, ochranné známky nebo zákony o hospodářské soutěži',
  [k.TERMS_AND_CONDITIONS_27]: 'které porušují zákon o právních službách,',
  [k.TERMS_AND_CONDITIONS_28]: 'nebo obsahuje urážlivý, rasistický, diskriminační nebo pornografický obsah.',
  [k.TERMS_AND_CONDITIONS_29]: 'které obsahují reklamu.',
  [k.TERMS_AND_CONDITIONS_30]:
    'EureHochzeitOnline.com má právo změnit nebo smazat jakýkoli obsah, který porušuje tyto povinnosti nebo je podezřelý z jejich porušení. EureHochzeitOnline.com je rovněž oprávněn zablokovat přístup uživatele a nastavenou svatební aplikaci, pokud uživatel porušuje podmínky používání. Uživatel je povinen nahradit poskytovateli škodu způsobenou porušením povinností. Poskytovatel je oprávněn požadovat po uživateli náhradu škody za nároky uplatněné třetími osobami v důsledku porušení práva uživatelem. Uživatel je povinen podpořit poskytovatele v obraně proti takovým nárokům. Uživatel je rovněž povinen nést náklady na přiměřenou právní obranu poskytovatele.',
  [k.TERMS_AND_CONDITIONS_31]: '§ 5 Duševní vlastnictví',
  [k.TERMS_AND_CONDITIONS_32]: 'Duševní vlastnictví',
  [k.TERMS_AND_CONDITIONS_33]:
    'Uživatel vlastní práva duševního vlastnictví k obsahu, textu, obrázkům a dalším materiálům, které zveřejňuje. EureHochzeitOnline.com si nenárokuje žádná práva duševního vlastnictví k takovému obsahu. Společnost EureHochzeitOnline.com však opravňujete k přístupu, kopírování a šíření tohoto obsahu za účelem jeho poskytnutí svatebčanům. Rovněž nás opravňujete k provádění jakýchkoli dalších úkonů nezbytných k poskytování našich služeb, mimo jiné k vytváření záložních kopií a poskytování přístupu pracovníkům podpory.',
  [k.TERMS_AND_CONDITIONS_34]: 'Naše duševní vlastnictví',
  [k.TERMS_AND_CONDITIONS_35]:
    'Poskytovatel neuděluje žádnou licenci ani jiné právo na používání práv duševního vlastnictví webových stránek nebo softwarové platformy EureHochzeitOnline.com mimo pravidelně vytvářenou svatební aplikaci poskytovanou v souladu s podmínkami používání. Duplikace, distribuce, reprodukce, přenos nebo jiné využití práv duševního vlastnictví, včetně jejich zpřístupnění třetím osobám, není bez předchozího písemného souhlasu poskytovatele povoleno.',
  [k.TERMS_AND_CONDITIONS_36]: '§ 6 Právo na odstoupení od smlouvy',
  [k.TERMS_AND_CONDITIONS_37]:
    'Máte právo odstoupit od této smlouvy do čtrnácti dnů bez udání důvodu. Lhůta pro odstoupení od smlouvy činí čtrnáct dní od uzavření smlouvy. Své právo na odstoupení od smlouvy můžete uplatnit zasláním e-mailu na adresu',
  [k.TERMS_AND_CONDITIONS_38]:
    's jasným vyjádřením vašeho rozhodnutí odstoupit od smlouvy. V e-mailu můžete také použít následující vzorový formulář:',
  [k.TERMS_AND_CONDITIONS_39]: 'Vzorový formulář pro odstoupení od smlouvy',
  [k.TERMS_AND_CONDITIONS_40]:
    '- Na adresu společnosti fidira Software UG (haftungsbeschränkt), e-mail: beeni@eurehochzeitonline.com:',
  [k.TERMS_AND_CONDITIONS_41]:
    '- Já/my ( * ) tímto odstupuji/odstupujeme od smlouvy uzavřené mezi mnou/námi ( * ) o aktivaci naší svatební aplikace ( * ).',
  [k.TERMS_AND_CONDITIONS_42]: '- Objednáno dne ( * ) / Obdrženo dne ( * )',
  [k.TERMS_AND_CONDITIONS_43]: '- Jméno spotřebitele (spotřebitelů)',
  [k.TERMS_AND_CONDITIONS_44]: '- Adresa spotřebitele (spotřebitelů)',
  [k.TERMS_AND_CONDITIONS_45]: '- Podpis spotřebitele (spotřebitelů) (pouze v případě oznámení v papírové podobě)',
  [k.TERMS_AND_CONDITIONS_46]: '- Datum',
  [k.TERMS_AND_CONDITIONS_47]: '(*) Nehodící se škrtněte',
  [k.TERMS_AND_CONDITIONS_48]:
    'dodržení lhůty pro odstoupení od smlouvy stačí zaslat oznámení o uplatnění práva na odstoupení od smlouvy před uplynutím lhůty pro odstoupení od smlouvy. důsledky odstoupení od smlouvy:',
  [k.TERMS_AND_CONDITIONS_49]: 'Důsledky odvolání:',
  [k.TERMS_AND_CONDITIONS_50]:
    'Pokud odstoupíte od smlouvy, vymažeme vaši svatební aplikaci, a pokud jsme od vás již obdrželi platby, vrátíme vám tyto platby stejnou platební metodou. Za toto vrácení vám nebudeme účtovat žádné poplatky.',
  [k.TERMS_AND_CONDITIONS_51]:
    'V případě smlouvy o dodání digitálního obsahu, který není na fyzickém nosiči dat, jako je svatební aplikace, právo na odstoupení od smlouvy podle § 356 odst. 5 BGB zaniká také tehdy, pokud dodavatel zahájil plnění smlouvy poté, co uživatel 1. výslovně souhlasil s tím, aby dodavatel zahájil plnění smlouvy před uplynutím lhůty pro odstoupení od smlouvy, a 2. potvrdil, že si je vědom toho, že souhlasem se zahájením plnění smlouvy ztrácí právo na odstoupení od smlouvy.',
  [k.TERMS_AND_CONDITIONS_52]: '§ 7 Záruka a vyloučení odpovědnosti',
  [k.TERMS_AND_CONDITIONS_53]:
    "Poskytovatel poskytuje své služby 'tak, jak jsou', 'se všemi vadami' a 'tak, jak jsou k dispozici', a neposkytuje žádné výslovné ani předpokládané záruky prodejnosti, vhodnosti pro určitý účel, kvality provedení, neporušování práv ani jiné záruky v rozsahu povoleném zákonem. Neexistuje také žádná záruka, že služby poskytovatele (nebo jakákoli jejich část, funkce nebo obsah) jsou (nebo budou) úplné, v určité kvalitě, spolehlivé, bezpečné, přesné, kompatibilní s vašimi zamýšlenými aktivitami, používanými zařízeními, operačními systémy, prohlížeči nebo programy (hostů nebo hostitelů), nebo že splňují jakékoli právní požadavky, které se na vás vztahují, nebo že služby neobsahují viry, chyby nebo jiné škodlivé součásti nebo programová omezení. Kromě toho společnost EureHochzeitOnline.com nenese žádnou odpovědnost za žádnou společnost, produkt nebo službu (včetně služeb třetích stran), které jsou uvedeny nebo nabízeny na platformě EureHochzeitOnline.com. Nepřebírá žádnou odpovědnost za ztrátu dat a informací a poskytovatel nezaručuje jejich obnovení. Uživatel zálohuje svá data a informace na vlastní riziko. EureHochzeitOnline.com rovněž nepřebírá žádnou odpovědnost za obsah zveřejněný uživateli ani za správnou identitu uživatelů.",
  [k.TERMS_AND_CONDITIONS_54]: '§ 8 Doložka o oddělitelnosti',
  [k.TERMS_AND_CONDITIONS_55]:
    'Pokud bude některé ustanovení této smlouvy shledáno neplatným nebo nevymahatelným z jakéhokoli důvodu nebo v jakémkoli rozsahu, nebude mít tato neplatnost nebo nevymahatelnost vliv na ostatní ustanovení smlouvy a použití takového ustanovení bude uplatněno v plném rozsahu povoleném zákonem. Pokud se společnost EureHochzeitOnline.com vzdá svého práva na vymáhání porušení jedné nebo více podmínek, neznamená to, že se společnost EureHochzeitOnline.com vzdává i práva na vymáhání ostatních podmínek.',
  [k.TERMS_AND_CONDITIONS_56]: '§ 9 Závěrečná ustanovení',
  [k.TERMS_AND_CONDITIONS_57]:
    'Pokud zákon nestanoví jinak, řídí se smlouvy právem Spolkové republiky Německo. Výhradním místem soudní příslušnosti pro všechny spory/nároky vyplývající z těchto podmínek používání nebo v souvislosti s nimi je Mnichov. Vyhrazujeme si právo tyto Podmínky používání v budoucnu změnit a novou verzi zveřejnit na stejném místě. Po změně Podmínek používání musí být nová verze potvrzena uživatelem.',
  [k.TERMS_AND_CONDITIONS_58]: 'Stav: Podmínky užívání jsou platné od 1. ledna 2015: 01.04.2018',
  [k.IMPRINT_00]: 'Imprint/právní upozornění',
  [k.IMPRINT_01]: 'fidira Software UG (společnost s ručením omezeným)',
  [k.IMPRINT_02]: 'EureHochzeitOnline.de je ochranná známka společnosti fidira Software UG',
  [k.IMPRINT_03]: 'Enzianstr. 31',
  [k.IMPRINT_04]: '86343 Königsbrunn',
  [k.IMPRINT_05]: 'E-mail: Fidigrifa.cz, spol. s r.o:',
  [k.IMPRINT_06]: 'Web:',
  [k.IMPRINT_07]: 'https://www.eurehochzeitonline.com',
  [k.IMPRINT_08]: 'Sídlo společnosti: Mnichov, obchodní rejstřík Mnichov HRB',
  [k.IMPRINT_09]: 'Zplnomocnění zástupci: Mnichov',
  [k.PRIVACY_00]: 'Zásady ochrany osobních údajů',
  [k.PRIVACY_01]: 'Úvod a obecné informace',
  [k.PRIVACY_02]:
    'Ochrana vašeho soukromí a soukromí vašich svatebních hostů je pro EureHochzeitOnline.com nejvyšší prioritou.',
  [k.PRIVACY_03]:
    'V souladu s požadavky německého spolkového zákona o ochraně osobních údajů (BDSG) a německého zákona o telemédiích (TMG) vždy zajišťujeme, aby vaše údaje byly použity pouze pro vytvoření a používání vaší osobní svatební aplikace a pro optimalizaci uživatelského zážitku z vaší svatební aplikace. Při vytváření i používání svatebních aplikací používáme technologii TLS/SSL (Transport Layer Security / Secure Sockets Layer), takže data jsou při přenosu vždy šifrována. Všechny naše servery aplikace se nacházejí v Evropské unii a splňují předpisy EU o ochraně osobních údajů. Vaše údaje nebudeme zpracovávat nad rámec účelů popsaných v těchto Zásadách ochrany osobních údajů. během procesu registrace budete požádáni o souhlas se zpracováním údajů popsaným v těchto Zásadách ochrany osobních údajů zaškrtnutím políčka. Udělením souhlasu potvrzujete, že můžeme shromažďovat, zpracovávat a používat vaše osobní údaje v níže popsaném rozsahu. Svůj souhlas můžete kdykoli odvolat. Chcete-li tak učinit, jednoduše zašlete e-mail našemu zákaznickému servisu na adresu',
  [k.PRIVACY_04]:
    'Při registraci budete požádáni o souhlas se zpracováním popsaným v zásadách ochrany osobních údajů, který udělíte kliknutím na zaškrtávací políčko. Udělením souhlasu potvrzujete, že můžeme shromažďovat, zpracovávat a používat vaše osobní údaje v rozsahu popsaném níže. Svůj souhlas můžete také kdykoli odvolat. Chcete-li tak učinit, jednoduše zašlete e-mail našemu zákaznickému servisu na adrese',
  [k.PRIVACY_05]:
    '. Náš zákaznický servis vám na požádání rovněž poskytne informace o všech údajích, které o vás uchovává (podle § 13 odst. 7 německého zákona o telemédiích (Telemediengesetz)), a na vaši žádost tyto údaje vymaže.',
  [k.PRIVACY_06]: '§ 1 Odpovědný orgán a oblast použití',
  [k.PRIVACY_07]:
    "Odpovědným subjektem je společnost fidira Software UG (haftungsbeschränkt), dále také 'poskytovatel', 'my' nebo 'EureHochzeitOnline.com'. Podrobnosti o adrese a oprávněných zástupcích naleznete v tiráži.",
  [k.PRIVACY_08]:
    'Tyto zásady ochrany osobních údajů se vztahují na shromažďování a používání osobních údajů při návštěvě a používání některé z platforem poskytovatele prostřednictvím internetových prohlížečů. Prostřednictvím těchto nabídek poskytovatel umožňuje vytvořit individuální svatební aplikaci, pomocí které se mohou hosté informovat o svatbě a vzájemně komunikovat během svatby (např. sdílet fotografie nebo odpovídat na otázky). Poskytovatel bude osobní údaje používat také pro účely statistické analýzy a analýzy trhu. Přitom sestavuje anonymní statistiky, např. o svatbách, lokalitách, velikosti svateb nebo používání aplikace, aby mohl dále optimalizovat naši nabídku.',
  [k.PRIVACY_09]: '§ 2 Odkazy na jiné webové stránky',
  [k.PRIVACY_10]:
    'Naše webové stránky mohou obsahovat odkazy na jiné webové stránky. Nemáme kontrolu nad obsahem, na který jste po kliknutí na tyto odkazy přesměrováni, ani nad zpracováním vašich údajů, které mohou být předány třetí straně (např. vaše IP adresa). Proto nemůžeme převzít žádnou odpovědnost za obsah třetích stran a zpracování těchto údajů třetími stranami.',
  [k.PRIVACY_11]: '§ 3 Použití údajů bez registrace',
  [k.PRIVACY_12]:
    'Když navštívíte webové stránky nebo aplikaci EureHochzeitOnline.com, automaticky ukládáme některé základní informace o vaší návštěvě: zejména IP adresu, navštívené stránky a dobu trvání návštěvy. Tyto informace používáme k sestavování anonymních statistik o chování uživatelů a často používaných funkcích, abychom mohli neustále zlepšovat naši nabídku. Vaše IP adresa je v technických souborech protokolů ukládána pouze po omezenou dobu, abychom chránili naše systémy a námi vytvořené svatební aplikace před zneužitím a podvody.',
  [k.PRIVACY_13]: '§ 4 Použití údajů při registraci',
  [k.PRIVACY_14]:
    'Aby uživatel získal přístup do svatební aplikace jako host nebo aby mohl vytvořit svatební aplikaci jako hostitel, musí se zaregistrovat. Během procesu registrace se uloží e-mailová adresa uživatele, zobrazované jméno (volně volitelné, může to být i smyšlený pseudonym), kód svatby (pokud je to relevantní) a heslo (volně definovatelné). Tyto informace slouží k ochraně svatebních seznamů našich hostitelů před neoprávněným přístupem. Při vytváření svatební aplikace mohou hostitelé zadat informace o své svatbě (např. místo, čas, harmonogram), které budou viditelné pouze pro hosty dané svatby. Toho je dosaženo tak, že hostitel vygeneruje svatební kódy, které musí hosté zadat, aby měli přístup do svatební aplikace.',
  [k.PRIVACY_15]:
    'Vaši e-mailovou adresu používáme také pro účely komunikace: hostiteli mohou být například zasílána e-mailová oznámení týkající se nových registrací, dalších svatebních novinek a na e-mailovou adresu mohou být zasílány e-maily o službách EureHochzeitOnline.com (např. průzkumy spokojenosti). E-mailové adresy svatebčanů také zobrazujeme hostiteli, aby je mohl použít pro další organizaci svatby.',
  [k.PRIVACY_16]: 'Vaše e-mailová adresa nebude nikdy poskytnuta třetím stranám nebo jiným společnostem.',
  [k.PRIVACY_17]: '§ 5 Soubory cookie',
  [k.PRIVACY_18]:
    "Abychom vám mohli poskytnout co nejlepší uživatelský zážitek, používáme 'cookies' a tzv. lokální úložiště vašeho prohlížeče. To nám umožňuje ukládat malé množství údajů o vaší svatební aplikaci ve vašem prohlížeči, aby se například neztratily vaše změny, když zavřete prohlížeč, přepnete do celoobrazovkového režimu konfigurátoru aplikace nebo abyste se nemuseli pokaždé přihlašovat. Pokud nechcete používat soubory cookie nebo chcete odstranit stávající soubory cookie, můžete je zakázat nebo odstranit prostřednictvím prohlížeče. Podívejte se do pokynů prohlížeče, jak to provést, nebo nás kontaktujte a požádejte o pomoc.",
  [k.PRIVACY_19]:
    "Soubory cookie používáme také pro služby třetích stran uvedené níže v '§ 7 Aplikace a webové stránky', abychom vám poskytli lepší uživatelský komfort.",
  [k.PRIVACY_20]: '§ 6 Aplikace a webové stránky',
  [k.PRIVACY_21]: 'Mapy Google',
  [k.PRIVACY_22]:
    'K zobrazování interaktivních map používáme Mapy Google od společnosti Google Inc, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. Vaše IP adresa, vaše poloha a informace o vašem používání našich služeb budou přenášeny a ukládány společností Google na serverech ve Spojených státech. Podmínky používání Map Google naleznete na adrese ',
  [k.PRIVACY_23]: 'https://www.google.com/intl/de_de/help/terms_maps.html',
  [k.PRIVACY_24]: 'HotJar',
  [k.PRIVACY_25]:
    "Ke shromažďování a ukládání dat pro marketingové a optimalizační účely používáme také HotJar, nástroj pro webovou analytiku od společnosti Hotjar Ltd. Data mohou být použita k vytvoření pseudonymních uživatelských profilů. zaznamenávají se náhodně vybrané jednotlivé návštěvy (bez dalších osobních údajů, tzv. 'uživatelské atributy'). vytváří se tak záznam pohybů myši a kliknutí se záměrem náhodně přehrát jednotlivé návštěvy webových stránek a odvodit případná zlepšení webových stránek. Shromážděné údaje nebudou použity k osobní identifikaci návštěvníka těchto webových stránek, ani nebudou sloučeny s osobními údaji o nositeli pseudonymu bez samostatného souhlasu dotyčné osoby. pokud nechcete být sledováni, můžete globálně zakázat sledování na všech webových stránkách, které používají HotJar pro prohlížeč, který právě používáte, kliknutím na následující odkaz",
  [k.PRIVACY_26]: 'https://www.hotjar.com/privacy/do-not-track/',
  [k.PRIVACY_27]: 'Mailchimp',
  [k.PRIVACY_28]:
    'K rozesílání našeho newsletteru používáme službu Mailchimp od společnosti The Rocket Science Group, LLC, 675 Ponce de Leon Ave NE Suite 5000, Atlanta, GA 30308 USA (Mailchimp). To nám umožňuje kontaktovat odběratele přímo. Kromě toho analyzujeme vaše chování při používání, abychom mohli optimalizovat naši nabídku.',
  [k.PRIVACY_29]: 'Za tímto účelem sdílíme se společností Mailchimp následující osobní údaje',
  [k.PRIVACY_30]: 'E-mailová adresa [Křestní jméno] [Datum svatby] [Informace o svatbě] [Přihlašovací údaje].',
  [k.PRIVACY_31]:
    'Naše e-mailové rozesílky budou obsahovat odkaz, který vám umožní aktualizovat vaše osobní údaje. Společnost Mailchimp je příjemcem vašich osobních údajů a jedná naším jménem jako zpracovatel, pokud jde o rozesílání našeho newsletteru. Zpracování údajů popsaných v této části není vyžadováno zákonem ani smlouvou. Bez vašeho souhlasu a poskytnutí vašich osobních údajů vám newsletter nemůžeme zasílat. Kromě toho společnost Mailchimp shromažďuje následující osobní údaje pomocí souborů cookie a dalších metod sledování Informace o vašem zařízení (IP adresa, informace o zařízení, operační systém, ID prohlížeče, informace o aplikaci, kterou používáte ke čtení e-mailů, a další informace o vašem hardwaru a internetovém připojení. Kromě toho jsou shromažďovány údaje o používání, jako je datum a čas otevření e-mailu/kampaň a aktivita prohlížeče (např. které e-maily/stránky byly otevřeny). Společnost Mailchimp potřebuje tyto informace k zajištění bezpečnosti a spolehlivosti systémů, k prosazování podmínek služby a k prevenci zneužití. To odpovídá oprávněnému zájmu společnosti Mailchimp (podle čl. 6 odst. 1 písm. f GDPR) a slouží k plnění smlouvy (podle čl. 6 odst. 1 písm. b GDPR). Mailchimp také analyzuje údaje o výkonu, jako jsou statistiky doručení e-mailů a další údaje o komunikaci. Tyto informace slouží k sestavování statistik o využívání a výkonu služeb. Společnost Mailchimp o vás shromažďuje informace také z jiných zdrojů. Čas od času a v neurčitém rozsahu jsou osobní údaje shromažďovány prostřednictvím sociálních médií a dalších poskytovatelů údajů třetích stran. Nad těmito procesy nemáme žádnou kontrolu. ',
  [k.PRIVACY_32]: 'Další informace o vašich možnostech volby a odstranění u společnosti Mailchimp naleznete na adrese',
  [k.PRIVACY_33]: 'https://mailchimp.com/legal/privacy/#3._Privacy_for_Contacts',
  [k.PRIVACY_34]:
    'The právním základem pro toto zpracování je váš souhlas podle čl. 6 odst. 1 písm. a GDPR. svůj souhlas se zpracováním osobních údajů můžete kdykoli odvolat. odkaz, jak tak učinit, najdete ve všech zásilkách. svůj souhlas můžete také odvolat pomocí uvedených kontaktních možností. Odvolání nemá vliv na zákonnost již provedeného zpracování.',
  [k.PRIVACY_35]:
    'Vaše údaje budou zpracovávány po dobu, po kterou jste souhlas udělili. V opačném případě budou vymazány po ukončení smlouvy mezi námi a společností Mailchimp, pokud není podle právních předpisů nutné jejich další uchovávání.',
  [k.PRIVACY_36]:
    'Společnost Mailchimp zavedla opatření pro zajištění souladu s předpisy pro mezinárodní přenosy údajů. Tato opatření se vztahují na všechny globální operace, při nichž Mailchimp zpracovává osobní údaje osob v EU. Tato opatření vycházejí ze standardních smluvních doložek EU (SCC). Další informace naleznete na adrese',
  [k.PRIVACY_37]: 'https://mailchimp.com/legal/data-processing-addendum/',
  [k.PRIVACY_38]: 'Spotify',
  [k.PRIVACY_39]:
    'Používáme také Spotify Music Player Widget, hudební přehrávač od společnosti Spotify AB, který umožňuje poslech svatebního playlistu. Poskytovatelem služby Spotify Music Player Widget je společnost Spotify AB, Regeringsgatan 19, 111 53 Stockholm, Švédsko. Další informace o zpracování údajů společností Spotify AB naleznete na adrese',
  [k.PRIVACY_40]: 'https://www.spotify.com/de/legal/privacy-policy/',
  [k.PRIVACY_41]: 'Facebook Pixel',
  [k.PRIVACY_42]:
    "Za účelem vylepšení stránek EureHochzeitOnline.com a jejich zpřístupnění větší skupině uživatelů používáme Facebook Pixel společnosti Facebook Inc, 1601 South California Avenue, Palo Alto, CA 94304, USA ('Facebook'). Za tímto účelem Facebook umístí do vašeho prohlížeče soubor cookie. Informace vygenerované tímto souborem cookie o vašem používání těchto webových stránek budou přeneseny a uloženy společností Facebook na serverech ve Spojených státech. Facebook může pomocí tohoto přenosu dat určit, že konkrétní stránka byla navštívena z vašeho prohlížeče a zda se tak stalo kliknutím na reklamu. Tyto údaje však nepoužíváme ke shromažďování informací z našich stránek. Facebook tyto informace využívá k tomu, aby nám pomohl vytvářet další reklamy a propagovat stránky EureHochzeitOnline.com. Údaje získané z Facebook pixelu vyhodnocujeme v analytickém nástroji Facebooku 'Facebook Analytics', abychom mohli stránky EureHochzeitOnline.com dále zlepšovat. Facebook může tyto informace předat třetím stranám, pokud to vyžaduje zákon nebo pokud tyto třetí strany zpracovávají informace jménem Facebooku. Používáním stránek EureHochzeitOnline.com souhlasíte se zpracováním údajů o vás společností Facebook popsaným způsobem a za účelem zlepšování stránek EureHochzeitOnline.com. Se společností Facebook nesdílíme žádné vaše osobní, kontaktní ani svatební údaje. Více informací o zásadách zabezpečení a ochrany osobních údajů Facebook Pixel naleznete na adrese",
  [k.PRIVACY_43]: 'https://www.facebook.com/policy.php',
  [k.PRIVACY_44]: 'Shromažďování informací prostřednictvím služby Facebook Pixel můžete také odmítnout kliknutím zde:',
  [k.PRIVACY_45]: 'Deaktivovat Pixel',
  [k.PRIVACY_46]: '§ 7 Právo na informace a další práva podle BDSG',
  [k.PRIVACY_47]:
    'Kdykoli vám rádi poskytneme informace o vašich osobních údajích, které uchováváme. Na vaši žádost vaše údaje také opravíme, zablokujeme nebo vymažeme. S těmito žádostmi nebo s prohlášením o odvolání se na nás můžete kdykoli obrátit na adrese',
  [k.PRIVACY_48]: 'a my provedeme změny co nejdříve. ',
  [k.PRIVACY_49]: '§ 8 Změny prohlášení o ochraně osobních údajů',
  [k.PRIVACY_50]:
    'Internet a technické možnosti se neustále vyvíjejí. Je samozřejmé, že tyto nové možnosti využijeme k tomu, aby svatby našich uživatelů byly ještě nezapomenutelnější. to nás může vést k aktualizaci a změnám našeho prohlášení o ochraně osobních údajů. tyto aktualizace zde zveřejníme.',
  [k.PRIVACY_51]: 'Poslední aktualizace: 11/28/2022',
  [k.IF_YOU_NEED_MORE_LANGUAGES]: 'Pokud potřebujete více jazyků, napište nám e-mail.',
  [k.DECIDE_LANGUAGES]:
    'Rozhodněte se, které jazyky by měly vaše svatební webové stránky podporovat. Můžete zadat své texty pro všechny vybrané jazyky.',
  [k.EDIT_LANGUAGES]: 'Upravit jazyky',
  [k.ACTIVE_VOUCHER]: `Aktivní voucher:`,
  [k.ENTER_VOUCHER_CODE]: `Zadejte kód kupónu`,
  [k.VOUCHER_CODE]: `Slevový kód`,
  [k.INVALID_CODE]: `Bohužel žádný platný kód!`,
  [k.WANT_CUSTOM_LOGO]: `Chcete použít vlastní svatební logo?`,
  [k.INDIVIDUAL_LOGO]: `Individuální logo`,
  [k.STANDARD_LOGO]: `Standardní logo`,
  [k.PRIVATE_PURCHASE]: `Nákup je soukromý`,
  [k.COMMERCIAL_PURCHASE]: `Nákup je komerční`,
  [k.MAX_GUESTS]: 'Bezplatná verze: Maximální počet hostů',
  [k.MAX_GUESTS_REACHED]:
    'Tato svatba stále používá bezplatnou základní verzi EureHochzeitOnline.com, ale nyní má více než pět registrovaných hostů. Podle našich podmínek použití je nutné upgradovat na kompletní nebo prémiový balíček. Proto prosím upgradujte svůj balíček, abyste mohli svůj svatební web nadále používat jako obvykle.',
  [k.ACTIVATE_PACKAGE]: 'Odemknout balíček',
  [k.IF_MESSAGE_WRONGFUL]:
    'Pokud se domníváte, že tato zpráva je zobrazena nesprávně, pošlete nám prosím e-mail na adresu:',
};
