import styled from 'styled-components';
import { DialogTitle } from '@material-ui/core';

import breakpoints from '../../../breakpoints';

export default styled(DialogTitle)`
  text-align: center;
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.mobileKeyboardHeight}) {
    padding: 12px;
  }
`;
