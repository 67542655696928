import i18n from 'i18next';
import k from './../../../i18n/keys';
import React from 'react';
import styled from 'styled-components';
import SectionContainer from '../Components/SectionContainer';
import SectionTextContainer from '../Components/SectionTextContainer';
import SectionTitle from '../Components/SectionTitle';
import SectionSubTitle from '../Components/SectionSubTitle';
import defaultStyles from '../../../Shared/WeddingStyles/DefaultStyles';
import IconBox from '../../../Shared/Components/SelectionComponent/IconBox';
import { NavLink, useHistory } from 'react-router-dom';
import boxShadows from '../../../Shared/boxShadows';
import { Button, Hidden } from '@material-ui/core';
import breakpoints from '../../../Shared/breakpoints';

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  min-height: 900px;
  background-color: #fde1e5;
  padding-bottom: 50px;
  box-shadow: ${boxShadows.z2};
  margin-bottom: 12px;
  ul {
    width: 60%;
    padding-inline-start: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: ${breakpoints.md}) {
      max-width: 430px;
    }
    @media (max-width: ${breakpoints.sm}) {
      margin-top: 200px;
      z-index: 1;
      width: 100%;
    }
  }
`;

const DesignContainer = styled.li`
  display: flex;
  min-height: 200px;
  margin: 18px 0;
  background-color: #ffffffbf;
  box-shadow: ${boxShadows.z1};
  padding: 6px 12px 6px 6px;
  cursor: pointer;
  border-radius: 0 12px 12px 0;
  @media (max-width: ${breakpoints.sm}) {
    max-width: 210px;
  }
  > div {
    min-width: 200px;
    margin-right: 21px;
    border-radius: 0 12px 12px 0;
    .image-in-icon-container {
      height: 100%;
      width: 100%;
      border-radius: 0 12px 0 0;
    }
  }
  h3 {
    font-weight: bold;
    margin-bottom: 3px;
  }
  p {
    margin-top: 6px;
  }
`;

const SideImage = styled.div`
  position: relative;
  background-image: url('/assets/landing-page/hochzeits-website-designs.jpg');
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 35%;
  margin-left: auto;
  margin: 36px 0 36px auto;
  box-shadow: ${boxShadows.z1};
  border-radius: 12px 0 0 12px;
  @media (max-width: ${breakpoints.sm}) {
    position: absolute;
    top: 200px;
    left: 0;
    right: 0;
    bottom: -100px;
    width: 100%;
  }
`;

const ImageText = styled.div`
  background-color: #ba5a5e99;
  width: 70%;
  margin-left: auto;
  max-width: 350px;
  border-radius: 12px 0 0 12px;
  box-shadow: ${boxShadows.z2};
  h3 {
    padding: 6px 12px;
    font-weight: bold;
    margin-bottom: -6px;
  }
  p {
    color: white;
    padding: 6px 12px;
    font-weight: bold;
    margin-top: -6px;
  }
`;

const SectionInnerContainer = styled.div`
  display: flex;
`;

const DesignSection = ({ search }) => {
  const history = useHistory();
  const navigateToCreation = design => {
    history.push(`/hochzeit-erstellen/1${search}&style=${design}`);
  };

  return (
    <Container>
      <SectionContainer alignment="right">
        <SectionTextContainer alignment="right">
          <SectionTitle>{i18n.t(k.PERFECT_PAGE_FOR_YOU)}</SectionTitle>
          <SectionSubTitle>{i18n.t(k.PERFECT_DESIGN_IN_FEW_CLICKS)}</SectionSubTitle>
          <div>
            <div></div>
            <SectionInnerContainer>
              <ul>
                {Object.keys(defaultStyles)
                  .filter(key => key !== 'eurehochzeitonline')
                  .map(key => {
                    const title = i18n.t(k[`DEFAULT_STYLES_${defaultStyles[key].type.toUpperCase()}_TITLE`]);
                    const description = i18n.t(
                      k[`DEFAULT_STYLES_${defaultStyles[key].type.toUpperCase()}_DESCRIPTION`]
                    );
                    return (
                      <DesignContainer onClick={() => navigateToCreation(key)} key={key}>
                        <IconBox
                          image={defaultStyles[key].assets.mood}
                          text={title}
                          question="weddingStyle"
                          selectValue={key}
                        />

                        <Hidden smDown>
                          <div>
                            <h3>
                              {title}
                              {i18n.t(k.DESIGN)}
                            </h3>
                            <Hidden mdDown>
                              <p>{description}</p>
                            </Hidden>
                          </div>
                        </Hidden>
                      </DesignContainer>
                    );
                  })}
              </ul>
              <SideImage>
                <ImageText>
                  <h3>{i18n.t(k.HOW_DESCRIBE_YOUR_DREAM_WEDDING)}</h3>
                  <p>{i18n.t(k.CLICK_ON_ONE_OF_THE_IMAGES)}</p>
                </ImageText>
              </SideImage>
            </SectionInnerContainer>
          </div>
          <NavLink exact to={{ pathname: `/hochzeit-erstellen/1${search}` }}>
            <Button color="primary" variant="contained">
              {i18n.t(k.CREATE_WEDDING_WEBSITE_NOW)}
            </Button>
          </NavLink>
        </SectionTextContainer>
      </SectionContainer>
    </Container>
  );
};

export default DesignSection;
