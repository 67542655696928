import React from 'react';
import styled from 'styled-components';

import BackgroundElementContainer from '../../Components/BackgroundElementContainer/BackgroundElementContainer';
import EditingContainerForBaseData from '../../../Pages/WeddingPage/Editing/Components/EditingContainer/EditingContainerForBaseData';

import defaultStyles from '../DefaultStyles';

import WeddingDate from './Components/WeddingDate';
import TopCenter from './Components/TopCenter';
import HorizontalLine from './Components/HorizontalLine';
import Heart from './Components/Heart';
import Headline from './Components/Headline';
import Countdown from '../Components/Countdown';

const Container = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  overflow: hidden;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
  background-image: ${`radial-gradient(circle at 50% 80%, ${defaultStyles.klassisch.background.colors[1]}, ${defaultStyles.klassisch.background.colors[0]})`};
`;

const CustomLogo = styled.div`
  height: 350px;
  max-height: 50vh;
  width: 600px;
  max-width: 70vw;
  margin: auto;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const WeddingTitleInnerContainer = styled.div`
  position: relative;
  width: 600px;
  max-width: 90vw;
`;

const KlassischAppBackground = ({ showStartPage, weddingConfig }) => {
  const { nameOfBride, nameOfGroom, weddingDate } = weddingConfig;
  return (
    <Container visible>
      <BackgroundElementContainer>
        {showStartPage && (
          <TopCenter visible>
            <Countdown weddingConfig={weddingConfig} weddingStyle={weddingConfig.weddingStyle}></Countdown>
            {weddingConfig.useCustomLogo ? (
              <WeddingTitleInnerContainer>
                <CustomLogo imageUrl={weddingConfig.customLogoUrl}></CustomLogo>
                <EditingContainerForBaseData></EditingContainerForBaseData>
              </WeddingTitleInnerContainer>
            ) : (
              <>
                <Heart />
                <WeddingTitleInnerContainer>
                  {nameOfBride && nameOfGroom && (
                    <>
                      <Headline>{nameOfBride}</Headline>
                      <Headline>&amp;</Headline>
                      <Headline>{nameOfGroom}</Headline>
                      <HorizontalLine />
                    </>
                  )}

                  <EditingContainerForBaseData>
                    <WeddingDate>{weddingDate}</WeddingDate>
                  </EditingContainerForBaseData>
                </WeddingTitleInnerContainer>
              </>
            )}
          </TopCenter>
        )}
      </BackgroundElementContainer>
    </Container>
  );
};

export default KlassischAppBackground;
