import React, { useContext, useState } from 'react';
import i18n from 'i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import GenericStyleContainer from '../../../../../Shared/WeddingStyles/GenericStyleContainer';
import defaultStyles from '../../../../../Shared/WeddingStyles/DefaultStyles';
import styled from 'styled-components';
import WeddingContext from '../../../../../Shared/Context/WeddingContext';
import k from '../../../../../i18n/keys';

const ErrorText = styled.p`
  color: darkred !important;
  font-size: 14px !important;
  font-weight: bold;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
`;

const VoucherDialog = ({ setAppliedVoucher, setShowVoucherDialog, showVoucherDialog }) => {
  const [currentValue, setCurrentValue] = useState('');
  const [showError, setShowError] = useState(false);
  const weddingContext = useContext(WeddingContext);
  const weddingStyle = weddingContext ? weddingContext.weddingConfig.weddingStyle : 'eurehochzeitonline';

  const handleSave = () => {
    if (currentValue === 'REGEN') {
      setAppliedVoucher({
        code: currentValue,
        discountInEuro: 15,
      });
      setShowVoucherDialog(false);
    } else {
      setShowError(true);
    }
  };

  const handleChange = value => {
    setCurrentValue(value);
    setShowError(false);
  };

  return (
    <Dialog
      open={showVoucherDialog}
      onClose={() => {
        setCurrentValue('');
        setShowError(false);
        setShowVoucherDialog(false);
      }}
      disableEscapeKeyDown
    >
      <GenericStyleContainer weddingStyleData={defaultStyles[weddingStyle]}>
        <DialogTitle className="color-background-white">
          <span>{i18n.t(k.ENTER_VOUCHER_CODE)}</span>
        </DialogTitle>
        <DialogContent className="color-background-white">
          <TextField
            type="text"
            variant="filled"
            value={currentValue}
            onChange={event => handleChange(event.target.value)}
            label={i18n.t(k.VOUCHER_CODE)}
          />
          <ErrorText>{showError && i18n.t(k.INVALID_CODE)}</ErrorText>
        </DialogContent>
        <DialogActions className="color-background-white">
          <Button
            onClick={() => {
              setCurrentValue('');
              setShowError(false);
              setShowVoucherDialog(false);
            }}
            color="secondary"
            variant="contained"
          >
            {i18n.t(k.CANCEL)}
          </Button>
          <Button
            onClick={() => {
              handleSave();
            }}
            color="primary"
            variant="contained"
            autoFocus
          >
            {i18n.t(k.SAVE)}
          </Button>
        </DialogActions>
      </GenericStyleContainer>
    </Dialog>
  );
};

export default VoucherDialog;
