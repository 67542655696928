import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { StylesProvider } from '@material-ui/core/styles';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import Amplify, { Auth } from 'aws-amplify';
import WebFont from 'webfontloader';
import axios from 'axios';

import './App.css';

import LanguageContext from './Shared/Context/LanguageContext';
import UserContext from './Shared/Context/UserContext';
import WeddingPage from './Pages/WeddingPage/WeddingPage';
import OnboardingPage from './Pages/OnboardingPage/OnboardingPage';
import MailConfirmationPage from './Pages/MailConfirmationPage/MailConfirmationPage';
import getFidiraUserByUsername from './Shared/FunctionUtils/getFidiraUserByUsername';
import isProduction from './Shared/FunctionUtils/isProduction';
import ImprintPage from './Pages/LegalPages/ImprintPage';
import PrivacyPage from './Pages/LegalPages/PrivacyPage';
import TermsAndConditionsPage from './Pages/LegalPages/TermsAndConditionsPage';
import ChangePasswordPage from './Pages/ChangePasswordPage/ChangePasswordPage';
import WeddingNotFoundPage from './Pages/WeddingPage/WeddingNotFoundPage/WeddingNotFoundPage';
import SpotifyLoginPage from './Pages/SpotifyLoginPage/SpotifyLoginPage';
import SpotifyPostLoginPage from './Pages/SpotifyLoginPage/SpotifyPostLoginPage';
import LandingPage from './Pages/LandingPage/LandingPage';
import CookieConsentDialog from './Shared/Components/CookieConsentDialog/CookieConsentDialog';
import getSearchParamsAsObject from './Shared/FunctionUtils/getSearchParamsAsObject';
import i18next from 'i18next';
import getRoleOfUserInWedding from './Shared/FunctionUtils/getRoleOfUserInWedding';

Amplify.configure({
  Auth: {
    mandatorySignId: true,
    region: 'eu-west-1',
    userPoolId:
      window.location.origin === process.env.REACT_APP_PROD_URL
        ? process.env.REACT_APP_COGNITO_USER_POOL_PROD
        : process.env.REACT_APP_COGNITO_USER_POOL_TEST,
    userPoolWebClientId:
      window.location.origin === process.env.REACT_APP_PROD_URL
        ? process.env.REACT_APP_COGNITO_APP_CLIENT_ID_PROD
        : process.env.REACT_APP_COGNITO_APP_CLIENT_ID_TEST,
  },
});

const configureAxiosDefaults = () => {
  let baseURL =
    window.location.origin === process.env.REACT_APP_PROD_URL
      ? `${process.env.REACT_APP_PROD_URL}/api/`
      : process.env.REACT_APP_API_URL;
  // Use test backend instead of local backend if specific cookie is set and location is localhost.
  const useTestBackend = document.cookie.replace(/(?:(?:^|.*;\s*)use-test-backend\s*=\s*([^;]*).*$)|^.*$/, '$1');
  if (window.location.hostname === 'localhost' && useTestBackend === 'true') {
    baseURL = process.env.REACT_APP_API_TEST_URL;
    axios.defaults.headers.common['X-Fidira-Test-Request'] = process.env.REACT_APP_FIDIRA_TEST_REQUEST_TOKEN;
  }
  axios.defaults.baseURL = baseURL;
};

function App() {
  const [currentLanguage, setCurrentLanguage] = useState(i18next.language);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);
  const [cookiePreference, setCookiePreference] = useState(null);
  const [utmMedium, setUtmMedium] = useState(null);
  const [utmSource, setUtmSource] = useState(null);
  const [utmCampaign, setUtmCampaign] = useState(null);
  const [utmContent, setUtmContent] = useState(null);
  const [utmTerm, setUtmTerm] = useState(null);

  const changeLanguage = async (lng, weddingId) => {
    localStorage.setItem('lng', lng);
    setCurrentLanguage(lng);
    await i18next.changeLanguage(lng);
    if (loggedInUser) {
      await axios.patch(`user-update/`, {
        updateType: 'UPDATE_LANGUAGE_OF_USER',
        username: loggedInUser.fidiraUser.username,
        language: lng,
        role: weddingId ? getRoleOfUserInWedding(loggedInUser.fidiraUser?.weddings, weddingId) : null,
      });
    }
  };

  const setAuthenticatedUser = async (cognitoUser, fidiraUser) => {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    axios.defaults.headers.common['Authorization'] = token;
    setLoggedInUser({ cognitoUser: cognitoUser, fidiraUser: fidiraUser });
    ReactGA.set({
      userId: fidiraUser.sub,
    });
  };
  const logOutCurrentUser = async () => {
    await Auth.signOut();
    setLoggedInUser(null);
  };

  const scrollToTop = () => {
    window.lastScrollPosition = 0;
  };

  const setCookiePreferenceFromCookies = () => {
    const preference = document.cookie.replace(/(?:(?:^|.*;\s*)cookie-preference\s*=\s*([^;]*).*$)|^.*$/, '$1');
    setCookiePreference(preference);
    return preference;
  };

  useEffect(() => {
    configureAxiosDefaults();
    WebFont.load({
      custom: {
        families: ['Great Vibes', 'Poppins', 'Amatic SC', 'Quicksand', 'Sofia'],
      },
    });
    const cookiePreference = setCookiePreferenceFromCookies();
    const {
      utm_medium: utmMediumInUrl,
      utm_source: utmSourceInUrl,
      utm_campaign: utmCampaignInUrl,
      utm_content: utmContentInUrl,
      utm_term: utmTermInUrl,
    } = getSearchParamsAsObject(window.location.search);
    if (utmMediumInUrl) {
      const date = new Date().toISOString();
      if (cookiePreference === 'ALL') {
        document.cookie = `medium=${utmMediumInUrl}_${date};path=/;max-age=31536000`;
      }
      setUtmMedium(`${utmMediumInUrl}_${date}`);
    } else {
      const utmMediumInCookie = document.cookie.replace(/(?:(?:^|.*;\s*)medium\s*=\s*([^;]*).*$)|^.*$/, '$1');
      if (utmMediumInCookie) {
        setUtmMedium(utmMediumInCookie);
      }
    }
    if (utmSourceInUrl) {
      if (cookiePreference === 'ALL') {
        document.cookie = `source=${utmSourceInUrl};path=/;max-age=31536000`;
      }
      setUtmSource(`${utmSourceInUrl}`);
    } else {
      const utmSourceInCookie = document.cookie.replace(/(?:(?:^|.*;\s*)source\s*=\s*([^;]*).*$)|^.*$/, '$1');
      if (utmSourceInCookie) {
        setUtmSource(utmSourceInCookie);
      }
    }
    if (utmCampaignInUrl) {
      if (cookiePreference === 'ALL') {
        document.cookie = `campaign=${utmCampaignInUrl};path=/;max-age=31536000`;
      }
      setUtmCampaign(`${utmCampaignInUrl}`);
    } else {
      const utmCampaignInCookie = document.cookie.replace(/(?:(?:^|.*;\s*)campaign\s*=\s*([^;]*).*$)|^.*$/, '$1');
      if (utmCampaignInCookie) {
        setUtmCampaign(utmCampaignInCookie);
      }
    }
    if (utmContentInUrl) {
      if (cookiePreference === 'ALL') {
        document.cookie = `content=${utmContentInUrl};path=/;max-age=31536000`;
      }
      setUtmContent(`${utmContentInUrl}`);
    } else {
      const utmContentInCookie = document.cookie.replace(/(?:(?:^|.*;\s*)content\s*=\s*([^;]*).*$)|^.*$/, '$1');
      if (utmContentInCookie) {
        setUtmContent(utmContentInCookie);
      }
    }
    if (utmTermInUrl) {
      if (cookiePreference === 'ALL') {
        document.cookie = `term=${utmTermInUrl};path=/;max-age=31536000`;
      }
      setUtmTerm(`${utmTermInUrl}`);
    } else {
      const utmTermInCookie = document.cookie.replace(/(?:(?:^|.*;\s*)term\s*=\s*([^;]*).*$)|^.*$/, '$1');
      if (utmTermInCookie) {
        setUtmTerm(utmTermInCookie);
      }
    }
    setLoadingUser(true);
    async function checkIfUserIsLoggedIn() {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        if (cognitoUser) {
          const session = await Auth.currentSession();
          const token = session.getIdToken().getJwtToken();
          axios.defaults.headers.common['Authorization'] = token;
          const fidiraUser = await getFidiraUserByUsername(cognitoUser.attributes.email);
          setLoggedInUser({ cognitoUser: cognitoUser, fidiraUser: fidiraUser });
          ReactGA.set({
            userId: fidiraUser.sub,
          });
        } else {
          setLoggedInUser({ cognitoUser: null, fidiraUser: null });
        }
        setLoadingUser(false);
      } catch (error) {
        setLoadingUser(false);
      }
    }
    checkIfUserIsLoggedIn();
  }, []);

  useEffect(() => {
    if (cookiePreference === 'ALL') {
      if (isProduction()) {
        ReactPixel.init('676421527776908', {
          autoConfig: true,
          debug: false,
        });
        ReactGA.initialize('G-S6K44G5F5B');
      } else {
        ReactPixel.init('856247466204653', {
          autoConfig: true,
          debug: true,
        });
      }
    }
  }, [cookiePreference]);

  const changeCookiePreferenceInCookies = preference => {
    document.cookie = `cookie-preference=${preference};path=/;max-age=31536000`;
    setCookiePreference(preference);
  };

  return (
    <div className="App">
      <StylesProvider injectFirst>
        <LanguageContext.Provider
          value={{
            currentLanguage: currentLanguage,
            setCurrentLanguage: changeLanguage,
          }}
        >
          {!loadingUser && (
            <UserContext.Provider
              value={{
                fidiraUser: loggedInUser ? loggedInUser.fidiraUser : null,
                cognitoUser: loggedInUser ? loggedInUser.cognitoUser : null,
                setAuthenticatedUser: setAuthenticatedUser,
                logOutCurrentUser: logOutCurrentUser,
              }}
            >
              <CookieConsentDialog
                open={!cookiePreference}
                saveCookieConsent={changeCookiePreferenceInCookies}
              ></CookieConsentDialog>
              <BrowserRouter>
                <Switch>
                  <Route
                    exact
                    path={['/']}
                    render={() => {
                      return <LandingPage cookiePreference={cookiePreference} />;
                    }}
                  />
                  <Route
                    path={['/hochzeit-erstellen/']}
                    render={() => {
                      return (
                        <OnboardingPage
                          cookiePreference={cookiePreference}
                          utmMedium={utmMedium}
                          utmCampaign={utmCampaign}
                          utmContent={utmContent}
                          utmTerm={utmTerm}
                          utmSource={utmSource}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path={['/f/mail-bestaetigen']}
                    render={() => {
                      return <MailConfirmationPage cookiePreference={cookiePreference} />;
                    }}
                  />
                  <Route
                    exact
                    path={['/f/hochzeit-nicht-gefunden']}
                    render={() => {
                      return <WeddingNotFoundPage />;
                    }}
                  />
                  <Route
                    exact
                    path={['/f/impressum']}
                    render={() => {
                      return <ImprintPage />;
                    }}
                  />
                  <Route
                    exact
                    path={['/f/datenschutz']}
                    render={() => {
                      return <PrivacyPage />;
                    }}
                  />
                  <Route
                    exact
                    path={['/f/nutzungsbedingungen']}
                    render={() => {
                      return <TermsAndConditionsPage />;
                    }}
                  />
                  <Route
                    exact
                    path={['/f/passwort-vergessen']}
                    render={() => {
                      return <ChangePasswordPage />;
                    }}
                  />
                  <Route
                    exact
                    path={['/f/spotify-login']}
                    render={() => {
                      return <SpotifyLoginPage />;
                    }}
                  />
                  <Route
                    exact
                    path={['/f/spotify-post-login']}
                    render={() => {
                      return <SpotifyPostLoginPage />;
                    }}
                  />
                  <Route
                    path={['/:weddingId/']}
                    render={params => {
                      return (
                        <WeddingPage
                          weddingId={params.match.params.weddingId}
                          fidiraUser={loggedInUser ? loggedInUser.fidiraUser : null}
                          scrollToTop={scrollToTop}
                          cookiePreference={cookiePreference}
                        ></WeddingPage>
                      );
                    }}
                  />
                </Switch>
              </BrowserRouter>
            </UserContext.Provider>
          )}
        </LanguageContext.Provider>
      </StylesProvider>
    </div>
  );
}

export default App;
