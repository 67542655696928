import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import adjustFontSize from './FontAdjustment';
import breakpoints from '../breakpoints';

const GlobalStyle = createGlobalStyle`
  .MuiPopover-root { 
    .MuiPickersToolbar-toolbar {
      background-color: ${({ weddingStyleData }) => weddingStyleData.colors.secondary.light};
    } 
    .MuiPickersDay-daySelected {
      background-color: ${({ weddingStyleData }) => weddingStyleData.colors.secondary.light};
    }
  }
  .MuiButton-containedPrimary {
      background-color: ${({ weddingStyleData }) => weddingStyleData.colors.button.primaryColor};
      color: ${({ weddingStyleData }) => weddingStyleData.colors.button.primaryColor_T};
  }
  .MuiButton-containedPrimary:hover {
      background-color: ${({ weddingStyleData }) => weddingStyleData.colors.button.primaryColorOnHover};
      color: ${({ weddingStyleData }) => weddingStyleData.colors.button.primaryColorOnHover_T};
  }
  .MuiDialogTitle-root {
    h2 {
      font-size: ${({ weddingStyleData }) => adjustFontSize(weddingStyleData.bodyFont, '20px')};
      font-weight: bold;
      color: ${({ weddingStyleData }) => weddingStyleData.colors.primary.onWhite};
    }
  }
  .MuiDialogContent-root {
    .MuiTypography-colorTextSecondary {
      color: rgba(0, 0, 0, 0.87);
    }
    p {
      font-size: ${({ weddingStyleData }) => adjustFontSize(weddingStyleData.bodyFont, '16px')};
    }
    a {
      color: ${({ weddingStyleData }) => weddingStyleData.colors.primary.onWhite};

    }
  }
  .Mui-error.MuiFormHelperText-root.MuiFormHelperText-contained {
    background-color: rgba(255, 255, 255, 0.62);
    border-radius: 3px;
    padding: 3px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
  }
  .MuiDialog-paperScrollPaper {
    @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.mobileKeyboardHeight}) {
    max-height: calc(100% - 100px);
    }
  }
  .MuiDialog-paper {
    @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.mobileKeyboardHeight}) {
    margin: 20px;
    }
  }
  .MuiTouchRipple-child {
    background-color: rgba(0, 0, 0, 0.62);
  }
  .MuiTypography-root {
    font-family: ${({ weddingStyleData }) => weddingStyleData.bodyFont};
    font-size: ${({ weddingStyleData }) => adjustFontSize(weddingStyleData.bodyFont, '18px')};
    @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
      font-size: ${({ weddingStyleData }) => adjustFontSize(weddingStyleData.bodyFont, '14px')};
    }
  }
  .MuiPickersYear-root.MuiTypography-colorPrimary, .MuiPickersYear-root:focus, .MuiPickersDay-current{
    color: ${({ weddingStyleData }) => weddingStyleData.colors.secondary.light};
  }
  .MuiPickersDay-daySelected{
    color: #ffffff !important;
  }
  .google-place-results {
    span {
      font-size: ${({ weddingStyleData }) => adjustFontSize(weddingStyleData.bodyFont, '16px')};
      font-family: ${({ weddingStyleData }) => weddingStyleData.bodyFont};
      color: #333333;
    }
  }
  a {
    color: ${({ weddingStyleData }) => weddingStyleData.colors.secondary.onWhite};
  }
  div,
  p,
  span, 
  .MuiTableCell-root,
  .MuiMenuItem-root {
    font-size: ${({ weddingStyleData }) => adjustFontSize(weddingStyleData.bodyFont, '18px')};
    @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
      font-size: ${({ weddingStyleData }) => adjustFontSize(weddingStyleData.bodyFont, '14px')};
    }
    font-family: ${({ weddingStyleData }) => weddingStyleData.bodyFont};
  }
  .MuiLinearProgress-colorPrimary {
    background-color: ${({ weddingStyleData }) => `${weddingStyleData.colors.secondary.dark}`};
  }
  .MuiLinearProgress-barColorPrimary {
    background-color: ${({ weddingStyleData }) => weddingStyleData.colors.secondary.light};
  }
  .pac-container{
    z-index: 1500;    
    max-height: 200px;
    overflow-y: auto;
    :after {
      display: none;
    }
  .pac-item {
    display: flex;
    flex-direction: column;
    padding: 6px;
    cursor: pointer;
    > .pac-icon-marker {
      display: none;
    }
    span {
    font-size: 18px !important;
    }
    > :last-child {      
      font-size: 15px !important;
      margin-top: -3px;
    }
  }
  }
`;

const Container = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: ${({ weddingStyleData }) => weddingStyleData.headerFont};
    color: ${({ weddingStyleData }) => weddingStyleData.colors.primary.onDefaultBackground};
    font-weight: normal;
  }
  h1 {
    font-size: ${({ weddingStyleData }) => adjustFontSize(weddingStyleData.headerFont, '36px')};
    @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
      font-size: ${({ weddingStyleData }) => adjustFontSize(weddingStyleData.headerFont, '18px')};
    }
  }
  h2 {
    font-size: ${({ weddingStyleData }) => adjustFontSize(weddingStyleData.headerFont, '24px')};
    @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
      font-size: ${({ weddingStyleData }) => adjustFontSize(weddingStyleData.headerFont, '18px')};
    }
  }
  div,
  p,
  span {
    font-size: ${({ weddingStyleData }) => adjustFontSize(weddingStyleData.bodyFont, '18px')};
    @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
      font-size: ${({ weddingStyleData }) => adjustFontSize(weddingStyleData.bodyFont, '14px')};
    }
    color: ${({ weddingStyleData }) => weddingStyleData.colors.secondary.onDefaultBackground};
    font-family: ${({ weddingStyleData }) => weddingStyleData.bodyFont};
    border-color: ${({ weddingStyleData }) => weddingStyleData.colors.primary.base};
    &.default-background {
      color: ${({ weddingStyleData }) => weddingStyleData.colors.secondary.onDefaultBackground};
    }
    &.primary-color-background {
      color: ${({ weddingStyleData }) => weddingStyleData.colors.secondary.onPrimaryColor};
    }
  }

  .MuiSwitch-thumb {
    background-color: ${({ weddingStyleData }) => `${weddingStyleData.colors.button.primaryColor_T}!important`};
    filter: brightness(0.8);
  }
  .MuiSwitch-track {
    background-color: ${({ weddingStyleData }) => `${weddingStyleData.colors.button.primaryColor_T}99!important`};
  }
  .Mui-checked {
    .MuiSwitch-thumb {
      background-color: ${({ weddingStyleData }) => `${weddingStyleData.colors.button.primaryColor_T}!important`};
      filter: brightness(1.5);
    }
  }
  .color-background-white {
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: ${({ weddingStyleData }) => weddingStyleData.colors.primary.onWhite};
    }
    div,
    p,
    span,
    a,
    .MuiInputBase-input,
    .MuiFormLabel-root,
    .MuiFormControlLabel-root .MuiTypography-root {
      color: ${({ weddingStyleData }) => weddingStyleData.colors.secondary.onWhite};
    }
    svg {
      fill: ${({ weddingStyleData }) => weddingStyleData.colors.button.primaryOnWhite};
    }
    button,
    a.MuiButton-root {
      border-color: ${({ weddingStyleData }) => weddingStyleData.colors.button.primaryOnWhite};
      background-color: ${({ weddingStyleData }) => weddingStyleData.colors.button.primaryOnWhite};
      > span {
        color: ${({ weddingStyleData }) => weddingStyleData.colors.button.primaryOnWhite_T};
      }
      svg {
        fill: ${({ weddingStyleData }) => weddingStyleData.colors.button.primaryOnWhite_T};
        circle {
          stroke: ${({ weddingStyleData }) => weddingStyleData.colors.button.primaryOnWhite_T};
        }
      }
      &.MuiButton-containedSecondary {
        background-color: ${({ weddingStyleData }) => weddingStyleData.colors.button.secondaryOnWhite};
        > span {
          color: ${({ weddingStyleData }) => weddingStyleData.colors.button.secondaryOnWhite_T};
        }
        svg {
          fill: ${({ weddingStyleData }) => weddingStyleData.colors.button.secondaryOnWhite_T};
        }
      }
      :hover {
        border-color: ${({ weddingStyleData }) => weddingStyleData.colors.button.primaryOnWhiteOnHover};
        background-color: ${({ weddingStyleData }) => weddingStyleData.colors.button.primaryOnWhiteOnHover};
        > span {
          color: ${({ weddingStyleData }) => weddingStyleData.colors.button.primaryOnWhiteOnHover_T};
        }
        svg {
          fill: ${({ weddingStyleData }) => weddingStyleData.colors.button.primaryOnWhiteOnHover_T};
        }
        &.MuiButton-containedSecondary {
          background-color: ${({ weddingStyleData }) => weddingStyleData.colors.button.secondaryOnWhiteOnHover};
          > span {
            color: ${({ weddingStyleData }) => weddingStyleData.colors.button.secondaryOnWhiteOnHover_T};
          }
          svg {
            fill: ${({ weddingStyleData }) => weddingStyleData.colors.button.secondaryOnWhiteOnHover_T};
          }
        }
      }
    }
    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: ${({ weddingStyleData }) => `2px solid ${weddingStyleData.colors.primary.onWhite}`};
    }
    .MuiInput-underline:after {
      border-bottom: ${({ weddingStyleData }) => `2px solid ${weddingStyleData.colors.primary.onWhite}`};
    }
    .MuiFilledInput-underline:after {
      border-bottom: 2px solid ${({ weddingStyleData }) => weddingStyleData.colors.primary.onWhite};
    }
    .MuiSwitch-thumb {
      background-color: ${({ weddingStyleData }) => `${weddingStyleData.colors.button.primaryOnWhite}!important`};
      filter: brightness(0.8);
    }
    .MuiSwitch-track {
      background-color: ${({ weddingStyleData }) => `${weddingStyleData.colors.button.primaryOnWhite}99!important`};
    }
    .Mui-checked {
      .MuiSwitch-thumb {
        background-color: ${({ weddingStyleData }) => `${weddingStyleData.colors.button.primaryOnWhite}!important`};
        filter: brightness(1.5);
      }
    }
  }
  .color-background-default {
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: ${({ weddingStyleData }) => weddingStyleData.colors.primary.onDefaultBackground};
    }
    div,
    p,
    span,
    a,
    .MuiInputBase-input,
    .MuiFormLabel-root,
    .MuiFormControlLabel-root .MuiTypography-root {
      color: ${({ weddingStyleData }) => weddingStyleData.colors.secondary.onDefaultBackground};
    }
    .MuiSwitch-thumb {
      background-color: ${({ weddingStyleData }) => `${weddingStyleData.colors.button.primaryColorOnHover}!important`};
      filter: brightness(0.8);
    }
    .MuiSwitch-track {
      background-color: ${({ weddingStyleData }) =>
        `${weddingStyleData.colors.button.primaryColorOnHover}99!important`};
    }
    .Mui-checked {
      .MuiSwitch-thumb {
        background-color: ${({ weddingStyleData }) =>
          `${weddingStyleData.colors.button.primaryColorOnHover}!important`};
        filter: brightness(1.5);
      }
    }
  }
  .color-background-primary {
    div,
    p,
    span {
      color: ${({ weddingStyleData }) => weddingStyleData.colors.secondary.onPrimaryColor};
    }
  }
  a {
    color: ${({ weddingStyleData }) => weddingStyleData.colors.secondary.onDefaultBackground};
    font-weight: bold;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: ${({ weddingStyleData }) => `2px solid ${weddingStyleData.colors.secondary.base}`};
  }
  .MuiInput-underline:after {
    border-bottom: ${({ weddingStyleData }) => `2px solid ${weddingStyleData.colors.secondary.base}`};
  }
  .MuiFilledInput-underline:after {
    border-bottom: 2px solid ${({ weddingStyleData }) => weddingStyleData.colors.secondary.onDefaultBackground};
  }
  .MuiFormHelperText-root.Mui-error {
    color: #f44336;
  }
  .MuiFormLabel-root,
  .MuiFormControlLabel-root .MuiTypography-root {
    font-family: ${({ weddingStyleData }) => weddingStyleData.bodyFont};
    color: ${({ weddingStyleData }) => weddingStyleData.colors.secondary.onDefaultBackground};
    font-size: ${({ weddingStyleData }) => adjustFontSize(weddingStyleData.bodyFont, '18px')};
    @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
      font-size: ${({ weddingStyleData }) => adjustFontSize(weddingStyleData.bodyFont, '14px')};
    }
  }
  .MuiInputBase-input {
    font-family: ${({ weddingStyleData }) => weddingStyleData.bodyFont};
    font-size: ${({ weddingStyleData }) => adjustFontSize(weddingStyleData.bodyFont, '24px')};
    color: ${({ weddingStyleData }) => weddingStyleData.colors.secondary.onDefaultBackground};
    transition: background-color 50000s ease-in-out 0s;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    font-family: ${({ weddingStyleData }) => weddingStyleData.bodyFont};
    font-size: ${({ weddingStyleData }) => adjustFontSize(weddingStyleData.bodyFont, '24px')};
    color: ${({ weddingStyleData }) => weddingStyleData.colors.secondary.onDefaultBackground};
    -webkit-text-fill-color: ${({ weddingStyleData }) => weddingStyleData.colors.secondary.onDefaultBackground};
  }
  .MuiSwitch-root {
    .MuiSwitch-thumb {
      background-color: ${({ weddingStyleData }) => weddingStyleData.colors.secondary.light};
    }
    .MuiSwitch-track {
      background-color: ${({ weddingStyleData }) => weddingStyleData.colors.secondary.onDefaultBackground};
    }
    .MuiButtonBase-root.Mui-checked:hover {
      background-color: rgba(0, 0, 0, 0.08);
    }
    .Mui-checked {
      .MuiSwitch-thumb {
        background-color: ${({ weddingStyleData }) => weddingStyleData.colors.secondary.onDefaultBackground};
      }
      + .MuiSwitch-track {
        opacity: 0.7;
        background-color: ${({ weddingStyleData }) => weddingStyleData.colors.secondary.onDefaultBackground};
      }
    }
  }
  .MuiIconButton-colorSecondary:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
  .MuiPickersDatePickerRoot-toolbar {
    background-color: black !important;
    opacity: 0.7;
  }
  button {
    transition: background-color 500ms cubic-bezier(0.4, 0, 0.2, 1), color 500ms cubic-bezier(0.4, 0, 0.2, 1);
    font-family: ${({ weddingStyleData }) => weddingStyleData.bodyFont};
    border-color: ${({ weddingStyleData }) => weddingStyleData.colors.button.primaryColor};
    background-color: ${({ weddingStyleData }) => weddingStyleData.colors.button.primaryColor};
    span {
      color: ${({ weddingStyleData }) => weddingStyleData.colors.button.primaryColor_T};
    }
    svg {
      fill: ${({ weddingStyleData }) => weddingStyleData.colors.button.primaryColor_T};
    }
    &.MuiButton-containedSecondary {
      background-color: ${({ weddingStyleData }) => weddingStyleData.colors.button.secondaryColor};
      > span {
        color: ${({ weddingStyleData }) => weddingStyleData.colors.button.secondaryColor_T};
      }
      svg {
        fill: ${({ weddingStyleData }) => weddingStyleData.colors.button.secondaryColor_T};
      }
    }
    :hover {
      border-color: ${({ weddingStyleData }) => weddingStyleData.colors.button.primaryColorOnHover};
      background-color: ${({ weddingStyleData }) => weddingStyleData.colors.button.primaryColorOnHover};
      > span {
        color: ${({ weddingStyleData }) => weddingStyleData.colors.button.primaryColorOnHover_T};
      }
      svg {
        fill: ${({ weddingStyleData }) => weddingStyleData.colors.button.primaryColorOnHover_T};
      }
      &.MuiButton-containedSecondary {
        background-color: ${({ weddingStyleData }) => weddingStyleData.colors.button.secondaryColorOnHover};
        > span {
          color: ${({ weddingStyleData }) => weddingStyleData.colors.button.secondaryColorOnHover_T};
        }
        svg {
          fill: ${({ weddingStyleData }) => weddingStyleData.colors.button.secondaryColorOnHover_T};
        }
      }
    }
  }
  .knob {
    border: ${({ weddingStyleData }) => `solid 2px ${weddingStyleData.colors.primary.base}`};
    &.active {
      background-color: ${({ weddingStyleData }) => weddingStyleData.colors.primary.base};
    }
  }
`;

const GenericStyleContainer = ({ weddingStyleData, children }) => {
  return (
    <>
      <GlobalStyle weddingStyleData={weddingStyleData} />
      <Container weddingStyleData={weddingStyleData}>{children}</Container>
    </>
  );
};

export default GenericStyleContainer;
