const k = require('./keys');
export default {
  [k._DOT]: '.',
  [k.HELLO]: 'Ciao,',
  [k.ENTER_NEW_PASSWORD]: 'Inserisci qui la tua nuova password. Verrai automaticamente connesso e reindirizzato.',
  [k.MINIMUM_6_CHARACTERS]: 'Utilizza un minimo di 6 caratteri.',
  [k.SAVE]: 'Salva',
  [k.UNABLE_TO_RESET_PASSWORD]: 'Non siamo riusciti a reimpostare la tua password.',
  [k.PASSWORD_CHANGED_SUCCESSFULLY_TITLE]: 'La password è stata modificata con successo',
  [k.PASSWORD_CHANGED_SUCCESSFULLY]:
    'Abbiamo cambiato la tua password! Ora puoi accedere con la nuova password. Ti stiamo inoltrando ora.',
  [k.FUNCTIONS]: 'Caratteristiche',
  [k.DESIGNS]: 'Temi',
  [k.PRICES]: 'Prezzi',
  [k.FAQ]: 'FAQ',
  [k.BLOG]: 'Blog',
  [k.CREATE_NOW_FREE]: 'Crea ora gratuitamente',
  [k.PERFECT_PAGE_FOR_YOU]: 'Il tuo sito web è su misura per te!',
  [k.PERFECT_DESIGN_IN_FEW_CLICKS]: 'Realizza il design perfetto in pochi clic con il nostro configuratore di design.',
  [k.DESIGN]: 'Design',
  [k.HOW_DESCRIBE_YOUR_DREAM_WEDDING]: 'Come descriveresti il matrimonio dei tuoi sogni?',
  [k.CLICK_ON_ONE_OF_THE_IMAGES]: 'Clicca su una delle immagini per avviare il configuratore di design',
  [k.CREATE_WEDDING_WEBSITE_NOW]: 'Crea ora gratuitamente',
  [k.FREQUENTLY_ASKED_QUESTIONS]: 'Domande frequenti',
  [k.ANY_MORE_QUESTIONS_OR_FEEDBACK]: 'Hai domande o commenti da fare?',
  [k.WE_LOOK_FORWARD_TO_IT]: 'Saremo lieti di ascoltarti!',
  [k.CONTENT_AND_DESIGN]: 'CONTENUTI E DESIGN',
  [k.PHOTO_GALLERY]: 'Gallerie fotografiche',
  [k.RSVP_CARD]: 'Scheda RSVP/Risposta',
  [k.PLAYLIST]: 'Playlist',
  [k.MULTILINGUAL_OWN_DOMAIN]: 'MULTILINGUISMO E DOMINIO PROPRIO',
  [k.DURATION_PASSWORD_PROTECTION]: 'PROTEZIONE RUNTIME E PASSWORD',
  [k.COST_SUPPORT]: 'COSTI E SUPPORTO',
  [k.WHAT_CONTENT_TYPICALLY]: 'Quali sono i contenuti tipici di un sito per matrimoni?',
  [k.CAN_CREATE_WEBSITE_BEFORE_DETAILS]:
    'Posso creare la pagina web del matrimonio prima che tutti i dettagli (come la data del matrimonio) siano definiti?',
  [k.CAN_CUSTOMIZE_DESIGN]: 'Posso personalizzare il design del sito web del mio matrimonio?',
  [k.CHANGES_VISIBLE_AFTER_SAVE]:
    'È possibile apportare modifiche alla home page senza che siano immediatamente visibili a tutti?',
  [k.PHOTO_UPLOAD_LIMIT]: "C'è un limite al numero di foto che possiamo caricare nella galleria?",
  [k.ORIGINAL_SIZE_PHOTO_STORAGE]: 'Le foto saranno salvate nelle loro dimensioni originali?',
  [k.GUESTS_DOWNLOAD_UPLOADED_PHOTOS]: 'Gli ospiti possono scaricare le foto caricate sul sito?',
  [k.USE_PHOTO_GALLERY_FOR_SLIDESHOW]:
    'Possiamo utilizzare la galleria fotografica per una presentazione dal vivo durante la nostra festa?',
  [k.HOW_DOES_R_S_V_P_WORK]: 'Come funziona la funzione RSVP?',
  [k.GUESTS_ADD_OWN_SONGS_TO_PLAYLIST]: 'Gli ospiti possono aggiungere le proprie canzoni alla playlist?',
  [k.CAN_DJ_ACCESS_PLAYLIST]: 'Il nostro DJ può accedere alla playlist?',
  [k.POSSIBILITY_OF_MULTIPLE_LANGUAGES]: 'È possibile avere la nostra homepage in diverse lingue?',
  [k.OWN_DOMAIN_POSSIBILITY]: 'Possiamo avere un nostro dominio per il sito web del nostro matrimonio?',
  [k.HOW_LONG_ACCESS_WEDDING_WEBSITE]: 'Per quanto tempo sarà disponibile il sito web del nostro matrimonio?',
  [k.PROTECT_SITE_WITH_PASSWORD]: 'Possiamo proteggere il nostro sito con una password?',
  [k.IS_EUREHOCHZEITONLINE_FREE]: 'EureHochzeitOnline.com è gratuito?',
  [k.HOW_GET_SUPPORT_EUREHOCHZEITONLINE]:
    'Come posso ricevere assistenza per creare la mia pagina web di matrimonio su EureHochzeitOnline.com?',
  [k.WEDDING_CONTENT_INDIVIDUAL]:
    "Il contenuto della pagina web del tuo matrimonio è individuale come il tuo matrimonio stesso. Oltre alle informazioni di base come data e luogo, puoi aggiungere codici di abbigliamento, indicazioni stradali, richieste speciali, informazioni sul contatto dell'officiante o una tabella di marcia. Per gli ospiti che viaggiano da lontano e hanno bisogno di un alloggio, puoi elencare i suggerimenti per gli hotel. È un'ottima idea anche condividere la vostra storia d'amore con gli ospiti o scrivere parole personali sul vostro partner. Non ci sono limiti alla tua creatività.",
  [k.YES_FEASIBLE_ADVANTAGES]:
    "Sì, è facile da fare e offre anche notevoli vantaggi. Puoi aggiungere informazioni e fornire aggiornamenti in qualsiasi momento, anche dopo l'invio delle partecipazioni di matrimonio.",
  [k.YES_EUREHOCHZEITONLINE_OFFERS]:
    'Sì, EureHochzeitOnline.com offre una varietà di opzioni di design per il sito web del tuo matrimonio. Puoi personalizzare i colori, i caratteri e altri elementi. Contattaci per qualsiasi domanda o commento.',
  [k.INDEED_CHANGES_VISIBLE]:
    'Infatti, tutte le modifiche apportate al tuo sito web saranno immediatamente visibili a tutti i visitatori in tempo reale. Al momento non offriamo una funzione per programmare o nascondere le modifiche in anticipo.',
  [k.IN_FREE_VERSION_LIMITED_PHOTOS]:
    "Nella versione gratuita c'è un numero limitato di foto che puoi caricare nella tua galleria. Questo perché abbiamo dei costi associati all'archiviazione e al traffico. Tuttavia, se acquisti i pacchetti Premium o Dominio, potrai caricare un numero illimitato di foto nella tua galleria. Dai un'occhiata al nostro blog per scoprire come ottenere le foto perfette per il tuo matrimonio.",
  [k.WE_GENERATE_DIFFERENT_SIZES]:
    'Quando carichi le tue foto, creiamo dimensioni diverse per ogni immagine. In base alle dimensioni dello schermo del visitatore, viene selezionata automaticamente la dimensione ottimale per garantire tempi di caricamento rapidi e la migliore qualità possibile delle immagini. Tuttavia, salviamo anche i file originali delle foto in modo che anche tu possa riceverle.',
  [k.YES_GUESTS_CAN_DOWNLOAD]: 'Sì, gli ospiti possono scaricare le foto caricate.',
  [k.YES_FEASIBLE_SCREEN_PROJECTOR]:
    'Assolutamente sì! In effetti, questa è una delle caratteristiche più apprezzate di EureHochzeitOnline. Tutto ciò di cui hai bisogno è una connessione internet stabile e, ovviamente, uno schermo o un proiettore.',
  [k.R_S_V_P_FUNCTION_SIMPLIFIES]:
    "La funzione RSVP consente di condividere facilmente le informazioni di accesso al sito web del matrimonio con i tuoi ospiti. Quando visiteranno il sito del tuo matrimonio, verrà chiesto loro di lasciare un feedback (ad esempio, quante persone parteciperanno, se si tratta di bambini o adulti, ed eventuali richieste speciali di cibo, allergie o altre esigenze). Nell'area riservata agli ospiti, puoi monitorare il numero di accettazioni e cancellazioni in qualsiasi momento. Consulta il nostro blog per le istruzioni passo-passo.",
  [k.YES_YOUR_GUESTS_CAN_SUGGEST]:
    'Assolutamente! I tuoi ospiti possono suggerire le loro canzoni preferite e aggiungerle alla playlist. In questo modo non solo potrai celebrare la diversità dei tuoi ospiti, ma potrai anche ascoltare la musica più importante per te e per i tuoi cari.',
  [k.NATURALLY_INVITE_DJ_TO_WEBSITE]:
    'Naturalmente. Basta invitare il DJ sul tuo sito web e tutti i tuoi desideri saranno a sua disposizione, anche in diretta durante la festa, se lo desideri.',
  [k.YES_YOU_CAN_HAVE_MULTIPLE_LANGUAGES]:
    'Sì, puoi avere il tuo sito web in più lingue. Questa funzione è disponibile nel pacchetto Premium e potrai tradurre tutte le informazioni in diverse lingue. Contattaci per impostare la configurazione per il tuo matrimonio.',
  [k.YES_YOU_CAN_HAVE_OWN_DOMAIN]:
    'Sì, con il nostro pacchetto Premium puoi prenotare facilmente il tuo dominio (ad esempio www.juliaundromeo.de) per il sito web del tuo matrimonio. Contattaci per prenotare il dominio e impostare il reindirizzamento.',
  [k.YOUR_WEDDING_WEBSITE_AVAILABLE_TWO_YEARS]:
    'Il sito web del tuo matrimonio sarà disponibile per due anni dalla sua creazione. Dopodiché verrà cancellato per fare spazio a nuovi siti web di matrimonio. Te lo ricorderemo in anticipo. I rinnovi sono disponibili su richiesta.',
  [k.ALL_WEDDING_WEBSITES_PASSWORD_PROTECTED]:
    'Tutte le pagine web di matrimonio sono protette da una password che ti verrà mostrata dopo la creazione.',
  [k.YES_CREATE_WEDDING_WEBSITE_FREE]:
    'Sì, puoi creare e progettare un sito web per matrimoni su EureHochzeitOnline.com gratuitamente. Tuttavia, alcune funzioni avanzate non sono gratuite, soprattutto quelle che richiedono risorse server aggiuntive. Non ci sono costi nascosti.',
  [k.IF_YOU_NEED_SUPPORT_WE_CAN_HELP]:
    "Se hai bisogno di aiuto per creare il tuo sito web di matrimonio, inviaci un messaggio all'indirizzo beeni@eurehochzeitonline.com. Ti risponderemo al più presto per assisterti.",
  [k.KEY_FEATURES_OVERVIEW]: "Le caratteristiche principali a colpo d'occhio!",
  [k.YOUR_WEDDING_WEBSITE_OFFERS_EVERYTHING]:
    'Ecco il tuo sito web per matrimoni con tutto ciò che ti serve per creare il matrimonio dei tuoi sogni.',
  [k.NEW_NOW]: 'Novità:',
  [k.MULTILINGUAL_WEDDINGS]: 'Matrimoni multilingue!',
  [k.ON_REQUEST_WE_PROVIDE_WEDDING_WEBSITES]: 'Ora disponibile: Siti web multilingue!',
  [k.SCHEDULE]: 'Cronologia del matrimonio',
  [k.LOCATIONS]: 'Luoghi del matrimonio',
  [k.RSVP]: 'Biglietto di risposta',
  [k.GUESTMANAGEMENT]: 'Gestione degli ospiti',
  [k.HOTEL_RECOMMENDATION]: "Raccomandazioni per l'hotel",
  [k.PRESENT_TABLE]: 'Tabella dei regali',
  [k.OWN_DOMAIN]: 'Dominio personalizzato',
  [k.DIVERSE_LAYOUTS]: 'Innumerevoli layout e design da scegliere e personalizzare.',
  [k.YOUR_WEDDING_DAY]: 'Il giorno del tuo matrimonio organizzato in modo ordinato per i tuoi ospiti.',
  [k.CLEAR_DIRECTIONS]: 'Indicazioni chiare per tutti i luoghi del matrimonio.',
  [k.NO_GUESSWORK]: 'Niente più noiose richieste di informazioni su chi verrà al matrimonio.',
  [k.GUEST_ADDRESSES_ALLERGIES]: 'Raccogli indirizzi, allergie, ecc. con un solo clic!',
  [k.HOTEL_SELECTION_FOR_GUESTS]: "Selezione dell'hotel per i tuoi ospiti per facilitare il loro viaggio!",
  [k.EASY_ONLINE_GIFT_REGISTRY]: 'La tua lista nozze semplificata.',
  [k.WEDDING_PICTURES_FOR_GUESTS]: 'Le foto del tuo matrimonio e quelle dei tuoi ospiti in un unico posto.',
  [k.CUSTOM_SPOTIFY_PLAYLIST_CREATION]: 'Una playlist Spotify personalizzata, creata da te e dai tuoi ospiti.',
  [k.WEDDING_HOMEPAGE_WITH_OWN_DOMAIN]: 'Sito web del matrimonio con dominio personale e password di protezione.',
  [k.YOUR_WEDDING_WEBSITE]: 'IL SITO WEB DEL TUO MATRIMONIO',
  [k.UNIQUE_LIKE_YOU]: '- UNICO COME TE!',
  [k.EASY_SETUP_CUSTOMIZED]: 'Facile da configurare e personalizzare!',
  [k.ALL_DETAILS_OF_YOUR_CELEBRATION]:
    "Tutti i dettagli del tuo evento in un colpo d'occhio, sia sul telefono che sul PC.",
  [k.GUEST_FEEDBACK_AND_WISHES]: 'Conferme e richieste degli ospiti a portata di mano',
  [k.YOUR_CELEBRATION_FROM_ALL_VIEWS]:
    'La tua festa da tutte le angolazioni - galleria di immagini in diretta per te e i tuoi ospiti',
  [k.MULTILINGUAL_SUPPORT]: '... naturalmente anche per matrimoni multilingue',
  [k.FREE_CREATE]: 'Crea ora gratuitamente',
  [k.DEMO_WEBSITE]: 'Sito web demo',
  [k.OUR_PRICING]: 'I nostri prezzi',
  [k.UNLOCK_ALL_FEATURES]: 'Sblocca tutte le funzionalità che desideri per un costo unico',
  [k.ANY_QUESTIONS_OR_FEEDBACK]: 'Hai domande o richieste?',
  [k.WE_ARE_HERE_TO_HELP]: 'Saremo lieti di ascoltarti!',
  [k.USER_TESTIMONIALS]: 'Le voci dei nostri utenti',
  [k.THOUSANDS_OF_PEOPLE]: 'Migliaia di persone hanno già celebrato il loro matrimonio con EureHochzeitOnline!',
  [k.OUR_GIFT_TIP]: 'La nostra idea regalo:',
  [k.FOR_ALL_WITNESSES]: '',
  [k.BUY_WEDDING_WEBSITE_AS_GIFT]: 'Regala agli sposi un sito web personalizzato!',
  [k.BRIDE]: 'La sposa',
  [k.F_WITNESS]: "Damigella d'onore",
  [k.GROOM]: 'Sposo',
  [k.TESTIMONIAL_1]:
    'Creare il nostro sito web è stato un gioco da ragazzi. Siamo rimasti davvero sorpresi dalla facilità con cui è stato possibile aggiungere anche informazioni complicate. Siamo stati davvero in grado di creare il NOSTRO sito web. I nostri ospiti sono rimasti impressionati!',
  [k.TESTIMONIAL_2]:
    'È stato fantastico che tutti i nostri ospiti abbiano potuto utilizzare il sito nella loro lingua. Hanno apprezzato molto la funzione playlist che permetteva loro di suggerire le proprie canzoni preferite. In questo modo, la diversità degli invitati è stata celebrata anche attraverso la musica della festa.',
  [k.TESTIMONIAL_3]:
    "Il nostro sito web è stato un aiuto prezioso durante l'organizzazione del nostro matrimonio. Abbiamo potuto aggiornare le informazioni importanti in qualsiasi momento e anche mostrare le foto in una splendida galleria fotografica. I nostri ospiti hanno adorato lo slideshow dal vivo e noi ancora di più.",
  [k.TESTIMONIAL_4]:
    'A distanza di oltre un anno, ci piace ancora ascoltare la playlist di Spotify del nostro matrimonio. Il nostro sito web per matrimoni ci ha aiutato ad avere un matrimonio fantastico con ricordi meravigliosi.',
  [k.THANK_YOU]: 'Grazie mille!',
  [k.ACTIVATED_MAIL]: 'Hai attivato il tuo indirizzo e-mail!',
  [k.CLICK_HERE_TO_GO_TO_WED]: 'Clicca qui per accedere al sito web del matrimonio di',
  [k.AND]: 'e',
  [k.BE_REDIRECTED]: 'per essere reindirizzato.',
  [k.SET_A_PASSWORD]: 'Imposta una password in modo da poter sempre accedere e modificare il tuo matrimonio!',
  [k.SET_A_PASSWORD_SHORT]: 'Scegli una password',
  [k.ERROR_OCCURED]: "Si è verificato un errore. Riprova. Se l'errore si ripete, puoi inviarci un'e-mail a:",
  [k.PASSWORD_ALREADY_EXISTING_TITLE]: 'Hai già una password',
  [k.PASSWORD_ALREADY_EXISTING]: 'Abbiamo notato che hai già creato una password. Puoi',
  [k.GO_TO_WEDDING]: 'andare alla pagina iniziale del tuo matrimonio e registrarti',
  [k.I_AGREE]: 'Accetto la password',
  [k.REGISTER_NOW]: 'Registrati ora!',
  [k.YOUR_FREE_WEDDING_WEBSITE]: 'Il tuo sito web gratuito per il matrimonio!',
  [k.ALREADY_CREATED_WEDDINGS]:
    'Hai già creato le seguenti homepage di matrimonio. Probabilmente preferisci modificare le homepage di matrimonio esistenti piuttosto che crearne una nuova. Clicca qui sotto.',
  [k.WEDDING]: 'Siti web di matrimonio',
  [k.ENTER_PASSWORD]: 'Inserisci la tua password',
  [k.USER_ALREADY_EXISTING]: 'Abbiamo già un utente registrato con questo indirizzo e-mail. Inserisci la tua password.',
  [k.BACK]: 'Indietro',
  [k.LOGIN]: 'Accedi',
  [k.FORGOT_PASSWORD]: 'Hai dimenticato la password? Clicca qui.',
  [k.MAIL_SENT]: "Ti abbiamo inviato un'e-mail. Clicca sul link contenuto nell'e-mail per reimpostare la tua password.",
  [k.OK]: 'OK',
  [k.PERFECT]: 'Perfetto,',
  [k.NAME_OF]: 'Qual è il nome',
  [k.SPOUSE_M]: 'Il tuo prescelto',
  [k.SPOUSE_F]: 'Il tuo prescelto',
  [k.NEXT]: 'Avanti',
  [k.WHATS_YOUR_NAME]: 'Fantastico! Qual è il tuo nome?',
  [k.PREPARING_1]: 'Stiamo preparando la tua app per il matrimonio...',
  [k.PREPARING_2]: 'Salviamo il design che hai scelto...',
  [k.PREPARING_3]: 'Qualche ritocco finale...',
  [k.FINISHED_ENTER_MAIL]:
    'Fatto! Inserisci il tuo indirizzo e-mail in modo che possiamo salvare la tua app di matrimonio e inviarti i dati di accesso.',
  [k.ACCEPT_TERMS]: 'Accetta i Termini di servizio.',
  [k.ENTER_VALID_MAIL]: 'Inserisci un indirizzo e-mail valido.',
  [k.QUESTION_BRIDE_OR_GROOM]: 'Sei la sposa o lo sposo?',
  [k.QUESTION_WEDDING_DATE]: 'Conosci la data del tuo matrimonio?',
  [k.YES_WE_KNOW]: 'Sì, la conosciamo!',
  [k.NO_WE_DONT_KNOW]: 'No, non ancora!',
  [k.MAKE_SELECTION]: 'Seleziona una data.',
  [k.QUESTION_WEDDING_LOCATION]: 'Hai già una location?',
  [k.YES_WE_HAVE]: "Sì, ce l'abbiamo!",
  [k.ENTER_YOUR_LOCATION]: 'Inserisci la location del tuo matrimonio.',
  [k.LOCATION_CLASSIC]: 'Classico',
  [k.LOCATION_COUNTRY]: 'Campestre',
  [k.LOCATION_OUTDOOR]: "All'aperto",
  [k.LOCATION_PALACE_HOTEL]: 'Castello',
  [k.QUESTION_WEDDING_STYLE]: 'Quale stile preferisci?',
  [k.HOTEL]: 'Hotel',
  [k.INFOS]: 'Info',
  [k.LOCATION]: 'Località',
  [k.PHOTOS]: 'Foto',
  [k.NO_MUSIC_SAVED]: 'Non ci sono ancora canzoni salvate...',
  [k.SEARCH_FOR_SONGS]: 'Cerca la tua canzone preferita qui sopra!',
  [k.LISTEN_TO_FULL_LIST]: "Ascolta l'intera playlist del matrimonio",
  [k.OPEN_IN_SPOTIFY]: 'Apri in Spotify',
  [k.LISTEN_TO_SINGLE_SONGS]: 'Ascolta le singole canzoni della playlist',
  [k.NO_RESULT_FOUND]: 'Nessun risultato trovato',
  [k.CANCEL]: 'Annulla',
  [k.ADD_SONG]: 'Aggiungi una canzone',
  [k.NO_PHOTOS_UPLOADED_YET]: 'Non ci sono ancora foto caricate...',
  [k.ADD_SOME_PHOTOS]: 'Clicca qui sopra e scatta le foto!',
  [k.DROP_PHOTOS_HERE_OR]: 'Salva le foto qui o',
  [k.SHOOT_PHOTO]: 'Scatta una foto',
  [k.OVERVIEW_OF_ALL_PRESENTS]: 'Visualizza tutti i regali e le partecipazioni',
  [k.LOADING_BACKINGS]: 'Le voci sono state caricate...',
  [k.NO_PRESENTS_YET]: 'Non hai ancora aggiunto nessun regalo alla tua lista di regali.',
  [k.PRESENT]: 'Aggiungi un regalo',
  [k.E_MAIL_ADDRESS]: 'Il tuo indirizzo e-mail',
  [k.DATE]: 'Data del regalo',
  [k.NO_BACKINGS_YET]: 'Non ci sono ancora voci',
  [k.CLOSE]: 'Chiudi',
  [k.ONLY_VISIBLE_FOR_WEDDING_COUPLE]: '(Visibile solo agli sposi)',
  [k.SHOW_ALL_ENTRIES]: 'Mostra tutte le iscrizioni',
  [k.EUR]: 'IMPORTO EUR',
  [k.ADD_BACKING]: 'Partecipa',
  [k.BUY]: 'Acquista ora',
  [k.YOUR_BACKING_SAVED]: 'Hai effettuato il login!',
  [k.A_GUEST_ALREADY_BACKED]: 'Un ospite si è già registrato.',
  [k.DELETE_MY_BACKING]: 'Cancella la mia iscrizione.',
  [k.OVERVIEW_OF_ALL_RSVPS]: 'Panoramica di tutte le registrazioni',
  [k.LOADING_RSVPS]: 'Le registrazioni sono in fase di caricamento...',
  [k.NO_RSVPS_YET]: 'Non sono ancora state ricevute schede di risposta.',
  [k.RESPONSE]: 'Risposta',
  [k.GUESTS_AND_MENU_WISH]: 'Richiesta di ospiti e menu',
  [k.QUESTIONS_AND_REMARKS]: 'Domanda/Commento',
  [k.WILL_ATTEND]: 'Impegno',
  [k.WILL_NOT_ATTEND]: 'Cancellazione',
  [k.SHOW_ALL_RSVPS]: 'Visualizza tutte le richieste',
  [k.YOU_ALREADY_SUBMITTED_ON]: 'Hai già inviato la risposta a',
  [k.CANNOT_CHANGE_ANY_MOR]: 'è già stata inviata. I dettagli non possono essere modificati.',
  [k.MENU_WISH]: 'Menu della richiesta',
  [k.RSVP_LOADING]: 'La scheda di risposta è in fase di caricamento...',
  [k.LIST_EVERYONE_WE_MAY_EXPECT]: 'Per favore, fai una lista di tutti i partecipanti che possiamo aspettarci',
  [k.LIST_EVERYONE_WE_MAY_EXPECT_WITH_MENU]:
    'Ti preghiamo di elencare qui di seguito tutti gli invitati e di indicarci le tue preferenze per il menu.',
  [k.GUEST]: 'Ospiti',
  [k.ADD_GUEST]: 'Aggiungi un altro ospite',
  [k.SUBMIT_RSVP]: 'Invia la scheda di risposta',
  [k.WEBSITE]: 'Sito web',
  [k.OUR_PACKAGES]: 'I nostri pacchetti',
  [k.PACKAGE_BASIC]: 'Base',
  [k.PACKAGE_COMPLETE]: 'Completo',
  [k.PACKAGE_PREMIUM]: 'Premium',
  [k.PACKAGE_VIP]: 'VIP',
  [k.BEST_SELLER]: 'Miglior venditore',
  [k.SELECTED]: 'Selezionato',
  [k.SELECT]: 'Seleziona',
  [k.CAN_UPGRADE_LATER]:
    'Non preoccuparti, puoi sempre fare un upgrade in un secondo momento pagando semplicemente la differenza.',
  [k.LOOK_IN_FAQ]: "Hai ancora domande? Dai un'occhiata alle nostre FAQ!",
  [k.PACKAGE_FEATURES_DESIGN]: 'Design, colori, caratteri',
  [k.PACKAGE_FEATURES_SUBDOMAIN]: 'Il tuo sottodominio con password',
  [k.PACKAGE_FEATURES_MOBILE]: 'Ottimizzato per desktop e cellulari',
  [k.PACKAGE_FEATURES_SCHEDULE]: 'Programma giornaliero con orari',
  [k.PACKAGE_FEATURES_LOCATION]: 'Pianificatore e informazioni sulla posizione',
  [k.PACKAGE_FEATURES_INFOS]: 'Pagine informative (FAQ, Regali, ecc.)',
  [k.PACKAGE_FEATURES_RSVP]: 'Registrazione e gestione degli ospiti',
  [k.PACKAGE_FEATURES_MUSIC]: 'Richieste di musica da parte degli ospiti',
  [k.PACKAGE_FEATURES_PHOTOS]: 'Caricamento di foto degli ospiti',
  [k.PACKAGE_FEATURES_SUPPORT]: 'Servizio clienti via e-mail',
  [k.PACKAGE_FEATURES_MULTILANGUAGE]: 'Sito web multilingue',
  [k.PACKAGE_FEATURES_OWN_DOMAIN]: 'Il tuo dominio (.de)',
  [k.PACKAGE_FEATURES_CUSTOM_DESIGN]: 'Design personalizzato da parte di un professionista',
  [k.MAX_FIVE]: 'max. 5',
  [k.CHECKING_PAYMENT_STATUS]: 'Controlla lo stato del pagamento',
  [k.PAYMENT_SUCCESSFULL]:
    'Grazie! Il pagamento è andato a buon fine, ora puoi utilizzare la tua app per matrimoni nel seguente pacchetto:',
  [k.PAYMENT_FAILED]: "Purtroppo il pagamento non è andato a buon fine. Puoi riprovare o inviarci un'e-mail a",
  [k.ACTIVATE_PACKAGE_NOW]: 'Sblocca ora:',
  [k.PAY]: 'Pagamenti',
  [k.EDITING_MODE]: 'La modalità di modifica',
  [k.ACTIVE]: 'è attiva',
  [k.NOT_ACTIVE]: 'non è attiva',
  [k.ERROR_OCCURED_WITH_MESSAGE]: 'Si è verificato un errore. Feedback tecnico:',
  [k.TRY_AGAIN_PLEASE]: "Riprova. Se l'errore si ripete, puoi inviarci un'e-mail all'indirizzo",
  [k.PACKAGE_UPGRADE]: 'Aggiornamento del pacchetto',
  [k.PACKAGE_ALREADY_BOUGHT]: 'Hai già acquistato un pacchetto per il tuo matrimonio.',
  [k.UPGRADE_TO_HIGHER_PACKAGE]:
    'Naturalmente, puoi aggiornare il tuo sito web con un pacchetto superiore pagando semplicemente la differenza. Inviaci una breve e-mail e ci occuperemo di tutto.',
  [k.REGISTRATION_NOT_COMPLETE]: 'La registrazione non è ancora completa. Clicca sul link che ti abbiamo inviato.',
  [k.YOU_HAVE_AN_EMAIL]: 'Hai ricevuto una nostra e-mail.',
  [k.YOU_MUST_REGISTER_FIRST]: 'Devi completare la registrazione per poter modificare il sito web del tuo matrimonio.',
  [k.CREATOR_MUST_REGISTER]:
    "È tutto gratuito e molto veloce: basta cliccare sul link contenuto nell'e-mail che ti abbiamo inviato. Non riesci a trovare l'e-mail? Nessun problema, clicca qui:",
  [k.HERE]: 'Qui',
  [k.E_MAIL_RESENT]: "L'e-mail è stata inviata di nuovo.",
  [k.CONFIRMATION_RESENT_TO]: 'Ti abbiamo inviato nuovamente il link di conferma:',
  [k.CAN_TAKE_SOME_MINUTES]:
    "Potrebbero essere necessari alcuni minuti prima che l'e-mail ti raggiunga. Se continui a riscontrare problemi, inviaci un'e-mail e ti aiuteremo:",
  [k.RESENDING_MAIL]: "L'e-mail verrà inviata nuovamente.",
  [k.DEMO_WEDDING_HINT]: 'Sito demo - crea il tuo sito gratuitamente e provalo!',
  [k.CREATE_OWN_WEBSITE]: 'Crea il tuo sito web!',
  [k.SOME_FEATURES_DEACTIVATED_IN_DEMO]: 'Alcune funzioni sono disabilitate sul sito demo.',
  [k.CAN_CREATE_OWN_WEDDING_WEBSITE]:
    'Puoi registrarti, creare il tuo sito web per matrimoni e provare tutte le funzionalità gratuitamente!',
  [k.NO_PACKAGE_BOUGHT_HINT]:
    "Versione base gratuita - fai subito l'upgrade per avere foto illimitate, ospiti e altre funzioni.",
  [k.LETS_UPGRADE]: "Effettuiamo l'aggiornamento.",
  [k.MAX_NUMBER_FOR_BASIC_PACKAGE]: 'Hai raggiunto il numero massimo di foto per il pacchetto base gratuito.',
  [k.UPGRADE_FOR_UNLIMITED_PHOTOS]:
    'Puoi passare a uno dei nostri piani premium per caricare un numero illimitato di foto.',
  [k.UPGRADE_FOR_UNLIMITED_MUSIC]:
    'Puoi passare a uno dei nostri piani premium per aggiungere un numero illimitato di canzoni.',
  [k.CONFIRM_DELETE_TITLE]: "Conferma l'eliminazione",
  [k.CONFIRM_DELETE_TEXT]: 'Sei sicuro di voler eliminare questo articolo?',
  [k.DELETE]: "Elimina l'articolo",
  [k.ADD_ELEMENT]: 'Aggiungi un nuovo articolo',
  [k.WHICH_ELEMENT_TO_ADD]: 'Che tipo di articolo vuoi aggiungere?',
  [k.SELECT_ICON_FOR_LOCATION]: "Seleziona un'icona per la posizione",
  [k.IMAGE_POSITION]: "Posizione dell'immagine",
  [k.SELECT_IMAGE]: "Seleziona l'immagine",
  [k.UPLOAD]: 'Carica immagine',
  [k.SELECTED_IMAGE]: 'Immagine selezionata',
  [k.NO_IMAGE]: 'Nessuna immagine',
  [k.OTHER_IMAGE]: 'Altra immagine',
  [k.EDIT_WEDDING_BASE_DATA]: 'Modifica i dettagli del matrimonio',
  [k.ENTER_NAME_OF_BRIDE]: 'Inserisci il nome della sposa.',
  [k.ENTER_NAME_OF_GROOM]: 'Inserisci il nome dello sposo.',
  [k.SELECT_DATE]: 'Seleziona una data.',
  [k.HINT_CHANGE_URL]:
    "Nota: se desideri cambiare l'indirizzo a cui sarà disponibile la pagina web del tuo matrimonio, inviaci un'e-mail:",
  [k.EDIT_COUNTDOWN]: 'Modifica il conto alla rovescia del matrimonio',
  [k.EDIT_INFO_BOX]: 'Modifica il riquadro informativo',
  [k.EDIT_BOX_WITH_LARGE_PHOTO]: 'Modifica il riquadro con la foto grande',
  [k.EDIT_LOCATION]: 'Modifica la posizione',
  [k.EDIT_PLACE]: 'Modifica la posizione',
  [k.ADD_LOCATION]: 'Aggiungi posizione',
  [k.ADD_PLACE]: 'Aggiungi posizione',
  [k.SELECT_SEARCH_RESULT]: 'Seleziona un risultato di ricerca.',
  [k.EDIT_PAGE_TITLE]: 'Modifica il titolo della pagina',
  [k.EDIT_PRESENTS]: 'Modifica la lista dei regali',
  [k.PRESENT_INTRO_TEXT]: 'Crea la tua lista regali personalizzata per ispirare i tuoi ospiti.',
  [k.ADDITIONAL_ENTRY]: 'Altre voci',
  [k.EDIT_RSVP]: 'Modifica RSVP',
  [k.MENU_WISHES_ACTIVATED]: "Vuoi che i tuoi ospiti indichino le loro preferenze per il menu al momento dell'RSVP?",
  [k.EDIT_SCHEDULE_ITEM]: 'Modifica voce di menu',
  [k.NAVIGATION]: 'Imposta menu',
  [k.COUPLE]: 'Sposa e Sposo',
  [k.MUSIC]: 'Menu musicale',
  [k.YOU_ARE_LOGGED_IN_AS_GROOM]: 'Sei registrato come sposo.',
  [k.YOU_ARE_LOGGED_IN_AS_BRIDE]: 'Tu sei registrata come sposa.',
  [k.ADDRESS_OF_YOUR_PAGE]: 'Indirizzo del sito web del tuo matrimonio:',
  [k.WEDDING_CODE_FOR_GUESTS]: 'Codice del matrimonio per i tuoi ospiti:',
  [k.CURRENT_PACKAGE]: 'Il tuo pacchetto attuale:',
  [k.UPGRADE]: 'Aggiornamento',
  [k.LOGOUT]: 'Esci',
  [k.FOR_GUESTS]: 'Per gli invitati',
  [k.INVISIBLE]: 'invisibile',
  [k.VISIBLE]: 'Visibile',
  [k.WEDDING_NOT_FOUND]: 'Pagina del matrimonio non trovata.',
  [k.WEDDING_NOT_FOUND_TEXT]:
    "Siamo spiacenti, non siamo riusciti a trovare una pagina web dedicata al matrimonio all'indirizzo che hai inserito. Forse hai sbagliato a digitare? Riprova.",
  [k.IF_ERROR_REPEATS]: "Se l'errore si ripete, puoi inviarci un'e-mail all'indirizzo",
  [k.ERROR_GENERAL]: 'Si è verificato un errore',
  [k.SORRY_WRITE_MAIL]:
    "Siamo spiacenti. Riprova. Se l'errore si ripete, puoi inviarci un'e-mail e ti aiuteremo a risolvere il problema.",
  [k.MOVE_UP]: 'Salire',
  [k.MOVE_DOWN]: 'Scorri verso il basso',
  [k.OH_SORRY]: 'Oh cielo... Ho commesso un errore...',
  [k.ERROR_REFRESH_PAGE]:
    "Ci dispiace molto. Ti invitiamo a ricaricare la pagina e a riprovare. Se l'errore si ripete, puoi inviarci un'e-mail all'indirizzo",
  [k.IF_YOU_WANT_TO_HELP]:
    "Se vuoi aiutarci a trovare e risolvere l'errore più rapidamente, puoi copiare il seguente testo nella tua e-mail:",
  [k.ENTER_DISPLAY_NAME]:
    'Ottimo! Inserisci il tuo nome - anche il nickname va bene. Verrà visualizzato sotto le foto che caricherai, ad esempio.',
  [k.ENTER_DISPLAY_NAME_SHORT]: 'Inserisci il tuo nome.',
  [k.WE_SENT_EMAIL]:
    "Ti abbiamo inviato un'e-mail. Clicca sul link contenuto nell'e-mail per reimpostare la tua password.",
  [k.WELCOME_BACK]: 'Bentornato sul sito,',
  [k.ALMOST_THERE]: 'Ci siamo quasi,',
  [k.PLEASE_SET_PASSWORD]: 'Scegli una password ora.',
  [k.PLEASE_SET_PASSWORD_SHORT]: 'Scegli una password',
  [k.WELCOME_TO_WEDDING_OF]: 'Benvenuto sul sito del matrimonio di',
  [k.PLEASE_ENTER_MAIL]: '! Inserisci il tuo indirizzo e-mail.',
  [k.WELCOME_TO_EHO]:
    'Benvenuto sulla piattaforma per matrimoni di EureHochzeitOnline.com! Inserisci il tuo indirizzo e-mail.',
  [k.TO_WEDDING]: 'Per il matrimonio',
  [k.ENTER_WEDDING_CODE]: '! Inserisci il codice del matrimonio.',
  [k.ENTER_WEDDING_CODE_SHORT]: 'Inserisci il codice del matrimonio.',
  [k.RESTART]: 'Riavvia',
  [k.UPLOAD_PREPARING]: 'Caricamento in preparazione...',
  [k.UPLOAD_STARTED]: 'Caricamento in corso...',
  [k.UPLOAD_FINISHING]: 'Caricamento completato...',
  [k.UPLOAD_CANCELED]: 'Il caricamento è stato annullato...',
  [k.INVALID_FILE_TYPE]: 'Il tipo di file non è valido...',
  [k.MAX_FILE_NUMBER]: 'Il numero massimo di file è stato superato...',
  [k.FILE_TOO_LARGE]: 'Il file è troppo grande...',
  [k.DAYS_TO_GO]: 'Mancano pochi giorni!',
  [k.DAY_TO_GO]: 'Ancora un giorno!',
  [k.TODAY]: 'Oggi',
  [k.INVALID_DATE]: 'La data non è valida',
  [k.DATE_MUST_BE_IN_FUTURE]: 'La data deve essere futura',
  [k.ADDRESS_OR_NAME_OF_LOCATION]: 'Indirizzo o nome della località',
  [k.ENTER_SONG_WISH]: 'Inserisci qui la tua canzone preferita...',
  [k.NAME_OF_GUEST]: "Nome dell'ospite",
  [k.QUESTION_REMARK_MAX_1000]: 'Domande/Commenti (massimo 1000 caratteri)',
  [k.LEFT]: 'Link',
  [k.TOP]: 'In alto',
  [k.RIGHT]: 'A destra',
  [k.BOTTOM]: 'In basso',
  [k.NAME_OF_BRIDE]: 'Nome della sposa',
  [k.NAME_OF_GROOM]: 'Nome dello sposo',
  [k.TITLE]: "Titolo dell'evento",
  [k.DESCRIPTION]: "Descrizione dell'evento",
  [k.NAME_OF_PLACE]: 'Nome del luogo',
  [k.STREET]: 'Via',
  [k.HOUSENUMBER]: 'N.',
  [k.ZIP]: 'CAP',
  [k.CITY]: 'Città',
  [k.COUNTRY]: 'Paese/Stato',
  [k.HINTS_FOR_GUESTS]: 'Note per gli ospiti',
  [k.TITLE_FOR_THIS_PAGE]: 'Titolo di questa pagina',
  [k.SUBTITLE_FOR_THIS_PAGE]: 'Sottotitolo per questa pagina',
  [k.LABEL]: 'Denominazione',
  [k.AMAZON_URL]: 'URL Amazon (opzionale)',
  [k.COSTS]: 'Costi',
  [k.NEW_FOOD_OPTION]: 'Nuova opzione di menu',
  [k.TIME]: 'Tempo',
  [k.SCHEDULE_ITEM]: 'Voce del programma',
  [k.YOUR_NAME_OR_NICKNAME]: 'Il tuo nome o nickname',
  [k.YOUR_EMAIL_ADDRESS]: 'Il tuo indirizzo e-mail',
  [k.YOUR_WEDDING_CODE]: 'Il tuo codice di matrimonio',
  [k.COULD_NOT_SAVE_WEDDING]: 'Siamo spiacenti, ma non siamo riusciti a salvare il matrimonio.',
  [k.ALREADY_HAVE_WEDDING]: 'Hai già una homepage per il matrimonio',
  [k.CREATE_NEW]: 'Creane una nuova',
  [k.COULD_NOT_LOAD_YOUR_DATA]: 'Non siamo riusciti a recuperare i tuoi dati.',
  [k.COULD_NOT_SEND_MAIL]: "Non siamo riusciti a inviare l'e-mail.",
  [k.DELETE_IMAGE]: "Elimina l'immagine",
  [k.DELETE_IMAGE_ARE_YOU_SURE]: 'Sei sicuro di voler cancellare questa immagine?',
  [k.COULD_NOT_LOGIN]: 'Non è stato possibile effettuare il login.',
  [k.WRONG_PASSWORD_TITLE]: 'Password errata',
  [k.WRONG_PASSWORD_TEXT]: 'La password inserita non è corretta.',
  [k.WRONG_CODE_TITLE]: 'Codice matrimonio errato',
  [k.WRONG_CODE_TEXT]: 'Il codice di matrimonio inserito non è corretto.',
  [k.LOADING]: 'Caricamento...',
  [k.GUEST_NAME_MISSING]: 'Inserisci i nomi dei seguenti invitati:',
  [k.GUEST_FOOD_MISSING]: 'Completa la richiesta del menu per i seguenti ospiti:',
  [k.LIMIT_EXCEEDED_PASSWORD_RESET]: 'Hai superato il numero massimo di tentativi. Puoi riprovare più tardi.',
  [k.EXPIRED_CODE_PASSWORD_RESET]: 'Hai già utilizzato questo link per reimpostare la tua password.',
  [k.PLEASE_ENTER_NAME]: 'Inserisci un nome',
  [k.COUNTDOWN_VISIBLE]: 'Conto alla rovescia visibile.',
  [k.COUNTDOWN_NOT_VISIBLE]: 'Conto alla rovescia non visibile.',
  [k.ASK_FOR_MENU_WISHES]: 'Sì, richiedi il menu',
  [k.ASK_NOT_FOR_MENU_WISHES]: 'No, non richiedere il menu',
  [k.SPRING]: 'Primavera',
  [k.SUMMER]: 'Estate',
  [k.AUTUMN]: 'Autunno',
  [k.WINTER]: 'Inverno',
  [k.COULD_NOT_SAVE_PASSWORD]: 'Non siamo riusciti a salvare la tua password.',
  [k.YES_WE_WILL_ATTEND]: 'Sì, parteciperemo al tuo matrimonio',
  [k.NO_WE_WILL_NOT_ATTEND]: 'No, non parteciperemo al tuo matrimonio',
  [k.CONFIRM_DIALOG_TITLE]: 'Sei sicuro?',
  [k.ALERT_DIALOG_TITLE]: 'Nota',
  [k.DEFAULT_IMAGES_TYPE_CAKE]: 'Torta nuziale',
  [k.DEFAULT_IMAGES_TYPE_CEREMONY]: 'Cerimonia nuziale',
  [k.DEFAULT_IMAGES_TYPE_CHAMPAGNERECEPTION]: 'Ricevimento e Champagne',
  [k.DEFAULT_IMAGES_TYPE_DANCE]: 'Ballo e festa',
  [k.DEFAULT_IMAGES_TYPE_FOOD]: 'Cena di nozze e buffet',
  [k.DEFAULT_IMAGES_TYPE_CAR]: 'Auto e sfilata nuziale',
  [k.DEFAULT_IMAGES_TYPE_GAME]: 'Giochi e intrattenimento',
  [k.DEFAULT_IMAGES_TYPE_DRESSCODE]: 'Codice di abbigliamento',
  [k.DEFAULT_IMAGES_TYPE_HOTEL]: 'Hotel e alloggio',
  [k.DEFAULT_IMAGES_TYPE_PHOTO]: 'Servizio fotografico',
  [k.DEFAULT_IMAGES_TYPE_PRESENT]: 'Regali e omaggi',
  [k.DEFAULT_IMAGES_TYPE_LOVE]: 'Generalità e amore',
  [k.DEFAULT_ELEMENT_TEXT_PAGE_TITLE_LABEL]: 'Titolo della pagina',
  [k.DEFAULT_ELEMENT_TEXT_PAGE_TITLE_TITLE]: 'Titolo della pagina',
  [k.DEFAULT_ELEMENT_TEXT_PAGE_TITLE_SUBTITLE]: 'Benvenuti in questa pagina',
  [k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_LABEL]: 'Voce del programma',
  [k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_TITLE]: 'Programma',
  [k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_TIME]: 'Dalle 15:00',
  [k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_DESCRIPTION]: 'Foto di gruppo',
  [k.DEFAULT_ELEMENT_TEXT_INFO_ITEM_LABEL]: 'Box informazioni',
  [k.DEFAULT_ELEMENT_TEXT_INFO_ITEM_TITLE]: '🍰 Torta',
  [k.DEFAULT_ELEMENT_TEXT_INFO_ITEM_DESCRIPTION]:
    " Se desideri preparare una torta, faccelo sapere. Non vediamo l'ora di mettere insieme una selezione colorata.",
  [k.DEFAULT_ELEMENT_TEXT_LARGE_PHOTO_ITEM_LABEL]: 'Scatola con foto grande',
  [k.DEFAULT_ELEMENT_TEXT_LARGE_PHOTO_ITEM_TITLE]: 'La tua presentazione',
  [k.DEFAULT_ELEMENT_TEXT_LARGE_PHOTO_ITEM_DESCRIPTION]:
    'Qui puoi presentarti ai tuoi ospiti. Racconta loro come vi siete conosciuti...',
  [k.DEFAULT_ELEMENT_TEXT_LOCATION_MAP_LABEL]: 'Mappa per le location',
  [k.DEFAULT_ELEMENT_TEXT_LOCATION_ITEM_LABEL]: 'Luogo del matrimonio',
  [k.DEFAULT_STYLES_MODERN_TITLE]: 'Moderno',
  [k.DEFAULT_STYLES_MODERN_DESCRIPTION]:
    "Il tuo matrimonio in stile moderno! Linee pulite, design innovativi ed eleganza chic. Dalla raffinatezza minimalista al glamour futuristico, gli stili del matrimonio moderno riflettono un'estetica contemporanea. Lascia che il tuo amore risplenda in un ambiente che cattura il ritmo dei tempi.",
  [k.DEFAULT_STYLES_KLASSISCH_TITLE]: 'Classico',
  [k.DEFAULT_STYLES_KLASSISCH_DESCRIPTION]:
    "L'eleganza dell'eternità ti aspetta con lo stile Classico. Bellezza senza tempo, tocchi tradizionali e un'atmosfera sofisticata definiscono questo stile. Tagli netti, colori neutri e un tocco di opulenza creano un ambiente che onora la storia del vostro amore. Il tuo matrimonio sarà un capolavoro senza tempo che durerà per generazioni.",
  [k.DEFAULT_STYLES_VINTAGE_TITLE]: 'Vintage',
  [k.DEFAULT_STYLES_VINTAGE_DESCRIPTION]:
    "Fai un salto indietro nel tempo in un'epoca dal fascino intramontabile. Gli stili vintage riportano il fascino delle epoche passate con un tocco di nostalgia ed eleganza romantica. Colori tenui, pizzi delicati e dettagli antichi creano un'atmosfera di eleganza e ricordo. Il tuo matrimonio sarà una favola vivente che celebra la bellezza dei tempi passati.",
  [k.DEFAULT_STYLES_FLOWER_TITLE]: 'I fiori',
  [k.DEFAULT_STYLES_FLOWER_DESCRIPTION]:
    "Benvenuto nello splendore fiorito del Flower Power! Questo stile di matrimonio celebra la bellezza naturale dei fiori in tutto il loro splendore. Una celebrazione di colori e profumi ti aspetta quando gli elementi floreali faranno da cornice al tuo amore. Dalle lussureggianti composizioni floreali alle decorazioni di ispirazione botanica, il tuo matrimonio sarà un giardino fiorito d'amore.",
  [k.COOKIES_01]: 'Ci sono dei biscotti per il matrimonio per te?',
  [k.COOKIES_02]:
    "Cliccando su 'Conferma tutto' accetterai tutti i cookie. In alternativa, puoi effettuare una selezione personalizzata. Puoi revocare la tua selezione in qualsiasi momento attraverso le 'Impostazioni dei cookie'.",
  [k.COOKIES_03]: 'Cookie necessari',
  [k.COOKIES_04]: 'Questi cookie sono necessari per la funzionalità del sito, pertanto non puoi disabilitarli.',
  [k.COOKIES_05]: 'Per saperne di più',
  [k.COOKIES_06]: 'Cookie di marketing e personalizzazione',
  [k.COOKIES_07]:
    'Questi cookie ti permettono di ricevere contenuti del sito web personalizzati in base al tuo comportamento e ci consentono di adattare il nostro sito web agli interessi dei nostri clienti in base alle statistiche di utilizzo.',
  [k.COOKIES_08]: 'Accetta la selezione',
  [k.COOKIES_09]: 'Accetta tutto',
  [k.TERMS_AND_CONDITIONS_00]: 'Condizioni di utilizzo',
  [k.TERMS_AND_CONDITIONS_01]:
    "fidira Software UG (haftungsbeschränkt), di seguito anche 'fornitore', 'noi' o 'EureHochzeitOnline.com', fornisce una piattaforma per la creazione di applicazioni per matrimoni individuali. Con queste applicazioni, gli utenti (di seguito denominati collettivamente 'Utenti') in qualità di padroni di casa possono creare un'applicazione per il loro matrimonio e offrire ai loro ospiti informazioni sul matrimonio e opportunità di interazione attraverso questa applicazione. I seguenti termini e condizioni si applicano all'uso della piattaforma.",
  [k.TERMS_AND_CONDITIONS_02]: '§ 1 Uso della Piattaforma',
  [k.TERMS_AND_CONDITIONS_03]:
    "L'uso della piattaforma è generalmente gratuito in una versione di prova. Se la piattaforma viene utilizzata per un matrimonio con un numero definito di invitati o superiore, è necessario pagare una tariffa una tantum. Sia la versione di prova che la versione completa richiedono la registrazione e la creazione di un account utente. L'utente non può permettere a terzi di utilizzare il suo account. L'utente è tenuto a mantenere segreti i propri dati di accesso e a proteggerli dall'accesso di terzi.",
  [k.TERMS_AND_CONDITIONS_04]:
    "L'App matrimonio sarà disponibile per sei mesi dopo il matrimonio. Dopodiché, disattiveremo la Wedding App in modo da poter offrire l'indirizzo desiderato ad altre coppie. Se hai bisogno di mantenere online la tua applicazione per matrimoni per un periodo più lungo, possiamo trovare un accordo speciale.",
  [k.TERMS_AND_CONDITIONS_05]:
    "Una volta creata l'applicazione per matrimoni, questa può essere utilizzata per un solo matrimonio. Se, ad esempio, scopriamo che un utente ha ceduto o venduto un'applicazione per matrimoni ad altre coppie, ci riserviamo il diritto di impedirne l'utilizzo e di addebitare il costo per ulteriori matrimoni.",
  [k.TERMS_AND_CONDITIONS_06]: '§ 2 Protezione dei dati',
  [k.TERMS_AND_CONDITIONS_07]: 'Utilizzando il software, gli utenti accettano la',
  [k.TERMS_AND_CONDITIONS_08]: 'protezione dei dati',
  [k.TERMS_AND_CONDITIONS_09]: 'regolamento',
  [k.TERMS_AND_CONDITIONS_10]: '§ 3 Funzioni e servizi di EureHochzeitOnline.com',
  [k.TERMS_AND_CONDITIONS_11]:
    'Le singole applicazioni per matrimoni che possono essere create tramite EureHochzeitOnline.com offrono le seguenti caratteristiche',
  [k.TERMS_AND_CONDITIONS_12]: 'Personalizzazione:',
  [k.TERMS_AND_CONDITIONS_13]:
    "L'ospite può configurare e progettare alcuni aspetti dell'applicazione di matrimonio, ad esempio, per abbinare il design grafico delle partecipazioni di matrimonio, può scegliere sfondi, immagini, colori e font.",
  [k.TERMS_AND_CONDITIONS_14]: 'Galleria fotografica:',
  [k.TERMS_AND_CONDITIONS_15]:
    'Gli invitati possono scattare e caricare foto prima o durante il matrimonio. Queste foto possono essere visualizzate in una galleria di immagini live aggiornata automaticamente.',
  [k.TERMS_AND_CONDITIONS_16]: 'Richieste di musica:',
  [k.TERMS_AND_CONDITIONS_17]:
    'Gli invitati possono utilizzare una funzione di ricerca per richiedere canzoni per il matrimonio e valutare le richieste. Il padrone di casa può utilizzare questa funzione per selezionare le canzoni.',
  [k.TERMS_AND_CONDITIONS_18]: 'Informazioni sul matrimonio:',
  [k.TERMS_AND_CONDITIONS_19]:
    "L'organizzatore può pubblicare la location con mappe interattive, il programma e altre informazioni per i suoi ospiti nella sua app per matrimoni.",
  [k.TERMS_AND_CONDITIONS_20]:
    'EureHochzeitOnline.com si riserva il diritto di apportare modifiche alla portata e al design delle funzioni del software in qualsiasi momento.',
  [k.TERMS_AND_CONDITIONS_21]:
    "Per utilizzare le funzioni è necessario uno smartphone, un tablet o un PC moderno con un browser internet moderno. In particolare, le funzioni live come il caricamento di immagini, i quiz sul matrimonio o le richieste di musica richiedono una connessione internet stabile e veloce. EureHochzeitOnline.com non si assume alcuna responsabilità per l'accuratezza, la completezza, l'affidabilità, la tempestività e la fruibilità dei contenuti forniti.",
  [k.TERMS_AND_CONDITIONS_22]:
    "Durante i lavori di manutenzione, che EureHochzeitOnline.com annuncerà agli host, la piattaforma potrebbe essere temporaneamente non disponibile. Ci impegniamo al massimo per mantenere il nostro servizio disponibile e siamo orgogliosi di dire che non abbiamo mai deluso i nostri utenti. Tuttavia, non possiamo assumerci ulteriori obblighi, in particolare l'utente non ha diritto alla disponibilità costante del servizio.",
  [k.TERMS_AND_CONDITIONS_23]: "§ 4 Obblighi dell'utente",
  [k.TERMS_AND_CONDITIONS_24]:
    "L'utente può salvare e pubblicare i propri contenuti e file sulla piattaforma EureHochzeitOnline.com come ospite o come host. L'utente è responsabile di tali contenuti. L'utente si impegna a non pubblicare articoli, file, informazioni sul matrimonio o altri contenuti che violino il buon costume o le leggi vigenti. In particolare, l'utente si impegna a non pubblicare articoli, file, informazioni sul matrimonio o altri contenuti",
  [k.TERMS_AND_CONDITIONS_25]: 'la cui pubblicazione costituisca un reato penale o amministrativo,',
  [k.TERMS_AND_CONDITIONS_26]: 'che violino le leggi sul copyright, sui marchi o sulla concorrenza',
  [k.TERMS_AND_CONDITIONS_27]: 'che violino la Legge sui Servizi Legali,',
  [k.TERMS_AND_CONDITIONS_28]: 'o che contengano contenuti offensivi, razzisti, discriminatori o pornografici',
  [k.TERMS_AND_CONDITIONS_29]: 'che contengano pubblicità.',
  [k.TERMS_AND_CONDITIONS_30]:
    "EureHochzeitOnline.com ha il diritto di modificare o eliminare qualsiasi contenuto che violi o sia sospettato di violare tali obblighi. EureHochzeitOnline.com ha inoltre il diritto di bloccare l'accesso dell'utente e l'applicazione matrimoniale installata se l'utente viola i termini d'uso. L'utente dovrà risarcire il fornitore per qualsiasi danno causato dalla violazione degli obblighi. Il fornitore ha il diritto di chiedere all'utente un indennizzo per le richieste di risarcimento avanzate da terzi a causa della violazione di un diritto da parte dell'utente. L'utente è tenuto a sostenere il fornitore nella difesa di tali rivendicazioni. L'utente è inoltre tenuto a sostenere i costi di un'adeguata difesa legale del fornitore.",
  [k.TERMS_AND_CONDITIONS_31]: '§ 5 Proprietà Intellettuale',
  [k.TERMS_AND_CONDITIONS_32]: "La proprietà intellettuale dell'utente",
  [k.TERMS_AND_CONDITIONS_33]:
    "L'utente detiene i diritti di proprietà intellettuale dei contenuti, dei testi, delle immagini e degli altri materiali che pubblica. EureHochzeitOnline.com non rivendica alcun diritto di proprietà intellettuale su tali contenuti. Tuttavia, autorizzi EureHochzeitOnline.com ad accedere, copiare e distribuire tali contenuti allo scopo di fornirli agli invitati al matrimonio. Ci autorizzi inoltre a compiere qualsiasi altra azione necessaria per fornire i nostri servizi, tra cui, a titolo esemplificativo ma non esaustivo, la realizzazione di copie di backup e l'accesso al personale di supporto.",
  [k.TERMS_AND_CONDITIONS_34]: 'La nostra proprietà intellettuale',
  [k.TERMS_AND_CONDITIONS_35]:
    "Il Fornitore non concede alcuna licenza o altro diritto di utilizzo dei diritti di proprietà intellettuale del sito web o della piattaforma software di EureHochzeitOnline.com al di fuori di un'applicazione per matrimoni regolarmente creata e fornita in conformità ai Termini d'uso. La duplicazione, la distribuzione, la riproduzione, la trasmissione o altri usi dei diritti di proprietà intellettuale, compresa la messa a disposizione di terzi, non sono consentiti senza il previo consenso scritto del Fornitore.",
  [k.TERMS_AND_CONDITIONS_36]: '§ 6 Diritto di recesso',
  [k.TERMS_AND_CONDITIONS_37]:
    "Hai il diritto di recedere dal presente contratto entro quattordici giorni senza indicarne le ragioni. Il periodo di recesso è di quattordici giorni dalla conclusione del contratto. Puoi esercitare il tuo diritto di recesso inviando un'e-mail a",
  [k.TERMS_AND_CONDITIONS_38]:
    'con una chiara dichiarazione della tua decisione di recedere dal contratto. Puoi anche utilizzare il seguente modulo di esempio nella tua e-mail:',
  [k.TERMS_AND_CONDITIONS_39]: 'Esempio di modulo di recesso',
  [k.TERMS_AND_CONDITIONS_40]: '- A fidira Software UG (haftungsbeschränkt), e-mail: beeni@eurehochzeitonline.com:',
  [k.TERMS_AND_CONDITIONS_41]:
    "- Io/noi ( * ) con la presente revoca il contratto da me/noi ( * ) stipulato per l'attivazione della nostra app per matrimoni ( * )",
  [k.TERMS_AND_CONDITIONS_42]: '- Ordinato il ( * ) / Ricevuto il ( * )',
  [k.TERMS_AND_CONDITIONS_43]: '- Nome del/dei consumatore/i',
  [k.TERMS_AND_CONDITIONS_44]: '- Indirizzo del/dei consumatore/i',
  [k.TERMS_AND_CONDITIONS_45]: '- Firma del consumatore (solo per la notifica cartacea)',
  [k.TERMS_AND_CONDITIONS_46]: '- Data',
  [k.TERMS_AND_CONDITIONS_47]: '(*) Cancellare la dicitura non pertinenteIn ordine',
  [k.TERMS_AND_CONDITIONS_48]:
    'Per rispettare il periodo di recesso, è sufficiente inviare la comunicazione di esercizio del diritto di recesso prima della fine del periodo di recesso.Conseguenze della revoca:',
  [k.TERMS_AND_CONDITIONS_49]: 'Conseguenze della revoca:',
  [k.TERMS_AND_CONDITIONS_50]:
    "Se revochi il contratto, cancelleremo la tua app matrimonio e, se abbiamo già ricevuto dei pagamenti da parte tua, ti rimborseremo tali pagamenti utilizzando lo stesso metodo di pagamento. Il rimborso non comporterà alcun costo per l'utente.",
  [k.TERMS_AND_CONDITIONS_51]:
    "Nel caso di un contratto per la fornitura di contenuti digitali non su supporto fisico, come un'app per matrimoni, il diritto di revoca ai sensi della Sezione 356 (5) BGB scadrà anche se il Fornitore ha iniziato l'esecuzione del contratto dopo che l'Utente 1. ha espressamente acconsentito al Fornitore di iniziare l'esecuzione del contratto prima della scadenza del periodo di revoca e 2. ha confermato di essere consapevole che perderà il suo diritto di revoca accettando l'inizio dell'esecuzione del contratto.",
  [k.TERMS_AND_CONDITIONS_52]: '§ 7 Garanzia ed esonero di responsabilità',
  [k.TERMS_AND_CONDITIONS_53]:
    "Il Fornitore fornisce i propri servizi 'così come sono', 'con tutti i difetti' e 'come disponibili' e non fornisce alcuna garanzia esplicita o implicita di commerciabilità, idoneità per uno scopo particolare, prestazione a regola d'arte, non violazione o qualsiasi altra garanzia nella misura consentita dalla legge. Inoltre, non vi è alcuna garanzia che i servizi del Fornitore (o qualsiasi parte, caratteristica o contenuto degli stessi) siano (o saranno) completi, di particolare qualità, affidabili, sicuri, accurati, compatibili con le attività previste dall'utente, con i dispositivi utilizzati, con i sistemi operativi, con i browser o con i programmi (degli ospiti o degli host), o che siano conformi a qualsiasi requisito legale applicabile all'utente, o che i servizi siano privi di virus, bug o altri componenti dannosi o limitazioni di programma. Inoltre, EureHochzeitOnline.com non si assume alcuna responsabilità per qualsiasi azienda, prodotto o servizio (compresi i servizi di terze parti) menzionati o offerti sulla piattaforma EureHochzeitOnline.com. Non si assume alcuna responsabilità per la perdita di dati e informazioni e il fornitore non garantisce la ricostruzione di tali dati e informazioni. L'Utente dovrà eseguire il backup dei propri dati e informazioni a proprio rischio e pericolo. EureHochzeitOnline.com non si assume inoltre alcuna responsabilità per i contenuti pubblicati dagli utenti o per la corretta identità degli stessi.",
  [k.TERMS_AND_CONDITIONS_54]: '§ 8 Clausola di esclusione della responsabilità',
  [k.TERMS_AND_CONDITIONS_55]:
    "Se una disposizione del presente Contratto è ritenuta non valida o non applicabile per qualsiasi motivo o in qualsiasi misura, tale invalidità o inapplicabilità non pregiudica le restanti disposizioni del Contratto e l'applicazione di tale disposizione sarà applicata nella misura massima consentita dalla legge. Se EureHochzeitOnline.com rinuncia al diritto di far valere la violazione di uno o più termini e condizioni, ciò non significa che EureHochzeitOnline.com rinunci anche al diritto di far valere gli altri termini e condizioni.",
  [k.TERMS_AND_CONDITIONS_56]: '§ 9 Disposizioni finali',
  [k.TERMS_AND_CONDITIONS_57]:
    "Se non diversamente richiesto dalla legge, i contratti saranno regolati dalle leggi della Repubblica Federale di Germania. Il foro competente esclusivo per tutte le controversie/reclami derivanti da o in relazione alle presenti Condizioni d'uso sarà Monaco di Baviera. Ci riserviamo il diritto di modificare le presenti Condizioni d'uso in futuro e di pubblicare la nuova versione nello stesso luogo. Dopo la modifica delle Condizioni d'uso, la nuova versione deve essere confermata dall'utente.",
  [k.TERMS_AND_CONDITIONS_58]: 'Stato: 01.04.2018',
  [k.IMPRINT_00]: 'Impressum/Avviso legale',
  [k.IMPRINT_01]: 'fidira Software UG (responsabilità limitata)',
  [k.IMPRINT_02]: 'EureHochzeitOnline.de è un marchio commerciale di fidira Software UG',
  [k.IMPRINT_03]: 'Enzianstr. 31',
  [k.IMPRINT_04]: '86343 Königsbrunn',
  [k.IMPRINT_05]: 'E-Mail:',
  [k.IMPRINT_06]: 'Web:',
  [k.IMPRINT_07]: 'https://www.eurehochzeitonline.com',
  [k.IMPRINT_08]: 'Sede legale della società: Monaco di Baviera, Registro Commerciale di Monaco HRB',
  [k.IMPRINT_09]: 'Rappresentanti autorizzati:',
  [k.PRIVACY_00]: 'Informativa sulla privacy',
  [k.PRIVACY_01]: 'Introduzione e informazioni generali',
  [k.PRIVACY_02]:
    'Proteggere la tua privacy e quella dei tuoi invitati al matrimonio è una priorità assoluta per EureHochzeitOnline.com.',
  [k.PRIVACY_03]:
    "In conformità con i requisiti della Legge Federale Tedesca sulla Protezione dei Dati (BDSG) e della Legge Tedesca sui Telemedia (TMG), garantiamo in ogni momento che i tuoi dati vengano utilizzati solo per la creazione e l'utilizzo della tua app di matrimonio personale e per ottimizzare l'esperienza utente della tua app di matrimonio. Utilizziamo la tecnologia TLS/SSL (Transport Layer Security / Secure Sockets Layer) sia per la creazione che per l'utilizzo delle app per matrimoni, in modo che i dati siano sempre criptati durante la trasmissione. Tutti i nostri server applicativi sono situati nell'Unione Europea e rispettano le normative sulla privacy dell'UE. Durante il processo di registrazione, ti verrà chiesto di dare il tuo consenso al trattamento dei dati descritto nella presente Informativa sulla privacy cliccando sulla casella di controllo. Fornendo il tuo consenso, confermi che possiamo raccogliere, elaborare e utilizzare i tuoi dati personali nella misura descritta di seguito. Puoi ritirare il tuo consenso in qualsiasi momento. Per farlo, è sufficiente inviare un'e-mail al nostro servizio clienti all'indirizzo",
  [k.PRIVACY_04]:
    "In fase di registrazione ti verrà richiesto il consenso alle operazioni di trattamento descritte nell'informativa privacy, che fornirai cliccando sull'apposito checkbox. Dando il tuo consenso, confermi che possiamo raccogliere, elaborare e utilizzare i tuoi dati personali nella misura descritta di seguito. Puoi anche revocare il tuo consenso in qualsiasi momento. Per fare ciò è sufficiente inviare una e-mail al nostro servizio clienti all'indirizzo",
  [k.PRIVACY_05]:
    '. Su richiesta, il nostro servizio clienti ti fornirà anche informazioni su tutti i dati memorizzati che ti riguardano (ai sensi del § 13 (7) della legge tedesca sui media telematici (Telemediengesetz)) e li cancellerà su tua richiesta.',
  [k.PRIVACY_06]: '§ 1 Ente responsabile e ambito di applicazione',
  [k.PRIVACY_07]:
    "L'ente responsabile è fidira Software UG (haftungsbeschränkt), di seguito indicato anche come 'fornitore', 'noi' o 'EureHochzeitOnline.com'. I dettagli relativi all'indirizzo e ai rappresentanti autorizzati sono riportati nell'imprinting.",
  [k.PRIVACY_08]:
    "La presente informativa sulla privacy riguarda la raccolta e l'utilizzo dei dati personali durante la visita e l'utilizzo di qualsiasi piattaforma del fornitore tramite browser internet. Attraverso queste offerte, il Fornitore permette di creare un'applicazione di matrimonio individuale con la quale gli invitati possono informarsi sul matrimonio e interagire tra loro durante il matrimonio (ad esempio, condividere foto o rispondere a domande). Il Fornitore utilizzerà i dati personali anche a fini statistici e di analisi di mercato. A tal fine, compiliamo statistiche anonime, ad esempio su matrimoni, località, dimensioni del matrimonio o utilizzo dell'app, al fine di ottimizzare ulteriormente la nostra offerta.",
  [k.PRIVACY_09]: '§ 2 Link ad altri siti web',
  [k.PRIVACY_10]:
    "Il nostro sito web può contenere link ad altri siti web. Non abbiamo alcun controllo sul contenuto a cui sei indirizzato dopo aver cliccato su questi link, né sul trattamento dei tuoi dati che possono essere trasmessi a terzi (ad esempio il tuo indirizzo IP). Pertanto, non possiamo assumerci alcuna responsabilità per i contenuti di terzi e per l'elaborazione di tali dati da parte di terzi.",
  [k.PRIVACY_11]: '§ 3 Utilizzo dei dati senza registrazione',
  [k.PRIVACY_12]:
    "Quando visiti il sito web o l'app di EureHochzeitOnline.com, salviamo automaticamente alcune informazioni di base sulla tua visita: in particolare, l'indirizzo IP, le pagine visitate e la durata della visita. Utilizziamo queste informazioni per compilare statistiche anonime sul comportamento degli utenti e sulle funzioni più utilizzate, al fine di migliorare continuamente la nostra offerta. L'indirizzo IP dell'utente viene memorizzato nei file di log tecnici solo per un periodo di tempo limitato al fine di proteggere i nostri sistemi e le app matrimoniali che creiamo da abusi e frodi.",
  [k.PRIVACY_13]: '§ 4 Utilizzo dei dati al momento della registrazione',
  [k.PRIVACY_14]:
    "Per poter accedere a un'app per matrimoni come ospite o per creare un'app per matrimoni come ospite, l'utente deve registrarsi. Durante il processo di registrazione, verranno memorizzati l'indirizzo e-mail dell'utente, un nome visualizzato (liberamente selezionabile, può anche essere uno pseudonimo inventato), un codice matrimonio (se applicabile) e una password (liberamente definibile). Queste informazioni vengono utilizzate per proteggere gli annunci di matrimonio dei nostri host da accessi non autorizzati. Quando si crea un'app per matrimoni, gli host possono inserire informazioni sul loro matrimonio (ad esempio, luogo, orario, programma) che saranno visibili solo agli ospiti di quel matrimonio. A tal fine, l'organizzatore genera dei codici di matrimonio che gli ospiti devono inserire per accedere all'applicazione.",
  [k.PRIVACY_15]:
    "Utilizziamo il tuo indirizzo e-mail anche per scopi di comunicazione: ad esempio, possiamo inviare all'organizzatore notifiche via e-mail relative a nuove registrazioni, altre notizie sul matrimonio e inviare all'indirizzo e-mail dei servizi di EureHochzeitOnline.com (ad esempio, sondaggi sulla soddisfazione). Inoltre, mostriamo gli indirizzi e-mail degli invitati al matrimonio all'organizzatore in modo che possa utilizzarli per l'organizzazione del matrimonio.",
  [k.PRIVACY_16]: 'Il tuo indirizzo e-mail non sarà mai condiviso con terze parti o altre aziende.',
  [k.PRIVACY_17]: '§ 5 Cookie',
  [k.PRIVACY_18]:
    "Per offrirti la migliore esperienza d'uso, utilizziamo i 'cookie' e la cosiddetta 'memorizzazione locale' del tuo browser. Questo ci permette di memorizzare nel tuo browser piccole quantità di dati relativi alla tua App per matrimoni in modo che, ad esempio, le tue modifiche non vadano perse quando chiudi il browser, passi alla modalità a schermo intero dell'App Configurator o non debba effettuare il login ogni volta. Se non vuoi utilizzare i cookie o vuoi eliminare quelli esistenti, puoi disabilitarli o rimuoverli tramite il tuo browser. Per sapere come fare, consulta le istruzioni del tuo browser o contattaci per ricevere assistenza.",
  [k.PRIVACY_19]:
    "Utilizziamo i cookie anche per i servizi di terze parti elencati di seguito in '§ 7 Applicazioni e siti web' per offrirti una migliore esperienza d'uso.",
  [k.PRIVACY_20]: '§ 6 Applicazioni e siti web',
  [k.PRIVACY_21]: 'Google Maps',
  [k.PRIVACY_22]:
    "Utilizziamo Google Maps di Google Inc, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA per visualizzare mappe interattive. Il tuo indirizzo IP, la tua posizione e le informazioni sul tuo utilizzo dei nostri servizi saranno trasmessi e memorizzati da Google su server negli Stati Uniti. Le condizioni di utilizzo di Google Maps sono disponibili all'indirizzo ",
  [k.PRIVACY_23]: 'https://www.google.com/intl/de_de/help/terms_maps.html',
  [k.PRIVACY_24]: 'HotJar',
  [k.PRIVACY_25]:
    "Utilizziamo anche HotJar, uno strumento di analisi web di Hotjar Ltd., per raccogliere e archiviare dati a fini di marketing e ottimizzazione. I dati possono essere utilizzati per creare profili utente pseudonimi. Vengono registrate visite individuali selezionate a caso (senza ulteriori informazioni personali, i cosiddetti 'attributi utente'), creando così un registro dei movimenti del mouse e dei clic con l'intento di riprodurre casualmente le singole visite al sito web e ricavarne potenziali miglioramenti. I dati raccolti non saranno utilizzati per identificare personalmente il visitatore di questo sito web, né saranno uniti ai dati personali del portatore dello pseudonimo, senza il consenso separato dell'interessato.Se non vuoi essere tracciato, puoi disabilitare globalmente il tracciamento su tutti i siti web che utilizzano HotJar per il browser che stai utilizzando cliccando sul seguente link",
  [k.PRIVACY_26]: 'https://www.hotjar.com/privacy/do-not-track/',
  [k.PRIVACY_27]: 'Mailchimp',
  [k.PRIVACY_28]:
    'Per inviare la nostra newsletter utilizziamo Mailchimp di The Rocket Science Group, LLC, 675 Ponce de Leon Ave NE Suite 5000, Atlanta, GA 30308 USA (Mailchimp). Questo ci permette di contattare direttamente gli iscritti. Inoltre, analizziamo il tuo comportamento di utilizzo per ottimizzare le nostre offerte.',
  [k.PRIVACY_29]: 'A tal fine, condividiamo le seguenti informazioni personali con Mailchimp',
  [k.PRIVACY_30]: 'Indirizzo e-mail [Nome] [Data del matrimonio] [Informazioni sul matrimonio] [Dettagli di accesso].',
  [k.PRIVACY_31]:
    "I nostri messaggi di posta elettronica includeranno un link per aggiornare i tuoi dati personali. Mailchimp è il destinatario dei tuoi dati personali e agisce come incaricato del trattamento per nostro conto per quanto riguarda la distribuzione della nostra newsletter. Il trattamento dei dati descritti in questa sezione non è richiesto dalla legge o dal contratto. Senza il tuo consenso e l'invio dei tuoi dati personali, non possiamo inviarti la newsletter. Inoltre, Mailchimp raccoglie i seguenti dati personali utilizzando i cookie e altri metodi di tracciamento Informazioni sul tuo dispositivo (indirizzo IP, informazioni sul dispositivo, sistema operativo, ID del browser, informazioni sull'applicazione che utilizzi per leggere le tue e-mail e altre informazioni sull'hardware e sulla connessione a Internet. Inoltre, vengono raccolti dati di utilizzo come la data e l'ora di apertura dell'email/campagna e l'attività del browser (ad esempio, quali email/siti web sono stati aperti). Mailchimp ha bisogno di queste informazioni per garantire la sicurezza e l'affidabilità dei sistemi, per far rispettare i Termini di servizio e per prevenire abusi. Ciò corrisponde al legittimo interesse di Mailchimp (ai sensi dell'art. 6 par. 1 lett. f GDPR) e serve all'esecuzione del contratto (ai sensi dell'art. 6 par. 1 lett. b GDPR). Mailchimp analizza anche i dati relativi alle prestazioni, come le statistiche di consegna delle e-mail e altri dati di comunicazione. Queste informazioni vengono utilizzate per compilare statistiche sull'utilizzo e sulle prestazioni dei Servizi. Mailchimp raccoglie informazioni su di te anche da altre fonti. Di tanto in tanto e su scala indeterminata, le informazioni personali vengono raccolte attraverso i social media e altri fornitori di dati di terze parti. Non abbiamo alcun controllo su questi processi. ",
  [k.PRIVACY_32]:
    'Per maggiori informazioni sulle tue scelte e sulle opzioni di rimozione con Mailchimp, visita il sito',
  [k.PRIVACY_33]: 'https://mailchimp.com/legal/privacy/#3._Privacy_for_Contacts',
  [k.PRIVACY_34]:
    "base giuridica per questo trattamento è il tuo consenso ai sensi dell'art. 6 par. 1 lit. a GDPR. Puoi revocare il consenso al trattamento dei tuoi dati personali in qualsiasi momento. Troverai un link per farlo in tutti i messaggi di posta elettronica. Puoi anche revocare il tuo consenso utilizzando le opzioni di contatto fornite. La revoca non pregiudica la liceità del trattamento già effettuato.",
  [k.PRIVACY_35]:
    "I tuoi dati saranno trattati per tutto il tempo in cui avrai dato il tuo consenso. In caso contrario, saranno cancellati dopo la conclusione del contratto tra noi e Mailchimp, a meno che i requisiti legali non rendano necessaria un'ulteriore conservazione.",
  [k.PRIVACY_36]:
    "Mailchimp ha implementato misure di conformità per i trasferimenti internazionali di dati. Tali misure si applicano a tutte le operazioni globali in cui Mailchimp tratta i dati personali di individui nell'UE. Queste misure si basano sulle Clausole Contrattuali Standard (SCC) dell'UE. Ulteriori informazioni sono disponibili all'indirizzo",
  [k.PRIVACY_37]: 'https://mailchimp.com/legal/data-processing-addendum/',
  [k.PRIVACY_38]: 'Spotify',
  [k.PRIVACY_39]:
    "Utilizziamo anche il Widget Spotify Music Player, un lettore musicale di Spotify AB, per permetterti di ascoltare la playlist del matrimonio. Il fornitore del servizio Spotify Music Player Widget è Spotify AB, Regeringsgatan 19, 111 53 Stoccolma, Svezia. Maggiori informazioni sul trattamento dei dati da parte di Spotify AB sono disponibili all'indirizzo",
  [k.PRIVACY_40]: 'https://www.spotify.com/de/legal/privacy-policy/',
  [k.PRIVACY_41]: 'Pixel di Facebook',
  [k.PRIVACY_42]:
    "Per migliorare EureHochzeitOnline.com e farlo conoscere a un gruppo più ampio di utenti, utilizziamo il Pixel di Facebook Inc, 1601 South California Avenue, Palo Alto, CA 94304, USA ('Facebook'). A tal fine, Facebook inserisce un cookie nel tuo browser. Le informazioni generate da questo cookie sull'utilizzo del sito web da parte dell'utente saranno trasmesse e memorizzate da Facebook su server situati negli Stati Uniti. Facebook può utilizzare questo trasferimento di dati per determinare se una determinata pagina è stata visitata dal tuo browser e se ciò è avvenuto cliccando su una pubblicità. Tuttavia, non utilizziamo questi dati per raccogliere informazioni dal nostro sito. Facebook utilizza queste informazioni per aiutarci a creare ulteriori inserzioni e per promuovere EureHochzeitOnline.com. Valutiamo i dati ottenuti dal pixel di Facebook nello strumento di analisi di Facebook 'Facebook Analytics' al fine di migliorare ulteriormente EureHochzeitOnline.com. Facebook può trasferire queste informazioni a terzi qualora ciò sia richiesto dalla legge o qualora tali terzi trattino le informazioni per conto di Facebook. Utilizzando EureHochzeitOnline.com, acconsenti al trattamento dei tuoi dati da parte di Facebook nelle modalità descritte e allo scopo di migliorare EureHochzeitOnline.com. Non condividiamo con Facebook nessuna delle tue informazioni personali, di contatto o di matrimonio. Per saperne di più sulle politiche di sicurezza e di privacy del Pixel di Facebook puoi visitare il sito",
  [k.PRIVACY_43]: 'https://www.facebook.com/policy.php',
  [k.PRIVACY_44]: 'Puoi anche rinunciare alla raccolta di informazioni da parte di Facebook Pixel cliccando qui:',
  [k.PRIVACY_45]: 'Disattiva Pixel',
  [k.PRIVACY_46]: "§ 7 Diritto all'informazione e altri diritti secondo il BDSG",
  [k.PRIVACY_47]:
    "Saremo lieti di fornirti in qualsiasi momento informazioni sui tuoi dati personali da noi conservati. Su tua richiesta, provvederemo anche a correggere, bloccare o cancellare i tuoi dati. Puoi contattarci in qualsiasi momento per queste richieste o per le dichiarazioni di revoca all'indirizzo",
  [k.PRIVACY_48]: 'e provvederemo ad apportare le modifiche nel più breve tempo possibile. ',
  [k.PRIVACY_49]: '§ 8 Modifiche alla dichiarazione sulla protezione dei dati',
  [k.PRIVACY_50]:
    'Internet e le possibilità tecniche sono in continuo sviluppo. È ovvio che utilizzeremo queste nuove possibilità per rendere i matrimoni dei nostri utenti ancora più indimenticabili, il che potrebbe portarci ad aggiornare e modificare la nostra Informativa sulla privacy.',
  [k.PRIVACY_51]: 'Ultimo aggiornamento: 11/28/2022',
  [k.IF_YOU_NEED_MORE_LANGUAGES]: 'Se hai bisogno di più lingue, scrivici una e-mail.',
  [k.DECIDE_LANGUAGES]:
    'Decidi quali lingue deve supportare il tuo sito web di matrimonio. Puoi inserire i tuoi testi per tutte le lingue selezionate.',
  [k.EDIT_LANGUAGES]: `Modifica lingue`,
  [k.ACTIVE_VOUCHER]: `Buono attivo:`,
  [k.ENTER_VOUCHER_CODE]: `Inserisci il codice coupon`,
  [k.VOUCHER_CODE]: `Codice di sconto`,
  [k.INVALID_CODE]: `Purtroppo non è un codice valido!`,
  [k.WANT_CUSTOM_LOGO]: `Volete utilizzare un proprio logo per il matrimonio?`,
  [k.INDIVIDUAL_LOGO]: `Logo personalizzato`,
  [k.STANDARD_LOGO]: `Logo standard`,
  [k.PRIVATE_PURCHASE]: `L'acquisto è privato`,
  [k.COMMERCIAL_PURCHASE]: `L'acquisto è commerciale`,
  [k.MAX_GUESTS]: 'Free Version: Maximum Number of Guests',
  [k.MAX_GUESTS_REACHED]:
    'This wedding is still using the free basic version of EureHochzeitOnline.com but now has more than five registered guests. According to our terms of use, an upgrade to a complete or premium package is required. Therefore, please upgrade your package to continue using your wedding website as usual.',
  [k.ACTIVATE_PACKAGE]: 'Unlock Package',
  [k.IF_MESSAGE_WRONGFUL]: 'If you believe this message is displayed incorrectly, please send us an email at:',
};
