import i18n from 'i18next';
import k from './../../i18n/keys';
import React, { useEffect, useState, useContext } from 'react';
import { hotjar } from 'react-hotjar';
import ReactPixel from 'react-facebook-pixel';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { RHFInput } from 'react-hook-form-input';
import { Auth } from 'aws-amplify';
import axios from 'axios';

import UserContext from '../../Shared/Context/UserContext';
import defaultStyles from '../../Shared/WeddingStyles/DefaultStyles';
import GenericStyleContainer from '../../Shared/WeddingStyles/GenericStyleContainer';
import ModernOnboardingContainer from '../../Shared/WeddingStyles/Modern/ModernOnboardingContainer';
import VintageOnboardingContainer from '../../Shared/WeddingStyles/Vintage/VintageOnboardingContainer';
import FlowerOnboardingContainer from '../../Shared/WeddingStyles/Flower/FlowerOnboardingContainer';
import KlassischOnboardingContainer from '../../Shared/WeddingStyles/Klassisch/KlassischOnboardingContainer';
import getSearchParamsAsObject from '../../Shared/FunctionUtils/getSearchParamsAsObject';
import OnboardingStyleContainer from '../../Shared/Components/OnboardingStyleContainer/OnboardingStyleContainer';
import FidiraDialog from '../../Shared/Components/Dialog/FidiraDialog';
import LinkButton from '../../Shared/Components/LinkButton/LinkButton';
import isProduction from '../../Shared/FunctionUtils/isProduction';

import SavePasswordButton from './Components/SavePasswordButton';
import Container from './Components/Container';
import StyledLinearProgress from './Components/StyledLinearProgress';
import StyledTextField from './Components/StyledTextField';
import StyledLink from './Components/StyledLink';
import StyledForm from './Components/StyledForm';
import LanguageSelect from '../LandingPage/Components/LanguageSelect';
import LanguageContext from '../../Shared/Context/LanguageContext';

const MailConfirmationPage = ({ cookiePreference }) => {
  const history = useHistory();
  const { setAuthenticatedUser } = useContext(UserContext);
  const { currentLanguage } = useContext(LanguageContext);
  const { handleSubmit, register, errors, setValue } = useForm({});
  const [loadingWeddingConfig, setLoadingWeddingConfig] = useState(true);
  const [errorWeddingConfig, setErrorWeddingConfig] = useState(false);
  const [loadingUserCreation, setLoadingUserCreation] = useState(false);
  const [errorUserCreationUsernameExists, setErrorUserCreationUsernameExists] = useState(false);
  const [errorUserCreationOther, setErrorUserCreationOther] = useState(false);
  const [weddingDataFromUrl, setWeddingDataFromUrl] = useState(null);
  const [weddingConfig, setWeddingConfig] = useState(null);

  const onSubmit = async values => {
    setLoadingUserCreation(true);
    try {
      const createdUser = await Auth.signUp({
        username: weddingConfig.mail,
        password: values.password,
      });
      const fidiraUser = await axios.post(`user-create/`, {
        creationType: 'AS_WEDDING_CREATOR',
        sub: createdUser.userSub,
        mail: weddingConfig.mail,
        weddingId: weddingConfig.weddingName,
        key: weddingDataFromUrl.key,
        role: weddingDataFromUrl.role,
        language: currentLanguage,
      });
      const signedInUser = await Auth.signIn(weddingConfig.mail, values.password);
      setAuthenticatedUser(signedInUser, fidiraUser.data);
      setLoadingUserCreation(false);
      history.push(`/${weddingConfig.weddingName}`);
    } catch (error) {
      if (error.code === 'UsernameExistsException') {
        setErrorUserCreationUsernameExists(true);
        setErrorUserCreationOther(false);
      } else {
        setErrorUserCreationOther(true);
        setErrorUserCreationUsernameExists(false);
      }
    }
  };

  const onClose = () => {
    setErrorUserCreationOther(false);
    setErrorUserCreationUsernameExists(false);
    setLoadingUserCreation(false);
  };

  const onForgotPassword = async () => {
    await Auth.signOut();
    setAuthenticatedUser(null, null);
    await Auth.forgotPassword(weddingConfig.mail, {
      reasonForMail: 'ForgotPassword',
      username: weddingConfig.mail,
      displayName: '',
      wedding: weddingConfig.weddingName,
    });
    history.push(`/${weddingConfig.weddingName}/login/forgotPasswordMessage`);
  };

  useEffect(() => {
    if (cookiePreference === 'ALL' && isProduction()) {
      hotjar.initialize(1808603, 6);
    }
  }, [cookiePreference]);

  useEffect(() => {
    const weddingData = getSearchParamsAsObject(window.location.search);
    setWeddingDataFromUrl(weddingData);
    const loadWeddingConfigForAdminRegistration = async () => {
      setLoadingWeddingConfig(true);
      try {
        const weddingConfig = await axios.post(`mail-confirm/`, {
          weddingName: weddingData.name,
          confirmationKey: weddingData.key,
          infoKey: weddingData.info,
        });
        setLoadingWeddingConfig(false);
        setWeddingConfig(weddingConfig.data);
        ReactPixel.trackCustom('LeadRegistrationCompleteFunnel', { mailAddress: weddingConfig.data.mail });
      } catch (error) {
        console.error(error);
        setErrorWeddingConfig(true);
      } finally {
        setLoadingWeddingConfig(false);
      }
    };
    const finishDoiForGuests = async () => {
      setLoadingWeddingConfig(true);
      try {
        const userConfig = await axios.patch(`user-update/`, {
          updateType: 'CONFIRM_DOI_OF_GUEST',
          confirmationKey: weddingData.key,
          username: weddingData.info,
          weddingId: weddingData.name,
          nameOfBride: weddingData.nameOfBride,
          nameOfGroom: weddingData.nameOfGroom,
          role: 'GUEST',
        });
        setLoadingWeddingConfig(false);
        setWeddingConfig(userConfig.data);
      } catch (error) {
        console.error(error);
        setErrorWeddingConfig(true);
      } finally {
        setLoadingWeddingConfig(false);
      }
    };
    if (weddingData.role === 'BRIDE' || weddingData.role === 'GROOM') {
      loadWeddingConfigForAdminRegistration();
    } else {
      finishDoiForGuests();
    }
  }, []);

  return (
    <>
      {weddingDataFromUrl && (
        <GenericStyleContainer weddingStyleData={defaultStyles[weddingDataFromUrl.style]}>
          <LanguageSelect></LanguageSelect>
          <OnboardingStyleContainer>
            {weddingDataFromUrl.style === 'modern' && <ModernOnboardingContainer visible />}
            {weddingDataFromUrl.style === 'vintage' && <VintageOnboardingContainer visible />}
            {weddingDataFromUrl.style === 'flower' && <FlowerOnboardingContainer visible />}
            {weddingDataFromUrl.style === 'klassisch' && <KlassischOnboardingContainer visible />}
            {loadingWeddingConfig && (
              <Container>
                <StyledLinearProgress visible="true" />
              </Container>
            )}

            {!loadingWeddingConfig && weddingConfig && !errorWeddingConfig && (
              <Container>
                <h1>{i18n.t(k.THANK_YOU)}</h1>
                <p>
                  {i18n.t(k.ACTIVATED_MAIL)} <br></br>
                  {weddingDataFromUrl.role === 'GUEST' && (
                    <StyledLink href={`/${weddingConfig.weddingId}`}>
                      <br></br>
                      {i18n.t(k.CLICK_HERE_TO_GO_TO_WED)} {weddingConfig.nameOfBride} {i18n.t(k.AND)}{' '}
                      {weddingConfig.nameOfGroom} {i18n.t(k.BE_REDIRECTED)}
                    </StyledLink>
                  )}
                  {weddingDataFromUrl.role !== 'GUEST' && <>{i18n.t(k.SET_A_PASSWORD)}</>}
                </p>
                {weddingDataFromUrl.role !== 'GUEST' && weddingConfig.weddingName && (
                  <>
                    <StyledForm onSubmit={handleSubmit(onSubmit)}>
                      <StyledTextField
                        style={{ display: 'none' }}
                        type="email"
                        variant="filled"
                        name="username"
                        label={i18n.t(k.E_MAIL_ADDRESS)}
                        autoComplete="username"
                      />

                      <RHFInput
                        as={
                          <StyledTextField
                            type="password"
                            variant="filled"
                            error={!!errors.password}
                            helperText={errors.password ? i18n.t(k.MINIMUM_6_CHARACTERS) : ''}
                            label={i18n.t(k.SET_A_PASSWORD_SHORT)}
                            autoComplete="new-password"
                          />
                        }
                        rules={{
                          required: true,
                          minLength: 6,
                        }}
                        mode="onSubmit"
                        name="password"
                        register={register}
                        setValue={setValue}
                      ></RHFInput>
                      <StyledLinearProgress visible={loadingUserCreation.toString()} />
                      <SavePasswordButton type="submit" variant="contained">
                        {i18n.t(k.SAVE)}
                      </SavePasswordButton>
                    </StyledForm>
                  </>
                )}
              </Container>
            )}

            {!loadingWeddingConfig && errorWeddingConfig && (
              <>
                <p>{i18n.t(k.ERROR_OCCURED)}</p>
                <a href="mailto:beeni@eurehochzeitonline.com">beeni@eurehochzeitonline.com</a>
              </>
            )}

            {!loadingWeddingConfig && weddingConfig && (
              <>
                <FidiraDialog
                  open={errorUserCreationUsernameExists}
                  messageTitle={i18n.t(k.PASSWORD_ALREADY_EXISTING_TITLE)}
                  messageDescriptionJsx={
                    <span>
                      {i18n.t(k.PASSWORD_ALREADY_EXISTING)}{' '}
                      <StyledLink href={`/${weddingConfig.weddingName}/`}>{i18n.t(k.GO_TO_WEDDING)}</StyledLink>
                      {i18n.t(k._DOT)}
                      <br></br>
                      <br></br>
                      <LinkButton onClick={onForgotPassword}>{i18n.t(k.FORGOT_PASSWORD)}</LinkButton>
                    </span>
                  }
                  handleClose={onClose}
                  suppressDefaultErrorText
                ></FidiraDialog>
                <FidiraDialog
                  open={errorUserCreationOther}
                  messageDescriptionJsx={i18n.t(k.COULD_NOT_SAVE_PASSWORD)}
                  handleClose={onClose}
                ></FidiraDialog>
              </>
            )}
          </OnboardingStyleContainer>
        </GenericStyleContainer>
      )}
    </>
  );
};

export default MailConfirmationPage;
