import React from 'react';
import styled from 'styled-components';

import CircularProgress from './CircularProgress';

const Container = styled.div`
  position: relative;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  border-top-left-radius: 30px;
  background-color: #ffffffc4;
`;

const OverlayContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffffc4;
  width: ${({ numberOfButtons }) => `${numberOfButtons * 61}px`};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  border-top-left-radius: 30px;
`;

const InnerContainer = ({ children, loadingSave, numberOfButtons }) => {
  return (
    <Container>
      {loadingSave ? (
        <OverlayContainer numberOfButtons={numberOfButtons}>
          <CircularProgress size={30}></CircularProgress>
        </OverlayContainer>
      ) : (
        <>{children}</>
      )}
    </Container>
  );
};

export default InnerContainer;
