import i18n from 'i18next';
import k from './../../../../../../i18n/keys';
import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/de';
import { useForm } from 'react-hook-form';
import { RHFInput } from 'react-hook-form-input';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { FormControlLabel, Switch } from '@material-ui/core';

import formatDate from '../../../../../../Shared/FunctionUtils/formatDate';
import getUpcomingFourSeasons from '../../../../../../Shared/FunctionUtils/getUpcomingFourSeasons';
import isMobile from '../../../../../../Shared/FunctionUtils/isMobile';
import breakpoints from '../../../../../../Shared/breakpoints';
import WeddingContext from '../../../../../../Shared/Context/WeddingContext';
import IconBox from '../../../../../../Shared/Components/SelectionComponent/IconBox';
import SelectionContainer from '../../../../../../Shared/Components/SelectionComponent/SelectionContainer';

import Dialog from '../../../../../../Shared/Components/Editing/EditingDialog/Dialog';
import DialogContent from '../../../../../../Shared/Components/Editing/EditingDialog/DialogContent';
import DialogTitle from '../../../../../../Shared/Components/Editing/EditingDialog/DialogTitle';
import DialogActions from '../../../../../../Shared/Components/Editing/EditingDialog/DialogActions';
import TextField from '../../../../../../Shared/Components/Editing/EditingDialog/TextField';
import ErrorMessage from '../Components/ErrorMessage';
import FadeOuterContainer from '../Components/FadeOuterContainer';
import FadeContainer from '../Components/FadeContainer';
import ImageUploader from '../Components/ImageUploader/ImageUploader';

const CustomLogoContainer = styled.div`
  width: 400px;
  max-width: 100%;
  margin: 6px auto;
  text-align: center;
  .image {
    height: 120px;
    background-size: contain;
    background-repeat: no-repeat;
  }
`;

const WeddingDateContainer = styled.div`
  width: 400px;
  max-width: 100%;
  min-height: 300px;
  margin: 6px auto;
  text-align: center;
  @media (max-width: ${breakpoints.xs}) {
    min-height: ${({ exact }) => (exact ? '150px' : '300px')};
  }
  .selection-container-outer {
    overflow: hidden;
    min-height: 280px;
    @media (max-width: ${breakpoints.xs}) {
      max-width: 300px;
      min-height: ${({ exact }) => (exact ? '150px' : '230px')};
    }
  }
  .selection-container-flex {
    flex-wrap: wrap;
    justify-content: center;
  }
  .MuiTextField-root {
    width: 400px;
    max-width: 100%;
  }
`;
const DatePickerContainer = styled.div`
  .native-picker input {
    text-align: center;
    padding-left: 50px;
  }
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: -24px;
`;

const StyledSwitch = styled(Switch)`
  @media (min-width: ${breakpoints.xs}) {
    margin-bottom: -18px;
    margin-top: -12px;
  }
`;

const Question = styled.p`
  margin: 0;
`;

const Hint = styled.p`
  width: 400px;
  max-width: 100%;
  font-size: 14px !important;
  margin: auto;
  line-height: 1.2;
  margin-top: 9px;
`;

const EditingDialogBaseData = ({
  nameOfGroom,
  nameOfBride,
  weddingDate,
  weddingDateIsExact,
  stopEditing,
  saveChanges,
  useCustomLogo,
  customLogoUrl,
}) => {
  const formattedWeddingDate = weddingDateIsExact
    ? Date.parse(`${weddingDate.split('.')[1]}/${weddingDate.split('.')[0]}/${weddingDate.split('.')[2]}`)
    : new Date(new Date().setFullYear(new Date().getFullYear() + 1));
  const season = weddingDateIsExact ? '' : weddingDate;
  const [currentWeddingDate, setCurrentWeddingDate] = useState(formattedWeddingDate);
  const [currentSeason, setCurrentSeason] = useState(season);
  const [currentWeddingDateIsExact, setCurrentWeddingDateIsExact] = useState(weddingDateIsExact);
  const [showError, setShowError] = useState(null);
  const { weddingConfig } = useContext(WeddingContext);
  const { handleSubmit, register, errors, setValue } = useForm({});
  const [currentUseCustomLogo, setCurrentUseCustomLogo] = useState(useCustomLogo);
  const [currentCustomLogoUrl, setCurrentCustomLogoUrl] = useState(customLogoUrl);

  const handleDateSwitch = e => {
    setCurrentWeddingDateIsExact(e.target.checked);
    setShowError(false);
  };

  const handleLogoSwitch = e => {
    setCurrentUseCustomLogo(e.target.checked);
    setShowError(false);
  };

  const handleNativeDateChange = event => {
    const date = new Date(event.target.valueAsNumber);
    setCurrentWeddingDate(date);
    setShowError(false);
  };

  const handleDateChange = date => {
    if (!Number.isNaN(Date.parse(date))) {
      setCurrentWeddingDate(date);
      setShowError(false);
    } else {
      setCurrentWeddingDate(null);
      setShowError(false);
    }
  };

  const onSelect = selection => {
    setCurrentSeason(selection.value);
    setShowError(false);
  };

  const handleSave = async values => {
    const weddingDateToBeSaved = currentWeddingDateIsExact
      ? new Date(currentWeddingDate).toLocaleDateString('de-DE', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      : currentSeason;
    if (!weddingDateToBeSaved || weddingDateToBeSaved === '01.01.1970' || weddingDateToBeSaved === 'Invalid Date') {
      setShowError(true);
      return;
    }
    saveChanges({
      nameOfBride: values.currentNameOfBride,
      nameOfGroom: values.currentNameOfGroom,
      weddingDate: weddingDateToBeSaved,
      weddingDateIsExact: currentWeddingDateIsExact,
      showCountdown: weddingConfig.showCountdown,
      selectedLanguagesForWedding: weddingConfig.selectedLanguagesForWedding,
      useCustomLogo: currentUseCustomLogo,
      customLogoUrl: currentCustomLogoUrl,
    });
  };

  return (
    <Dialog onSubmit={handleSubmit(handleSave)} open>
      <DialogTitle>{i18n.t(k.EDIT_WEDDING_BASE_DATA)}</DialogTitle>
      <DialogContent>
        <RHFInput
          as={
            <TextField
              type="text"
              variant="filled"
              label={i18n.t(k.NAME_OF_BRIDE)}
              ref={register}
              autoFocus={isMobile() ? false : true}
              error={!!errors.currentNameOfBride}
              helperText={errors.currentNameOfBride ? i18n.t(k.ENTER_NAME_OF_BRIDE) : ''}
            />
          }
          rules={{
            required: true,
          }}
          mode="onBlur"
          name="currentNameOfBride"
          register={register}
          setValue={setValue}
          defaultValue={nameOfBride}
        ></RHFInput>
        <RHFInput
          as={
            <TextField
              type="text"
              variant="filled"
              ref={register}
              label={i18n.t(k.NAME_OF_GROOM)}
              error={!!errors.currentNameOfGroom}
              helperText={errors.currentNameOfGroom ? i18n.t(k.ENTER_NAME_OF_GROOM) : ''}
            />
          }
          rules={{
            required: true,
          }}
          mode="onBlur"
          name="currentNameOfGroom"
          register={register}
          setValue={setValue}
          defaultValue={nameOfGroom}
        ></RHFInput>
        <WeddingDateContainer exact={currentWeddingDateIsExact}>
          <Question>{i18n.t(k.QUESTION_WEDDING_DATE)}</Question>
          <FormControlLabel
            control={
              <StyledSwitch
                checked={currentWeddingDateIsExact}
                onChange={handleDateSwitch}
                value="currentWeddingDateIsExact"
              />
            }
            label={currentWeddingDateIsExact ? i18n.t(k.YES_WE_KNOW) : i18n.t(k.NO_WE_DONT_KNOW)}
          />
          <FadeOuterContainer>
            <FadeContainer visible={currentWeddingDateIsExact}>
              <DatePickerContainer>
                {!isMobile() && (
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      inputVariant="filled"
                      format="dd.MM.yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      value={currentWeddingDate}
                      onChange={handleDateChange}
                      invalidDateMessage={i18n.t(k.INVALID_DATE)}
                      invalidLabel={i18n.t(k.INVALID_DATE)}
                      maxDateMessage={i18n.t(k.INVALID_DATE)}
                      minDateMessage={i18n.t(k.DATE_MUST_BE_IN_FUTURE)}
                      autoComplete="off"
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                )}

                {isMobile() && (
                  <TextField
                    className="native-picker"
                    id="date"
                    label=""
                    variant="filled"
                    value={formatDate(currentWeddingDate)}
                    type="date"
                    onChange={handleNativeDateChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              </DatePickerContainer>
              {showError && <ErrorMessage>{i18n.t(k.SELECT_DATE)}</ErrorMessage>}
            </FadeContainer>
            <FadeContainer visible={!currentWeddingDateIsExact}>
              <SelectionContainer smallHeight>
                {getUpcomingFourSeasons().map(season => {
                  return (
                    <IconBox
                      active={currentSeason === season.name}
                      image={`/assets/seasons/${season.image}.jpg`}
                      text={season.name}
                      question="weddingSeason"
                      selectValue={season.name}
                      onSelect={onSelect}
                      key={season.name}
                      weddingStyle={weddingConfig.weddingStyle}
                      smallHeight
                    />
                  );
                })}
              </SelectionContainer>
              {showError && <StyledErrorMessage>{i18n.t(k.MAKE_SELECTION)}</StyledErrorMessage>}
            </FadeContainer>
          </FadeOuterContainer>
        </WeddingDateContainer>
        <CustomLogoContainer>
          <Question>{i18n.t(k.WANT_CUSTOM_LOGO)}</Question>
          <FormControlLabel
            control={
              <StyledSwitch checked={currentUseCustomLogo} onChange={handleLogoSwitch} value="currentUseCustomLogo" />
            }
            label={currentUseCustomLogo ? i18n.t(k.INDIVIDUAL_LOGO) : i18n.t(k.STANDARD_LOGO)}
          />
          {currentUseCustomLogo && (
            <ImageUploader imageUrl={currentCustomLogoUrl} setImageUrl={setCurrentCustomLogoUrl}></ImageUploader>
          )}
        </CustomLogoContainer>
        <Hint>
          {i18n.t(k.HINT_CHANGE_URL)}
          <a href="mailto:beeni@eurehochzeitonline.com">beeni@eurehochzeitonline.com</a>
          {i18n.t(k._DOT)}
        </Hint>
      </DialogContent>
      <DialogActions stopEditing={stopEditing}></DialogActions>
    </Dialog>
  );
};

export default EditingDialogBaseData;
