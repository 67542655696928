import i18n from 'i18next';
import k from './../../../i18n/keys';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useForm } from 'react-hook-form';
import { RHFInput } from 'react-hook-form-input';

import AcceptTermsCheckbox from '../Components/AcceptTermsCheckbox';
import ErrorMessage from '../Components/ErrorMessage';
import { ReactComponent as UploadingSvg } from '../../../_assets/svg/loading/uploading.svg';
import { ReactComponent as DesigningSvg } from '../../../_assets/svg/loading/designing.svg';
import { ReactComponent as SavingSvg } from '../../../_assets/svg/loading/saving.svg';
import { ReactComponent as FinishedSvg } from '../../../_assets/svg/loading/finished.svg';
import defaultStyles from '../../../Shared/WeddingStyles/DefaultStyles';
import adjustFontSize from '../../../Shared/WeddingStyles/FontAdjustment';
import breakpoints from '../../../Shared/breakpoints';
import { Checkbox } from '@material-ui/core';

const timeStep = 10;
const progressStep = 1;

const ProgressContainer = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${({ show }) => (show ? '1' : '0')};
  opacity: ${({ show }) => (show ? '1' : '0')};
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1);
  @media (max-width: ${breakpoints.xs}) {
    height: ${({ finished }) => (finished ? '70px' : '90px')};
  }
`;

const ProgressContainerTop = styled(ProgressContainer)`
  @media (max-height: ${breakpoints.mobileKeyboardHeight}) {
    display: ${({ show }) => (show ? 'flex' : 'none')};
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  min-width: 80px;
  min-height: 80px;
`;

const Percentage = styled.h3`
  position: absolute;
  margin: auto;
  font-size: ${({ weddingStyle }) => adjustFontSize(defaultStyles[weddingStyle].headerFont, '20px')};
`;

const ProgressExplanation = styled.div`
  position: absolute;
  top: 80px;
  width: 450px;
  max-width: 95vw;
  margin: 12px 0;
  font-weight: bold;
  text-align: ${({ finished }) => (finished ? 'left' : 'center')};
  margin-bottom: 0;
  z-index: ${({ showExplanation }) => (showExplanation ? '1' : '0')};
  opacity: ${({ showExplanation }) => (showExplanation ? '1' : '0')};
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1);
  transform: ${({ finished }) => (finished ? 'translate(0, -100px)' : 'none')};
  @media (max-height: ${breakpoints.mobileKeyboardHeight}) {
    top: 0;
  }
`;

const StyledIcon = styled.div`
  display: block;
  height: 60px;
  width: 60px;
  margin: 24px auto 12px auto;
  svg {
    fill: ${({ weddingStyle }) => defaultStyles[weddingStyle].colors.secondary.dark};
  }
`;

const HeartIcon = styled(StyledIcon)`
  animation: zoominoutsinglefeatured 1s infinite;
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.2, 1.2);
    }
    100% {
      transform: scale(1, 1);
    }
  }
`;

const StyledButton = styled(Button)`
  height: 63px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
  box-shadow: none;
`;

const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    border-top-right-radius: 0;
  }
  input {
    font-size: 16px !important;
    height: 26px;
    width: 300px;
    max-width: 90vw;
  }
`;

const HiddenCheckBox = styled(Checkbox)`
  display: none;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 6px;
  margin-bottom: 0;
`;

const StyledLinearProgress = styled(LinearProgress)`
  opacity: ${({ visible }) => (visible === 'true' ? '1' : '0')};
`;

const FormContainer = styled.form`
  display: flex;
  max-width: 95vw;
  label {
    top: -6px;
  }
  .MuiFormHelperText-contained.Mui-error {
    width: 320px;
  }
`;

const Register = ({ visible, weddingStyle, saveWedding, saveInProcess }) => {
  const [progress, setProgress] = useState(0);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const { handleSubmit, register, errors, setValue } = useForm({});
  const calculateProgress = () => progress + progressStep;

  const onSubmit = values => {
    saveWedding(values.username);
  };

  const changeAcceptance = accepted => {
    delete errors.termsAccepted;
    setTermsAccepted(accepted);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (visible && progress < 100) {
        setProgress(calculateProgress());
      }
    }, timeStep);
    return () => clearTimeout(timeout);
  });

  useEffect(() => {
    setProgress(0);
  }, [visible]);

  useEffect(() => {
    setValue('termsAccepted', termsAccepted);
  }, [setValue, termsAccepted]);

  return (
    <>
      <ProgressContainerTop show={progress < 100}>
        <StyledCircularProgress variant="determinate" value={progress} />
        <Percentage weddingStyle={weddingStyle}>{Math.round(progress)}%</Percentage>
      </ProgressContainerTop>
      <ProgressContainer show finished={progress >= 100}>
        <ProgressExplanation showExplanation={progress <= 30}>
          <StyledIcon weddingStyle={weddingStyle} className="icon">
            <UploadingSvg />
          </StyledIcon>
          <p>{i18n.t(k.PREPARING_1)}</p>
        </ProgressExplanation>
        <ProgressExplanation showExplanation={progress > 30 && progress <= 60}>
          <StyledIcon weddingStyle={weddingStyle} className="icon">
            <DesigningSvg />
          </StyledIcon>
          <p>{i18n.t(k.PREPARING_2)}</p>
        </ProgressExplanation>
        <ProgressExplanation showExplanation={progress > 60 && progress < 100}>
          <StyledIcon weddingStyle={weddingStyle} className="icon">
            <SavingSvg />
          </StyledIcon>
          <p>{i18n.t(k.PREPARING_3)}</p>
        </ProgressExplanation>
        <ProgressExplanation finished showExplanation={progress >= 100}>
          <HeartIcon weddingStyle={weddingStyle} className="icon">
            <FinishedSvg />
          </HeartIcon>
          <p>{i18n.t(k.FINISHED_ENTER_MAIL)}</p>
        </ProgressExplanation>
      </ProgressContainer>
      <AcceptTermsCheckbox show={progress >= 100} changeAcceptance={changeAcceptance}></AcceptTermsCheckbox>
      {errors.termsAccepted && !errors.username && <StyledErrorMessage>{i18n.t(k.ACCEPT_TERMS)}</StyledErrorMessage>}

      <ProgressContainer show={progress >= 100}>
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <RHFInput
            as={
              <StyledTextField
                type="email"
                variant="filled"
                error={!!errors.username}
                helperText={errors.username ? i18n.t(k.ENTER_VALID_MAIL) : ''}
                label={i18n.t(k.E_MAIL_ADDRESS)}
              />
            }
            rules={{
              // eslint-disable-next-line
              pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            }}
            mode="onBlur"
            name="username"
            register={register}
            setValue={setValue}
          ></RHFInput>
          <RHFInput
            as={<HiddenCheckBox checked={termsAccepted}></HiddenCheckBox>}
            mode="onChange"
            rules={{ required: true }}
            name="termsAccepted"
            register={register}
            value={termsAccepted}
          ></RHFInput>
          <StyledButton type="submit" variant="contained" disabled={saveInProcess}>
            {i18n.t(k.SAVE)}
          </StyledButton>
        </FormContainer>
      </ProgressContainer>
      <StyledLinearProgress visible={saveInProcess.toString()} />
    </>
  );
};

export default Register;
