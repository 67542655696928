import styled from 'styled-components';

import breakpoints from '../../../breakpoints';
import Element from './Element';

export default styled(Element)`
  bottom: -120px;
  transform: rotate(60deg);
  height: 500px;
  width: 500px;
  left: -140px;
  transform: ${({ visible }) => (visible ? 'rotate(66deg)' : 'rotate(66deg) translate3d(300px, 300px, 0)')};
  z-index: 1;
  @media (max-width: ${breakpoints.xs}), (max-height: ${breakpoints.xs}) {
    height: 500px;
    width: 300px;
    bottom: -200px;
    left: -150px;
  }
`;
